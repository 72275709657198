import { observer } from 'mobx-react-lite';
import { useEffect, useRef, useState } from 'react';
import { GenderTypes, INewBeneficiary } from '../../../../../../Models/App/EquityPlans/Beneficiaries';
import Button from '../../../../../../Shared/Components/Button/Button';
import DatePickerInput from '../../../../../../Shared/Components/Input/DatePickerInput';
import TextInput from '../../../../../../Shared/Components/Input/TextInput';
import Flex from '../../../../../../Shared/Components/Layout/Flex';
import Select from '../../../../../../Shared/Components/Select/Select';
import { InputRefs, InputValidationRef, useFormValidation } from '../../../../../../Shared/Hooks/useFormValidation';
import useModal from '../../../../../../Shared/Hooks/useModal';
import useRootStore from '../../../../../../Shared/Hooks/useRootStore';
import { commonValidators } from '../../../../../../Shared/ObjectValidator';
import { getUniqueId, isNullOrUndefined, isNumber, isObject } from '../../../../../../Shared/Utilities';
import useEsopModals from '../../../../useEsopModals';
import NewBeneficiaryStyles from './NewBeneficiaryDetails.style';
import Clickable from '../../../../../../Shared/Components/Clickable/Clickable';
import { useAppendState } from '../../../../../../Shared/Hooks/useAppendState';
import Title from '../../../../../../Shared/Components/Layout/Title';
import ExpandToggle from '../../../../../../Shared/Components/ExpandToggle';
import PhoneInput from '../../../../../../Shared/Components/Input/PhoneInput';
import { Fade } from '@mui/material';
import { ModalBodyProps } from '../../../../../../Shared/Components/Modal/types';
import classNames from 'classnames';
import useContact from '../../../../../../Shared/Hooks/useContact';
import CompanyUserAutoComplete from '../../../../../../Shared/Components/CompanyUserAutoComplete';
import { IContact, IContactCreate, IContactDetails } from '../../../../../../Models/API/Contact/contact';
import { NetworkStatusCode } from '../../../../../../Services/Axios';
import { genderOptions } from '../../../../../../Shared/StaticData/equityPlans';

interface newBenDetailsProps extends ModalBodyProps {
	showKeep?: boolean;
	onCancel?: () => unknown;
	onSave?: (id: number) => unknown;
	openBenModal?: boolean;
	isFromGrant?: boolean;
}

const NewBeneficiaryDetails = (props: newBenDetailsProps) => {
	const {
		equityPlansStore,
		companyStore,
		contactStore: { setEditContactId },
	} = useRootStore();

	const [sitesArr, setSitesArr] = useState<{ value: number; label: string }[]>();
	const [departmentsArr, setDepartmentsArr] = useState<{ value: number; label: string }[]>();
	const [subDepartmentsArr, setSubDepartmentsArr] = useState<{ value: number; label: string }[]>();
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [isValidating, setIsValidating] = useState<boolean>(false);
	// const [keepDialog, setKeepDialog] = useState<boolean>(true);
	const [isEmployeeExists, setIsEmployeeExists] = useState<boolean>(false);
	const employeeNumberRef = useRef<string>();
	const { showSuccessModal, showErrorModal, openBeneficiaryModal } = useEsopModals();
	const [beneficiary, setBeneficiary, onInputHandler] = useAppendState<INewBeneficiary>();

	const { showModal } = useModal();

	const [showMoreInfo, setShowMoreInfo] = useState(false);

	const { setContact, onCreateContactHandler, contact } = useContact({
		isRoleRequired: false,
		isEmailRequired: false,
		async onSuccess(contact) {
			onAddBeneficiaryHandler(contact.contactId);
		},
		onMerge(contact) {
			onAddBeneficiaryHandler(contact.contactId);
		},
	});

	useEffect(() => {
		setContact({
			firstName: beneficiary.firstName,
			lastName: beneficiary.lastName,
			email: beneficiary.businessEmail,
			contactDetails: {
				idNumber: beneficiary.id,
			},
			address: beneficiary.address,
		} as IContactCreate);
	}, [beneficiary]);

	const formSchema = {
		firstName: [
			commonValidators.required(),
			commonValidators.minLength(2),
			commonValidators.regex(/^[a-zA-Z0-9 ]+$/, 'Only letters, numbers or space allowed'),
		],
		lastName: [
			commonValidators.required(),
			commonValidators.minLength(2),
			commonValidators.regex(/^[a-zA-Z0-9 ]+$/, 'Only letters, numbers or space allowed'),
		],
		employeeNumber: [
			commonValidators.required(),
			commonValidators.regex(/^[a-zA-Z0-9 ]+$/, 'Only letters, numbers or space allowed'),
			commonValidators.customValidation(!isEmployeeExists, 'Employee number already exists'),
		],
		businessEmail: [
			(v: string | undefined) => {
				if (isNullOrUndefined(v)) return { isValid: true };
				return commonValidators.emailValidator(v);
			},
		],
	};

	const { formValidationState, validateForm, clearErrors, inputRefs, setFormValidationState } = useFormValidation({
		form: beneficiary,
		schema: formSchema,
		deps: [isEmployeeExists],
	});

	// const onInputHandler = (value: string | number, name: string) => {
	// 	equityPlansStore.newBeneficiary = {
	// 		...equityPlansStore.newBeneficiary,
	// 		[name]: value,
	// 	};
	// };

	// const generateCCD = (key: number,) => {
	//     const date = equityPlansStore.newBeneficiary.employmentStartDate ? equityPlansStore.newBeneficiary.employmentStartDate : new Date('1-1-1990')
	//     return {
	//         key,
	//         value: 'KKK',
	//         date
	//     }
	// }

	useEffect(() => {
		!isNullOrUndefined(equityPlansStore.companyData?.sites) &&
			setSitesArr(
				equityPlansStore.companyData?.sites.map((opt) => {
					return { value: opt.key, label: opt.value };
				})
			);
		!isNullOrUndefined(equityPlansStore.companyData?.departments) &&
			setDepartmentsArr(
				equityPlansStore.companyData?.departments.map((opt) => {
					return { value: opt.key, label: opt.value };
				})
			);
		!isNullOrUndefined(equityPlansStore.companyData?.subDepartments) &&
			setSubDepartmentsArr(
				equityPlansStore.companyData?.subDepartments.map((opt) => {
					return { value: opt.key, label: opt.value };
				})
			);

		return () => {
			setBeneficiary({} as INewBeneficiary);
		};
	}, []);

	// const [clicked, setClicked] = useState<number>()
	// const [lastClickInterval, setLastClickInterval] = useState<number>()

	const time = useRef(0);

	const onValidateUser = async () => {
		if (employeeNumberRef.current === beneficiary.employeeNumber || !/^[a-zA-Z0-9 ]+$/.test(beneficiary.employeeNumber || '')) {
			return;
		}

		setIsValidating(true);
		const { data } = await equityPlansStore.checkIfUserExist(beneficiary.employeeNumber);
		setIsEmployeeExists(data);
		setIsValidating(false);
		employeeNumberRef.current = undefined;
	};

	const onAddBeneficiaryHandler = async (sourceContactId: number) => {
		try {
			const isValidated = await validateForm();
			if (!isValidated) return;
			setIsLoading(true);
			const res = await equityPlansStore.AddBeneficiary({ ...beneficiary, sourceContactId });
			if (props.onSave && res.data?.beneficiaryId) {
				props.onSave(res.data?.beneficiaryId);
			}
			if (res.data?.beneficiaryId) {
				showSuccessModal('The beneficiary was added successfully');
				props.removeModal && props.removeModal();
			} else {
				showErrorModal(res);
			}
			clearErrors();
			// if (!data?.beneficiaryId) {
			// 	showErrorModal("Something went wrong,  Please Try Again");
			// 	return;
			// }
			// props.onSave?.(data.beneficiaryId);
			// if (!keepDialog) {
			// TODO: change clear modal
			// clearModals();
			// props.openBenModal && openBeneficiaryModal(res.data.beneficiaryId, true);
			props.removeModal?.();
			// }
		} catch (error) {
			console.log(error);
		} finally {
			setIsLoading(false);
		}
	};

	const onSuggestionSelected = async (contact: IContact) => {
		const res = await equityPlansStore.getBeneficiaryByContactId(contact.contactId);
		if (res.statusCode === NetworkStatusCode.UNPROCESSABLE_ENTITY) {
			showModal({
				type: 'confirm',
				title: 'Update Beneficiary',
				body: () => (
					<Flex direction="column" justify="center">
						<div>
							You’ve selected to add
							<span className="bold">
								{contact.isLegalEntity ? ` ${contact.organizationName} ` : ` ${contact.firstName} ${contact.lastName} `}
							</span>
							{contact.isLegalEntity ? 'which' : 'who'} already exists in the company records.
						</div>
						<div>Do you want to proceed and merge?</div>
					</Flex>
				),
				confirmButton: {
					label: 'Proceed',
				},
				onConfirm: () => {
					setBeneficiary((prevState) => ({
						...prevState,
						firstName: contact.firstName ?? '',
						lastName: contact.lastName ?? '',
						id: contact.contactDetails?.idNumber,
						businessEmail: contact.email,
						sourceContactId: contact.contactId,
					}));
				},
			});
		} else if (res.data) {
			showModal({
				type: 'confirm',
				title: 'Update Beneficiary',
				body: () => (
					<Flex direction="column" justify="center">
						<div>
							You’ve selected to add
							<span className="bold">
								{contact.isLegalEntity ? ` ${contact.organizationName} ` : ` ${contact.firstName} ${contact.lastName} `}
							</span>
							{contact.isLegalEntity ? 'which' : 'who'} already exists in the company records.
						</div>
						<div>Do you want to proceed and merge?</div>
					</Flex>
				),
				confirmButton: {
					label: 'Proceed',
				},
				onConfirm: () => {
					if (props.onSave && res.data!.beneficiaryId) {
						props.onSave(res.data!.beneficiaryId);
					} else {
						openBeneficiaryModal(res.data!.beneficiaryId);
					}
					props.removeModal?.();
				},
			});
		}
		// setEditContactId(contact.contactId);
	};

	return (
		<div className={classNames(NewBeneficiaryStyles, { 'no-pd': props.isFromGrant })}>
			{props.isFromGrant ? (
				<div className={`${NewBeneficiaryStyles}__grant-title`}>Beneficiary details</div>
			) : (
				<Title className={`${NewBeneficiaryStyles}__title`}>Add New Beneficiary</Title>
			)}
			<div className={`${NewBeneficiaryStyles}__form`}>
				<CompanyUserAutoComplete
					searchBy="firstName"
					label="account.firstName"
					onSuggestionSelected={onSuggestionSelected}
					required
					value={beneficiary.firstName}
					name="firstName"
					onChange={onInputHandler}
					error={formValidationState?.firstName?.message}
					ref={(el: InputValidationRef) => (inputRefs.firstName = el)}
				/>
				<CompanyUserAutoComplete
					searchBy="lastName"
					label="account.lastName"
					onSuggestionSelected={onSuggestionSelected}
					required
					value={beneficiary.lastName}
					name="lastName"
					onChange={onInputHandler}
					error={formValidationState?.lastName?.message}
					ref={(el: InputValidationRef) => (inputRefs.lastName = el)}
				/>
				<TextInput
					label="Employee number"
					qaid="NewBeneficiary.Input.EmployeeNumber"
					value={beneficiary.employeeNumber}
					name="employeeNumber"
					onChange={onInputHandler}
					onFocus={() => (employeeNumberRef.current = beneficiary.employeeNumber)}
					onBlur={onValidateUser}
					error={formValidationState?.employeeNumber?.message}
					ref={(el: InputValidationRef) => (inputRefs.employeeNumber = el)}
					required
				/>
				<CompanyUserAutoComplete
					searchBy="email"
					label="Business email"
					onSuggestionSelected={onSuggestionSelected}
					value={beneficiary.businessEmail}
					name="businessEmail"
					onChange={onInputHandler}
					error={formValidationState?.businessEmail?.message}
					ref={(el: InputValidationRef) => (inputRefs.businessEmail = el)}
				/>
			</div>
			<Clickable
				justify="start"
				margin="2rem 0 0 0"
				width="100%"
				qaid="NewBeneficiary.Button.MoreInfo"
				onClick={() => setShowMoreInfo(!showMoreInfo)}
				gap="1.2rem"
				className={`${NewBeneficiaryStyles}__more-info-btn`}
				position="relative"
			>
				Add more info (optional) <ExpandToggle isOpen={showMoreInfo} />
			</Clickable>

			<Fade in={showMoreInfo} unmountOnExit>
				<div className={`${NewBeneficiaryStyles}__form mt-5`}>
					<TextInput qaid="NewBeneficiary.Input.ID" label="ID" name="id" value={beneficiary.id} onChange={onInputHandler} />
					<PhoneInput
						label="Mobile"
						qaid="NewBeneficiary.Input.Mobile"
						value={beneficiary.mobilePhone}
						name="mobilePhone"
						onChange={(mobile: string, mobilePrefix?: string) => {
							setBeneficiary((prevData) => ({
								...prevData,
								mobilePhone: `${mobilePrefix} ${mobile}`,
							}));
							setContact((prevContact) => ({
								...prevContact,
								mobilePrefix: mobile ? mobilePrefix : undefined,
								mobile: mobile || undefined,
							}));
						}}
					/>
					<Select
						qaid="NewBeneficiary.Input.Gender"
						label="Gender"
						options={genderOptions}
						value={genderOptions.filter((gn) => gn.value === beneficiary.gender)[0]?.value}
						name="gender"
						onChange={onInputHandler}
					/>
					<Select
						label="Employee status"
						qaid="NewBeneficiary.Select.EmployeeStatus"
						options={[
							{ label: 'Employee', value: 0 },
							{ label: 'Ex. Employee', value: 1 },
							{ label: 'Other', value: 2 },
						]}
						height={10}
						value={beneficiary.employementStatus}
						name="employementStatus"
						onChange={onInputHandler}
					/>
					<Select
						label="Site"
						qaid="NewBeneficiary.Select.Site"
						value={sitesArr?.filter((st) => st.value === beneficiary.site)[0]?.value}
						name="site"
						onChange={onInputHandler}
						options={sitesArr}
					/>
					<Select
						label="Department"
						qaid="NewBeneficiary.Select.Department"
						value={departmentsArr?.filter((st) => st.value === beneficiary.departmentEmp)[0]?.value}
						name="departmentEmp"
						onChange={onInputHandler}
						options={departmentsArr}
					/>
					<Select
						label="Sub department"
						qaid="NewBeneficiary.Select.SubDepartment"
						value={subDepartmentsArr?.filter((st) => st.value === beneficiary.subDepartmentEmp)[0]?.value}
						name="subDepartmentEmp"
						onChange={onInputHandler}
						options={subDepartmentsArr}
					/>
					<TextInput qaid="NewBeneficiary.Input.Level" label="Level" name="level" value={beneficiary.level} onChange={onInputHandler} />
					<DatePickerInput
						label="Start date"
						qaid="NewBeneficiary.Input.StartDate"
						value={beneficiary.employmentStartDate}
						name="employmentStartDate"
						onChange={onInputHandler}
					/>

					<DatePickerInput
						qaid="NewBeneficiary.Input.EndDate"
						label="End date"
						value={beneficiary.employmentEndDate}
						name="employmentEndDate"
						onChange={onInputHandler}
					/>
					<TextInput qaid="NewBeneficiary.Input.address" label="Address" name="address" value={beneficiary.address} onChange={onInputHandler} />
				</div>
			</Fade>

			<Flex justify="between" margin="2rem 0 0 0">
				{/* {props.showKeep ? (
					<CheckBox
						qaid="NewBeneficiary.CheckBox.AddAdditionalBeneficiary"
						isChecked={keepDialog}
						onClick={(val) => setKeepDialog(val)}
						label="Add Additional Beneficiary"
					/>
				) : (
					<div></div>
				)} */}
				<Flex direction="row" justify="end" gap="1.2rem">
					{props.onCancel && <Button qaid="" label="Cancel" cancel onClick={props.onCancel} />}
					<Button
						label="Add"
						qaid="NewBeneficiary.Button.AddBeneficiary"
						disabled={isValidating}
						onClick={async () => {
							if (!validateForm()) return;

							if (beneficiary.sourceContactId) onAddBeneficiaryHandler(beneficiary.sourceContactId);
							else {
								const res = await onCreateContactHandler(contact, {
									allowMerge: true,
									isEmailUnique: true,
									isSearchByBoth: !!beneficiary.businessEmail,
								});
								if (isObject(res) && res.error) {
									setFormValidationState({ businessEmail: { isValid: false, message: res.error, level: 'error' } });
								}
							}
						}}
						isLoading={isLoading}
					/>
				</Flex>
			</Flex>
		</div>
	);
};
export default observer(NewBeneficiaryDetails);
