import { css } from "@emotion/css";
import appConfig from "../../../../../config/config";

const IsoTableStyles = css({
	label: "IsoTable",
	display: "flex",
	flexDirection: "column",
	flex: 1,
	width: '100%',

	"&__titleWrap": {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center'
	},

	"&__actionsWrap": {
		display: 'flex',
		alignItems: 'center',
		gap: '10px'
	},

	"&__table": {
		width: '100%',
		borderCollapse: 'separate',
		borderSpacing: '0px'
	},

	"&__headerWrap": {
		position: 'sticky',
		top: 0,
		backgroundColor: '#fff',
		zIndex: 1
	},

	"&__header": {
		backgroundColor: '#F0F1FF',
		padding: '10px',
		borderRadius: '0px !important',
		borderRight: '1px solid #D0D4E4',
		overflowWrap: 'break-word',
		whiteSpace: 'normal',
		maxWidth: '40rem',
		textAlign: 'center',
	},

	"&__tableData": {
		padding: '10px',
		textAlign: 'center'
	},

	"&__otherGrants": {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		gap: '5px'
	}

});

export default IsoTableStyles;
