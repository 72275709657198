import { css } from '@emotion/css';
import { IC_FUNNEL2, IC_FUNNEL2_PURPLE } from '../../../../../../Assets';
import Image from '../../../../../../Shared/Components/Image';
import { useEffect, useState } from 'react';
import appConfig from '../../../../../../config/config';
import classNames from 'classnames';
import { ClickAwayListener, Fade } from '@mui/material';
import { useAppendState } from '../../../../../../Shared/Hooks/useAppendState';
import { TextInput } from '../../../../../../Shared/Components';
import NumberInput from '../../../../../../Shared/Components/Input/NumberInput';
import useRootStore from '../../../../../../Shared/Hooks/useRootStore';
import Button from '../../../../../../Shared/Components/Button/Button';
import useGeneralModal from '../../../../../../Shared/Hooks/useGeneralModal';
import { InputValidationRef, useFormValidation } from '../../../../../../Shared/Hooks/useFormValidation';
import { commonValidators } from '../../../../../../Shared/ObjectValidator';
import { isNullOrUndefined } from '../../../../../../Shared/Utilities';
import { getMinIntervalValue } from './deps/helpers';
import { observer } from 'mobx-react-lite';
import { useSummary } from './deps/useSummary';

const Style = css({
	label: 'AnalysisSettings',
	position: 'relative',
	'&__btn': {
		// background: appConfig.style.colors.gradientPurpleVertical,
		padding: '0.75rem',
		borderRadius: 8,
		color: '#ffffff',
		display: 'flex',
		alignItems: 'center',
		gap: '0.8rem',
		cursor: 'pointer',
		userSelect: 'none',
		transition: 'all .1s',
		border: `1px solid ${appConfig.style.colors.cancel}`,
		height: '3.6rem',
		width: '3.6rem',
		'&.clicked': {
			border: `1px solid ${appConfig.style.colors.color1}`,
			background: appConfig.style.colors.purple,
			color: appConfig.style.colors.color1,
		},
	},
	'&__form': {
		position: 'absolute',
		top: 'calc(100% + 1rem)',
		right: 0,
		width: '52.7rem',
		borderRadius: 8,
		padding: '2.5rem 3rem 6rem',
		background: '#ffffff',
		boxShadow: `3px 3px 3px 0px ${appConfig.style.colors.boxShadowColor1}`,
		border: `1px solid ${appConfig.style.colors.background1}`,
		display: 'grid',
		gridTemplateColumns: 'repeat(3, 1fr)',
		gridGap: '1.6rem',
		gridRowGap: '2.4rem',
		'.a': {
			fontWeight: 700,
			gridArea: '1 / 1 / 1 / 4',
		},
		'.b': {
			gridArea: '2 / 1 / 2 / 3',
		},
		'.e, .c': {
			alignSelf: 'flex-end',
		},
	},
});

type AnalysisForm = {
	exitValue: number | undefined;
	from: number | undefined;
	to: number | undefined;
	every: number | undefined;
};

type Props = {
	exitValue: number;
};

const AnalysisSettings = ({ exitValue }: Props) => {
	const { waterfallStore, companyStore } = useRootStore();
	const [form, setForm, onInputHandler] = useAppendState<AnalysisForm>({
		exitValue,
		from: undefined,
		to: undefined,
		every: undefined,
	});
	const [isOpen, setIsOpen] = useState<boolean>(false);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const { showErrorModal } = useGeneralModal();
	const { formValidationState, validateForm, inputRefs } = useFormValidation({
		form,
		schema: {
			exitValue: [
				commonValidators.required(),
				(value) => {
					if (value === undefined || value === 0) {
						return {
							isValid: true,
						};
					}

					if (!form.from || !form.to) {
						return {
							isValid: true,
						};
					}

					const isValid = value <= form.to;
					return {
						isValid,
						message: isValid ? '' : 'Simulated exit value should be within simulation range',
					};
				},
			],
			from: [
				(value) => {
					if (isNullOrUndefined(value)) {
						return {
							isValid: true,
						};
					}
					const isValid = value <= exitValue && value > 0;
					return {
						isValid,
						message: isValid ? '' : 'Value must be lower than or equal to exit value and great than 0',
					};
				},
			],
			to: [
				(value) => {
					if (isNullOrUndefined(value)) {
						return {
							isValid: true,
						};
					}
					const isValid = value >= exitValue && value > 0;
					return {
						isValid,
						message: isValid ? '' : 'Value must be lower than or equal to exit value and great than 0',
					};
				},
			],
			every: [
				(value) => {
					if (isNullOrUndefined(value)) {
						return {
							isValid: true,
						};
					}
					const minIntervalValue = getMinIntervalValue(form.from ?? 0, form.to ?? 0);
					if (value < minIntervalValue) {
						return {
							isValid: false,
							message: `Value must be equal to or higher than ${minIntervalValue} ($M)`,
						};
					}

					const maxIntervalValue = (form.to ?? 0) - (form.from ?? 0);
					if (value > maxIntervalValue) {
						return {
							isValid: false,
							message: `Value must be equal to or lower than ${maxIntervalValue}`,
						};
					}

					return {
						isValid: true,
					};
				},
			],
		},
	});
	const { setIsTimeout, handleSummaryStatus, status } = useSummary({
		onGetSummary: () => waterfallStore.getWaterfallSummary(),
		onGetSummaryStatus: async () => {
			const res = await waterfallStore.getSummaryStatus();
			return res.data;
		},
		onSetSummary: () => waterfallStore.setSummary(null),
		onRunSummary: async () => {
			const payload = {
				waterfallId: waterfallStore.waterfallId,
				companyId: companyStore.companyId,
				simulation: form,
			};
			const res = await waterfallStore.runSummary(payload, true);
			return res.data;
		},
		autoStart: false,
	});

	useEffect(() => {
		setForm((prevState) => ({ ...prevState, exitValue }));
	}, [exitValue]);

	useEffect(() => {
		return () => {
			setForm({
				exitValue,
				from: undefined,
				to: undefined,
				every: undefined,
			});
		};
	}, []);

	useEffect(() => {
		// Set default values for exit simulation form:
		if (waterfallStore.summaryData) {
			const simulationData = waterfallStore.summaryData?.simulation;
			const exitValue = simulationData?.exitValue;
			//  || DEFAULT_EXIT_VALUE;
			const every = simulationData?.every;

			setForm({
				exitValue: waterfallStore.summaryData.businessValue || waterfallStore.scenariosForm?.exitValue || exitValue,
				from: simulationData?.from,
				to: simulationData?.to,
				every,
			});
		}
	}, [waterfallStore.summaryData]);

	const onEvaluateHandler = async (simulation: AnalysisForm) => {
		const isFormValid = validateForm();
		if (!isFormValid) return;
		setIsLoading(true);

		const payload = {
			waterfallId: waterfallStore.waterfallId,
			companyId: companyStore.companyId,
			simulation,
		};

		// const res = await waterfallStore.getSummary(payload);
		waterfallStore
			.runSummary(payload, true)
			.then((res) => {
				if (!res.isSuccess) {
					showErrorModal(res.errorMessage);
				}
			})
			.finally(() => {
				setIsLoading(false);
				setIsOpen(false);
			});
		handleSummaryStatus();

		// waterfallStore.checkAndGetSummary(payload);
	};

	return (
		<ClickAwayListener onClickAway={() => setIsOpen(false)}>
			<div className={Style}>
				<Image
					className={classNames(`${Style}__btn`, { clicked: isOpen })}
					onClick={() => setIsOpen(!isOpen)}
					src={isOpen ? IC_FUNNEL2_PURPLE : IC_FUNNEL2}
					data-qaid="AnalysisSettings.Button.Toggle"
				/>
				<Fade in={isOpen} unmountOnExit>
					<div className={`${Style}__form`}>
						<span className="a">Sensitivity Analysis Settings</span>
						<NumberInput
							qaid="AnalysisSettings.Input.ExitValue"
							label={`Company Exit Value ($M)`}
							value={form.exitValue}
							name="exitValue"
							number="float"
							onChange={onInputHandler}
							containerClassName="b"
							placeholder="Exit value"
							error={formValidationState?.exitValue?.message}
							ref={(el: InputValidationRef) => (inputRefs.exitValue = el)}
						/>
						<Button
							qaid="AnalysisSettings.Button.ReEvaluate"
							label="Re-evaluate"
							className="c"
							onClick={() => onEvaluateHandler(form)}
							isLoading={isLoading}
						/>
						<NumberInput
							qaid="AnalysisSettings.Input.From"
							label="Range"
							value={form.from}
							name="from"
							number="float"
							onChange={onInputHandler}
							containerClassName="d"
							placeholder={`from ($M)`}
							error={formValidationState?.from?.message}
							ref={(el: InputValidationRef) => (inputRefs.from = el)}
						/>
						<NumberInput
							qaid="AnalysisSettings.Input.To"
							value={form.to}
							name="to"
							number="float"
							onChange={onInputHandler}
							containerClassName="e"
							placeholder={`to ($M)`}
							error={formValidationState?.to?.message}
							ref={(el: InputValidationRef) => (inputRefs.to = el)}
						/>
						<NumberInput
							qaid="AnalysisSettings.Input.Intervals"
							label="Intervals"
							value={form.every}
							name="every"
							number="float"
							onChange={onInputHandler}
							containerClassName="f"
							placeholder={`$M`}
							error={formValidationState?.every?.message}
							ref={(el: InputValidationRef) => (inputRefs.every = el)}
						/>
					</div>
				</Fade>
			</div>
		</ClickAwayListener>
	);
};

export default observer(AnalysisSettings);
