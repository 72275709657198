import { forwardRef, useImperativeHandle } from "react";

import Flex from "../../../../../../Shared/Components/Layout/Flex";
import { IMG_OPTION_REG_INTRO } from "../../../../../../Assets";
import Button from "../../../../../../Shared/Components/Button/Button";
import { MultiStepElementProps, ForwardedRef } from "../../../../../../Shared/Hooks/useMultiStepForm";

import IntroStyle from "./Intro.Style";
import useRootStore from "../../../../../../Shared/Hooks/useRootStore";
import Image from '../../../../../../Shared/Components/Image';

const Intro = forwardRef<ForwardedRef, MultiStepElementProps>(({ onNextStep }, forwardedRef) => {
	const { equityPlansStore } = useRootStore();

	useImperativeHandle(forwardedRef, () => ({
		async onValidate() {
			return true;
		},
	}));

	const onNextStepHandler = async () => {
		const res = await equityPlansStore.CreateNewCompany();

		if (!res) throw new Error('Create Company Faild');

		onNextStep?.();
	};

	return (
		<div className={IntroStyle}>
			<Image width="13.5rem" src={IMG_OPTION_REG_INTRO} alt="intro step" />
			<span className="title">
				Start with Equity Plans
				<br /> general settings
			</span>

			<Flex className="button">
				<Button qaid="Registration.Button.NewCompany" onClick={onNextStepHandler}>
					Continue
				</Button>
			</Flex>
		</div>
	);
});

export default Intro;
