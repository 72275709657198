import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import Flex from '../../../../Shared/Components/Layout/Flex';
import CustomSelect from '../../../../Shared/Components/Select/CustomSelect';
import appConfig from '../../../../config/config';
import MainMenuStyle from './index.style';
import { ClickAwayListener, Collapse, Fade } from '@mui/material';
import ProjectsList from '../ProjectsList';

const MainMenu = () => {
	const [showVersionsDialog, setShowVersionsDialog] = useState<boolean>(true);

	const handleToggleDialog = (event: React.MouseEvent) => {
		event.stopPropagation();
		setShowVersionsDialog((prev) => !prev);
	};

	const handleCloseDialog = () => setShowVersionsDialog(false);

	return (
		<div className={`${MainMenuStyle}__menu`}>
			<Flex className={`${MainMenuStyle}__menu-side-a`} align="center" justify="start" gap="2.6rem">
				<CustomSelect
					qaid="CapTable.Select.Version"
					onClick={handleToggleDialog}
					className={`${MainMenuStyle}__version-select`}
					isOpen={showVersionsDialog}
				>
					<Flex flex={0} align="center" justify="start" gap="0.4rem">
						Select 409A project
					</Flex>
				</CustomSelect>
				<ClickAwayListener onClickAway={handleCloseDialog}>
					<div className={`${MainMenuStyle}__projects-container`}>{showVersionsDialog && <ProjectsList />}</div>
				</ClickAwayListener>
			</Flex>
		</div>
	);
};

export default observer(MainMenu);
