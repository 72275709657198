import { DataCollectionFieldTypeEnum } from '../../../Models/API/DataCollection/comment-response';
import { DataCollectionProjectPreview } from '../../../Models/API/DataCollection/project-preview';
import useModal from '../../../Shared/Hooks/useModal';
import useRootStore from '../../../Shared/Hooks/useRootStore';
import DocumentModal from '../../Valution/Components/ProjectsList/DocumentModal';
import ProjectFilePreview from '../Components/ProjectFilePreview';
import QuestionHistory from '../Components/QuestionHistory';

export const useDataCollectionModal = () => {
	const { showModal } = useModal();
	const { dataCollectionStore } = useRootStore();

	const onOpenDocument = (path: string, valuationProjectId: string) => {
		dataCollectionStore.getDataCollection(valuationProjectId);
		if (!path.startsWith('http')) return showModal({ type: 'error', title: 'Error', body: 'Unable to open document, please try again later.' });

		showModal({
			body: <DocumentModal path={path} />,
			maximize: true,
			background: 'transparent',
			showClose: false,
		});
	};

	const onOpenGeneralChat = (valuationProjectId: string) => {
		dataCollectionStore.resetValuationProject();
		dataCollectionStore.getDataCollection(valuationProjectId);
		showModal({
			body: <QuestionHistory fieldType={DataCollectionFieldTypeEnum.General} />,
			isFrameless: true,
			width: '70rem',
			height: '70dvh',
			allowScrolling: false,
		});
	};

	const onFilePreviewHandler = (project: DataCollectionProjectPreview, reportId?: string, onClose?: () => void) => {
		showModal({
			body: <ProjectFilePreview project={project} reportId={reportId} />,
			isFrameless: true,
			width: '100%',
			maxWidth: '130rem',
			qaid: 'DataCollection.Modal.ReportPreview',
			onModalClose: onClose,
		});
	};

	return {
		onFilePreviewHandler,
		onOpenGeneralChat,
		onOpenDocument,
	};
};
