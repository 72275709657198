import { observer } from 'mobx-react-lite';
import { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { IC_CAPTABLE_LOGO, IC_VALUATION_LOGO } from '../../Assets';
import { Routes } from '../../Routes';
import Button from '../../Shared/Components/Button/Button';
import useQueryParams from '../../Shared/Hooks/useQueryParams';
import useRootStore from '../../Shared/Hooks/useRootStore';
import { WaterfallIndexStyle } from '../Waterfall/index.Style';
import MainMenu from './Components/MainMenu';
import { useDataCollectionModal } from './helpers/useDataCollectionModal';
import { DataCollectionPermissionsEnum, hasProjectPermissions } from './helpers/utils';
import { DataCollectionWelcomeProps } from '../../Shared/Components/GetStartedPage/config';
import GetStartedPage from '../../Shared/Components/GetStartedPage';
import { css } from '@emotion/css';
import appConfig from '../../config/config';
import ProjectOverview from './Components/ProjectOverview';
import useModal from '../../Shared/Hooks/useModal';
import Image from '../../Shared/Components/Image';
import usePermissions from '../../Shared/Hooks/usePermissions';
import useGeneralModal from '../../Shared/Hooks/useGeneralModal';

const DataCollection = () => {
	const params = useQueryParams();
	const history = useHistory();
	const {
		dataCollectionStore,
		capTableStore,
		companyStore: { companyId },
		auth,
		paymentStore,
	} = useRootStore();
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const { onFilePreviewHandler } = useDataCollectionModal();
	const [showVersionsDialog, setShowVersionsDialog] = useState<boolean>(false);
	const [page, setPage] = useState<number>(0);
	const { showModal } = useModal();
	const { permissions } = usePermissions(auth.permissions, paymentStore.currentPlan);
	const { onUpgradePlan } = useGeneralModal();

	useEffect(() => {
		(async () => {
			if (!permissions.dataCollection.included) return;
			const res = await dataCollectionStore.getProjects();
			setShowVersionsDialog(!!res.data?.data?.length && res.data?.data.length > 0);
		})();
	}, [companyId, permissions]);

	useEffect(() => {
		if (!params.preview) return;
		const project = dataCollectionStore.projects?.find((proj) => proj.valuationProjectId === params.preview);
		if (project === undefined) return;

		onFilePreviewHandler(project, params.reportId, () => history.replace(Routes.dataCollection.index));
	}, [params, dataCollectionStore.projects]);

	const onOrderHandler = async () => {
		setIsLoading(true);

		// const capTableRes = await capTableStore.loadUserProjects();
		// const isProjectExists = !!capTableRes.data.projectsUserList?.length;
		const capTableRes = await capTableStore.getProjectsCount();
		const isProjectExists = !!capTableRes.data?.drafts || !!capTableRes.data?.published;

		if (!isProjectExists) {
			showModal({
				body: () => (
					<div className="flex flex-column align-center gap-3 text-center" style={{ paddingInline: '4rem' }}>
						<Image src={IC_CAPTABLE_LOGO} width="20.6rem" />
						<div className="flex flex-column">
							<span className="bold mb-3">It seems your company cap table is not yet onboarded on altshare platform.</span>
							<span>
								To ensure an efficient 409A valuation process, please continue to our <span className="semi-bold">Cap Table</span> product and
								import your company cap table. It should only take a few minutes. Once completed, you can back & proceed with your 409A request.
							</span>
						</div>
						<Button qaid="" label="Continue to Cap Table" onClick={() => history.push(Routes.capTable.index)} />
					</div>
				),
				width: '80rem',
				onModalClose: () => setIsLoading(false),
			});
			return;
		}

		if (!permissions.dataCollection.included) {
			setIsLoading(false);
			return onUpgradePlan();
		}

		const res = await dataCollectionStore.createProject();
		if (!res.data?.data.valuationProjectId) return setIsLoading(false); 

		const dcRes = await dataCollectionStore.createDataCollection({
			valuationProjectId: res.data.data.valuationProjectId,
		});
		setIsLoading(false);
		if (!dcRes.data?.data.dataCollectionId) return;

		dataCollectionStore.valuationProjectId = res.data.data.valuationProjectId;
		history.push(`${Routes.dataCollection.form}/${dcRes.data.data.dataCollectionId}/0`);
	};

	const WelcomePage = useMemo(() => {
		return (
			<GetStartedPage
				{...DataCollectionWelcomeProps}
				className={css({
					background: 'linear-gradient(0deg,#9ca7ff -23.09%,#f0f1ff 61.7%,hsla(0,0%,100%,0) 118.58%)',
					paddingTop: 0,
					paddingBottom: 0,
					animation: 'fadeIn .5s ease-out',
					'div[data-el-type="info-container"]': {
						justifyContent: 'flex-start',
						paddingTop: '8rem',
						paddingBottom: '14rem',
					},
					'img[data-el-type="img-logo"]': {
						width: '7.5rem',
					},
					'span[data-el-type="title"]': {
						fontSize: '2.5rem',
						marginTop: '2rem',
						lineHeight: 1.3,
						letterSpacing: '0.1rem',
						color: appConfig.style.colors.text1,
					},
					'div[data-el-type="benefits"]': {
						marginBottom: '4rem',
						marginTop: '4rem',
						maxWidth: '72rem',
					},
					'button[data-el-type="action"]': {
						fontSize: '1.6rem',
						fontWeight: 500,
						width: '23.3rem',
						height: '5rem',
						letterSpacing: '0.32px',
					},
					'div[data-el-type="actions"]': {
						marginTop: 'auto',
					},
					...(showVersionsDialog
						? {
								'* >:not(div[data-el-type="actions"])': {
									filter: 'blur(3px)',
									transition: 'all .1s',
									opacity: 0.8,
								},
								'div[data-el-type="actions"] *': {
									filter: 'none !important',
								},
						  }
						: {}),
				})}
				action={{
					qaid: 'DataCollection.Button.Start409A',
					label: 'Start 409A request',
					onClick: () => setPage(1),
					isLoading,
				}}
			/>
		);
	}, [showVersionsDialog, isLoading, onOrderHandler]);

	const PageComponent = useMemo(() => {
		switch (page) {
			case 1:
				return (
					<ProjectOverview
						onBack={() => {
							setPage(0);
							setTimeout(() => {
								setShowVersionsDialog(!!dataCollectionStore.projects?.length && dataCollectionStore.projects.length > 1);
							}, 500);
						}}
						onNext={onOrderHandler}
						isLoading={isLoading}
					/>
				);
			case 0:
			default:
				return WelcomePage;
		}
	}, [WelcomePage]);

	return (
		<section>
			<MainMenu setShowVersionsDialog={setShowVersionsDialog} showVersionsDialog={showVersionsDialog} />
			{PageComponent}
		</section>
	);
};

export default observer(DataCollection);
