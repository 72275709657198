import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Routes } from '../../Routes';
import Button from '../../Shared/Components/Button/Button';
import EquityDashboard from './Sections/Dashboard/EquityDashboard';
import EquityEntitySelector from './Sections/Dashboard/EquityEntitySelector';
import useRootStore from '../../Shared/Hooks/useRootStore';
import useEsopModals from './useEsopModals';
// import MainMenu from "./MainMenu/MainMenu";
import Spinner from '../../Shared/Components/Spinner/Spinner';
import { CompanyRegistrationEnum } from './Sections/Registration/Registration';
import { toJS } from 'mobx';
import { isNullOrUndefined } from '../../Shared/Utilities';
import MainMenu from '../../Shared/Components/MainMenu/MainMenu';
import { MenuActionType } from '../../Shared/Components/MainMenu/ActionsMenu/ActionsMenu';
import { IC_EDIT2 } from '../../Assets';
import { Product } from '../../Models/API/enums';
import useTrusteeModals from '../TrusteeEquity/useTrusteeModals';
import GetStartedPage from '../../Shared/Components/GetStartedPage';
import { EquityPlansWelcomeProps } from '../../Shared/Components/GetStartedPage/config';
import { css } from '@emotion/css';
import appConfig from '../../config/config';

export enum EquityEntities {
	Beneficiaries,
	Grants,
	Orders,
	Cancellations,
}

const Index = () => {
	const { equityPlansStore, auth, companyStore, trusteeStore } = useRootStore();
	const { onRegistrationHandler } = useEsopModals();
	const { showPivotalRegistration } = useTrusteeModals();

	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [isRegisting, setIsRegistering] = useState<boolean>(false);
	const params = useParams<{ entity: string | undefined }>();
	const history = useHistory();

	useEffect(() => {
		fetchData();
		return () => setIsLoading(false);
	}, []);

	const onGetStartedHandler = () => {
		if (isLoading || equityPlansStore.isRegistrationCompleted || isRegisting || companyStore.company.pivotalEquityPlans) return;
		let step = -1;

		const { companyData } = equityPlansStore;

		if (!companyData) {
			step = CompanyRegistrationEnum.intro;
		} else if (!companyData.plans?.length) {
			step = CompanyRegistrationEnum.newEquityPlan;
		} else if (!companyData.vestings.some((vesting) => vesting.active)) {
			step = CompanyRegistrationEnum.vestingScheduleTemplates;
		} else if (!companyData.taxTracks?.length) {
			step = CompanyRegistrationEnum.companyData;
		}

		if (!auth.authToken) return;

		equityPlansStore.isRegistrationCompleted = false;
		setIsRegistering(true);
		onRegistrationHandler(step, () => {
			setIsRegistering(false);
			equityPlansStore.isRegistrationCompleted && fetchData();
		});
	};

	// useEffect(() => {
	// 	if (isRegisting) return;

	// 	fetchData();
	// }, [isRegisting, equityPlansStore.isRegistrationCompleted]);

	const fetchData = async () => {
		setIsLoading(true);
		await Promise.all([
			equityPlansStore.getEquitySettings(),
			equityPlansStore.GetGrantsForCompany(),
			equityPlansStore.GetBeneficiariesDashboardData(),
			// equityPlansStore.GetDashboardOrders(),
		]);
		setIsLoading(false);
	};

	useEffect(() => {
		if (!trusteeStore.pivotalRegistration) return;

		// TODO: Add multiple times pop-up
		if (trusteeStore.pivotalRegistrationOpen) {
			// console.log('from ESOP, already open')

			return;
		}
		// console.log('from ESOP')
		showPivotalRegistration(true);
		//trusteeStore.setRegistration = false
	}, [trusteeStore.pivotalRegistration]);

	return (
		<section className="page--equity dashboard default">
			{isLoading ? (
				<Spinner center incorporated />
			) : equityPlansStore.isRegistrationCompleted || companyStore.company.pivotalEquityPlans ? (
				<>
					<MainMenu product={companyStore.company.pivotalEquityPlans ? Product.TrusteeEquity : Product.EquityPlans} />
					<EquityEntitySelector />
				</>
			) : (
				<GetStartedPage
					{...EquityPlansWelcomeProps}
					className={css({
						background: 'linear-gradient(0deg,#9ca7ff -23.09%,#f0f1ff 61.7%,hsla(0,0%,100%,0) 118.58%)',
						height: '100%',
						animation: 'fadeIn .5s ease-out',
						'button[data-el-type="action"]': {
							fontSize: '1.6rem',
							fontWeight: 500,
							width: '23.3rem',
							height: '5rem',
							letterSpacing: '0.32px',
						},
					})}
					action={{
						qaid: 'EquityPlans.Button.GetStarted',
						label: 'Get Started',
						onClick: onGetStartedHandler,
						isLoading,
					}}
				/>
			)}
		</section>
	);
};
export default Index;

// const createNewCompanyHandler = async () => {
// const company = await equityPlansStore.CreateNewCompany();
// if (company) {
// 	setIsCompanyRegistered(true);
// } else {
// onRegistrationHandler();
// }
// };

// useEffect(() => {
// 	params.entity && setEquityEntity(+params.entity);
// }, [params]);

// const checkEquityCompany = async () => {
// 	await equityPlansStore.GetEquitySettings();
// 	const isFullyRegistered = !!(
// 		equityPlansStore.companyData?.plans?.length &&
// 		equityPlansStore.companyData.vestings?.length &&
// 		equityPlansStore.companyData.taxTracks?.length
// 	);
// 	setIsCompanyRegistered(isFullyRegistered);
// 	// if (equityPlansStore.companyData) {
// 	// 	setIsCompanyRegistered(() => true);
// 	// } else {
// 	// 	setIsCompanyRegistered(() => false);
// 	// }
// };

// useEffect(() => {
// 	isCompanyRegistered && equityPlansStore.GetEquitySettings();
// }, [isCompanyRegistered]);
