import { css } from "@emotion/css";
import { breakpoints } from "../../../config/style/theme";

export const CheckoutStyle = css({
	label: 'Checkout',
	display: 'flex',
	flexDirection: 'row',
	flex: 1,
	gap: '6.2rem',
	padding: '4rem 10rem',
	backgroundColor: '#FFF',
	boxShadow: '3px 3px 3px 0px rgba(201, 202, 208, 0.40)',

	'&.noOverflow': {
		overflow: 'hidden',
	},

	'.webTitle': {
		width: '100%',
		fontWeight: '500',
		color: '#4D4D4D',
		fontSize: '2.5rem',
		marginBottom: '-1rem',
	},

	'.mobileTitle': {
		display: 'none',
	},

	'.selectedPlanHeader': {
		display: 'none',
	},

	[`@media screen and (max-width: ${breakpoints.lg})`]: {
		padding: '4rem',
	},

	[`@media screen and (max-width: ${breakpoints.md})`]: {
		flexDirection: 'column',
		padding: '2rem',
		gap: '1.9rem',
		overflowY: 'auto',
		overflowX: 'hidden',
		backgroundColor: '#F5F5FD',

		'.webTitle': {
			display: 'none',
		},

		'.mobileTitle': {
			display: 'block',
			backgroundColor: '#FFF',
			margin: '-2rem',
			padding: '2rem 0',
			textAlign: 'center',
			fontWeight: 'bold',
		},

		'.goBackButton': {
			margin: '2rem 0 6.3rem 0',
			width: 'fit-content',
		},

		'.selectedPlanHeader': {
			positoin: 'relative',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'start',
			flex: 0,
			gap: '1.1rem',

			'&__logo': {
				height: '4.4rem',
				width: 'auto',
			},

			'.rightSide': {
				flex: '0',
				flexDirection: 'column',
				justifyContent: 'start',
				alignItems: 'start',

				'&__title': {
					fontSize: '1.8rem',
					fontWeight: 'bold',
					color: '#8B96E9',
				},

				'&__subTitle': {
					fontSize: '1.2rem',
				},

				'.priceContainer': {
					display: 'flex',
					justifyContent: 'start',
					flex: 0,

					'&__price': {
						fontSize: '1.8rem',
					},

					'&__length': {
						fontSize: '1.8rem',
						color: '#4D4D4D',
						whiteSpace: 'nowrap',
					},
				},
			},
		},
	},
});
