import { LoginRequest } from '../Models/API/Auth/login-request';
import { ILoginResponse, RegResponse } from '../Models/API/Auth';
import { ICreateAccount, IRegResponse, IUserRegistration, RegUser, resendOTPRequest } from '../Models/App/Auth/RegUser';

import { CompanyRegistration } from '../Models/API/Auth/company-registration';

import { BaseService } from './BaseService';
import AppUser, { CurrentUserInfo, ILoginPivotal, IOtpLoginPivotal, IPivotalTokenCompanies, IUserLogin } from '../Models/App/AppUserModel';
import Currency from '../Models/API/All/currency';
import { UserInitialRegistration } from '../Models/App/Auth/IUserInitialRegistration';
import { encrypt, encryptAes } from '../Shared/Utilities';
import { UserPermissions } from '../Models/API/UsersAndPermissions/user-permissions-info';
import { HigherLevelResponse, HighLevelErrorResponse, userApi } from './Axios';
import { ServerResponseApi } from '../Models/API/All/server-response';

export default class AuthService extends BaseService {
	//http = httpClient();
	endPoints = {
		UserRegistration: 'Registration/cfo',
		reSendVerificationMail: 'Verification/re-verification',
		ValidateRegistrationOtp: 'otp/cfo',
		// CompanyRegistration: "Registration/CompanyRegistration",
		CompanyRegistration: 'Dashboard/CreateNewCompany',
		CompanyCreation: 'Dashboard/CreateNewCompany',
		Login: 'login',
		PivotalLogin: 'Login',
		PivotalLoginOtp: 'Otp/validate',
		ResendOTP: 'otp/resend',
		ValidateLoginOtp: 'otp/cfo',
		// GetRelatedCompaniesAndUserInfo: "Dashboard/GetRelatedCompaniesAndUserInfo",
		GetCompanyProfileData: 'Company/GetCompanyProfileData',
		UpdateCompanyProfileData: 'Company/UpdateCompanyProfileData',
		ResetPassword: 'reset-password/forgot',
		ValidateOTPForREsetPassword: 'reset-password',
		resetPasswordValidate: '/reset-password',
		UsersPermission: (companyId: number) => `userPermission/${companyId}`,
		UpdateUserRole: '/user',
		GetUserByUniq: (uniqueKey: string) => `/user/${uniqueKey}`,
		validateEmpFirstReg: (uniqueKey: string) => `/user/invite-emp/${uniqueKey}`,
		Invite: 'user/invite',

		logout: '/logout',
	};

	async getCurrentUserInfo() {
		return userApi.get<CurrentUserInfo[]>('/contact/currentUser');
	}

	async userCreateAccountFirstPhase(user: ICreateAccount) {
		try {
			const response = await this.httpUserManagement.post<IRegResponse>(this.endPoints.UserRegistration, user);
			return {
				token: response.headers.authorization,
				data: response.data,
				isSuccess: true,
			};
		} catch (error) {
			return {
				error: 'Error account registration',
			};
		}
	}

	async completeInvitation(user: IUserRegistration) {
		try {
			const response = await this.httpUserManagement.put<Partial<AppUser>>(this.endPoints.Invite, {
				password: encryptAes(user.password!),
				uniqueKey: user.uniqueKey,
				isTermsOfUse: user.isTermsOfUse,
				isMarketingSubscription: user.isMarketingSubscription,
				privateEmail: user.privateEmail,
			} as IUserRegistration);
			return {
				token: response.headers.authorization,
				data: response.data,
				isSuccess: true,
			};
		} catch (error) {
			return {
				error: 'Error account registration',
				isSuccess: false,
			};
		}
	}

	async getUserDataByUniqkey(uniqueKey: string) {
		try {
			const response = await this.httpUserManagement.get<Partial<AppUser>>(this.endPoints.GetUserByUniq(uniqueKey));
			return {
				token: response.headers.authorization,
				data: response.data,
				isSuccess: true,
			};
		} catch (error) {
			return {
				error: 'Error account registration',
				isSuccess: false,
			};
		}
	}

	async validateEmpFirstReg(uniqueKey: string) {
		try {
			const response = await this.httpUserManagement.put<Partial<AppUser>>(this.endPoints.validateEmpFirstReg(uniqueKey));
			return {
				token: response.headers.authorization,
				data: response.data,
				isSuccess: true,
			};
		} catch (error) {
			return {
				error: 'Error account registration',
				isSuccess: false,
			};
		}
	}

	async userCreateAccountSecondPhase(user: IUserRegistration) {
		try {
			const response = await this.httpUserManagement.put<Partial<AppUser>>(this.endPoints.UserRegistration, user);
			return {
				token: response.headers.authorization,
				data: response.data,
			};
		} catch (error) {
			return {
				error: 'Error account setup',
			};
		}
	}

	async reSendVerificationMail(reCaptchaToken: string) {
		return await this.safeExecuteAsync(async () => {
			return await (
				await this.httpOrder.post<any>(this.endPoints.reSendVerificationMail, {
					reCaptchaToken,
				})
			).data;
		});
	}

	async userRegistration(user: IUserRegistration) {
		return await this.safeExecuteAsync(async () => {
			return await (
				await this.httpUserManagement.post<IRegResponse>(this.endPoints.UserRegistration, user)
			).data;
		});
	}

	async logout() {
		return await this.safeExecuteAsync(async () => {
			return await (
				await this.httpUserManagement.post<IRegResponse>(this.endPoints.logout)
			).data;
		});
	}

	async registerUser(user: RegUser, IsTermsOfservice: boolean, reCaptchaToken: string) {
		const data: UserInitialRegistration = {
			firstName: user.firstName,
			lastName: user.lastName,
			personalIdnumber: encrypt(user.password?.toString() || ''),
			workMail: user.email,

			userId: 0,
			plan: 0,
			creditDetails: {
				credit: 'string',
			},
			IsTermsOfservice: IsTermsOfservice,
			expiredDate: '2021-11-04T14:03:25.235Z',
			mobileNumber: 'string',
			organization: 'string',
			role: 0,
			nationality: 0,
			id: 'string',
			companyName: 'string',
			corporateNumber: 'string',
			isLegalEntity: true,
			reCaptchaToken: reCaptchaToken,
		};
		return await this.safeExecuteAsync(async () => {
			return await (
				await this.httpUserManagement.post<RegResponse>(this.endPoints.UserRegistration, data)
			).data;
		});
	}
	async getUserPermissions(companyID: number) {
		return await this.safeExecuteAsync(async () => {
			const data = (await this.httpUserManagement.get<UserPermissions>(this.endPoints.UsersPermission(companyID))).data;

			return data;
		});
	}

	async validateRegistrationOtp(otp: string, uniqueKey: string) {
		const data = {
			uniqueKey,
			otp: otp,
		};

		let jwttoken: string = '';

		const se = await this.safeExecuteAsync(async () => {
			const res = await this.httpUserManagement.post<RegResponse>(this.endPoints.ValidateRegistrationOtp, data);

			jwttoken = res.headers.authorization;

			return await res.data;
		});
		se.data.jwttoken = jwttoken;
		return se;
	}

	async pivotalLogin(data: ILoginPivotal) {
		return await this.safeExecuteAsync(async () => {
			return await (
				await this.httpPivotal.post<RegResponse>(this.endPoints.PivotalLogin, data)
			).data;
		});
	}

	async pivotalValidateLoginOtp(data: IOtpLoginPivotal) {
		return await this.safeExecuteAsync(async () => {
			return await (
				await this.httpPivotal.post<IPivotalTokenCompanies>(this.endPoints.PivotalLoginOtp, data)
			).data;
		});
	}

	async login(userLoginData: IUserLogin) {
		return await this.safeExecuteAsync(async () => {
			return await (
				await this.httpUserManagement.post<RegResponse>(this.endPoints.Login, userLoginData)
			).data;
		});
	}

	async loginGoogle(userLoginData: IUserLogin) {
		try {
			const response = await this.httpUserManagement.post<ILoginResponse>(this.endPoints.Login, userLoginData);
			return {
				token: response.headers.authorization,
				data: response.data,
				isSuccess: true,
			};
		} catch (error) {
			return {
				error: 'Error account setup',
			};
		}
	}

	async resendOTP(guid: string, reCaptchaToken: string) {
		const data = {
			uniqueKey: guid,
			recaptcha: reCaptchaToken,
		};

		return await this.safeExecuteAsync(async () => {
			return await (
				await this.httpUserManagement.post<RegResponse>(this.endPoints.ResendOTP, data)
			).data;
		});
	}

	async validateLoginOtp(otp: string, requestId: string) {
		const data = {
			uniqueKey: requestId,
			otp: otp,
		};

		try {
			const response = await this.httpUserManagement.post<AppUser>(this.endPoints.ValidateLoginOtp, data);

			return {
				data: { ...response.data, jwttoken: response.headers.authorization },
			};
		} catch (error: any) {
			if (error.data?.statusCode === 401) {
				return {
					isSuccess: false,
					status: 401,
				};
			}
		}
	}

	async chiefLogin(token: string, valuationProjectId: string): Promise<HighLevelErrorResponse | HigherLevelResponse<ServerResponseApi<AppUser>>> {
		const res = await userApi.post<ServerResponseApi<AppUser>>(`/user/invite-chief/${token}/${valuationProjectId}`);
		const jwt = res.headers['authorization'];
		if (jwt && res.isSuccess) res.data.data.jwttoken = jwt;
		return res;
	}

	// async getCompanyProfileData(companyId: number) {
	// 	return await this.safeExecuteAsync(async () => {
	// 		return await (
	// 			await this.httpLogin.post<CompanyRegistration>(this.endPoints.GetCompanyProfileData, {
	// 				companyID: companyId,
	// 			})
	// 		).data;
	// 	});
	// }

	// async updateCompanyProfileData(data: CompanyRegistration, companyId: number) {
	// 	data.guid = "00000000-0000-0000-0000-000000000000";
	// 	return await this.safeExecuteAsync(async () => {
	// 		return await (
	// 			await this.httpLogin.post<CompanyRegistration>(this.endPoints.UpdateCompanyProfileData, {
	// 				companyFullData: data,
	// 				companyID: companyId,
	// 			})
	// 		).data;
	// 	});
	// }

	async resetPassword(reCaptchaToken: string, email: string, application: number) {
		const data = {
			recaptcha: reCaptchaToken,
			email: email,
			application: application,
		};
		return await this.safeExecuteAsync(async () => {
			return (await this.httpUserManagement.post<any>(this.endPoints.ResetPassword, data)).data;
		});
	}

	async changePassword(data: IUserRegistration): Promise<HighLevelErrorResponse | HigherLevelResponse<AppUser>> {
		const res = await userApi.put<AppUser>('/user/email', { ...data, password: encryptAes(data.password?.toString() || '') });
		if (!res.isSuccess) return res;
		return {
			...res,
			data: {
				...res.data,
				jwttoken: res.headers['authorization'],
			},
		};
	}

	async validateResetPasswordOTP(newPass: string, guid: string, application: number) {
		const data = {
			uniqueKey: guid,
			password: encryptAes(newPass?.toString() || ''),
			application: application,
		};

		return await this.safeExecuteAsync(async () => {
			return (await this.httpUserManagement.put<AppUser>(this.endPoints.ValidateOTPForREsetPassword, data)).data;
		});
	}

	async validateUniqueKey(uniqueKey: string) {
		const data = {
			uniqueKey,
		};

		return await this.safeExecuteAsync(async () => {
			return (await this.httpUserManagement.post<any>(this.endPoints.resetPasswordValidate, data)).data;
		});
	}

	async getBuildVersion() {
		return await this.safeExecuteAsync(async () => {
			const data = (await this.httpLocal.get('meta.json')).data;

			return data;
		});
	}

	async addUserRole(role?: number) {
		return await this.safeExecuteAsync(async () => {
			return (await this.httpUserManagement.patch<any>(this.endPoints.UpdateUserRole, { role })).data;
		});
	}

	async updateUserStatus(status: number) {
		return await this.safeExecuteAsync(async () => {
			return (await this.httpUserManagement.patch<any>(this.endPoints.UpdateUserRole, { status })).data;
		});
	}
}
