import { css } from "@emotion/css";
import { observer } from "mobx-react-lite";
import { ReactElement, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { IC_EDIT2, IC_INVITE, IC_TERMINATE, IC_TRASH2, IC_USER_CIRCLE, IC_WATERFALL_SUMMARY_GRAY } from "../../../../Assets";
import { BenTable } from "../../../../Models/App/EquityPlans/Beneficiaries";
import AddButton from "../../../../Shared/Components/Button/AddButton";
import Clickable from "../../../../Shared/Components/Clickable/Clickable";
import Image from "../../../../Shared/Components/Image";
import Flex from "../../../../Shared/Components/Layout/Flex";
import Table, { TableColumn } from "../../../../Shared/Components/Table/Table";
import { Cell } from "../../../../Shared/Components/Table/Table.Style";
import useRootStore from "../../../../Shared/Hooks/useRootStore";
import { employmentStatus } from "../../../../Shared/StaticData/equityPlans";
import { formatNumber } from "../../../../Shared/Utilities";
import appConfig from "../../../../config/config";
import useEsopModals from "../../useEsopModals";
import TableActions from "../TableActions/TableActions";

const style = css({
    ".actions": {
        opacity: 0,
        visibility: "hidden",
        position: "absolute",
        background: "#ffffff",
        transition: "opacity .3s",
        right: 0,
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        paddingLeft: "1rem",
    },
    ".benName": { cursor: "pointer" },
    "&:hover": {
        ".actions": { opacity: 1, visibility: "visible" },
        ".benName": { transform: "scale(1.05)", textDecoration: "underline" },
    },
});

interface Props {
    header: ReactElement;
    expand?: boolean;
}

const BeneficiariesTable = ({ header, expand }: Props) => {
    const history = useHistory();

    const { equityPlansStore } = useRootStore();
    const { newBeneficiaryModalHandler, openBeneficiaryModal, showDeleteBeneficiary, showTerminationModal, inviteToEmployeeManagment, openTotalEquityAwardsModal } = useEsopModals();
    const [openedMenuId, setOpenedMenuId] = useState<number>(-1);
    const [hoveredMenuId, setHoveredMenuId] = useState<number>(-1);

    const beneficiariesColumns: TableColumn<BenTable>[] = [
        {
            label: "Beneficiary",
            name: "fullName",
            format: (val: { firstName: string; lastName: string }) => `${val?.firstName} ${val?.lastName}`,
            sortable: true,
            isOverflow: true,
            render: (ben, val: { firstName: string; lastName: string }) => {
                const hasGrants = equityPlansStore.companyGrants?.some((grnt) => grnt.beneficiaryId === ben.beneficiaryId);
                return (
                    <Cell style={{ position: "relative" }} isOverflow>
                        <Clickable
                            qaid="GrantsTable.Button.GrantNumber"
                            className="text-ellipsis"
                            applyStyle={hoveredMenuId === ben.beneficiaryId}
                            justify="start"
                            gap="1.2rem"
                            align="center"
                        >
                            <Image width="3.1rem" src={IC_USER_CIRCLE} alt="" />
                            <span>{`${val?.firstName}  ${val?.lastName}`}</span>
                        </Clickable>
                        <TableActions
                            cssClass="actions"
                            autoClose={() => openedMenuId !== ben.beneficiaryId || hoveredMenuId !== ben.beneficiaryId}
                            items={[
                                {
                                    label: "Edit",
                                    icon: IC_EDIT2,
                                    qaid: "EditShareholder.Button.Edit",
                                    onClick: () => [openBeneficiaryModal(ben.beneficiaryId, true), equityPlansStore.setSelectedBenRowData = ben],
                                },
                                {
                                    label: "Total equity awards",
                                    icon: IC_WATERFALL_SUMMARY_GRAY,
                                    qaid: "EditShareholder.Button.Edit",
                                    onClick: () => openTotalEquityAwardsModal(ben),
                                },
                                {
                                    label: "Delete",
                                    icon: IC_TRASH2,
                                    qaid: "EditShareholder.Button.DeleteShareholder",
                                    onClick: () => showDeleteBeneficiary(ben.beneficiaryId),
                                },
                                {
                                    label: "Terminate",
                                    icon: IC_TERMINATE,
                                    qaid: "EditShareholder.Button.DeleteShareholder",
                                    hide: !hasGrants,
                                    onClick: () => showTerminationModal(ben.beneficiaryId),
                                },
                            ]}
                        />
                    </Cell>
                );
            },
            style: { flex: "1 0 18rem" },
        },
        {
            label: "Employee number",
            name: "employeeNumber",
            sortable: true,
        },
        {
            label: "Employee status",
            name: "employementStatus",
            format(val) {
                return employmentStatus[val]?.label;
            },
            sortable: true,
        },
        {
            label: "Email",
            name: "email",
            sortable: true,
            onCellClick: (value) => console.log(value),
        },
        {
            label: "Granted",
            name: "granted",
            sortable: true,
            sortType: "number",
            format: (val) => formatNumber(val),
        },
        {
            label: "Unvested",
            name: "unvested",
            sortable: true,
            sortType: "number",
            format: (val) => formatNumber(val),
        },
        {
            label: "Exercisable",
            name: "exercisable",
            sortable: true,
            sortType: "number",
            format: (val) => formatNumber(val),
        },
        {
            label: "Salable",
            name: "shares",
            sortable: true,
            sortType: "number",
            format: (val) => formatNumber(val),
        },
        {
            name: "outstanding",
            label: "Outstanding",
            sortable: true,
            sortType: "number",
            style: { background: appConfig.style.colors.total },
        },
    ];

    useEffect(() => {
        equityPlansStore.GetBeneficiariesDashboardData();
    }, [equityPlansStore.dataAsOfDate, equityPlansStore.selectedPlan]);

    return (
        <Table
            rowClassName={style}
            columns={beneficiariesColumns}
            rows={equityPlansStore.companyBeneficiaries}
            selectBy="beneficiaryId"
            scrollAfterRows={expand ? 16 : 7}
            filterBy={beneficiariesColumns.filter((col) => col.name !== "actions").map((col) => col.name as keyof BenTable)}
            searchBy={["employeeNumber", "fullName"]}
            exportToExcel={{ fileName: "Beneficiaries", sheetName: "Beneficiaries" }}
            fullscreen
            onRowClick={(ben) => [openBeneficiaryModal(ben.beneficiaryId), equityPlansStore.setSelectedBenRowData = ben]}
            renderBottom={
                <Flex align="center" justify="start">
                    <AddButton onClick={newBeneficiaryModalHandler} qaid="BeneficiariesTable.Button.AddNewBeneficiary" label="Add new beneficiary" />
                </Flex>
            }
            onRowEnter={(row) => setHoveredMenuId(row.beneficiaryId)}
            onRowLeave={() => setHoveredMenuId(-1)}
            customHeaderRender={header}
        />
    );
};
export default observer(BeneficiariesTable);
