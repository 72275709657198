export interface GrantIsoDataResponseType {
    contactOtherGrants: string;
    grantISOVestingDataDtos: IsoListType[];
    thisGrantNumber: string;
}

export interface IsoListType {
    vestDate: Date;
    year: number;
    isYearTotal: boolean;
    isoVestingAmount: number;
    nsoVestingAmount: number;
    isoValue: number;
    otherGrantsIsoAmount: number;
    otherGrantsValue: number;
    originalNSOVested: number;
    originalISOVested: number;
    originalISOValue: number;
    status: GrantISOVestingStatusEnum;
}

export enum GrantISOVestingStatusEnum {
    Removed = 0,
    New = 1,
    Updated = 2,
}