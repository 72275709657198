import { observer } from 'mobx-react-lite';
import { IOrder, OrderType } from '../../../../Models/App/EquityPlans/Order';
import { IGrantCancelation } from '../../../../Models/App/EquityPlans/GrantCancelations';
import Select from '../../../../Shared/Components/Select/Select';
import Table, { TableColumn } from '../../../../Shared/Components/Table/Table';
import useRootStore from '../../../../Shared/Hooks/useRootStore';
import GrantCardStyles from './GrantCard.style';
import GrantDetails from './GrantDetails/GrantDetails';
import useEsopModals from '../../useEsopModals';
import { IBaseGrant } from '../../../../Models/App/EquityPlans/GrantEvents';
import { cancelationReason, cancelationType, orderTypes } from '../../../../Shared/StaticData/equityPlans';
import { formatDate, formatNumber, isNullOrUndefined, isString } from '../../../../Shared/Utilities';
import TableDrawer from '../../General/TableDrawer/TableDrawer';
import Menu from '../../../../Shared/Components/Menu';
import {
	IC_ADD_CERTIFICATE,
	IC_CANCEL_ROUND,
	IC_EYE_ACTION,
	IC_LETTER_CANCELLED,
	IC_LETTER_READY,
	IC_LETTER_SENT,
	IC_LETTER_SIGNED,
	IC_PLUS_PURPLE,
	IC_SEND_FOR_SIGNATURE,
	IC_TRASH2,
} from '../../../../Assets';
import { useEffect, useState } from 'react';
import { Collapse } from '@mui/material';
import { TemplateTypeEnum } from '../../../../Models/API/Document/template-type-enum';
import Button from '../../../../Shared/Components/Button/Button';
import useEffectOnce from '../../../../Shared/Hooks/useEffectOnce';
import Image from '../../../../Shared/Components/Image';
import { ElectronicSignatureStatus } from '../../../../Models/API/Document/eletronic-signature-status-enum';
import useGeneralModal from '../../../../Shared/Hooks/useGeneralModal';
import useTemplate from '../../../Documents/helpers/hooks/useTemplate';
import useDocument from '../../../Documents/helpers/hooks/useDocument';
import { IContact } from '../../../../Models/API/Contact/contact';
import ContactsEmailFillTable from '../../../../Shared/Components/ContactsEmailFillTable';
import useModal from '../../../../Shared/Hooks/useModal';
import AddButton from '../../../../Shared/Components/Button/AddButton';
import { IsoListType } from '../../../../Models/API/EquityPlans/IsoNso';
import CircularSwitchToggle from '../../../../Shared/Components/CircularSwitchToggle/CircularSwitchToggle';
import IsoTable from './IsoTable';
import { ModalBodyProps } from '../../../../Shared/Components/Modal/types';
import { Routes } from '../../../../Routes';
import { useHistory } from 'react-router-dom';
import appConfig from '../../../../config/config';

interface GrantCardProps {
	isEdit?: boolean;
	modalProps?: ModalBodyProps;
}

const GrantCard = (props: GrantCardProps) => {
	const { equityPlansStore, documentsStore, contactStore, paymentStore } = useRootStore();
	const { newOrderHandler, showCancelationModal } = useEsopModals();
	const [isAdd, setIsAdd] = useState<boolean>(false);
	const [isAdding, setIsAdding] = useState<boolean>(false);
	const [grantLetterTemplateId, setGrantLetterTemplateId] = useState<string>();
	const { previewDocument } = useDocument();
	const { showErrorModal, onUpgradePlan } = useGeneralModal();
	const { showAsyncModal, showModal } = useModal();
	const { previewTemplate } = useTemplate();
	const history = useHistory();

	useEffectOnce(
		() => {
			if (!isAdd) return;

			documentsStore.getDocuments();
		},
		[isAdd],
		isAdd
	);

	const { grantId, documentDetailsDto, isIso } = equityPlansStore.beneficiaryGrantData;

	const ordersTableColumns: TableColumn<IOrder>[] = [
		{
			label: 'Order number',
			name: 'orderNumber',
		},
		{
			label: 'Grant number',
			name: 'grantNumber',
		},
		// TODO: ADD This

		// {
		// 	label: "Grant type",
		// 	name: "grantType",
		// 	format: (val) => grantTypes[val]?.label,
		// 	sortable: false,
		// 	style: { flex: 1.5 }
		// },
		{
			label: 'Order type',
			name: 'orderType',
			format(val) {
				return orderTypes[val]?.label;
			},
		},
		{
			label: 'Order date',
			name: 'date',
			format(val) {
				return formatDate(val);
			},
		},
		{
			label: 'Amount',
			name: 'amount',
			format: (val) => formatNumber(val),
		},
		{
			label: 'Sale Price',
			name: 'sellPrice',
			format(val, obj) {
				return obj?.orderType === OrderType.Exercise ? '' : val;
			},
		},
		{
			label: 'Exercise price',
			name: 'exercisePrice',
		},
	];

	const vestColumns: TableColumn<IBaseGrant>[] = [
		{
			name: 'date',
			label: 'Date',
			format(val) {
				return formatDate(val);
			},
		},
		{
			name: 'vestedNotAccumulated',
			label: 'Vested',
			format: (val) => formatNumber(val),
		},
		{
			name: 'vested',
			label: 'Total vested',
			format: (val) => formatNumber(val),
		},
	];

	const cancelationsTableColumns: TableColumn<IGrantCancelation>[] = [
		{
			label: 'Cancelation number',
			name: 'grantCancelationId',
		},
		{
			label: 'Cancel type',
			name: 'cancelType',
			format(val, obj) {
				if (!obj) return;
				return cancelationType.filter((ctyp) => obj.cancelType === ctyp.value)[0].label;
			},
		},
		{
			label: 'Cancel date',
			name: 'cancelationDate',
			format(val) {
				return formatDate(val);
			},
		},
		{
			label: 'Cancel in',
			name: 'gracePeriodNum',
		},
		{
			label: 'Cancel reason',
			name: 'cancelationReason',
			format(val, obj) {
				if (!obj) return;
				return cancelationReason.filter((ctyp) => +obj.cancelationReason === ctyp.value)[0].label;
			},
		},
		{
			label: 'Amount',
			name: 'amountToCancel',
			format: (val) => formatNumber(val),
		},
	];

	const getDrawerHeader = (title: string, postfix?: string, total: number = 0, bodyRender?: any) => {
		return (
			<div className={`${GrantCardStyles}__drawer-header`}>
				<span className="bold">{title}</span>
				{!!postfix && (
					<span className="header-comment">
						({total} {postfix})
					</span>
				)}
				{bodyRender}
			</div>
		);
	};

	const onSendForSignature = async () => {
		const lastUnsignedDoc = documentDetailsDto?.find((doc) => doc.electronicSignatureStatus === ElectronicSignatureStatus.DocumentCreated);
		if (lastUnsignedDoc?.electronicSignatureStatus !== ElectronicSignatureStatus.DocumentCreated) return;

		const templates = (await documentsStore.getTemplates()).data?.data;
		if (!templates || !documentDetailsDto) return;

		const contacts = documentDetailsDto.reduce(
			(acc, doc) => {
				const grant = equityPlansStore.beneficiaryGrantData;
				if (!grant?.sourceContactId) return acc;

				const contact = contactStore.getContactById(grant?.sourceContactId);
				if (contact) {
					!contact?.email && acc.editableContacts.push(contact.contactId);
					acc.contacts.push(contact);
				}

				return acc;
			},
			{ editableContacts: [] as number[], contacts: [] as IContact[] }
		);

		if (contacts.editableContacts.length) {
			const isCompleted = await showAsyncModal({
				body: (props) => (
					<ContactsEmailFillTable
						title="Please confirm email addresses for all beneficiaries you send grant letter to:"
						actionLabel="Send for signature"
						{...props}
						{...contacts}
					/>
				),
				width: '90%',
				maxWidth: '100rem',
				maxHeight: '95rem',
				isFrameless: true,
			});

			if (!isCompleted) return;
		}

		const template = templates.find((template) => template.templateId === lastUnsignedDoc.templateId);
		if (!template) return;

		const signatures = [
			{
				rule: template.signinRule,
				document: {
					documentName: lastUnsignedDoc.documentName,
					id: lastUnsignedDoc.documentId,
					recordId: lastUnsignedDoc.recordId,
					securityId: equityPlansStore.beneficiaryGrantData.grantNumber,
				},
				signerDetailsList: template.signers.map((signer) => {
					let contactId = signer.contactId;

					if (contactId === null) {
						contactId =
							equityPlansStore.companyGrants?.find((g) => g.documentDetailsDto?.some((d) => d.documentId === lastUnsignedDoc.documentId))
								?.sourceContactId ?? 0;
					}

					return {
						contactId,
						isDesignatedCompanySignatory: signer.isDesignatedCompanySignatory,
						positionForSignature: signer.positionForSignature,
					};
				}),
			},
		];

		await documentsStore.sendForSignature({ type: TemplateTypeEnum.GrantLetter, electronicSignatureDetailsList: signatures });
		await equityPlansStore.GetGrantsForCompany();
		grantId && (await equityPlansStore.GetSingleGrant(grantId));
	};

	const getMenuItems = () => {
		const latestDocument = documentDetailsDto?.find((doc) => !doc.isCancelled);
		const isCancelledStatus = documentDetailsDto?.every((doc) => doc.isCancelled);
		const items = [
			latestDocument
				? {
					qaid: 'GrantCard.Button.CreateGrantLetter',
					icon: IC_EYE_ACTION,
					label: 'View grant letter',
					props: { width: '3rem' },
					onClick: () => {
						previewDocument(latestDocument.documentId, latestDocument.templateId, latestDocument.documentName);
					},
				}
				: undefined,
			latestDocument?.electronicSignatureStatus === ElectronicSignatureStatus.DocumentCreated
				? {
					qaid: 'GrantCard.Button.SendForSignature',
					icon: IC_SEND_FOR_SIGNATURE,
					label: 'Send for signature',
					props: { width: '4rem' },
					onClick: onSendForSignature,
				}
				: undefined,
			isCancelledStatus
				? {
					qaid: 'GrantCard.Button.CreateGrantLetter',
					icon: IC_ADD_CERTIFICATE,
					label: 'Create grant letter',
					props: { width: '3rem' },
					onClick: () => setIsAdd((prev) => !prev),
				}
				: undefined,
			isCancelledStatus
				? undefined
				: {
					qaid: 'GrantCard.Button.CancelGrantLetter',
					icon: latestDocument?.electronicSignatureStatus === ElectronicSignatureStatus.DocumentCreated ? IC_TRASH2 : IC_CANCEL_ROUND,
					label:
						latestDocument?.electronicSignatureStatus === ElectronicSignatureStatus.DocumentCreated
							? 'Delete this grant letter'
							: 'Cancel grant letter',
					props: { width: '2rem' },
					disabled: !latestDocument,
					onClick: () => {
						const docId = latestDocument?.documentId;
						if (!docId) return;

						const isSent = latestDocument?.electronicSignatureStatus === ElectronicSignatureStatus.ElectronicSignatureCreated;

						showModal({
							title: 'Are you sure?',
							body: `Do you really want to ${isSent ? 'cancel' : 'delete'} this Grant Letter?`,
							type: 'warning',
							confirmButton: {
								label: 'Continue',
							},
							onConfirm: async () => {
								const res = await (isSent ? documentsStore.cancelDocument(docId) : documentsStore.deleteDocument(docId));
								if (res.data) {
									grantId && (await equityPlansStore.GetSingleGrant(grantId));
									equityPlansStore.GetGrantsForCompany();
								}
							},
						});
					},
				},
		];
		return items.filter((data) => !isNullOrUndefined(data));
	};

	return (
		<>
			<div className={GrantCardStyles}>
				<GrantDetails isEdit={props.isEdit} modalProps={props.modalProps} />
				<div className={`${GrantCardStyles}__docs`}>
					<div className="info">
						<span className="title bold">Documents</span>
						{documentDetailsDto === null ? (
							<div
								className="flex align-center gap-1 pointer"
								onClick={() => {
									if (!paymentStore.currentPlan.documents) {
										return onUpgradePlan();
									}
									setIsAdd((prev) => !prev);
								}}
							>
								<span>Create grant letter</span>
								<AddButton qaid="GrantCard.Button.CreateGrantLetter" />
							</div>
						) : (
							<div className="menu-container">
								{[...(documentDetailsDto ?? [])]
									?.sort((a, b) => (a.isCancelled ? -1 : 1))
									?.map((doc) => (
										<Image
											className="clickable"
											key={doc.documentId}
											onClick={() => previewDocument(doc.documentId, doc.templateId, doc.documentName)}
											src={
												doc.isCancelled
													? IC_LETTER_CANCELLED
													: doc.electronicSignatureStatus === ElectronicSignatureStatus.DocumentCreated
														? IC_LETTER_READY
														: doc.electronicSignatureStatus === ElectronicSignatureStatus.ElectronicSignatureCreated
															? IC_LETTER_SENT
															: doc.electronicSignatureStatus === ElectronicSignatureStatus.ElectronicSignatureSigned
																? IC_LETTER_SIGNED
																: undefined
											}
										/>
									))}
								<Menu className="menu" isHorizontal position={{ top: 'calc(100% + 10px)' }} items={getMenuItems()} />
							</div>
						)}
					</div>
					<Collapse in={isAdd} unmountOnExit>
						<div className="add-grant-letter">
							<Select
								qaid="GrantCard.Select.Templates"
								label="Grant Letter template"
								options={documentsStore.getTemplatesOptionsByType(TemplateTypeEnum.GrantLetter)}
								value={grantLetterTemplateId}
								onChange={(value) => isString(value) && setGrantLetterTemplateId(value)}
								required
								style={{ maxWidth: '30rem' }}
								endIcon={
									grantLetterTemplateId
										? {
											src: IC_EYE_ACTION,
											onClick: () => {
												if (!grantLetterTemplateId) return;
												previewTemplate(grantLetterTemplateId, '');
											},
											width: '2rem',
										}
										: undefined
								}
								customAction={{
									children: <div style={{ color: appConfig.style.colors.color1 }}>Create new template</div>,
									onClick: () => history.push(`${Routes.docs.index}?tab=templates`),
								}}
							/>
							<Button
								qaid="GrantCard.Button.AddTemplate"
								label="Create"
								position="end"
								isLoading={isAdding}
								onClick={async () => {
									if (!grantId || !grantLetterTemplateId) return;
									setIsAdding(true);
									const res = await equityPlansStore.createGrantLetter(
										grantId,
										grantLetterTemplateId,
										documentsStore.templates?.find((t) => t.templateId === grantLetterTemplateId)?.variablesCollection
									);
									if (res.isSuccess) {
										await equityPlansStore.GetSingleGrant(grantId);
										equityPlansStore.GetGrantsForCompany();
									} else {
										showErrorModal(res.errorMessage);
									}
									setIsAdd(false);
									setIsAdding(false);
								}}
							/>
							<Button
								qaid="GrantCard.Button.CancelTemplate"
								label="Cancel"
								inverse
								cancel
								position="end"
								disabled={isAdding}
								onClick={() => setIsAdd(false)}
							/>
						</div>
					</Collapse>
				</div>

				{isIso && (
					<IsoTable
						data={equityPlansStore.isoTableData?.grantISOVestingDataDtos || []}
						grantId={equityPlansStore.isoTableData?.thisGrantNumber || ''}
						otherGrantId={equityPlansStore.isoTableData?.contactOtherGrants || ''}
					/>
				)}

				{/* Vesting schedule */}
				<TableDrawer
					tableColumns={vestColumns}
					tableData={equityPlansStore.originalGrantEvents}
					header={getDrawerHeader('Vesting schedule')}
					isOpen={true}
				/>

				{/* Orders */}
				<TableDrawer
					tableColumns={ordersTableColumns}
					tableData={equityPlansStore.grantOrders}
					actionBtn={{ desc: 'Add new order', func: newOrderHandler }}
					// header={`Grant Orders (${equityPlansStore.grantOrders ? equityPlansStore.grantOrders.length : 0} Orders)`}
					header={getDrawerHeader(
						'Grant orders',
						(equityPlansStore.grantOrders?.length ?? 0) > 1 ? 'orders' : 'order',
						equityPlansStore.grantOrders?.length
					)}
				/>

				{/* Cancelations */}
				<TableDrawer
					tableColumns={cancelationsTableColumns}
					tableData={equityPlansStore.grantCancelations}
					actionBtn={{ desc: 'Add new grant cancelation', func: () => showCancelationModal() }}
					// header={`Grant Cancelations   (${equityPlansStore.grantCancelations ? equityPlansStore.grantCancelations.length : 0} Cancelations)`}
					header={getDrawerHeader('Grant cancelations', 'cancelations', equityPlansStore.grantCancelations?.length)}
				/>
			</div>
		</>
	);
};

export default observer(GrantCard);