import { HTMLAttributes, useEffect, useMemo, useState } from 'react';
import { PoolStep } from '../../../../../../Models/API/EquityPlans/pool-step';
import { EditablePoolStep } from '../../../../../../Models/App/EquityPlans/pool-step';
import { useAppendState } from '../../../../../../Shared/Hooks/useAppendState';
import DatePickerInput from '../../../../../../Shared/Components/Input/DatePickerInput';
import NumberInput from '../../../../../../Shared/Components/Input/NumberInput';
import { IC_EDIT2, IC_MINUS_GREY, IC_PLUS_GREY, IC_TRASH2 } from '../../../../../../Assets';
import Label from '../../../../../../Shared/Components/Layout/Label';
import { TextAreaInput } from '../../../../../../Shared/Components/Input/Input.Style';
import TextInput from '../../../../../../Shared/Components/Input/TextInput';
import { formatNumber, isArray } from '../../../../../../Shared/Utilities';
import Button from '../../../../../../Shared/Components/Button/Button';
import useRootStore from '../../../../../../Shared/Hooks/useRootStore';
import useGeneralModal from '../../../../../../Shared/Hooks/useGeneralModal';
import Menu from '../../../../../../Shared/Components/Menu';
import { toJS } from 'mobx';
import useModal from '../../../../../../Shared/Hooks/useModal';

type Props = { poolStep?: PoolStep; planId: number; className?: string; totalPool: number; approvalDate?: Date } & HTMLAttributes<HTMLDivElement>;

const AddPoolStep = ({ poolStep, planId, className, totalPool, approvalDate, ...props }: Props) => {
	const INITIAL_POOL: EditablePoolStep = useMemo(() => {
		return {
			amount: poolStep ? Math.abs(poolStep.amount) : undefined,
			comment: poolStep?.comment,
			poolId: poolStep?.poolId ?? planId,
			stepDate: poolStep ? new Date(poolStep.stepDate) : undefined,
		};
	}, [poolStep]);
	const isEdit = !!poolStep?.poolStepsId;
	const { equityPlansStore } = useRootStore();
	const [isViewMode, setIsViewMode] = useState<boolean>(isEdit);
	const [pool, setPool, onInputHandler] = useAppendState<EditablePoolStep>(INITIAL_POOL);
	const [isInc, setIsInc] = useState<boolean>(poolStep ? poolStep.amount > 0 : true);
	const { showErrorModal, showSuccessModal } = useGeneralModal();
	const { showModal } = useModal();
	const [isLoading, setIsLoading] = useState<boolean>(false);

	useEffect(() => {
		setIsInc(poolStep ? poolStep.amount > 0 : true);
	}, [poolStep]);

	useEffect(() => {
		return () => {
			equityPlansStore.isAddingPoolStep = false;
		};
	}, []);

	const onAddPoolStep = async () => {
		if (!pool.amount || !pool.stepDate || !pool.poolId) return;

		setIsLoading(true);
		const payload = { ...pool, amount: isInc ? pool?.amount : -(pool?.amount ?? 0) };
		const res = isEdit ? await equityPlansStore.updatePoolStep(payload, poolStep?.poolStepsId) : await equityPlansStore.addPoolStep(payload);
		setIsLoading(false);
		if (!res.data) return showErrorModal(isArray(res.error.data) ? res.error.data.map((error: any) => error.message).join(', ') : res.error.data);

		equityPlansStore.isAddingPoolStep = false;
		isEdit && setIsViewMode(true);
	};

	const onDeletePoolStep = async () => {
		if (!poolStep?.poolStepsId) return;

		showModal({
			title: 'Are you sure?',
			body: <>Are you sure you would like to permanently delete increase/decrease of pool?</>,
			type: 'delete',
			onConfirm: async () => {
				const res = await equityPlansStore.deletePoolStep(poolStep?.poolStepsId, planId);
				if (res.data) {
					showSuccessModal({ body: 'The pool step was deleted successfully' });
				} else {
					showErrorModal(res.errorMessage);
				}
			},
			confirmButton: {
				label: 'Delete',
			},
		});
	};

	const totalPoolCalculated = isViewMode ? totalPool : totalPool - (poolStep?.amount ?? 0) + (pool.amount ?? 0) * (isInc ? 1 : -1);

	return (
		<div className={className} data-mode={isViewMode ? 'view' : 'edit'} {...props}>
			<DatePickerInput
				label="Date"
				qaid="PoolStepsList.Input.DatePicker"
				value={pool?.stepDate}
				onChange={onInputHandler}
				name="stepDate"
				containerClassName={`${className}__stepDate`}
				isViewMode={isViewMode}
				data-col-type="stepDate"
				minDate={approvalDate}
			/>
			<div className={`${className}__amount flex gap-1 align-end`} data-col-type="amount">
				<NumberInput
					qaid="PoolStepsList.Input.Amount"
					value={pool?.amount ? (isViewMode ? pool.amount * (isInc ? 1 : -1) : pool.amount) : undefined}
					onChange={onInputHandler}
					label={isInc ? 'Increase pool' : 'Decrease pool'}
					name="amount"
					data-input="amount"
					isViewMode={isViewMode}
				/>
				{!isViewMode && (
					<>
						<Button
							qaid="PoolStepsList.Button.Decrease"
							inverse={isInc}
							label="-"
							onClick={() => setIsInc(false)}
							autoWidth={false}
							width="fit-content"
						/>
						<Button
							qaid="PoolStepsList.Button.Increase"
							inverse={!isInc}
							label="+"
							onClick={() => setIsInc(true)}
							autoWidth={false}
							width="fit-content"
						/>
					</>
				)}
			</div>
			<div className={`${className}__comment`} data-col-type="comment">
				{isViewMode ? (
					<TextInput qaid="PoolStepsList.TextArea.Comment" value={pool?.comment} disabled isViewMode label="Comment" />
				) : (
					<>
						<Label>Comment</Label>
						<TextAreaInput
							data-qaid="PoolStepsList.TextArea.Comment"
							value={pool?.comment ?? ''}
							onChange={(e) => onInputHandler(e.target.value, e.target.name)}
							name="comment"
							className="w-100"
							style={{ resize: 'none', height: 'calc(100% - 2.5rem)' }}
						/>
					</>
				)}
			</div>
			<TextInput
				qaid="PoolStepsList.Input.TotalPool"
				label="Total pool"
				disabled
				isTotal
				value={formatNumber(totalPoolCalculated)}
				containerClassName={`${className}__totalPool`}
				isViewMode={isViewMode}
				data-col-type="totalPool"
			/>
			{!isViewMode && (
				<div className={`flex justify-end gap-1 ${className}__buttons`} data-col-type="buttons">
					<Button
						cancel
						label="Cancel"
						qaid="PoolStepsList.Button.Cancel"
						onClick={() => {
							setIsViewMode(true);
							equityPlansStore.isAddingPoolStep = false;
							setPool(INITIAL_POOL);
						}}
						disabled={isLoading}
						small={isEdit}
					/>
					<Button label="Confirm" qaid="PoolStepsList.Button.Confirm" onClick={onAddPoolStep} isLoading={isLoading} small={isEdit} />
				</div>
			)}
			{isEdit && isViewMode && (
				<Menu
					items={[
						{
							label: 'Edit',
							icon: IC_EDIT2,
							onClick: () => setIsViewMode((prev) => !prev),
							qaid: 'PoolStepsList.Menu.Edit',
						},
						{
							label: 'Delete',
							icon: IC_TRASH2,
							onClick: onDeletePoolStep,
							qaid: 'PoolStepsList.Menu.Delete',
						},
					]}
					className={`${className}__menu`}
					data-col-type="menu"
					isHorizontal
				/>
			)}
		</div>
	);
};

export default AddPoolStep;
