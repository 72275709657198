import { CircularProgress } from "@mui/material";
import classNames from "classnames";
import { createRef, forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { useTranslation } from "react-i18next";
import { InputRef, TextInputProps } from "../../../Models/App/Inputs/types";
import { checkQaidConvention, isNullOrUndefined } from "../../Utilities";
import Flex from "../Layout/Flex";
import { StyledInput, inputClassName } from "./Input.Style";
import InputWrapper from "./InputWrapper";
import LongText from "../OverflowText";
import OverflowText from "../OverflowText";

const TextInput = forwardRef<InputRef, TextInputProps>((props, ref) => {
    checkQaidConvention(props.qaid);
    const { onEnter, onOptionSelect, selectedValue, ratio, errorOnBlur, ...rst } = props;
    const { t } = useTranslation();
    const [isFocusOnce, setIsFocusOnce] = useState<boolean>(false);
    const inputRef = createRef<HTMLInputElement>();
    const [error, setError] = useState<string>();

    useImperativeHandle(ref, () => ({
		resetError() {
			setIsFocusOnce(false);
		},
		showError() {
			// !errorOnBlur && setIsFocusOnce(true);
			setIsFocusOnce(true);
		},
		focus(autoScroll: boolean = true) {
			inputRef?.current?.focus();
			autoScroll && inputRef?.current?.scrollIntoView({ behavior: 'smooth' });
			// dateRef?.current?.setFocus();
		},
		clear() {
			// setInternalValue("");
		},
		// setErrorMessage(err, force) {
		// 	setError(err);
		// 	force && setIsFocusOnce(true);
		// },
		getName() {
			return props.name;
		},
		value: props.value || '',
	}));

    useEffect(() => {
        setError(undefined);
    }, [props.value]);

    const errorToDisplay = props.error || error;

    const showError = (isFocusOnce || props.forceValidation) && errorToDisplay;

    const onKeyDownHandler = (e: any) => {
        if (props.onKeyDown) return props.onKeyDown(e);
        if (e.key === "Enter") {
            onEnter?.(e.target.value, e.target.name);
        }
    };

    return (
		<InputWrapper {...rst} error={showError ? errorToDisplay : undefined} onOptionSelect={onOptionSelect} selectedValue={selectedValue}>
			{props.isViewMode ? (
				<Flex height="3.6rem" align="end" justify="start">
					<OverflowText>{props.value || '---------'}</OverflowText>
				</Flex>
			) : (
				<>
					<StyledInput
						{...rst}
						flex={ratio || props.options ? 1.5 : 1}
						onKeyDown={onKeyDownHandler}
						className={classNames(inputClassName, props.className || '', {
							inverse: props.inverse,
							flat: props.flat,
						})}
						disabled={props.disabled || props.isTotal || props.isLoading || (!!rst.options && isNullOrUndefined(selectedValue))}
						onBlur={(e) => {
							setIsFocusOnce(true);
							props.onBlur?.(e);
						}}
						placeholder={props.placeholder ? t(props.placeholder) : props.placeholder}
						onChange={(e: any) => {
							// setIsFocusOnce(!errorOnBlur);
							setIsFocusOnce(false);
							setError(undefined);
							props.onChange?.(e.target.value, e.target.name);
						}}
						isMerged={!!rst.options && !!onOptionSelect}
						onClick={(e) => {
							e.stopPropagation();
							props.onClick?.(e);
						}}
						value={props.value || ''}
						data-value={props.value}
						ref={inputRef}
						data-qaid={props.qaid}
						autoComplete="off"
					/>
					{props.isLoading && <CircularProgress className="loading-spinner" size={14} />}
				</>
			)}
		</InputWrapper>
	);
});

export default TextInput;
