import SquareIcon from "@mui/icons-material/Square";
import { FC } from "react";
import { ChartLegendItem, ChartLegendItemAmount, ChartLegendItemFigure, ChartLegendItemLabel, StyledChartLegend } from "./BenChartLegend.style";
import { defaultColors } from "../../../../../../Shared/Components/WidgetDashboard/shared/components/DoughnutChart/DoughnutChart.style";
import OverflowText from "../../../../../../Shared/Components/OverflowText";
import { formatNumber } from "../../../../../../Shared/Utilities";

export interface ChartLegendProps {
    colors?: string[];
    labels: string[];
    figures: number[] | string[];
    amounts: number[];
    total: number;
    className?: string;
}

function sortLegendItems(legendItems: any[]) {
    const lastItem = legendItems.find((item) => item.label === "Others");
    const items = legendItems.filter((item) => item.label !== "Others");

    if (lastItem) {
        items.push(lastItem);
    }

    return items;
}

const BenChartLegend: FC<ChartLegendProps> = ({ colors = defaultColors, labels, figures, className, total, amounts }: ChartLegendProps) => {
    if (!labels || labels.length <= 0) {
        return null;
    }

    const formatFigure = (figure: number | string): string => figure.toString() + "%";

    const legendItems = labels.map((label, index) => ({
        color: colors[index],
        label: label,
        figure: figures[index],
        amount: amounts[index]
    }));

    const sortedLegendItems = sortLegendItems(legendItems);

    return (
        <StyledChartLegend className={className}>
            {sortedLegendItems.map((item, index) => (
                <>
                    <ChartLegendItem style={{ borderBottom: (sortedLegendItems.length !== index + 1) ? '1px solid #D0D4E4' : 'none' }} key={index}>
                        <SquareIcon style={{ color: item.color }} />
                        <ChartLegendItemLabel>
                            <OverflowText>{item.label}</OverflowText>
                        </ChartLegendItemLabel>
                        <ChartLegendItemAmount>{formatNumber(item.amount)}</ChartLegendItemAmount> {/* Linoy: to fix */}
                        <ChartLegendItemFigure>{formatFigure(item.figure)}</ChartLegendItemFigure>
                    </ChartLegendItem>
                </>
            ))}
        </StyledChartLegend>
    );
};

export default BenChartLegend;