import styled from '@emotion/styled';
import { PoolStep } from '../../../../../../Models/API/EquityPlans/pool-step';
import Title from '../../../../../../Shared/Components/Layout/Title';
import appConfig from '../../../../../../config/config';
import AddPoolStep from './AddPoolStep';
import useRootStore from '../../../../../../Shared/Hooks/useRootStore';
import { css } from '@emotion/css';
import { observer } from 'mobx-react-lite';

const ScrollContainer = styled.div({
	label: 'ScrollContainer',
	overflowY: 'auto',
	height: '100%',
	marginTop: '1.6rem',
	borderTop: `1px solid ${appConfig.style.colors.table}`,
});

type Props = {
	planId: number;
	approvalDate: Date;
};

const FormStyle = css({
	display: 'grid',
	gridColumnGap: '2.4rem',
	gridRowGap: '1.6rem',
	'&__stepDate': {
		order: 1,
		flex: 1,
		height: 'fit-content',
	},
	'&__amount': {
		order: 2,
		flex: 1,
		height: 'fit-content',
	},
	'&__totalPool': {
		order: 3,
		flex: 1,
		height: 'fit-content',
	},
	'&__comment': {
		order: 4,
		flex: 1,
	},
	'&__menu': {
		order: 5,
		alignSelf: 'center',
	},
	'&[data-mode="view"]': {
		gridTemplateColumns: '1fr 1fr 1fr 0.5fr 0.5fr',
		'div[data-col-type="comment"]': {
			maxWidth: '15rem',
			overflow: 'hidden',
			textOverflow: 'ellipsis',
			whiteSpace: 'nowrap',
		},
	},
	'&__buttons': {
		gridArea: '2 / 4 / 2 / 4',
	},
	'&[data-mode="edit"]': {
		gridTemplateColumns: '1fr 1fr 1fr 0.25fr',
	},
	padding: '2rem 3.4rem',
	borderBottom: `1px solid ${appConfig.style.colors.table}`,
	position: 'relative',
	'&::before': {
		content: 'attr(data-index)',
		position: 'absolute',
		left: 0,
		top: '2rem',
	},
});

const PoolStepsHistory = ({ planId, approvalDate }: Props) => {
	const { equityPlansStore } = useRootStore();

	return (
		<>
			<Title>Pool history</Title>
			<ScrollContainer>
				{equityPlansStore.poolSteps?.map((poolStep, idx) => (
					<AddPoolStep
						key={poolStep.poolStepsId}
						poolStep={poolStep}
						planId={planId}
						className={FormStyle}
						totalPool={poolStep.totalPool}
						approvalDate={idx === equityPlansStore.poolSteps!.length - 1 ? approvalDate : new Date(equityPlansStore.poolSteps![idx + 1].stepDate)}
						data-index={`${equityPlansStore.poolSteps!.length - idx}.`}
					/>
				))}
			</ScrollContainer>
		</>
	);
};

export default observer(PoolStepsHistory);
