import { FC, useMemo, useState } from "react";

import { IC_REPORTS_CIRCLE } from "../../../../Assets";
import { ExpensingDashboardData } from "../../../../Models/API/Expensing/dashboard-data";
import { AmortizationDate, ExpensingMethod } from "../../../../Models/API/Expensing/options-migration";
import CircularSwitchToggle from "../../../../Shared/Components/CircularSwitchToggle/CircularSwitchToggle";
import { } from "../../../../Shared/Components/WidgetDashboard/CaptableDashboard/CaptableDashboard.style";
import NoData from "../../../../Shared/Components/WidgetDashboard/CaptableDashboard/dependencies/components/SwitchSelectChartWidget/NoData/NoData";
import WidgetDashboard from "../../../../Shared/Components/WidgetDashboard/shared/components/WidgetDashboard/WidgetDashboard";
import { formatDate, formatDecimal, formatNumber, isNullOrUndefined, isNumber } from "../../../../Shared/Utilities";
import DashboardText from "../../../../Shared/Components/Dashboard/DashboardText";
import { ExpensingDashboardComponents, ExpensingDashboardStyle } from "./index.style";
import ChartLabels from "../../../../Shared/Components/Dashboard/ChartLabels";
import { ChartLegendItem, ChartLegendItemFigure, ChartLegendItemLabel } from "../../../../Shared/Components/Dashboard/ChartLabels.style";
import classNames from "classnames";
import { ExpenseCompanyReportStatus } from "../../../../Models/API/Expensing/expense-report-status-enum";
import { toJS } from "mobx";
import { Currency, currencies } from '../../../../Shared/Config';
import { GrantReportPeriodEnum } from '../../../../Models/API/Expensing/grant-report-period-enum';
import DoughnutChart from "../../../../Shared/Components/WidgetDashboard/shared/components/DoughnutChart/DoughnutChart";

interface ExpensingDashboardProps {
	data?: ExpensingDashboardData;
	onToggle?: (val: boolean) => void;
}

const getTotal = (obj: { previousAmortizedExpense: number; expenseToAmortizeForPeriod: number; unamortizedExpense: number }) => {
	return Object.keys(obj).reduce((acc, key) => acc + obj[key as keyof typeof obj], 0);
};

type CalculatedData = {
	expenses: CalculatedType;
	department: CalculatedType;
};

type CalculatedType = {
	labels: string[];
	figures: string[];
	tooltipFigures?: number[];
	centerFigures?: string[];
	total: number;
};

const ExpensingDashboard: FC<ExpensingDashboardProps> = ({ data, onToggle }) => {
	const [isByDepartment, setIsByDepartment] = useState<boolean>(false);

	const currency = currencies.find((c) => c.currencyId === data?.operationalCurrency)?.symbol;

	const calculatedData: CalculatedData = useMemo(() => {
		if (isNullOrUndefined(data)) {
			const emptyObj: CalculatedType = {
				labels: [],
				figures: [],
				tooltipFigures: [],
				centerFigures: undefined,
				total: 0,
			};
			return {
				expenses: emptyObj,
				department: emptyObj,
			};
		}
		const total = getTotal(data.pie.expensesSegmentation);
		return {
			expenses: {
				labels: ['Previous armotized expense', 'Expense to armotize for period', 'Unarmotized expense'],
				figures: Object.keys(data.pie.expensesSegmentation).map((key) =>
					formatNumber((data.pie.expensesSegmentation[key as keyof typeof data.pie.expensesSegmentation] / total) * 100)
				),
				tooltipFigures: Object.keys(data.pie.expensesSegmentation).map(
					(key) => data.pie.expensesSegmentation[key as keyof typeof data.pie.expensesSegmentation]
				),
				centerFigures: undefined,
				total,
			},
			department: data.pie.departmentSegmentation.departmentExpenses.reduce(
				(acc, department) => {
					acc.labels.push(department.department);
					acc.figures.push(formatNumber(department.expensePercentage) + '');
					acc.tooltipFigures?.push(department.expenseForPeriod);
					acc.centerFigures?.push(formatNumber(department.expenseForPeriod));
					acc.total += department.expenseForPeriod;
					return acc;
				},
				{
					labels: [],
					figures: [],
					tooltipFigures: [],
					centerFigures: [],
					total: 0,
				} as CalculatedType
			),
		};
	}, [data]);

	const dataByType = calculatedData[isByDepartment ? 'department' : 'expenses'];

	const noData = !dataByType.figures.length;

	const getCalculationMethodLabel = (method?: ExpensingMethod, amorization?: AmortizationDate) => {
		switch (method) {
			case ExpensingMethod.StraightLine:
				return amorization === AmortizationDate.VestingStartDate
					? 'Non ratable straight line from start vesting date'
					: 'Non ratable straight line from grant date';
			case ExpensingMethod.Accelerated:
				return amorization === AmortizationDate.VestingStartDate ? 'Accelerated from start vesting date' : 'Accelerated from grant date';
			default:
				return undefined;
		}
	};

	// if (isNullOrUndefined(data)) return <></>;

	return (
		<WidgetDashboard onToggle={onToggle}>
			<ExpensingDashboardComponents.Wrapper className={ExpensingDashboardStyle}>
				<ExpensingDashboardComponents.ChartWidgetWrapper>
					<div className={`${ExpensingDashboardStyle}__chart-container`}>
						{noData ? (
							<NoData content="Data will be presented once expensing report is created" />
						) : (
							<>
								<div className="chart-part">
									<CircularSwitchToggle
										value={isByDepartment}
										actions={[
											{ value: false, label: 'Expenses' },
											{ value: true, label: 'By department' },
										]}
										onChange={(value) => setIsByDepartment(value)}
										type="secondary"
										size="sm"
										style={{ width: '25rem' }}
									/>
										<DoughnutChart ap={{ ...dataByType, expensingPie: true }} />
								</div>
								<div className={classNames('labels-part', { expense: !isByDepartment })}>
									{isByDepartment && (
										<ChartLegendItem className="label-top">
											<ChartLegendItemLabel className="dash-title">Expenses for period:</ChartLegendItemLabel>
											<ChartLegendItemFigure className="center-figure">
												{currency}
												{formatNumber(dataByType.total)}
											</ChartLegendItemFigure>
											<ChartLegendItemFigure></ChartLegendItemFigure>
										</ChartLegendItem>
									)}
									<ChartLabels
										labels={dataByType.labels}
										figures={dataByType.figures}
										centerFigures={dataByType.centerFigures?.map((f) => (
											<>
												{currency}
												{f}
											</>
										))}
									/>
								</div>
							</>
						)}
					</div>
				</ExpensingDashboardComponents.ChartWidgetWrapper>

				<ExpensingDashboardComponents.TopWidgetWrapper img={IC_REPORTS_CIRCLE}>
					<DashboardText title="Period" text={isNumber(data?.period) ? GrantReportPeriodEnum[data.period] : '-'} />
					<DashboardText title="Start date" text={formatDate(data?.reportStartDate)} />
					<DashboardText title="End date" text={formatDate(data?.reportEndDate)} />
					<DashboardText
						title="Based on report"
						text={
							data?.basedOnReportName ? (
								<>
									{data?.basedOnReportName} <span className="published">(published)</span>
								</>
							) : (
								'---'
							)
						}
					/>
				</ExpensingDashboardComponents.TopWidgetWrapper>

				<ExpensingDashboardComponents.BottomLeftWidgetWrapper>
					<DashboardText
						title="Total Expenses"
						text={data ? `${currency}${formatDecimal(data?.expenseDetails.totalExpense, { decimalLength: 2 })}` : ''}
					/>
					<DashboardText
						title="Armotized"
						text={data ? `${currency}${formatDecimal(data?.expenseDetails.amortizedExpense, { decimalLength: 2 })}` : ''}
					/>
					<DashboardText
						title="Unarmotized"
						text={data ? `${currency}${formatDecimal(data?.expenseDetails.unamortizedExpense, { decimalLength: 2 })}` : ''}
					/>
				</ExpensingDashboardComponents.BottomLeftWidgetWrapper>

				<ExpensingDashboardComponents.BottomRightWidgetWrapper>
					<DashboardText
						title="Forfeiture rate"
						text={data?.forfeitureRate === -1 ? 'Per grant' : data?.forfeitureRate ? `${formatNumber(data.forfeitureRate * 100)}%` : '-'}
					/>
					<DashboardText
						title="Calculation method"
						text={getCalculationMethodLabel(data?.calculationMethod, data?.amortizationStartDate)} // to replace by text
					/>
				</ExpensingDashboardComponents.BottomRightWidgetWrapper>
			</ExpensingDashboardComponents.Wrapper>
		</WidgetDashboard>
	);
};

export default ExpensingDashboard;
