import { css } from "@emotion/css";
import appConfig from "../../../config/config";
import Clickable from "../Clickable/Clickable";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import MoreHorIcon from "@mui/icons-material/MoreHoriz";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Fade from "@mui/material/Fade";
import classNames from "classnames";
import Image, { ImageProps } from "../Image";
import { IC_MENU_HORIZONTAL, IC_MENU_HORIZONTAL_HOVER, IC_MENU_VERTICAL, IC_MENU_VERTICAL_HOVER } from "../../../Assets";
import Flex from "../Layout/Flex";

const Styles = css({
    label: "Menu",
    position: "relative",
    marginLeft: "auto",
    height: "100%",
    // width: "3rem",
    // height: "3rem",
    // borderRadius: 8,
    "&__menu": {
        padding: "1.2rem",
        zIndex: 999999,
        position: "absolute",
        top: "calc(100% - 0.5rem)",
        boxShadow: appConfig.style.boxShadow.card1,
        border: `1px solid ${appConfig.style.colors.background1}`,
        background: "#ffffff",
        display: "flex",
        gap: "2rem",
        right: "2rem",
        borderRadius: 8,
        alignItems: "center",
        width: "max-content",
        "&:not(.horizontal)": {
            flexDirection: "column",
            minWidth: "15rem",
        },
        "&.right": {
            right: "unset",
            left: "4rem",
            top: "-1.2rem",
        },
    },
    "&__menu-item": {
        background: "#ffffff",
        userSelect: "none",

        // img: {
        //     width: "2rem",
        // },

        "&.commingSoon": {
            opacity: 0.5,
        },
    },
});

interface Props {
    items: MenuItem[];
    isHorizontal?: boolean;
    autoClose?: () => boolean;
    right?: boolean;
    position?: { left?: number | string; top?: number | string; bottom?: number | string; right?: number | string };
    className?: string;
}

export interface MenuItem {
    label: string;
    qaid: string;
    icon?: string;
    onClick?: () => void;
    hide?: boolean;
    comingSoon?: boolean;
    disabled?: boolean;
    props?: ImageProps;
    customTooltip?: string;
}

export type MenuElement = Partial<HTMLDivElement> & { hide: () => void; show: () => void };

const Menu = forwardRef<MenuElement, Props>(({ items, isHorizontal, autoClose, right, position, className = '', ...props }: Props, forwardedRef) => {
	const [showMenu, setShowMenu] = useState<boolean>(false);

	useImperativeHandle(forwardedRef, () => ({
		hide: () => setShowMenu(false),
		show: () => setShowMenu(true),
	}));

	useEffect(() => {
		const shouldClose = autoClose?.();
		if (!shouldClose) return;

		setShowMenu(false);
	}, [autoClose]);

	return (
		<div className={classNames(Styles, { [className]: !!className })} {...props}>
			<ClickAwayListener
				onClickAway={() => {
					setShowMenu(false);
				}}
			>
				<Flex flex={0} align="center" justify="center">
					<Clickable
						qaid="Menu.Button.Toggle"
						// onClick={onDeleteShareholder}
						onClick={() => setShowMenu(!showMenu)}
						height="100%"
					>
						<Image
							data-type="menu-img"
							width="3rem"
							src={isHorizontal ? IC_MENU_HORIZONTAL : IC_MENU_VERTICAL}
							srcHover={isHorizontal ? IC_MENU_HORIZONTAL_HOVER : IC_MENU_VERTICAL_HOVER}
						/>
						{/* {isHorizontal ? <MoreHorIcon fontSize="large" /> : <MoreVertIcon />} */}
					</Clickable>
					<Fade in={showMenu} timeout={100} unmountOnExit>
						<div
							className={classNames(`${Styles}__menu`, { horizontal: isHorizontal, right })}
							style={{ left: position?.left, right: position?.right, bottom: position?.bottom, top: position?.top }}
						>
							{items
								.filter((itm) => !itm.hide)
								.map((item) => (
									<Clickable
										qaid={item.qaid}
										onClick={() => {
											if (item.comingSoon || item.disabled) return;
											item.onClick?.();
											setShowMenu(false);
										}}
										flex="0 0 2rem"
										height="100%"
										margin="0 0 0 auto"
										className={classNames(`${Styles}__menu-item`, { commingSoon: item.comingSoon })}
										gap="1.2rem"
										justify="start"
										key={item.label}
										tooltip={item.comingSoon ? 'Coming soon!' : undefined}
										disabled={item.disabled}
									>
										<>
											{!!item.icon && (
												<Image
													src={item.icon}
													alt=""
													width={item.props?.width ?? '2rem'}
													height="auto"
													tooltip={item.customTooltip || item.label}
													{...item.props}
												/>
											)}
											{!isHorizontal && <span>{item.label}</span>}
										</>
									</Clickable>
								))}
						</div>
					</Fade>
				</Flex>
			</ClickAwayListener>
		</div>
	);
});

export default Menu;
