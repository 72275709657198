import { css } from '@emotion/css';
import classNames from 'classnames';
import { IC_LIST_ITEM } from '../../../Assets';
import appConfig from '../../../config/config';
import { breakpoints } from '../../../config/style/theme';
import { WelcomePageProps } from '../../../Models/App/welcome-page';
import useGeneralModal from '../../Hooks/useGeneralModal';
import Button from '../Button/Button';
import Image from '../Image';

const Style = css({
	label: 'WelcomePage',
	display: 'flex',
	padding: '3rem 10rem',
	gap: '10rem',
	background: `linear-gradient(90deg, ${appConfig.style.colors.background1} 0%, #ffffff 100%)`,
	'&__info-container': {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		flex: 1,
	},
	'&__title': {
		fontSize: '4rem',
		fontWeight: 900,
		color: appConfig.style.colors.color1,
		lineHeight: '5.5rem',
		[`@media screen and (min-width: ${breakpoints.sm})`]: {
			whiteSpace: 'pre',
		},
	},
	'&__benefits': {
		display: 'flex',
		flexDirection: 'column',
		gap: '2rem',
		margin: '2.5rem 0',
	},
	'&__benefit': {
		lineHeight: '4rem',
		display: 'flex',
		alignItems: 'flex-start',
		gap: '2rem',
		fontSize: '1.8rem',
		img: {
			borderRadius: '50%',
			background: '#ffffff',
		},
		// [`@media screen and (min-width: ${breakpoints.sm})`]: {
		// 	whiteSpace: 'pre',
		// },
		'> span': {
			marginTop: '-1rem',
		},
	},
	'&__preview': {
		height: 'calc(100dvh - 14.3rem)',
		img: {
			height: '100%',
			width: '100%',
			minWidth: '40rem',
			maxWidth: '80rem',
		},
		[`@media screen and (max-width: ${breakpoints.sm})`]: {
			display: 'none',
		},
	},
	'&__actions': {
		display: 'flex',
		alignItems: 'center',
		gap: '3rem',
		'.back-btn': {
			color: appConfig.style.colors.color1,
			fontWeight: 500,
			cursor: 'pointer',
		},
	},
});

const GetStartedPage = ({ logo, title, benefits, previewImage, action, ...rst }: WelcomePageProps) => {
	const { onUpgradePlan } = useGeneralModal();

	return (
		<section {...rst} className={classNames(Style, rst.className)}>
			<div className={`${Style}__info-container`} data-el-type="info-container">
				<Image src={logo} width="10rem" data-el-type="img-logo" />
				<span className={`${Style}__title`} data-el-type="title">
					{title}
				</span>
				<div className={`${Style}__benefits`} data-el-type="benefits">
					{benefits.map((benefit, idx) => (
						<div className={`${Style}__benefit`} key={idx}>
							<Image src={IC_LIST_ITEM} width="2.5rem" />
							<span>{benefit}</span>
						</div>
					))}
				</div>

				<div className={`${Style}__actions`} data-el-type="actions">
					{action ? (
						<Button {...action} data-el-type="action" />
					) : (
						<Button qaid="WelcomePage.Button.GetStarted" label="Get Started" onClick={onUpgradePlan} width="16rem" data-el-type="action" />
					)}
				</div>
			</div>
			<div className={`${Style}__preview`} data-el-type="img-preview">
				<Image src={previewImage} />
			</div>
		</section>
	);
};

export default GetStartedPage;
