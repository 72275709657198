import { css } from '@emotion/css';
import appConfig from '../../../../../../config/config';

const newEquityPlanStyle = css({
	label: 'NewEquityPlan',
	display: 'flex',
	flexDirection: 'column',
	padding: '6rem 7.7rem 6.7rem 7.7rem',
	width: '100rem',
	overflow: 'hidden',
	'&__title': {
		paddingBottom: '2rem',
		borderBottom: `1px solid ${appConfig.style.colors.table}`,
	},
	'&__forms': {
		display: 'flex',
		flexDirection: 'column',
		'&.multiple': {
			overflow: 'auto',
			paddingRight: '1rem',
		},
	},

	'&__form': {
		display: 'flex',
		flexDirection: 'column',
		gap: '2rem',
		paddingTop: '2rem',
		'&:not(:first-of-type)': {
			borderTop: `1px solid ${appConfig.style.colors.table}`,
		},
		'.title': {
			fontWeight: 500,
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'space-between',
		},
	},
	'&__actions': {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		paddingTop: '2rem',
		'&.border-top': {
			borderTop: `1px solid ${appConfig.style.colors.table}`,
		},
		'&.steps': {
			marginTop: '2rem',
		},
	},
});

export default newEquityPlanStyle;
