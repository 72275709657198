import { useState } from "react"
import { IC_CHANGE_ARROWS } from "../../../../../Assets"
import Button from "../../../../../Shared/Components/Button/Button"
import { ModalBodyProps, ModalProps } from "../../../../../Shared/Components/Modal/types"
import useRootStore from "../../../../../Shared/Hooks/useRootStore"

import DataSourceStyle from './ChangeDataSource.module.css'
import useTrusteeModals from "../../../../TrusteeEquity/useTrusteeModals"

interface Props extends ModalBodyProps {
    fromPivotal: boolean
    initialRegistrartion?: boolean
}

const ChangeDataSource = (props: Props) => {
    const [working, setWorking] = useState(false)
    const { companyStore, trusteeStore } = useRootStore()
    const { showPivotalRegistration } = useTrusteeModals()

    const onChangeConfirm = async () => {
        console.log('Change data source (fromPivotal) ', props.fromPivotal)
        setWorking(true)

        if (props.fromPivotal) {

            const tRes = await trusteeStore.GetPoolSummary()
            if (tRes.errorCode == 1061 || tRes.statusCode == 420) {
                console.log('Data Source RES, TRES', tRes)
                showPivotalRegistration(false)

                setTimeout(() => {
                    props.removeModal?.()
                }, 600);


                return
            }
        }
        console.log('Not returned')
        const res = await companyStore.setCompanyEquitySource(props.fromPivotal);

        props.removeModal?.()
    }

    return (
        <div className={DataSourceStyle.cardContainer}>

            <img src={IC_CHANGE_ARROWS} height={70} />
            <h1>Change data source</h1>
            <p>Are you sure you want to change the source of</p>
            <p>Equity Plans data?</p>
            <div className={DataSourceStyle.buttons}>
                <Button
                    qaid=""
                    label="No"
                    cancel
                    onClick={props.removeModal}
                />
                <Button
                    qaid=""
                    label="Yes"
                    onClick={onChangeConfirm}
                    isLoading={working}
                />
            </div>
        </div>
    )
}

export default ChangeDataSource