import IsoTableStyles from './IsoTable.style';
import { GrantISOVestingStatusEnum, IsoListType } from '../../../../../Models/API/EquityPlans/IsoNso';
import { formatDate, formatNumber, onExportToExcel } from '../../../../../Shared/Utilities';
import Image from '../../../../../Shared/Components/Image';
import { IC_EXCEL_EXPORT, IC_FULLSCREEN_EXPAND, IC_FULLSCREEN_SHRINK, IC_WARNING_INFO_CIRCLE } from '../../../../../Assets';
import Clickable from '../../../../../Shared/Components/Clickable/Clickable';
import { TableColumn } from '../../../../../Shared/Components/Table/Table';
import useEsopModals from '../../../useEsopModals';
import appConfig from '../../../../../config/config';

interface IProps {
	data: IsoListType[];
	grantId: string;
	otherGrantId: string;
	updateChangestable?: boolean;
	isFullscreen?: boolean;
}

const IsoTable = (props: IProps) => {
	const { data, updateChangestable, grantId, isFullscreen, otherGrantId } = props;
	const { showFullScreenIsoModal } = useEsopModals();

	const columns: TableColumn<IsoListType>[] = [
		{ label: 'Date exercisable', name: 'vestDate', format: (val, obj) => (obj?.isYearTotal ? `${obj.year} year total` : formatDate(val)) },
		{ label: 'ISO awards', name: 'isoVestingAmount', format: (val) => (!val ? '-----' : formatNumber(val)) },
		{ label: 'ISO Value', name: 'isoValue', format: (val) => (!val ? '-----' : `$${formatNumber(val)}`) },
		{ label: 'NSO awards', name: 'nsoVestingAmount', format: (val) => (!val ? '-----' : formatNumber(val)) },
		{ label: 'ISO awards', name: 'otherGrantsIsoAmount', format: (val) => (!val ? '-----' : formatNumber(val)) },
		{ label: 'ISO Value', name: 'otherGrantsValue', format: (val) => (!val ? '-----' : `$${formatNumber(val)}`) },
	];

	const onExportHandler = () => {
		onExportToExcel({
			rows: data,
			exportToExcel: { fileName: 'ISO-NSO split', sheetName: 'ISO-NSO split' },
			color: 'primary',
			columns: columns,
			onAfterDataHandler: (ws) => {
				const grantTitle = `Grant ${grantId}`;
				const otherGrantTitle = `Other grants ${otherGrantId.replace(/,/g, ', ')}`;
				const header = ['', grantTitle, grantTitle, grantTitle, otherGrantTitle, otherGrantTitle];

				ws.insertRow(1, header).eachCell((cell) => {
					cell.fill = {
						type: 'pattern',
						pattern: 'solid',
						fgColor: {
							argb: appConfig.style.colors.color1.replace('#', ''),
						},
					};
					cell.alignment = {
						horizontal: 'center',
						vertical: 'middle',
					};
					cell.font = {
						...cell.font,
						color: {
							argb: 'ffffff',
						},
						bold: true,
					};
				});

				const borderColor = appConfig.style.colors.table.replace('#', '');
				for (let i = 0; i <= header.length; i++) {
					const lastIndex = header.lastIndexOf(header[i]); // Getting the last index of the title
					if (lastIndex === -1) break; // If not found, exit from the loop

					// Vertical merging if empty
					if (!ws.getCell(2, i + 1).value && lastIndex === i) {
						try {
							ws.mergeCells(1, i + 1, 2, i + 1);
						} catch (error) { }
					}

					// Settings right border to all the cells
					ws.getColumn(i + 1).eachCell((cell) => {
						cell.border = {
							...cell.border,
							right: { style: 'thin', color: { argb: borderColor } },
						};
					});

					// Settings right medium border by ranges
					ws.getColumn(lastIndex + 1).eachCell((cell) => {
						cell.border = {
							...cell.border,
							right: { style: 'thin', color: { argb: borderColor } },
						};
					});

					// 	// If last index is the current iterator, that means the title is only in one place, don't need to merge
					if (lastIndex === i) continue;

					// 	// Merging cells with try & catch (important to continue with the code)
					try {
						ws.mergeCells(1, i + 1, 1, lastIndex + 1);

						const mergedCell = ws.getCell(1, lastIndex);
						mergedCell.border = {
							...mergedCell.border,
							right: { style: 'thin', color: { argb: borderColor } },
						};
					} catch (error) { }
					//   i = lastIndex; // Setting the iterator to the last index to check the next title
				}

				return ws;
			},
		});
	};

	const updateSpan = (status: GrantISOVestingStatusEnum, origin: string, towValues: boolean = false, update?: string) => {
		if (towValues && status === GrantISOVestingStatusEnum.Updated) {
			return (
				<span>
					<span style={{ textDecoration: 'line-through' }}>{origin}</span> <span style={{ color: '#68CE61' }}>{update}</span>
				</span>
			);
		} else {
			if (status === GrantISOVestingStatusEnum.New) {
				return <span style={{ color: '#68CE61' }}>{origin}</span>;
			} else if (status === GrantISOVestingStatusEnum.Removed) {
				return <span style={{ textDecoration: 'line-through' }}>{origin}</span>;
			} else {
				return <span>{origin}</span>;
			}
		}
	};

	return (
		<div className={IsoTableStyles}>
			{!updateChangestable && (
				<div className={`${IsoTableStyles}__titleWrap`}>
					<b>ISO/NSO Split</b>
					<div className={`${IsoTableStyles}__actionsWrap`}>
						<Clickable flex={0} onClick={onExportHandler} qaid="Toolbar.Button.Export">
							<Image src={IC_EXCEL_EXPORT} alt={`${'ISO/NSO'}.xlsx`} tooltip="Export to Excel" width="2.6rem" />
						</Clickable>
						<Clickable
							flex={1}
							onClick={() => showFullScreenIsoModal(data, grantId, otherGrantId)}
							qaid="Toolbar.Button.FullScreen"
							title={isFullscreen ? 'Exit fullscreen' : 'View in fullscreen'}
						>
							{!isFullscreen && (
								<Image
									width="2.6rem"
									className="icon--action"
									src={isFullscreen ? IC_FULLSCREEN_SHRINK : IC_FULLSCREEN_EXPAND}
									alt="Fullscreen"
									tooltip={isFullscreen ? 'Exit fullscreen' : 'View in fullscreen'}
								/>
							)}
						</Clickable>
					</div>
				</div>
			)}
			<br />

			<div
				style={{
					maxHeight: updateChangestable ? '35rem' : '55rem',
					overflow: 'auto',
					position: 'relative',
					borderRadius: '15px',
					WebkitMaskImage: '-webkit-radial-gradient(white, black)',
					borderRight: '1px solid #D0D4E4',
					borderBottom: '1px solid #D0D4E4',
				}}
			>
				<table className={`${IsoTableStyles}__table`}>
					<thead className={`${IsoTableStyles}__headerWrap`}>
						<tr>
							<th colSpan={1} className={`${IsoTableStyles}__header`}></th>
							<th colSpan={3} className={`${IsoTableStyles}__header`}>
								Grant {grantId}
							</th>
							<th colSpan={2} className={`${IsoTableStyles}__header`}>
								<div className={`${IsoTableStyles}__otherGrants`}>
									<span>Other grants</span>
									<Image tooltip={otherGrantId.replace(/,/g, ', ')} width={17} src={IC_WARNING_INFO_CIRCLE} alt="Other grants" />
								</div>
							</th>
						</tr>
						<tr>
							{columns.map((row, index) => (
								<th key={index} className={`${IsoTableStyles}__header`}>
									{row.label}
								</th>
							))}
						</tr>
					</thead>
					<tbody>
						{data.map((row, index) => (
							<tr key={index}>
								<td className={`${IsoTableStyles}__tableData`} style={{ backgroundColor: row.isYearTotal ? '#ECECEC' : '' }}>
									{updateChangestable
										? updateSpan(row.status, row?.isYearTotal ? `${row.year} year total` : formatDate(row.vestDate) || '')
										: row?.isYearTotal
											? `${row.year} year total`
											: formatDate(row.vestDate)}
								</td>

								<td className={`${IsoTableStyles}__tableData`} style={{ backgroundColor: row.isYearTotal ? '#ECECEC' : '' }}>
									{updateChangestable
										? updateSpan(row.status, formatNumber(row.originalISOVested), true, formatNumber(row.isoVestingAmount))
										: !row.isoVestingAmount
											? '-----'
											: formatNumber(row.isoVestingAmount)}
								</td>

								<td className={`${IsoTableStyles}__tableData`} style={{ backgroundColor: row.isYearTotal ? '#ECECEC' : '' }}>
									{updateChangestable
										? updateSpan(row.status, formatNumber(row.originalISOValue), true, formatNumber(row.isoValue))
										: !row.isoValue
											? '-----'
											: `$${formatNumber(row.isoValue)}`}
								</td>

								<td className={`${IsoTableStyles}__tableData`} style={{ backgroundColor: row.isYearTotal ? '#ECECEC' : '' }}>
									{updateChangestable
										? updateSpan(row.status, formatNumber(row.originalNSOVested), true, formatNumber(row.nsoVestingAmount))
										: !row.nsoVestingAmount
											? '-----'
											: formatNumber(row.nsoVestingAmount)}
								</td>

								<td className={`${IsoTableStyles}__tableData`} style={{ backgroundColor: row.isYearTotal ? '#ECECEC' : '' }}>
									{updateChangestable && row.otherGrantsIsoAmount
										? updateSpan(row.status, formatNumber(row.otherGrantsIsoAmount))
										: !row.otherGrantsIsoAmount
											? '-----'
											: formatNumber(row.otherGrantsIsoAmount)}
								</td>

								<td className={`${IsoTableStyles}__tableData`} style={{ backgroundColor: row.isYearTotal ? '#ECECEC' : '' }}>
									{updateChangestable && row.otherGrantsValue
										? updateSpan(row.status, formatNumber(row.otherGrantsValue))
										: !row.otherGrantsValue
											? '-----'
											: `$${formatNumber(row.otherGrantsValue)}`}
								</td>
							</tr>
						))}
					</tbody>
				</table>
			</div>
		</div>
	);
};

export default IsoTable;
