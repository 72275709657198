import { useRef } from 'react';
import { useFetchApi } from '../../../Shared/Hooks/useFetchApi';
import { WaterfallPeer } from '../../../Models/API/Waterfall/IForm';
import useRootStore from '../../../Shared/Hooks/useRootStore';
import { css } from '@emotion/css';
import appConfig from '../../../config/config';
import CircularProgress from '@mui/material/CircularProgress';
import { formatDate, formatDecimal } from '../../../Shared/Utilities';
import { currencies } from '../../../Shared/Config';

const TooltipStyle = css({
	label: 'PeerTooltip',
	fontSize: '1.3rem',
	lineHeight: '1.8rem',
	letterSpacing: '2%',
	padding: '0.8rem',
	maxWidth: '40rem',
	fontFamily: appConfig.style.fonts.textPro,
	textAlign: 'start',
	'.light': {
		fontWeight: 400,
	},
	'> div': {
		'> span:first-child': {
			marginRight: '0.4rem',
		},
	},
	'.comment': {
		color: '#AFAFAF',
	},
	'.desc': {
		letterSpacing: '0.15rem',
		lineHeight: '2rem',
	},
});

const useVolatilityTooltip = () => {
	const { run, data: tooltipData, clearData } = useFetchApi<WaterfallPeer>();
	const hoverRef = useRef<NodeJS.Timeout[]>([]);
	const { valuationStore } = useRootStore();

	const onTooltipEnter = (stockId: number) => {
		hoverRef.current[stockId] = setTimeout(() => {
			run(() => valuationStore.getPeerInfo(stockId), {
				cacheKey: `peer-${stockId}-${valuationStore.generalForm.valuationDate}`,
				updateState: true,
			});
		}, 300);
	};

	const onTooltipLeave = (stockId: number) => {
		clearTimeout(hoverRef.current[stockId]);
		delete hoverRef.current[stockId];
		clearData();
	};

	const getTooltip = (peer: WaterfallPeer, tooltipData?: WaterfallPeer | null) => {
		const currency = currencies.find((c) => c.currencyId === tooltipData?.currency);
		return (
			<div className={TooltipStyle}>
				<div>
					<span className="bold">Company name:</span>
					<span className="light">{peer.companyName}</span>
				</div>
				<div>
					<span className="bold">Ticker:</span>
					<span className="light">{peer.ticker}</span>
				</div>
				<div>
					<span className="bold">IPO Date:</span>
					<span className="light">{tooltipData ? formatDate(tooltipData.ipoDateTime) : <CircularProgress size={14} />}</span>
				</div>
				{currency && (
					<div>
						<span className="bold">Currency:</span>
						<span className="light">{currency.label}</span>
					</div>
				)}
				<br />
				<div>
					<span className="bold">Market Cap:</span>
					<span className="light">{tooltipData ? `${formatDecimal(tooltipData.marketCap)}M` : <CircularProgress size={14} />}</span>
				</div>
				<div>
					<span className="bold">Enterprise value:</span>
					<span className="light">{tooltipData ? `${formatDecimal(tooltipData.enterpriseValue)}M` : <CircularProgress size={14} />}</span>
				</div>
				<div className="comment">
					<span>As of date:</span>
					<span className="light">{tooltipData ? formatDate(tooltipData.asOfDate) : <CircularProgress size={14} />}</span>
				</div>
				<br />
				<div>
					<span className="bold">Description:</span>
					<span className="light desc">{peer.description}</span>
				</div>
			</div>
		);
	};

	return {
		onTooltipEnter,
		onTooltipLeave,
		tooltipData,
		getTooltip,
		clearData,
	};
};

export default useVolatilityTooltip;
