import { action, makeAutoObservable, toJS } from 'mobx';
import { clearPersistedStore, makePersistable } from 'mobx-persist-store';
import { EquityValueEnum } from '../Models/API/Valuation/equity-value-enum';
import { GeneralFormRequest } from '../Models/API/Valuation/general-request';
import { ValuationFormSteps } from '../Models/API/Valuation/steps-enum';
import {
	CapTableRequest,
	CreateProjectRequest,
	PayoffSelectionOptions,
	PeerSearchParams,
	PreferenceShareClassRequest,
	PreferenceShareClassRight,
	ShareClassRight,
	ShareClassRightIssueRequest,
	ShareClassRightRequest,
	ToggleReviewerRequest,
	UploadOptionsRequest,
	ValuationProject,
	VolatilityPayload,
	VolatilityTablePreviewData,
	WaterfallAwardType,
	WaterfallDefaultParams,
	WaterfallProject,
} from '../Models/API/Waterfall/IForm';
import { GeneralForm } from '../Models/App/Valuation/general-form';
import { ValuationService } from '../Services/ValuationService';
import { isBoolean, isNullOrUndefined } from '../Shared/Utilities';
import { RootStore } from './RootStore';
import { SummaryData } from '../Models/API/Valuation/summary';
import { FourONinePermission } from '../Models/API/UsersAndPermissions/permissions-enum';
import { EconomicUser } from '../Models/API/Valuation/economic-user';
import { UploadReportPayload } from '../Models/API/DataCollection/upload-report-payload';
import { DataCollectionFieldTypeEnum } from '../Models/API/DataCollection/comment-response';

const DEFAULT_GENERAL_FORM = { equityType: EquityValueEnum.AccordingToLastInvestmentRound } as GeneralForm;

export default class ValuationStore {
	projectName: string | undefined = undefined;
	protected valuationService: ValuationService;
	waterfallId: number = 0;
	lastAllowedStep: number = 0;
	originalValuationDate: Date | undefined = undefined;
	shareClasses?: ShareClassRight[] = undefined;
	preferenceShareClasses?: PreferenceShareClassRight[] = undefined;
	isViewMode: boolean | undefined = undefined;
	isNewProject: boolean = false;
	isOptionsAllowed: boolean = true;
	projects?: ValuationProject[] | null = null;
	generalForm: GeneralForm = DEFAULT_GENERAL_FORM;
	summaryData?: SummaryData;
	economicUsers: EconomicUser[] = [];
	lastViewedProjects: string[] = [];

	constructor(private rootStore: RootStore) {
		makeAutoObservable(this, {
			resetForm: action,
			setGeneralForm: action,
			// getProjects: action,

			// step 6:
			// setSummary: action,
			// setIsShowError: action,
		});
		makePersistable(this, {
			name: 'ValuationStore',
			properties: [
				'generalForm',
				'waterfallId',
				'lastAllowedStep',
				'projects',
				'isViewMode',
				'projectName',
				'originalValuationDate',
				'economicUsers',
				'lastViewedProjects',
			],
			storage: window.sessionStorage,
			expireIn: 10800000, // 3 hours
		});
		rootStore.companyStore.onCompanyChange.subscribe(() => {
			if (!rootStore.companyStore.companyId) return;
			this.resetStoreToDefaultValues();
		});
		this.valuationService = new ValuationService();
	}

	get currentRole() {
		return this.rootStore.auth.permissions?.fourONinePermission ?? FourONinePermission.NoAccess;
	}

	get currentWaterfallId() {
		return this.waterfallId;
	}

	set currentWaterfallId(id: number) {
		this.waterfallId = id;
	}

	setLastViewedProject(id: string) {
		const data = [...this.lastViewedProjects];
		const index = data.indexOf(id);
		if (index > -1) {
			data.splice(index, 1);
			data.unshift(id);
		}
		this.lastViewedProjects = data;
		this.projects = this.sortProjectsByLastViewed(this.projects);
	}

	sortProjectsByLastViewed(projects: typeof this.projects) {
		if (!projects) return projects;
		const idIndexMap = new Map(this.lastViewedProjects.map((id, index) => [id, index]));
		return projects.sort((a, b) => (idIndexMap.get(a.valuationProjectId) ?? 0) - (idIndexMap.get(b.valuationProjectId) ?? 0));
	}

	setProjectViewMode(isViewMode: boolean | undefined) {
		this.isViewMode = isViewMode;
	}

	setProjectName(name: string | undefined) {
		this.projectName = name;
	}

	setGeneralForm = (form: GeneralForm) => {
		this.generalForm = form;
	};

	resetWaterfallProject() {
		this.waterfallId = 0;
		this.setProjectName(undefined);
		this.lastAllowedStep = 0;
		// this.originalValuationDate = undefined;
		// this.projects = null;
		// this.summaryData = null;
		this.resetForm();
		this.generalForm = DEFAULT_GENERAL_FORM;
		this.shareClasses = undefined;
		this.preferenceShareClasses = undefined;
		// ui related observables:
		this.isViewMode = undefined;
		this.isNewProject = false;
		this.isOptionsAllowed = false;
	}

	resetStoreToDefaultValues = () => {
		this.isNewProject = false;
		clearPersistedStore(this);
		this.lastViewedProjects = [];
	};

	resetForm = () => {
		this.generalForm = DEFAULT_GENERAL_FORM;
		this.waterfallId = 0;
		this.lastAllowedStep = 0;
		this.setProjectViewMode(undefined);
		this.setProjectName(undefined);
	};

	clearProjects = () => (this.projects = null);

	getProjects = async (companyId: number = this.rootStore.companyStore.companyId) => {
		const res = await this.valuationService.getProjects(companyId);
		if (res.data) {
			this.projects = this.sortProjectsByLastViewed(res.data);

			if (!this.lastViewedProjects.length) {
				this.lastViewedProjects = res.data.map((p) => p.valuationProjectId);
			}
		}
		return res;
	};

	deleteProject = async (waterfallId: number) => {
		const res = await this.valuationService.deleteProject(waterfallId);
		if (res.error) return res.errorMessage;

		this.projects = this.projects?.filter((project) => project.waterfallId !== waterfallId);
	};

	getProject = async (waterfallId: number) => {
		const res = await this.valuationService.getProject(waterfallId);
		if (isNullOrUndefined(res.data)) return res;

		const { projectName, valuationDate, unfulfillmentStep } = res.data;
		this.originalValuationDate = valuationDate;
		this.setGeneralForm({ ...res.data, riskFreeRate: res.data.riskFreeRate * 100 });
		// if doesnt need the project return a promise
		// this.updateCurrentStep(step);
		this.setProjectName(projectName);
		this.setLastAllowedStep(unfulfillmentStep);
		const valuationProjectId = this.projects?.find((p) => p.waterfallId === waterfallId)?.valuationProjectId;
		valuationProjectId && this.setLastViewedProject(valuationProjectId);

		return res;
	};

	setLastAllowedStep = (step: number = ValuationFormSteps.general) => {
		// this.lastAllowedStep = step === ValuationFormSteps.summary && this.summaryData ? 7 : step;
		this.lastAllowedStep = step - 1;
	};

	duplicateProject = async (payload: CreateProjectRequest) => {
		return this.valuationService.duplicateProject(payload);
	};

	getSteps = async (waterfallId: number = this.waterfallId, companyId: number = this.rootStore.companyStore.companyId) => {
		const res = await this.valuationService.getSteps({ waterfallId, companyId });
		if (isNullOrUndefined(res.data)) return;

		this.isOptionsAllowed = res.data.isOptionsAllowed;
		this.setLastAllowedStep(res.data.unfulfillmentStep);
	};

	async getEconomicUsers() {
		const res = await this.valuationService.getEconomicUsers();
		this.economicUsers = res.data?.users || [];
	}

	async changeProjectOwner(userId: number, valuationProjectId: string) {
		const res = await this.valuationService.changeProjectOwner(userId, valuationProjectId);

		const projectIdx = this.projects?.findIndex((p) => p.valuationProjectId === res.data?.valuationProjectId);
		if (isNullOrUndefined(this.projects) || projectIdx === undefined || res.data === undefined || projectIdx === -1) return;
		this.projects = [
			...this.projects.slice(0, projectIdx),
			{
				...this.projects[projectIdx],
				valuatorContactId: res.data.valuatorContactId,
				valuatorFullName: res.data.valuatorFullName,
			},
			...this.projects.slice(projectIdx + 1),
		];
	}

	updateFairMarketValue(fairMarketValue: number, isDcf: boolean, valuationProjectId: string) {
		return this.valuationService.updateFairMarketValue(fairMarketValue, isDcf, valuationProjectId);
	}

	async uploadReport(data: UploadReportPayload) {
		const res = await this.valuationService.uploadReport(data);
		const projectIdx = this.projects?.findIndex((p) => p.valuationProjectId === data.valuationProjectId);
		if (projectIdx === undefined || res.data === undefined || projectIdx === -1) return res;
		this.projects?.[projectIdx].reports.push({
			reportType: res.data.data.reportType,
			id: res.data.data.reportId,
			uploadDate: res.data.data.uploadDate,
			valuationProjectId: res.data.data.valuationProjectId,
			filePath: res.data.data.filePath,
			fileName: res.data.data.fileName,
		});
		return res;
	}

	async markAsRead(dataCollectionId: string, ids: string[], valuationProjectId: string) {
		const res = await this.valuationService.markAsRead(dataCollectionId, ids);
		if (!res.isSuccess) return;
		this.projects = this.projects?.map((p) => {
			if (p.valuationProjectId === valuationProjectId) {
				p.unreadMessagesGeneral -= res.data.data.length;
			}
			return p;
		});
	}

	// step 1

	createProject = async (payload: CreateProjectRequest) => {
		const res = await this.valuationService.createProject(payload);
		if (isNullOrUndefined(res.data)) return res;

		this.setLastAllowedStep(res.data.unfulfillmentStep);
		this.waterfallId = res.data.waterfallId;
		this.setProjectName(payload.projectName);
		this.getSteps();
	};

	getProjectReviewers = (payload: WaterfallDefaultParams) => {
		return this.valuationService.getProjectReviewers(payload);
	};

	toggleProjectReviewer = (payload: ToggleReviewerRequest) => {
		return this.valuationService.toggleProjectReviewer(payload);
	};

	updateScenarioStep = async (payload: GeneralFormRequest) => {
		const res = await this.valuationService.updateScenarioStep(payload);
		if (isNullOrUndefined(res.data)) return res;

		this.setLastAllowedStep(res.data.unfulfillmentStep);
		this.originalValuationDate = payload.valuationDate;
		return res;
	};

	getRiskFreeRate = (valuationDate: Date) => {
		return this.valuationService.getRiskFreeRate(valuationDate);
	};

	getRiskFreeRateValue = (valuationDate: Date, timeToExit: number) => {
		return this.valuationService.getRiskFreeRateValue(valuationDate, timeToExit);
	};

	// step 2

	getSectorAndIndstryList() {
		return this.valuationService.getSectorAndIndstryList();
	}

	getVolatilityAndMultiple(waterfallId: number = this.waterfallId) {
		return this.valuationService.getVolatilityAndMultiple(waterfallId);
	}

	getAvailablePeers(params?: PeerSearchParams) {
		return this.valuationService.getAvailablePeers(params);
	}

	getVolatilityProject(waterfallId: number = this.waterfallId) {
		return this.valuationService.getVolatilityProject(waterfallId);
	}

	async updateVolatilityProject(data: VolatilityPayload, waterfallId: number = this.waterfallId) {
		const res = await this.valuationService.updateVolatilityProject(data, waterfallId);
		if (res.isSuccess) {
			this.setLastAllowedStep(res.data.unfulfillmentStep);
		}
		return res;
	}

	addPeer(identifier: string, waterfallId: number = this.waterfallId) {
		return this.valuationService.addPeer(identifier, waterfallId);
	}

	deletePeer(identifier: string, waterfallId: number = this.waterfallId) {
		return this.valuationService.deletePeer(identifier, waterfallId);
	}

	// getAddedPeers(waterfallId: number = this.waterfallId) {
	// 	return this.valuationService.getAddedPeers(waterfallId);
	// }

	submitAddedPeers(data: VolatilityTablePreviewData) {
		return this.valuationService.submitAddedPeers(data);
	}

	calculateAddedPeers(data: VolatilityTablePreviewData, waterfallId: number = this.waterfallId) {
		return this.valuationService.calculateAddedPeers(data, waterfallId);
	}

	// step 3

	getCapTableBases = async (companyId: number) => {
		const res = await this.valuationService.getCapTableBases(companyId);
		if (!res.data || isNullOrUndefined(res.data?.projectsUserList)) return res;

		return res;
	};

	getPeerInfo = (peerId: number, waterfallId = this.waterfallId) => {
		return this.valuationService.getPeerInfo(peerId, waterfallId);
	};

	// get a snapshot of a captable base (result might not be updated with the latest captable):
	getSavedCapTableBase = async (companyId: number, waterfallId: number) => {
		const res = await this.valuationService.getSavedCapTableBase(companyId, waterfallId);
		if (isNullOrUndefined(res.data)) return res;

		const isShowSelect = res.data.captableImportDate === null;
		// this.setShowSelectImportSource(isShowSelect);
		return res;
	};

	// get captable base directly from captable module:
	getSingleCapTableData = async (payload: CapTableRequest) => {
		const res = await this.valuationService.getSingleCapTableData(payload);

		if (res.data) {
			this.getSteps();
		}
		return res;
	};

	// step 4

	uploadOptions = async (payload: UploadOptionsRequest) => {
		const res = await this.valuationService.uploadOptions(payload);
		this.getSteps();
		return res;
	};

	reimportOptions = (companyId: number = this.rootStore.companyStore.companyId, waterfallId: number = this.waterfallId) => {
		return this.valuationService.reimportOptions(companyId, waterfallId);
	};

	getOptions = (waterfallId: number = this.waterfallId) => {
		return this.valuationService.getOptions(waterfallId);
	};

	updateOptionsMode = (payload: UploadOptionsRequest) => {
		return this.valuationService.updateOptionsMode(payload);
	};

	getValuationDate = (waterfallId: number = this.waterfallId) => {
		return this.valuationService.getValuationDate(waterfallId);
	};

	addAwardType = (data: WaterfallAwardType, waterfallId: number = this.waterfallId) => {
		return this.valuationService.addAwardType(data, waterfallId);
	};

	updateAwardType = (data: WaterfallAwardType, waterfallId: number = this.waterfallId) => {
		return this.valuationService.updateAwardType(waterfallId, data);
	};

	deleteAwardType = (shareClassId: number, waterfallId: number = this.waterfallId) => {
		return this.valuationService.deleteAwardType(waterfallId, shareClassId);
	};

	// step 5

	getShareClassRights = async ({ waterfallId }: WaterfallDefaultParams) => {
		const res = await this.valuationService.getShareClassRights(waterfallId);
		this.shareClasses = res.data?.shareClasses;
	};

	getShareClassRight = async (payload: WaterfallDefaultParams) => {
		return this.valuationService.getShareClassRight(payload);
	};

	updateClassRight = async (payload: ShareClassRightRequest | ShareClassRightIssueRequest) => {
		const res = await this.valuationService.updateClassRight(payload);
		if (!res.isSuccess) return;

		this.updateClassRightValue(payload.shareClassId, 'isPreferred', true);
		const areClassesAdded = this.shareClasses?.every((s) => isBoolean(s.isPreferred));
		areClassesAdded && this.getSteps();
	};

	deleteClassRight = async (payload: WaterfallDefaultParams): Promise<void> => {
		const res = await this.valuationService.deleteClassRight(payload);
		if (!res.isSuccess) return;

		const areClassesAdded = this.shareClasses?.every((s) => isBoolean(s.isPreferred));
		areClassesAdded && this.getSteps();
		this.updateClassRightValue(payload.shareClassId as number, 'isPreferred', null);
	};

	updateLastModified(payload: WaterfallDefaultParams) {
		return this.valuationService.updateLastModified(payload);
	}

	private updateClassRightValue = (shareClassId: number, key: string, value: any) => {
		if (isNullOrUndefined(this.shareClasses)) return;
		const shareClassIdx = this.shareClasses.findIndex((sc) => sc.shareClassId === shareClassId);
		this.shareClasses = [
			...this.shareClasses.slice(0, shareClassIdx),
			{
				...this.shareClasses[shareClassIdx],
				[key]: value,
			},
			...this.shareClasses.slice(shareClassIdx + 1),
		];
	};

	// step 6

	getPreferenceClassRights = async ({ waterfallId }: WaterfallDefaultParams) => {
		const res = await this.valuationService.getPreferenceClassRights(waterfallId);
		if (!res.isSuccess) return;

		this.preferenceShareClasses = res.data;
	};

	addPreferenceClassRight = async (payload: PreferenceShareClassRequest) => {
		// await new Promise(res => setTimeout(res, 2000));
		const res = await this.valuationService.addPreferenceClassRight(payload);
		if (!res.isSuccess) return;

		this.getSteps();
		if (isNullOrUndefined(this.preferenceShareClasses)) return;
		const scIdx = this.preferenceShareClasses.findIndex((sc) => sc.shareClassId === payload.shareClassId);
		this.preferenceShareClasses = [
			...this.preferenceShareClasses.slice(0, scIdx),
			{
				...this.preferenceShareClasses[scIdx],
				...res.data,
			},
			...this.preferenceShareClasses.slice(scIdx + 1),
		];
	};

	deletePreferenceClassRight = async (payload: WaterfallDefaultParams) => {
		// await new Promise(res => setTimeout(res, 2000));
		await this.valuationService.deletePreferenceClassRight(payload);
		this.getSteps();
		if (isNullOrUndefined(this.preferenceShareClasses)) return;
		const scIdx = this.preferenceShareClasses.findIndex((sc) => sc.shareClassId === payload.shareClassId);
		this.preferenceShareClasses = [
			...this.preferenceShareClasses.slice(0, scIdx),
			{
				...this.preferenceShareClasses[scIdx],
				shareClassPreferenceTermsTypeId: PayoffSelectionOptions.None,
			},
			...this.preferenceShareClasses.slice(scIdx + 1),
		];
	};

	updatePreferenceClassRights = async (payload: WaterfallDefaultParams) => {
		return this.valuationService.updatePreferenceClassRights(payload);
	};

	// step 7

	async runSummary(data?: WaterfallDefaultParams, isUpdate: boolean = false) {
		const defaultData = {
			waterfallId: this.waterfallId,
			companyId: this.rootStore.companyStore.companyId,
		};
		const res = await this.valuationService.runSummary(data ?? defaultData);
		// if (res.isSuccess) this.setSummary(res.data);
		return res;
	}

	getSummary = async (waterfallId: number = this.waterfallId) => {
		const res = await this.valuationService.getSummary(waterfallId);
		if (res.isSuccess) this.setSummary(res.data);
		return res;
	};

	cancelSummary = async (waterfallId: number = this.waterfallId) => {
		const res = await this.valuationService.cancelSummary(waterfallId);
		if (res.isSuccess) this.setSummary(undefined);
		return res;
	};

	getSummaryStatus = async (waterfallId: number = this.waterfallId) => {
		return this.valuationService.getSummaryStatus(waterfallId);
	};

	setSummary(data?: SummaryData) {
		this.summaryData = data;
	}

	getSummaryExcelData(waterfallId: number = this.waterfallId) {
		return this.valuationService.getSummaryExcelData(waterfallId);
	}
}
