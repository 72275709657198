import { IContactCreate } from '../../../../Models/API/Contact/contact';
import { InputRefs, useFormValidation } from '../../../../Shared/Hooks/useFormValidation';
import { commonValidators } from '../../../../Shared/ObjectValidator';
import { isBoolean } from '../../../../Shared/Utilities';

const useContactValidation = (
	contact: IContactCreate,
	inputRefs: InputRefs<IContactCreate> = {} as InputRefs<IContactCreate>,
	isEmailRequired: boolean = false,
	isNameRequired: boolean | ((contact: IContactCreate) => boolean) = true,
	isOrganizationNameRequired: boolean = false,
	deps: any[] = []
) => {
	const isNameRequiredValue = isBoolean(isNameRequired) ? isNameRequired : isNameRequired?.(contact);
	const { isFormValid, formErrors, formValidationState, setFormValidationState, validateForm, clearErrors } = useFormValidation({
		form: contact,
		schema: {
			companyName: [commonValidators.requiredIf(() => !!contact.isLegalEntity || isOrganizationNameRequired), commonValidators.minLength(2)],
			firstName: [
				commonValidators.requiredIf(
					() => isEmailRequired || isNameRequiredValue || !!((isEmailRequired || isNameRequiredValue) && contact.isLegalEntity)
				),
				commonValidators.regex(/^[a-z .-]*$/i),
				commonValidators.minLength(2),
			],
			lastName: [
				commonValidators.requiredIf(
					() => isEmailRequired || isNameRequiredValue || !!((isEmailRequired || isNameRequiredValue) && contact.isLegalEntity)
				),
				commonValidators.regex(/^[a-z .-]*$/i),
				commonValidators.minLength(2),
			],
			// employeeNumber: [
			// 	commonValidators.requiredIf(() => employeeNumberRequired),
			// 	commonValidators.lengthRange(employeeNumberRequired ? 1 : 0, 100, "Insert at least one character"),
			// 	commonValidators.regex(/^[a-zA-Z0-9]*$/),
			// ],
			email: [commonValidators.requiredIf(() => isEmailRequired || !!(isEmailRequired && contact.isLegalEntity)), commonValidators.emailValidator],
			mobile: [
				(v) => {
					const isValid = (!v && !contact.mobilePrefix) || !!(v && contact.mobilePrefix);
					return {
						isValid,
						message: isValid ? '' : 'Mobile number is incorrect',
					};
				},
			],
			// id: [commonValidators.lengthRange(2, 15, "Insert at least two characters")],
			// contactDetailsDto: [commonValidators.customValidation((contact.contactDetailsDto?.idNumber?.length ?? 0) > 2, "Error!")],
		},
		refs: inputRefs,
		deps,
	});

	return {
		isFormValid,
		formErrors,
		formValidationState,
		setFormValidationState,
		validateForm,
		clearErrors,
	};
};

export default useContactValidation;
