import { observer } from 'mobx-react-lite';
import useRootStore from '../../../../Shared/Hooks/useRootStore';
import useEsopModals from '../../useEsopModals';
import { useState } from 'react';
import appConfig from '../../../../config/config';
import { css } from '@emotion/css';
import { IC_EDIT2, IC_TRASH2, IC_USER_PROFILE_PURPLE } from '../../../../Assets';
import Clickable from '../../../../Shared/Components/Clickable/Clickable';
import Title from '../../../../Shared/Components/Layout/Title';
import Menu from '../../../../Shared/Components/Menu';
import Select from '../../../../Shared/Components/Select/Select';
import { IPromisedTable, PromisedType } from '../../../../Models/App/EquityPlans/Promised';
import { NumberOption } from '../../../../Models/API/All/NumberOption';
import { GrantTypes } from '../../../../Models/App/EquityPlans/Grants';
import TextInput from '../../../../Shared/Components/Input/TextInput';
import NumberInput from '../../../../Shared/Components/Input/NumberInput';
import Flex from '../../../../Shared/Components/Layout/Flex';
import Button from '../../../../Shared/Components/Button/Button';
import { isNullOrUndefined } from '../../../../Shared/Utilities';
import { useAppendState } from '../../../../Shared/Hooks/useAppendState';
import { commonValidators } from '../../../../Shared/ObjectValidator';
import { InputValidationRef, useFormValidation } from '../../../../Shared/Hooks/useFormValidation';

interface PromisedCardProps {
	isEdit?: boolean;
	promised: IPromisedTable;
	onSave: (promised: PromisedType) => Promise<void>;
}

export const PromisedDetailsStyle = css({
	label: "PromisedCard",
	display: "flex",
	flexDirection: "column",
	justifyContent: "space-between",
	padding: "7rem 8rem 7rem",

	"&__main-section": {
		width: "100%",
	},
	"&__inputs-container": {
		display: "grid",
		gridTemplateColumns: "repeat(6, 1fr)",
		gridColumnGap: "2.4rem",
		gridRowGap: "3.4rem",
		border: `1px solid ${appConfig.style.colors.table}`,
		borderRadius: 16,
		padding: "3.6rem 4.4rem",
	},
	"&__add-ben-btn": {
		alignSelf: "flex-end",
		marginBottom: "0.4rem",
	},
	"&__title": {
		fontSize: "2.5rem",
		marginBottom: "4.8rem",
		fontWeight: 500,
	},
	"&__title-container": {
		display: "flex",
		alignItems: "center",
		gap: "2rem",
		textTransform: "capitalize",
		".img-sh-type": {
			width: "5.4rem",
		},
		marginBottom: "2.4rem",
	},
});

const PromisedCard = (props: PromisedCardProps) => {
	const { equityPlansStore } = useRootStore();
	const { openBeneficiaryModal, showDeletePromisedModal } = useEsopModals();
	const [isEdit, setIsEdit] = useState<boolean>(false);
	const [promised, _, onInputChange] = useAppendState<IPromisedTable>(props.promised);
	const [isGrantNumberExists, setIsGrantNumberExists] = useState<boolean>(false);
	const { formValidationState, inputRefs, validateForm } = useFormValidation({
		form: promised,
		schema: {
			planId: [commonValidators.required()],
			grantNumber: [commonValidators.required(), commonValidators.customValidation(!isGrantNumberExists, 'Grant number already exists')],
			awardTypes: [commonValidators.required()],
			amountPromised: [commonValidators.required(), commonValidators.graterThan(0)],
			// taxTrack: [commonValidators.required()],
		},
	});

	const plans = equityPlansStore.companyData?.plans?.map((pln) => {
		return { label: pln.name, value: pln.planId };
	}) || [];

	const setAvialableAwardTypes = () => {
		const options = equityPlansStore.companyData?.plans?.filter((pln) => pln.planId === promised.planId);
		let newOptions: NumberOption[] = [];
		options?.forEach((opt) => {
			if (opt.allowRsu) newOptions.push({ label: "RSU", value: GrantTypes.RSU });
			if (opt.allowSop) newOptions.push({ label: "Options", value: GrantTypes.Options });
		});
		return newOptions;
	};

	const onCheckValidate = () => {
		if (!validateForm()) return;
		props.onSave(promised);
	}

	const onValidateGrantNumber = async (e: any) => {
		if (!promised.grantNumber) return;
		const res = await equityPlansStore.CheckIfGrantNumberExists(promised.grantNumber);
		setIsGrantNumberExists(res.data);
	};

	return (
		<>
			<div className={PromisedDetailsStyle}>
				<div className={`${PromisedDetailsStyle}__title-container`}>
					<img className="img-sh-type" src={IC_USER_PROFILE_PURPLE} alt="" />
					<Title className="flex gap-1">
						<Clickable
							qaid=""
							margin={0}
							justify="start"
							className="ben-modal"
							onClick={() => !isNullOrUndefined(promised.beneficiaryId) && openBeneficiaryModal(promised.beneficiaryId)}
							applyStyle
						>
							{`${promised.fullName.firstName} ${promised.fullName.lastName}`}
						</Clickable>
						({promised.beneficiaryId})
					</Title>
					<Menu
						items={[
							{
								label: 'Edit',
								icon: IC_EDIT2,
								qaid: 'EditPromised.Button.Edit',
								onClick: () => setIsEdit(true),
							},
							{
								label: 'Delete',
								icon: IC_TRASH2,
								qaid: 'EditPromised.Button.DeleteShareholder',
								onClick: () => showDeletePromisedModal(promised),
								// onClick: () => grant.grantId && showDeleteGrantModal(grant.grantId),
							},
						]}
					/>
				</div>
				<div className={`${PromisedDetailsStyle}__details-section`}>
					<div className={`${PromisedDetailsStyle}__grant-details`}>
						<div className={`${PromisedDetailsStyle}__inputs-container`}>
							{/* Plan */}
							<Select
								label="Equity plan"
								qaid="EditPromised.Select.EquityPlan"
								options={plans}
								name="planId"
								onChange={onInputChange}
								value={promised.planId}
								error={formValidationState?.planId?.message}
								ref={(el: InputValidationRef) => (inputRefs.planId = el)}
								required
								className="mb-0"
								isViewMode={!isEdit}
							/>

							{/* Award Type */}
							<Select
								label="Award type"
								qaid="EditPromised.Select.AwardType"
								options={setAvialableAwardTypes()}
								name="awardTypes"
								onChange={onInputChange}
								value={promised.awardTypes}
								error={formValidationState?.awardTypes?.message}
								ref={(el: InputValidationRef) => (inputRefs.awardTypes = el)}
								required
								className="mb-0"
								isViewMode={!isEdit}
							/>

							{/* Grant Number */}
							<TextInput
								label="Grant number"
								qaid="EditPromised.Input.GrantNumber"
								name="grantNumber"
								value={promised.grantNumber}
								onChange={onInputChange}
								onBlur={onValidateGrantNumber}
								error={formValidationState?.grantNumber?.message}
								ref={(el: InputValidationRef) => (inputRefs.grantNumber = el)}
								required
								containerClassName="mb-0"
								isViewMode={!isEdit}
								info={equityPlansStore.companyData?.autoGenerateGrantNumber ? 'Auto-generated by altshare' : undefined}
								disabled={equityPlansStore.companyData?.autoGenerateGrantNumber}
							/>

							{/* Granted */}
							<NumberInput
								label="Amount promised"
								qaid="EditPromised.Input.GrantNumber"
								name="amountPromised"
								value={promised.amountPromised}
								onChange={onInputChange}
								error={formValidationState?.amountPromised?.message}
								ref={(el: InputValidationRef) => (inputRefs.amountPromised = el)}
								required
								containerClassName="mb-0"
								isViewMode={!isEdit}
							/>

							{/* Tax Track */}
							{/* <Select
								label="Tax track"
								options={equityPlansStore.taxTracks}
								onChange={onInputChange}
								name="taxTrack"
								value={promised.taxTrack}
								qaid="EditPromised.Input.TaxTrack"
								error={formValidationState?.taxTrack?.message}
								ref={(el: InputValidationRef) => (inputRefs.taxTrack = el)}
								required
								className="mb-0"
								isViewMode={!isEdit}
							/> */}

							{/* Department */}
							<Select
								label="Department"
								options={equityPlansStore.departments}
								onChange={onInputChange}
								value={promised.department}
								name="department"
								qaid="EditPromised.Input.Department"
								className="mb-0"
								isViewMode={!isEdit}
							/>

							{/* Sub-department */}
							<Select
								label="Sub-department"
								options={equityPlansStore.subDepartments}
								onChange={onInputChange}
								value={promised.subDepartment}
								name="subDepartment"
								qaid="EditPromised.Input.Department"
								className="mb-0"
								isViewMode={!isEdit}
							/>

							{/* Site */}
							<Select
								label="Site"
								options={equityPlansStore.sites}
								onChange={onInputChange}
								value={promised.site}
								name="site"
								qaid="EditPromised.Input.Site"
								className="mb-0"
								isViewMode={!isEdit}
							/>
						</div>

						{isEdit && (
							<Flex gap={10} style={{}} justify="end" margin="6rem 0 0 0">
								<Button label="Cancel" qaid="EditPromised.Button.Cancel" square inverse cancel onClick={() => [setIsEdit(false)]} />
								<Button
									label="Save Changes"
									qaid="EditPromised.Button.Save"
									onClick={onCheckValidate}
									// isLoading={isLoading}
								/>
							</Flex>
						)}
					</div>
				</div>
			</div>
		</>
	);
};

export default observer(PromisedCard);