import styled from '@emotion/styled';
import { DataCollectionFieldTypeEnum } from '../../../../Models/API/DataCollection/comment-response';
import QuestionHistory from '../../../DataCollection/Components/QuestionHistory';
import Container from '../../../../Shared/Components/Modal/Modal.Style';
import CloseIcon from '@mui/icons-material/Close';
import { ModalBodyProps } from '../../../../Shared/Components/Modal/types';
import { useState } from 'react';
import { Collapse } from '@mui/material';
import ExpandCircleDownIcon from '@mui/icons-material/ExpandCircleDown';
import appConfig from '../../../../config/config';
import classNames from 'classnames';
import Image from '../../../../Shared/Components/Image';
import CommentsDisabledIcon from '@mui/icons-material/CommentsDisabled';
import AddCommentIcon from '@mui/icons-material/AddComment';
import Tooltip from '../../../../Shared/Components/Tooltip';
import { IC_MESSAGE_HIDE, IC_MESSAGE_WHITE } from '../../../../Assets';

export const Modal = styled.div({
	label: 'DocumentModal',
	width: '100%',
	height: '100%',
	display: 'flex',
	gap: '2rem',
	padding: '2rem',
	iframe: {
		flex: 2,
		borderRadius: 16,
	},
	'div[data-qaid="Modal.Button.Close"]': {
		color: '#ffffff',
	},
	'.chatContainer': {
		position: 'relative',
		flex: '0 0 4.5rem',
		paddingTop: '6rem',
		'.toggleBtn': {
			position: 'absolute',
			top: '50%',
			left: 0,
			zIndex: 3,
			fontSize: '4rem',
			cursor: 'pointer',
		},
		':not(.isOpen)': {
			'.toggleBtn': {
				color: '#ffffff',
			},
		},
		'&.isOpen': {
			flex: '0 0 50rem',
			'.toggleBtn': {
				left: 20,
				top: 'calc(20px + 6rem)',
			},
		},
	},
});

type Props = ModalBodyProps & {
	path: string;
};

const DocumentModal = ({ path, removeModal }: Props) => {
	const [isChatOpen, setIsChatOpen] = useState<boolean>(true);
	const onToggle = () => setIsChatOpen((prev) => !prev);

	return (
		<Modal>
			<iframe src={path} />
			<div className={classNames('chatContainer', { isOpen: isChatOpen })}>
				{isChatOpen ? (
					<Image src={IC_MESSAGE_HIDE} className="toggleBtn" tooltip="Hide chat" width="3rem" key="hide" onClick={onToggle} />
				) : (
					<Image src={IC_MESSAGE_WHITE} className="toggleBtn" tooltip="Show chat" key="hide" width="4.5rem" onClick={onToggle} />
				)}
				{isChatOpen && <QuestionHistory fieldType={DataCollectionFieldTypeEnum.General} />}
			</div>
			<Container.Close data-qaid="Modal.Button.Close" onClick={removeModal} data-modal="close">
				<CloseIcon style={{ width: '2rem', height: '2rem' }} />
			</Container.Close>
		</Modal>
	);
};

export default DocumentModal;
