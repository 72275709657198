import styled from '@emotion/styled';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import { useCallback, useEffect, useRef } from 'react';
import { InView } from 'react-intersection-observer';
import { IC_ALTSHARE_LOGO_CIRCLE, IC_SEND_MESSAGE_BUTTON } from '../../../../Assets';
import { DataCollectionComment, DataCollectionFieldTypeEnum } from '../../../../Models/API/DataCollection/comment-response';
import { FourONinePermission } from '../../../../Models/API/UsersAndPermissions/permissions-enum';
import Image from '../../../../Shared/Components/Image';
import useRootStore from '../../../../Shared/Hooks/useRootStore';
import { DateFormatsEnum, formatDate, isNullOrUndefined } from '../../../../Shared/Utilities';
import appConfig from '../../../../config/config';
import { DataCollectionPermissionsEnum, hasProjectPermissions } from '../../helpers/utils';

const Container = styled.div({
	label: 'ChatContainer',
	display: 'flex',
	flexDirection: 'column',
	height: '100%',
	position: 'relative',
	background: '#ffffff',
	borderRadius: 16,
});

const Header = {
	Container: styled.div({
		label: 'ChatHeader',
		display: 'flex',
		alignItems: 'center',
		width: '100%',
		flexBasis: '7rem',
		boxShadow: `0px 1px 4px 0px #0c0c0d24`,
		justifyContent: 'center',
		zIndex: 1,
		// position: 'sticky',
		height: '7.2rem',
		borderTopLeftRadius: 16,
		borderTopRightRadius: 16,
		gap: '2rem',
	}),
	TextsContainer: styled.div({
		display: 'flex',
		flexDirection: 'column',
	}),
	Title: styled.span({
		fontWeight: 700,
		fontSize: '1.8rem',
	}),
	Subtitle: styled.span({
		fontWeight: 500,
		fontSize: '1.3rem',
		color: '#1e1e1e7f',
	}),
};

const MessagesContainer = styled.div({
	label: 'MessagesContainer',
	padding: '2rem 3rem',
	display: 'flex',
	flexDirection: 'column',
	// justifyContent: 'flex-end',
	gap: '1rem',
	flex: 1,
	overflow: 'auto',
});

const MessageContainer = styled.div(
	{
		label: 'MessageContainer',
		display: 'flex',
		flexDirection: 'column',
		width: '60%',
	},
	({ isSelf }: { isSelf: boolean }) => ({
		...(isSelf
			? {
					alignSelf: 'flex-end',
					'> span': {
						alignSelf: 'flex-end',
					},
			  }
			: {
					alignSelf: 'flex-start',
			  }),
	})
);

const Message = styled.div(
	{
		label: 'ChatMessage',
		padding: '1.6rem 2.4rem',
		borderRadius: 16,
		whiteSpace: 'pre-line',
	},
	({ isSelf }: { isSelf: boolean }) => ({
		...(isSelf
			? {
					background: '#DCE0FE',
					borderBottomRightRadius: 4,
			  }
			: {
					background: appConfig.style.colors.background1,
					borderBottomLeftRadius: 4,
			  }),
	})
);

const Input = styled.textarea({
	label: 'ChatInput',
	height: '100%',
	outline: 'none',
	border: 'none',
	padding: '2rem 3rem',
	width: '100%',
	resize: 'none',
	borderTopLeftRadius: 16,
	borderTopRightRadius: 16,
	borderBottomLeftRadius: 16,
	borderBottomRightRadius: 16,
	boxShadow: '0px 16px 32px -8px #000000db',
	alignContent: 'center',
	// outline: '1px solid green',
	'&::placeholder': {
		color: '#000000',
		opacity: 0.5,
	},
	'&::-ms-input-placeholder': {
		color: '#000000',
		opacity: 0.5,
	},
});

const InputContainer = styled.div({
	label: 'InputContainer',
	// position: 'sticky',
	bottom: 0,
	width: '100%',
	height: '7.2rem',
	maxHeight: '23rem',
	// outline: '1px solid red',
});

const TimeAgo = styled.span({
	label: 'MessageTimeAgo',
	fontWeight: 400,
	fontSize: '1.2rem',
	marginTop: '0.8rem',
});

const ReadIndicator = styled.span({
	label: 'ReadIndicator',
	fontWeight: 500,
	fontSize: '1.2rem',
	marginTop: '0.8rem',
	color: appConfig.style.colors.color1,
});

type Props = {
	fieldType: DataCollectionFieldTypeEnum;
};

const QuestionsHistory = ({ fieldType }: Props) => {
	const { dataCollectionStore, valuationStore } = useRootStore();
	const inputTextRef = useRef<HTMLTextAreaElement>(null);
	const inputRef = useRef<HTMLDivElement>(null);
	const messeagesContainerRef = useRef<HTMLDivElement>(null);
	const timeoutRef = useRef<NodeJS.Timeout>();
	const msgsRef = useRef<string[]>([]);

	const isValuationTeam = [FourONinePermission.Chief, FourONinePermission.Valuation].includes(dataCollectionStore.currentRole);
	const hasCommentPermission =
		hasProjectPermissions(dataCollectionStore.currentRole, DataCollectionPermissionsEnum.SEND_COMMENT) || dataCollectionStore.isOwner;
	const comments = dataCollectionStore.commentsByQuestion[fieldType];

	const isLastGroupedMessage = useCallback(
		(idx: number) => {
			const nextComment = comments[idx + 1];
			if (nextComment === undefined) return true;

			return comments[idx].isValuationTeam !== nextComment.isValuationTeam;
		},
		[comments]
	);

	useEffect(() => {
		scrollToBottom();
	}, [comments]);

	const scrollToBottom = () => {
		if (!messeagesContainerRef.current) return;
		messeagesContainerRef.current.scrollTop = 9999999;
	};

	const onSendMessageHandler = async () => {
		if (!hasCommentPermission || !inputTextRef.current?.value.replace(/\n/g, '') || isNullOrUndefined(dataCollectionStore.valuationProjectId)) return;
		await dataCollectionStore.addComment({
			commentDescription: inputTextRef.current.value,
			fieldType,
			valuationProjectId: dataCollectionStore.valuationProjectId,
		});
		inputTextRef.current.value = '';
		inputRef.current!.style.height = `calc(7.2rem + ${(inputTextRef.current.value.split('\n').length - 1) * 2}rem)`;
	};

	const onIntersectionHandler = (comment: DataCollectionComment) => {
		msgsRef.current.push(comment.id);
		timeoutRef.current && clearTimeout(timeoutRef.current);
		timeoutRef.current = setTimeout(() => {
			if (fieldType === DataCollectionFieldTypeEnum.General && isValuationTeam && dataCollectionStore.valuationProjectId) {
				valuationStore.markAsRead(dataCollectionStore.dataCollectionId, msgsRef.current, dataCollectionStore.valuationProjectId);
			} else {
				dataCollectionStore.markAsRead(dataCollectionStore.dataCollectionId, msgsRef.current, fieldType);
			}
			msgsRef.current = [];
		}, 1000);
	};

	function replaceLastOccurrence(str: string, search: string = '\n', replaceWith: string = '') {
		const lastIndex = str.lastIndexOf(search);
		if (lastIndex === -1) return str; // If the string is not found, return the original

		return str.substring(0, lastIndex) + replaceWith + str.substring(lastIndex + search.length);
	}

	return (
		<Container>
			<Header.Container>
				<Image src={IC_ALTSHARE_LOGO_CIRCLE} width="4.8rem" />
				<Header.TextsContainer>
					<Header.Title>altshare</Header.Title>
					<Header.Subtitle>{isValuationTeam ? 'Client' : '409A valuation team'}</Header.Subtitle>
				</Header.TextsContainer>
			</Header.Container>
			<MessagesContainer ref={messeagesContainerRef}>
				{comments
					?.sort((a, b) => +new Date(a.createDate) - +new Date(b.createDate))
					.map((comment, idx) => {
						const isSelf = isValuationTeam ? comment.isValuationTeam : !comment.isValuationTeam;
						const isRead = isSelf && comment.isRead;
						return (
							<MessageContainer key={comment.commentDescription + idx} isSelf={isSelf}>
								<InView triggerOnce skip={comment.isRead} onChange={(inView) => inView && !isSelf && onIntersectionHandler(comment)}>
									<Message isSelf={isSelf}>{comment.commentDescription}</Message>
								</InView>
								{isLastGroupedMessage(idx) && (
									<div className="flex align-center justify-end gap-1">
										{isRead && <ReadIndicator>Read</ReadIndicator>}
										<TimeAgo>{formatDate(comment.createDate * 1000, DateFormatsEnum.LABEL_AMPM_TIME_SHORT)}</TimeAgo>
									</div>
								)}
							</MessageContainer>
						);
					})}
			</MessagesContainer>
			<InputContainer ref={inputRef}>
				<Input
					placeholder="Type your message..."
					onChange={(e) => {
						if (inputTextRef.current) {
							// replaceLastOccurrence to remove last line caused by enter to send
							inputTextRef.current.value = e.target.value.endsWith('\n') ? replaceLastOccurrence(e.target.value) : e.target.value;
						}
						if (inputRef.current && inputTextRef.current) {
							inputRef.current.style.height = `calc(7.2rem + ${(inputTextRef.current.value.split('\n').length - 1) * 2}rem)`;
						}
					}}
					ref={inputTextRef}
					disabled={!hasCommentPermission}
					onKeyDown={(e) => {
						if (e.ctrlKey && e.code === 'Enter' && inputTextRef.current && inputRef.current) {
							inputTextRef.current.value += '\n';
							inputRef.current.style.height = `calc(7.2rem + ${(inputTextRef.current.value.split('\n').length - 1) * 2}rem)`;
						}
						if (!e.ctrlKey && e.code === 'Enter') {
							onSendMessageHandler();
						}
					}}
				/>
				<Image
					src={IC_SEND_MESSAGE_BUTTON}
					width="4.3rem"
					style={{
						position: 'absolute',
						zIndex: 2,
						right: '3.6rem',
						bottom: '1.8rem',
						boxShadow: '0px 2px 6px -1px rgba(12, 12, 13, 0.15)',
						borderRadius: '50%',
						cursor: hasCommentPermission ? 'pointer' : 'default',
					}}
					className={classNames({ disabled: !hasCommentPermission })}
					onClick={onSendMessageHandler}
					tooltip={
						hasCommentPermission
							? 'Press Enter to send'
							: isValuationTeam
							? 'Only the project valuator can send messages'
							: 'Only the originator of this 409A valuation request can send messages'
					}
				/>
			</InputContainer>
		</Container>
	);
};
export default observer(QuestionsHistory);
