export enum NationalityOptions {
    None = 0,
    Israel = 100,
    USA = 200,
}

export enum ShareClassTypeOptions {
    None = 0,
    Normal = 100,
    Common = 200,
    Safe = 300,
}

export enum AntiDilutedModes {
    None = 0,
    BroadBase = 100,
    NarrowBase = 200,
    FullRachet = 300,
}

export enum PersonRoleOptions {
    None = 0,
    Investor = 100,
    CEO = 200,
}

export enum UserPermissionType {
    None = 0,
    WriteAndRead = 100,
    ReadOnly = 200,
    NotAllowed = 300,
}

export enum BooleanicPermissionName {
    None = 0,
    IsAddShareClass = 10,
    IsAddPerson = 20,
}

export enum EditViewPermissionName {
    None = 0,
    OptionPlanSceen = 30,
}

export enum ErrorMessageOptions {
    None = 0,
    NumberOfSharesIsNagative = 10,
    NumberOfWarrantblalbla = 11,
}

export enum PersonTypesEnum {
    None = 0,
    Person = 10,
    LegalCompany = 20,
    //
    //Without = 30,
}

export enum EntityTypeEnum {
    Buyer = 0,
    Seller = 1,
}

export enum TransactionTypesEnum {
    None = 0,
    IssueTransaction = 10,
    SecondaryTransaction = 20,
    ExerciseWarrantsTransaction = 30,
}

export enum OptionUpdateModeTypeEnum {
    NotChoosen = 1,
    Automatic = 10,
    // Manual = 20,
    CompanyTrusteeData = 30,
}

export enum OptionTransactionTypesEnum {
    None = 0,
    Pool = 10,
    Grant = 20,
    Exercise = 30,
    Sell = 40,
    Cancel = 50,
}

export enum OptionScreenTransactionTypesEnum {
    None = 0,
    Pool = 10,
    Grant = 20,
    Exercise = 30,
    Sell = 40,
    Cancel = 50,
    sameDaySell = 60,
}

export enum ProductTypeEnum {
    CapTable = 10,
    NextRound = 20,
}
export enum eFinancingRoundShareClassType {
    NextRound = 1,
    Safe = 2,
    Option = 3,
    SeriesCommon = 4,
    Warrants = 5,
    ConvertibleLoan = 6,
    OptionsUnAllocated = 7,
}
export enum ePreRoundDataType {
    Safe = 1,
    convertible_loan = 2,
    ShareClass = 3,
}
export enum eShareClassTransactionType {
    FinancingRound = 1,
}

export enum eRound {
    ROUND = 1,
    UP = 2,
    DOWN = 3,
}

export enum RecordState {
    New = 1,
    Updated = 0,
    Deleted = 2,
}

export enum DataRoomPermissionsLVL {
    NotAllowed = 0,
    Admin = 1,
    Owner = 2,
    Contributer = 3,
    Downloader = 4,
    Viewer = 5,
    General = 6,
}

export enum RoomDefaultFolderStructure {
    EmptyRoom = 0,
    MA = 1,
    DD = 2,
    PreIPO = 3,
}

export enum EmployeesWebsitePermission {
    //none=0,
    no_access = 0,
    admin = 1,
    Viewer = 5,
}

export enum SplitType {
    Split = 1,
    ReverseSplit = 2,
}

export enum ProductType {
    CapTable = 10,
    DataRoom = 20,
}

export enum DiscountTypesEnum {
    Type0 = 0,
    Type1 = 1,
    Type2 = 2,
}

export enum FileStatusOverrideOptions {
    NoAction = 0,
    Add = 1,
    Remove = 2,
}

export enum CapTableProductType {
    Publish = 1, // Not in use
    CapTable = 2,
    NextRound = 3, // Funding
    All = 4,
    Source = 5,
}

export enum AppProducts {
    DataRoom = 0,
    Options = 1,
    CapTable = 2,
    Funding = 3,
    Waterfall = 4,
    EmployeeManagement = 5,
    ShareRepresentitive = 6,
}

export enum InvitationStatus {
    accepted = 1,
    sent = 2,
    notSent = 3,
    // resendAvailable = 4,
    failed = 5,
}

export enum CompanyLimitationsPopUpType {
    Non = 0,
    NumberOfShareHoldersLimitation_Options = 1,
    NumberOfShareHoldersLimitation_CapTable = 2,
    NumberOfDataRoomLimitation = 3,
    DataRoomSizeLimitation = 4,
    FundingNotAllowed = 5,
    NumberOfDraftsLimitation = 6,
    NumberOfAllowedFreeCompanies = 7,
    DataRoomCloseToSizeLimitation = 8,
    CompanyUserPlaneLimitation = 9,
}

export enum SafeAndConvertibleLoanEnum {
    NotChoosen = 1,
    Safe = 10,
    ConvertibleLoan = 20,
    SafeAndConvertibleLoan = 30,
}

export enum WarrantsType {
    None = 0,
    Warrants = 20,
    Bsa = 30,
}

export enum AwardType {
    Options = 0,
    RSU = 1,
    BSPCE = 2,
    AGA = 3,
}

export enum Currencies {
    USD = 1,
    EUR = 2,
    JPY = 3,
    GBP = 4,
    ILS = 5,
}

export enum TaxTracks {
    None,
    ISO,
    NSO,
    CapitalGainTranch_102,
    OrdinaryIncomeTranch_102,
    CSOP,
    EMI,
    Other,
    AGA,
    BSPCE,
}

export enum EditGrantErrorField {
    GrantId,
    GrantNumber,
    Granted,
    GrantDate,
    VestStartDate,
    VestingId,
    ExpirationDate,
    RetentionDate,
}

export enum OptionsTabsEnum {
    Beneficiaries = "Beneficiaries",
    Grants = "Grants",
    Transactions = "Transactions",
}

export enum OptionsTransactionTypes {
    Cancelation,
    Sell,
    Exercise,
    SameDaySale,
}

export enum OrderType {
    SellOfShares = 'Sell Of Shares',
    ExerciseAndHold = 'Exercise And Hold',
    SameDaySale = 'Same Day Sale',
}

export enum CancelType {
    Vested,
    Unvested,
    VestedAndUnvested,
    VestedByAmount,
}

export enum CancelDateType {
    Days,
    Months,
    Years,
}

export enum CancelationReason {
    Default,
    Termination,
    Resignation,
    Death,
    Retirment,
    EndOfGPT,
    GrantExpiration,
    DueToExercise,
    Waiver,
}

export enum EditTransactionErrorField {
    NumberOfOptions,
    ExerciseDate,
    SellDate,
    CancelationDate,
}

export enum GetGrantsFilterBy {
    Company,
    Contact,
    Grant,
}

export enum UserStatus {
    None = 0,
    PendingForOtp = 10,
    PendingForCompanyRegist = 20,
    PendingForPaymentPlan = 30,
    Active = 40,
    InActive = 50,
    InvitationSent = 60,
    PendingForAccountSetup = 70,
    PendingForAccountSetupForGoogle = 71,
}

export enum ApplicationEnum {
    None,
    Cfo,
    Employee,
}

export enum RegistrationTypeEnum {
    manual,
    google,
}

export enum ExternalAuthEnum {
    None,
    google,
}

export enum AutoCompleteInputType {
    FirstName = 0,
    LastName = 1,
    Email = 2,
    CompanyName = 3,
    EmployeeNumber = 4,
}

export enum AntiDilitedModeEnum {
    None = 0,
    BroadBase = 100,
    NarrowBase = 200,
    FullRatchet = 300,
}

export enum PaymentCurrencyEnum {
    usd = 0,
    eur = 1,
}

export enum PaymentPlanEnum {
	Starter,
	Growth,
	Scale,
	Custom,
	Enterprise,
}

export enum ShareholderNameTypeEnum {
    ShareholderName = 1,
    Others = 2,
    Options = 3,
}

export enum CapTableFullscreenMode {
    none = 0,
    fullscreen = 1,
    expand = 2,
}

export enum Product {
    EquityPlans,
    CapTable,
    Funding,
    Waterfall,
    TrusteeEquity
}
