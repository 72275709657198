import { ReactElement, useEffect, useState } from "react";
import { IC_CHEVRON_DOWN, IC_CHEVRON_UP } from "../../../../Assets";
import Button from "../../../../Shared/Components/Button/Button";
import DrawerStyles from "./Drawer.style";
import classNames from "classnames";
import AddButton from "../../../../Shared/Components/Button/AddButton";
import { Collapse, Slide } from "@mui/material";
import Image from "../../../../Shared/Components/Image";
import appConfig from "../../../../config/config";

interface DrawerProps {
	header?: string | ReactElement;
	actionBtn?: { func: () => void; desc: string };
	children?: React.ReactNode;
	label?: string;
	companyRegistration?: boolean;
	tbRows?: any[];
	onClick?: () => void;
	isOpen?: boolean;
	closeShit?: () => React.Dispatch<React.SetStateAction<boolean>>;
}

const Drawer = (props: DrawerProps) => {
	const [drawerOpen, setDrawerOpen] = useState(props.isOpen);

	const drawerHandler = () => {
		setDrawerOpen((prevValue) => !prevValue);
	};

	useEffect(() => {
		setDrawerOpen(false);
	}, [props.tbRows]);

	useEffect(() => {
		if (!props.isOpen && drawerOpen) return;
		setDrawerOpen(props.isOpen);
	}, [props.isOpen]);

	return (
		<div className={classNames(DrawerStyles, { "company-registration": props.companyRegistration })}>
			<div onClick={drawerHandler} style={{ borderBottom: drawerOpen ? 'none' : `1px solid ${appConfig.style.colors.table}` }} className={classNames("drawer-header", { "company-registration": props.companyRegistration })}>
				<div className={classNames("header-details", { "company-registration": props.companyRegistration })}>
					<span style={{ fontWeight: 'bold' }}>{props.header && props.header}</span>
					<Image
						src={drawerOpen ? IC_CHEVRON_UP : IC_CHEVRON_DOWN}
						className="ml-4"
						alt=""
					/>

					{props.tbRows?.length ? (
						<div className="chevron">
							{drawerOpen ? (
								<img src={IC_CHEVRON_UP} className="ml-4" width={20} alt="" />
							) : (
								<img src={IC_CHEVRON_DOWN} className="ml-4" width={20} alt="" />
							)}
						</div>
					) : null}
				</div>

				{props.actionBtn && (
					<AddButton
						qaid=""
						// label={props.actionBtn?.desc}
						label={""}
						tooltip={props.actionBtn?.desc}
						onClick={props.actionBtn?.func}
					/>
				)}
			</div>

			{props.companyRegistration && !drawerOpen && (
				<AddButton className="add-button" onClick={() => setDrawerOpen(true)} qaid="VestingScheduleTemplates.Button.Add" label={props.label ?? ""} />
			)}

			{drawerOpen && (
				<Collapse in={true} collapsedSize={0} timeout={500}>
					<div className={classNames("drawer-content-wrapper", { open: drawerOpen })}>
						<Slide direction="down" in={drawerOpen} mountOnEnter unmountOnExit>
							<div>{props.children}</div>
						</Slide>
					</div>
				</Collapse>
			)}
		</div>
	);
};
export default Drawer;
