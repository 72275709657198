import { makeAutoObservable, reaction, runInAction } from 'mobx';
import { IAddress, IAddressRegistration, IAddressUpdate, ICompany, ICompanyRegistration, ICompanyUpdate } from '../Models/API/Company';

import { makePersistable } from 'mobx-persist-store';
import { ICompanyContact, ICompanyContactRegistration } from '../Models/API/Company/company-contact';
import { UserStatus } from '../Models/API/enums';
import CompanyService from '../Services/CompanyService';
import EventHandler from '../Shared/Middleware/EventHandler';
import { RootStore } from './RootStore';
import { LimitCodesEnum, PlanLimitationState } from '../Models/App/PaymentModels';

export class CompanyStore {
	companyService: CompanyService = new CompanyService(0);
	initiated = false;
	company: ICompany = {} as ICompany;
	addresses: IAddress[] = [];
	companies: ICompany[] = [];
	companyContact: ICompanyContact = {} as ICompanyContact;
	private limitation: PlanLimitationState = { show: false, limitCode: null };
	// showLimitation: boolean = false;
	isCreatingCompanyFromCompany: boolean = false;
	inCompanyCreationProcess: boolean = false;

	get companyId() {
		return this.company?.companyId ?? 0;
	}

	get odooTransactionId() {
		return this.company?.odooTransactionId ?? 0;
	}

	get companyProfile() {
		return this.company;
	}

	get companyContactProfile() {
		return this.companyContact;
	}

	get companiesList() {
		return this.companies;
	}

	get companyAddresses() {
		return this.addresses;
	}

	get isInCompanyCreationProcess() {
		return this.inCompanyCreationProcess;
	}

	setPlanLimitation(data: PlanLimitationState) {
		this.limitation = data;
	}

	resetPlanLimitation = () => {
		this.limitation = { show: false, limitCode: null };
	};

	get planLimitation() {
		return this.limitation;
	}

	setCompany(company: ICompany) {
		this.company = company;
	}

	setAddresses(addresses: IAddress[]) {
		this.addresses = addresses;
	}

	setCopmanies(companies: ICompany[]) {
		this.companies = companies;
	}

	setCompanyContact(companyContact: ICompanyContact) {
		this.companyContact = companyContact;
	}

	triggerCompanyChange(company: ICompany) {
		this.onCompanyChange.trigger(this, company);
	}

	setCompanyCreationProcessStatus(status: boolean) {
		this.inCompanyCreationProcess = status;
	}

	onCompanyChange = new EventHandler<CompanyStore, ICompany>();

	constructor(private rootStore: RootStore) {
		makeAutoObservable(this);
		this.onCompanyChange.subscribe(this.initServices);

		makePersistable(this, {
			name: 'CompanyStore',
			properties: ['company', 'addresses', 'companies'],
			storage: window.sessionStorage,
			expireIn: 10800000, // 3 hours
		});

		reaction(
			() => this.company,
			(company: ICompany) => {
				//Prevent auto reload when creating company from company
				if (this.isCreatingCompanyFromCompany) {
					return;
				}

				this.onCompanyChange.trigger(this, company);
				this.initServices();

				// Reload permissions
				if (this.rootStore.auth.userInfo.status === UserStatus.Active) {
					this.rootStore.auth.setCompanyPermissions(company.companyId);
				}
			}
		);
	}

	private initServices = () => {
		this.companyService = new CompanyService(this.companyId, this.odooTransactionId);

		RootStore.subscribeToLoading([this.companyService], this.rootStore);

		if (this.initiated) {
			this.init();
		}
	};

	resetStoreToDefaultValues = () => {
		this.companies = [];
		this.addresses = [];
		this.company = {} as ICompany;
	};

	createCompany = async (data: ICompanyRegistration) => {
		this.setCompanyCreationProcessStatus(true);
		const res = await this.companyService.createCompany(data);

		if (res.data) {
			this.company = res.data;
			this.companies ? this.setCopmanies([...this.companies, res.data]) : this.setCopmanies([res.data]);
			this.rootStore.auth.updateToken(res.token);

			return {
				isSuccess: true,
				data: res.data,
				token: res.token,
			};
		}

		return {
			isSuccess: false,
			error: res.error,
		};
	};

	getCompany = async (companyId: number) => {
		if (!companyId) {
			return;
		}

		const res = await this.companyService.getCompany(companyId);

		if (res.data) {
			runInAction(async () => {
				this.company = res.data;
				!this.addresses.length && this.rootStore.auth.isUserActive && this.getAddresses(companyId);
			});
		}

		return res;
	};

	updateCompany = async (companyId: number, data: ICompanyUpdate) => {
		const res = await this.companyService.updateCompany(companyId, data);

		if (res.data) {
			this.setCompany(res.data);
			this.setCopmanies([...this.companies.filter((company) => company.companyId !== companyId), res.data]);

			return {
				isSuccess: true,
				data: res.data,
			};
		}

		return {
			isSuccess: false,
			error: 'Error Occur while updating company',
		};
	};

	deleteCompany = async (companyId: number) => {
		const res = await this.companyService.deleteCompany(companyId);
		return res;
	};

	getCompanies = async (companyId?: number) => {
		if (this.companyId) {
			if (companyId) {
				this.company = this.companies.find((company) => company.companyId === companyId) ?? this.company;
			}
			return;
		}

		const res = await this.companyService.getCompanies();

		if (res.data) {
			this.setCopmanies(res.data);
			this.company = res.data.find((company) => company.companyId === (companyId || this.companyId)) ?? res.data[0];
			//this.company.pivotalEquityPlans = true
		}
	};

	addAddress = async (companyId: number, data: IAddressRegistration) => {
		const res = await this.companyService.addAddress(companyId, data);

		if (res.data) {
			return {
				isSuccess: true,
				data: res.data,
			};
		}

		return {
			isSuccess: false,
			error: 'Error Occur while creating company address',
		};
	};

	getAddresses = async (companyId: number = this.companyId) => {
		const res = await this.companyService.getAddresses(companyId);

		if (res.data) {
			runInAction(async () => {
				this.addresses = res.data;
			});
		}

		return res;
	};

	updateAddress = async (addressId: number, data: IAddressUpdate) => {
		const res = await this.companyService.updateAddress(addressId, data);

		if (res.data) {
			this.setAddresses([...this.addresses.filter((address) => address.addressId !== addressId), res.data]);
		}

		return res;
	};

	deleteAddress = async (companyId: number, addressId: number) => {
		const res = await this.companyService.deleteAddress(companyId, addressId);
		return res;
	};

	getCompanyContact = async (companyId: number = this.companyId) => {
		if (!companyId) {
			return;
		}

		const res = await this.companyService.getCompanyContact(companyId);

		if (res.data) {
			runInAction(async () => {
				this.companyContact = res.data;
			});
		}

		return res;
	};

	updateCompanyContact = async (companyId: number, companyContactId: number, data: ICompanyContactRegistration) => {
		const res = await this.companyService.updateCompanyContact(companyId, companyContactId, data);

		if (res.data) {
			this.setCompanyContact(res.data);
		}

		return res;
	};

	async setCompanyEquitySource(fromPivotal: boolean) {
		const res = await this.companyService.updateCompanyEquitySource(fromPivotal, this.companyId);
		if (res) {
			this.company.pivotalEquityPlans = res.data as boolean;
		}
	}

	async setCapTableEquitySource(fromPivotal: boolean) {
		const res = await this.companyService.updateCapTableEquitySource(fromPivotal, this.companyId);
		if (res) {
			this.company.pivotalEquityPlans = res.data as boolean;
			this.company.capTableTrusteeEquity = res.data as boolean;
		}
	}

	async init() {
		this.initiated = true;
	}
}
