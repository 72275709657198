import { Switch } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { EquityPlan, IEquityPlan, IPlanVest, IVestingSchedule } from '../../../../../../Models/App/EquityPlans/Company';
import Button from '../../../../../../Shared/Components/Button/Button';
import CheckBox from '../../../../../../Shared/Components/CheckBox/CheckBox';
import DatePickerInput from '../../../../../../Shared/Components/Input/DatePickerInput';
import { Comment } from '../../../../../../Shared/Components/Input/Input.Style';
import NumberInput from '../../../../../../Shared/Components/Input/NumberInput';
import TextInput from '../../../../../../Shared/Components/Input/TextInput';
import Flex from '../../../../../../Shared/Components/Layout/Flex';
import Title from '../../../../../../Shared/Components/Layout/Title';
import { ModalBodyProps } from '../../../../../../Shared/Components/Modal/types';
import { TableColumn } from '../../../../../../Shared/Components/Table/Table';
import { useAppendState } from '../../../../../../Shared/Hooks/useAppendState';
import { InputValidationRef, useFormValidation } from '../../../../../../Shared/Hooks/useFormValidation';
import useGeneralModal from '../../../../../../Shared/Hooks/useGeneralModal';
import useModal from '../../../../../../Shared/Hooks/useModal';
import { ForwardedRefType, MultiStepElementProps } from '../../../../../../Shared/Hooks/useMultiStepForm';
import useRootStore from '../../../../../../Shared/Hooks/useRootStore';
import { commonValidators } from '../../../../../../Shared/ObjectValidator';
import { isNullOrUndefined } from '../../../../../../Shared/Utilities';
import useEsopModals from '../../../../useEsopModals';
import { StepDivider, StepTitle } from '../../../Registration/Registration.Style';
import PoolStepsTable from '../PoolStepsTable';
import NewPlanStyle from './index.style';
import RadioButton from '../../../../../../Shared/Components/RadioButton/RadioButton';

interface IProps extends MultiStepElementProps, ModalBodyProps {
	readonly registration?: boolean;
	isViewMode?: boolean;
	data?: EquityPlan;
}

const AddEditEquityPlan = forwardRef<ForwardedRefType<IEquityPlan>, IProps>(
	({ data, isViewMode = false, registration, removeModal, hideModal, displayModal }, forwardedRef) => {
		const { equityPlansStore } = useRootStore();
		const [plan, setPlan, onInputHandler] = useAppendState<IEquityPlan>(data || { isCancellationsReturnToPool: true });
		const [isAwardError, setIsAwardError] = useState<boolean>(false);
		const { showErrorModal } = useEsopModals();

		const { showSuccessModal } = useGeneralModal();

		const isDisabled = (isViewMode ?? !!data?.planId) || equityPlansStore.isAddingPoolStep;
		const isEdit = !(isViewMode ?? !!data?.planId) && !!data?.planId;

		useImperativeHandle(forwardedRef, () => ({
			async onValidate() {
				setIsAwardError(false);
				if (plan.allowRsu !== true && plan.allowSop !== true) {
					setIsAwardError(true);
					return false;
				}
				if (isDisabled) return true;
				let isValidated = validateForm();
				return poolValidateForm() && isValidated && !isAwardError;
			},
			getData: () => plan,
		}));

		const formSchema = {
			name: [commonValidators.required()],
			planApprovalDateByBoard: [commonValidators.required()],
		};

		const poolSchema = {
			amount: [commonValidators.required()],
		};

		const {
			formValidationState: poolFormValidationState,
			validateForm: poolValidateForm,
			inputRefs: poolInputRefs,
		} = useFormValidation({
			form: plan.poolParams,
			schema: poolSchema,
		});

		const { formValidationState, validateForm, inputRefs } = useFormValidation({
			form: plan,
			schema: formSchema,
		});

		const onAddEditHandler = async () => {
			const isError = !plan.allowRsu && !plan.allowSop;
			setIsAwardError(isError);
			if (isError) return;

			if (isEdit) {
				const { allowRsu, allowSop, isCancellationsReturnToPool, name, planApprovalDateByBoard } = plan;
				const res = await equityPlansStore.updateEquityPlan(data.planId, {
					allowRsu,
					allowSop,
					isCancellationsReturnToPool,
					name,
					planApprovalDateByBoard,
				});

				res.data?.plan.planId ? removeModal?.() : showErrorModal(res.errorMessage, res.errorMessage);
				return;
			}

			const res = await equityPlansStore.addEquityPlan(plan);
			if (res?.data?.planId) {
				showSuccessModal({ body: 'The plan was added successfully', onModalClose: removeModal });
			} else {
				showErrorModal(res, res?.errorMessage);
			}
		};

		const getPoolAmount = async () => {
			if (isNullOrUndefined(data)) return;

			const res = await equityPlansStore.getEquityPlan(data.planId);
			if (!res.data) return;
			setPlan((prevState) => ({
				...prevState,
				poolParams: {
					...prevState.poolParams,
					amount: res.data.poolSteps.map((pls) => pls?.amount)?.reduce((acc, crr) => acc + crr),
				},
			}));
		};

		useEffect(() => {
			getPoolAmount();
		}, []);

		const AwardType = (
			<Flex direction="column" width={'100%'} align="start">
				<div className={`${StepTitle}__award-title`}>
					<span>Award type *</span>
					{isAwardError && (
						<Comment absolute={false} error>
							Required field
						</Comment>
					)}
				</div>
				<Flex width={'fit-content'}>
					<CheckBox
						className="checkboxContainer"
						isChecked={!!plan.allowRsu}
						label="RSU"
						qaid="NewEquityPlan.CheckBox.Rsu"
						name="allowRsu"
						disabled={isDisabled}
						onClick={onInputHandler}
					/>
					<CheckBox
						disabled={isDisabled}
						isChecked={!!plan.allowSop}
						label="Options"
						qaid="NewEquityPlan.CheckBox.Options"
						name="allowSop"
						onClick={onInputHandler}
					/>
				</Flex>
			</Flex>
		);

		const ReturnToTool = (
			<Flex justify="start" align="center" gap="3rem" margin={registration ? '4rem 0 2rem 0' : 0}>
				<span>Return canceled options/RSU to pool?</span>
				<RadioButton
					qaid="NewEquityPlan.Checkbox.ReturnCanceledOptions"
					name="isCancellationsReturnToPool"
					checked={plan.isCancellationsReturnToPool}
					value={true}
					disabled={isDisabled}
					onChange={onInputHandler}
					label="Yes"
				/>
				<RadioButton
					qaid="NewEquityPlan.Checkbox.DoNotReturnCanceledOptions"
					name="isCancellationsReturnToPool"
					checked={plan.isCancellationsReturnToPool === false}
					value={false}
					disabled={isDisabled}
					onChange={onInputHandler}
					label="No"
				/>
			</Flex>
		);

		return (
			<div className={NewPlanStyle}>
				{!registration && (
					<>
						<Title className={`${NewPlanStyle}__title`}>{data ? 'Equity Plan' : 'New Equity Plan'}</Title>
						<span className={`${StepTitle}__subTitle`}>{isEdit ? 'Edit plan settings' : 'Plan settings'}</span>
						<hr className={StepDivider} />
						{AwardType}
						<hr className={StepDivider} />
						{ReturnToTool}
						<hr className={StepDivider} />
					</>
				)}
				<Flex justify="between" gap="2.4rem" align="center" margin={registration ? '0 0 2.4rem 0' : 0}>
					<TextInput
						containerClassName="mb-0 inputContainer"
						label="Plan name"
						ref={(el: InputValidationRef) => (inputRefs.name = el)}
						error={formValidationState?.name?.message}
						required
						qaid="NewEquityPlan.Input.PlanName"
						value={plan.name}
						name="name"
						onChange={onInputHandler}
						isViewMode={isDisabled}
					/>
					<DatePickerInput
						containerClassName="mb-0 inputContainer"
						label="Plan board approval date"
						required
						qaid="NewEquityPlan.Input.PlanApprovalDateByBoard"
						name="planApprovalDateByBoard"
						placeholder="Select date"
						ref={(el: InputValidationRef) => (inputRefs.planApprovalDateByBoard = el)}
						error={formValidationState?.planApprovalDateByBoard?.message}
						value={plan.planApprovalDateByBoard}
						isViewMode={isDisabled}
						// error={formValidationState?.planApprovalDateByBoard?.message}allowSopallowSop
						onChange={onInputHandler}
					/>
					{!isEdit && (
						<NumberInput
							containerClassName="mb-0 inputContainer"
							label="Initial pool size"
							required
							qaid="NewEquityPlan.Input.PoolAmount"
							value={plan.poolParams?.amount}
							onChange={(value) => {
								setPlan((prevState) => ({
									...prevState,
									poolParams: {
										...prevState.poolParams,
										amount: value,
									},
								}));
							}}
							isViewMode={isDisabled}
							ref={(el: InputValidationRef) => (poolInputRefs.amount = el)}
							error={poolFormValidationState?.amount?.message}
						/>
					)}
				</Flex>
				{!!registration ? AwardType : <hr className={StepDivider} />}
				{isEdit && !!data?.planId && (
					<PoolStepsTable planId={data?.planId} {...{ hideModal, displayModal }} approvalDate={data.planApprovalDateByBoard} />
				)}

				{!!registration && ReturnToTool}

				{!registration && (
					<Flex gap={8} align="center" className="mt-5" justify="end">
						{!isDisabled && <Button label={isEdit ? 'Confirm' : 'Create plan'} qaid="NewPlan.Button.CreatePlan" onClick={onAddEditHandler} />}
					</Flex>
				)}
			</div>
		);
	}
);
export default observer(AddEditEquityPlan);
