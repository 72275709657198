import { observer } from 'mobx-react-lite';
import { Addon } from '..';
import Image from '../../../../../Shared/Components/Image';
import Flex from '../../../../../Shared/Components/Layout/Flex';
import useRootStore from '../../../../../Shared/Hooks/useRootStore';
import { formatDecimal, formatNumber } from '../../../../../Shared/Utilities';
import { getPlanData } from '../../../Pricing/utils/utils';
import { SelectedPlanStyle } from '../../SelectedPlan/SelectedPlan.Style';

type Props = {
	addon: Addon;
};

const SelectedAddon = ({ addon }: Props) => {
	return (
		<div className={SelectedPlanStyle}>
			<Flex className="topContainer">
				<Image className="topContainer__logo" src={addon.logo} alt="altshare logo" />
				<span className="topContainer__title">{addon.name}</span>
				<Flex className="priceContainer">
					<span className="priceContainer__price">${formatDecimal(addon.price)}</span>
				</Flex>
				<span className="topContainer__subTitle">{addon.description}</span>
				<span className="topContainer__subTitle comment">Excluding sales taxes</span>
			</Flex>
			<hr className="divider" />
			<hr className="divider" />
			<div className="invoiceContainer">
				<span className="invoiceContainer__title">Payment summary</span>
				<div className="invoiceContainer__paymentsContiner">
					<div className="invoiceContainer__payment" key={addon.name}>
						<span className="desc">{addon.name}</span>
						<span className="amount">{formatDecimal(addon.price)}</span>
					</div>
					<hr className="divider clean" />
					<div className="invoiceContainer__payment total">
						<span className="desc">Total amount</span>
						<span className="amount">${formatNumber(addon.price)}</span>
					</div>
				</div>
			</div>
		</div>
	);
};

export default observer(SelectedAddon);
