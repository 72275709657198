import { useEffect, useMemo, useState } from "react";
import Select from "../../../../../Shared/Components/Select/Select";
import useRootStore from "../../../../../Shared/Hooks/useRootStore";
import { OrderType, OrderTypeAndAmount } from "../../../../../Models/App/EquityPlans/Order";
import { observer } from "mobx-react-lite";
import NewOrderStyles from "./NewOrder.style";
import Button from "../../../../../Shared/Components/Button/Button";
import Flex from "../../../../../Shared/Components/Layout/Flex";
import NumberInput from "../../../../../Shared/Components/Input/NumberInput";
import DatePickerInput from "../../../../../Shared/Components/Input/DatePickerInput";
import { formatNumber, isNullOrUndefined, isNumber } from "../../../../../Shared/Utilities";
import { orderTypes } from "../../../../../Shared/StaticData/equityPlans";
import Title from "../../../../../Shared/Components/Layout/Title";
import { ModalBodyProps } from "../../../../../Shared/Components/Modal/types";
import useEsopModals from "../../../useEsopModals";
import { NumberOption } from "../../../../../Models/API/All/NumberOption";

// import Input from "../../../../../Shared/Components/Input/Input";
// import { Switch } from "@mui/material";
// import { toJS } from "mobx";
// import { nulldefined } from "../../../../../Shared/Interfaces/global";
// import { useFormValidation } from "../../../../../Shared/Hooks/useFormValidation";

interface NewOrderProps extends ModalBodyProps {
    selectBen?: boolean;
    selectGrant?: boolean;
    grantId?: number;
    beneficiaryId?: number;
    orderType?: number;
    onSave?: (grant?: any) => unknown;
}

const NewOrder = (props: NewOrderProps) => {
    const { equityPlansStore, currenciesOptions } = useRootStore();
    const { showSuccessModal, showErrorModal } = useEsopModals();

    // console.log('New order PROPS', props)

    const [grant, setGrant] = useState<number>();
    // const [exerciseable, setExerciseable] = useState<number>(0);
    // const [sellable, setSellable] = useState<number>(0);
    const [benId, setBenId] = useState<number>();

    const [avlbleOrderTypes, setAvlbleOrderTypes] = useState<NumberOption[]>([]);
    const [amountByOrderType, setAmountByOrderType] = useState<OrderTypeAndAmount[]>();
    const [aAmount, setAamount] = useState<string>("");

    // const [grantDsbld, setGrantDsbld] = useState(false);

    const [orderFrom, setOrderFrom] = useState<"grntCard" | "benCard" | "grntQA" | "grntsTable">();

    const order = equityPlansStore.newOrder;

    const onInputHandler = (value: string | number | undefined, name: string) => {
        equityPlansStore.newOrder = {
            ...equityPlansStore.newOrder,
            [name]: value,
        };
    };

    const choosenGrantHandler = (g: number) => {
        setGrant(g);
        // getAvailableAmount();
        // equityPlansStore.beneficiaryGrants?.forEach((grt) => {
        // 	if (grt.grantId !== g) return;

        // 	setExerciseable(grt.exercisable);
        // 	setSellable(grt.sellable);
        // });
    };

    const getTypesAndAmountsQa = async () => {
        if (!props.grantId || !order.date) return;

        setAamount("");
        const options = await equityPlansStore.TypesAndAmountsForOrder(props.grantId, order.date);
        const fOptions = options.data?.filter((opt) => opt.orderType === props.orderType);
        setAmountByOrderType(fOptions);

        if (isNullOrUndefined(fOptions)) return;
        const amount = fOptions[0].amount.toString();
        setAamount(amount);
    };

    const getTypesAndAmounts = async () => {
        if (orderFrom === "grntQA") {
            getTypesAndAmountsQa();
        } else {
            if (!grant || !order.date) return;

            setAamount("");
            const options = await equityPlansStore.TypesAndAmountsForOrder(grant, order.date);

            const fOptions = options.data?.filter((opt) => opt.amount);
            setAmountByOrderType(fOptions);

            if (isNullOrUndefined(fOptions)) return;
            let aOptions: NumberOption[] = [];

            fOptions.forEach((fopt) => {
                orderTypes.forEach((otp) => {
                    if (fopt.orderType === otp.value) aOptions.push(otp);
                });
            });

            setAvlbleOrderTypes(aOptions);
            setAvailableAmount();
        }
    };

    const setAvailableAmount = () => {
        if (isNullOrUndefined(order.orderType)) return;
        const amount = amountByOrderType?.filter((otp) => otp.orderType === order.orderType)[0]?.amount;
        const str = amount?.toString();
        if (!str) return;
        setAamount(str);
    };

    const CreateNewOrderHandler = async () => {
        if (!grant || !benId) return;

        switch (orderFrom) {
            case "benCard":
                const res = await equityPlansStore.CreateNewOrderForBeneficiary(grant);

                if (res.data?.orderId) {
                    props.removeModal && props.removeModal();
                    showSuccessModal("The order was added successfully");
                    equityPlansStore.GetOrdersForBeneficiary(benId);
                    equityPlansStore.GetBeneficiariesDashboardData();
                    equityPlansStore.GetBeneficiary(benId);
                } else {
                    showErrorModal(res);
                }
                break;

            case "grntCard":
                const gRes = await equityPlansStore.CreateNewOrder(grant, benId);

                if (gRes.data?.orderId) {
                    props.removeModal && props.removeModal();
                    showSuccessModal("The order was added successfully");
                    equityPlansStore.GetOrdersForSingleGrant(benId, grant);
                    equityPlansStore.GetOrdersForBeneficiary(benId);
                    equityPlansStore.GetGrantsForCompany();
                } else {
                    showErrorModal(gRes);
                }
                break;

            case "grntsTable":
                const tRes = await equityPlansStore.CreateNewOrder(grant, benId);

                if (tRes.data?.orderId) {
                    props.removeModal && props.removeModal();
                    showSuccessModal("The order was added successfully");
                    equityPlansStore.GetDashboardOrders();
                } else {
                    showErrorModal(tRes);
                }
                break;

            case "grntQA":
                if (isNullOrUndefined(props.orderType)) return;
                const qaRes = await equityPlansStore.CreateNewQaOrder(grant, benId, props.orderType);
                console.log("HERE !!!");
                if (qaRes.data?.orderId) {
                    props.removeModal && props.removeModal();
                    showSuccessModal("The order was added successfully");
                    equityPlansStore.GetGrantsForCompany();
                } else {
                    showErrorModal(qaRes);
                }
                break;
        }
    };

    const beneficiaryHandler = async (id: number) => {
        await equityPlansStore.GetGrantsForBeneficiary(id);
        // setGrantDsbld(false);
    };

    // const getAmountPerDate = async (value: Date) => {
    // 	console.log("firstfirstfirst");
    // 	if (!grant || isNullOrUndefined(order.orderType)) return;

    // 	const amount = await equityPlansStore.ValidateOrderAmount(grant, value, order.orderType);
    // 	if (order.orderType === OrderType.Exercise || order.orderType === OrderType.SDS) {
    // 		setExerciseable(amount.data?.amount);
    // 	} else if (order.orderType === OrderType.Sell) {
    // 		setSellable(amount.data?.amount);
    // 	}
    // };

    const resetCard = () => {
        equityPlansStore.newOrder = {};
        setBenId(undefined);
        // setSellable(0);
        // setExerciseable(0);
        setGrant(undefined);
    };

    const defineOrderFrom = () => {
        if (!isNullOrUndefined(props.orderType)) {
            setOrderFrom("grntQA");
        } else if (props.selectGrant && !props.selectBen) {
            setOrderFrom("benCard");
            // console.log('setting ben Card', equityPlansStore.beneficiaryData.beneficiaryId)
        } else if (!props.selectGrant && !props.selectBen) {
            setOrderFrom("grntCard");
        } else if (props.selectGrant && props.selectBen) {
            setOrderFrom("grntsTable");
        }
    };

    const mountCard = () => {
        if (isNullOrUndefined(orderFrom)) defineOrderFrom();
        // console.log('orderFrom', orderFrom)
        // console.log('benId, grant', benId, grant)

        switch (orderFrom) {
            case "benCard":
                setBenId(equityPlansStore.beneficiaryData.beneficiaryId);
                // console.log('setting benId', equityPlansStore.beneficiaryData.beneficiaryId)
                break;
            case "grntCard":
                setGrant(equityPlansStore.beneficiaryGrantData.grantId);
                setBenId(equityPlansStore.beneficiaryGrantData.beneficiaryId);
                break;
            case "grntsTable":
                // setGrantDsbld(true)
                if (!equityPlansStore.companyBeneficiaries) {
                    equityPlansStore.GetBeneficiariesDashboardData();
                }
                break;
            case "grntQA":
                setBenId(props.beneficiaryId);
                setGrant(props.grantId);
                order.orderType = props.orderType;
                order.grantId = props.grantId;
                break;
        }
    };

    useEffect(() => {
        setAvailableAmount();
    }, [order.orderType]);

    useEffect(() => {
        // console.log('canhging!!!!', order)
        order.orderType = undefined;
        getTypesAndAmounts();
    }, [order.date, grant]);

    useEffect(() => {
        mountCard();
    }, [orderFrom]);

    useEffect(() => {
        mountCard();

        return () => {
            resetCard();
        };
    }, []);

    // const comment = order.orderType === OrderType.Sell ? `Available: ${sellable}` : order.orderType === OrderType.Exercise ? `Available: ${exerciseable}` : undefined;

    return (
        <div className={NewOrderStyles}>
            <Title className={`${NewOrderStyles}__title`}>Add Grant Order</Title>
            {/* <Flex direction="row" justify="around">
				{isNullOrUndefined(order.orderType) && isNullOrUndefined(order.date) ? (
					<>
						<NumberInput qaid="NewOrder.Input.Exerciseable" label="Exerciseable" value={exerciseable} name="exerciseable" isViewMode />
						<NumberInput qaid="NewOrder.Input.Sellable" label="Sellable" value={sellable} name="sellable" isViewMode />
					</>
				) : order.orderType === OrderType.Sell ? (
					<NumberInput qaid="NewOrder.Input.Sellable" label="Sellable" value={sellable} name="sellable" isViewMode />
				) : (
					<NumberInput qaid="NewOrder.Input.Exerciseable" label="Exerciseable" value={exerciseable} name="exerciseable" isViewMode />
				)}
			</Flex> */}

            <div className={`${NewOrderStyles}__form`}>
                {props.selectBen === true && (
                    <>
                        <Select
                            label="Select beneficiary"
                            options={
                                equityPlansStore.companyBeneficiaries?.map((ben) => {
                                    return {
                                        label: `${ben.fullName.firstName} ${ben.fullName.lastName}, Emp. Number: ${ben.employeeNumber}`,
                                        value: ben.beneficiaryId,
                                    };
                                }) ?? []
                            }
                            onChange={(e) => {
                                setBenId(e as number);
                                beneficiaryHandler(+e);
                            }}
                            value={benId}
                            qaid="NewOrder.Select.BeneficiaryId"
                        />
                    </>
                )}

                {/* Grants */}

                {props.selectGrant && (
                    <Select
                        label="Select grant"
                        options={equityPlansStore.beneficiaryGrantList ?? []}
                        value={equityPlansStore.beneficiaryGrantList?.filter((grt) => grt.value === grant)[0]?.value}
                        onChange={(e) => choosenGrantHandler(e as number)}
                        qaid="NewOrder.Select.Grant"
                        disabled={!!!benId}
                    />
                )}

                {/* Date */}

                <DatePickerInput
                    label="Order date"
                    qaid="NewOrder.Input.OrderDate"
                    value={order.date}
                    maxDate={new Date()}
                    name="date"
                    onChange={(value, name) => {
                        if (isNullOrUndefined(value)) return;
                        // getAmountPerDate(value);
                        onInputHandler(value?.toISOString(), name!);
                    }}
                    // onBlur={getAvailableAmount}
                />

                {/* Type */}

                {!isNullOrUndefined(props.orderType) ? (
                    <Select
                        label="Order type"
                        options={orderTypes}
                        value={props.orderType}
                        name="orderType"
                        onChange={() => {}}
                        qaid="NewOrder.Select.OrderType"
                        disabled
                    />
                ) : (
                    <Select
                        label="Order type"
                        options={avlbleOrderTypes}
                        value={order.orderType}
                        name="orderType"
                        onChange={(value, name) => onInputHandler(value, name!)}
                        qaid="NewOrder.Select.OrderType"
                        disabled={!!!order.date}
                    />
                )}

                {/* Amount */}

                <NumberInput
                    label="Amount"
                    qaid="NewOrder.Input.Amount"
                    value={order.amount}
                    max={aAmount}
                    name="amount"
                    onChange={(value, name) => {
                        if (isNullOrUndefined(value) || isNullOrUndefined(amountByOrderType) || value > amountByOrderType[0].amount) {
                            return;
                        }
                        onInputHandler(value, name!);
                    }}
                    comment={`Available to date: ${formatNumber(aAmount)}`}
                    disabled={!(!!order.date && !(isNullOrUndefined(order.orderType) && isNullOrUndefined(props.orderType)))}
                />

                {/* Price */}

                {(order.orderType === OrderType.Sell || props.orderType === OrderType.Sell || order.orderType === OrderType.SDS) && (
                    <NumberInput
                        qaid="AddGrant.Input.price"
                        label="Sale price"
                        value={order.sellPrice}
                        name="price"
                        onChange={(value, name) => {
                            if (isNumber(value)) onInputHandler(value, name!);
                        }}
                        options={currenciesOptions}
                        selectedValue={order?.currency}
                        onOptionSelect={(value) => {
                            if (isNumber(value)) onInputHandler(value, "currency");
                        }}
                        number="float"
                        ratio={0.33}
                    />
                )}
            </div>

            <Flex
                className="buttons"
                justify="end"
                margin="2rem 0 0 0"
            >
                <Button
                    qaid="NewOrder.Select.BeneficiaryId"
                    label="Save"
                    onClick={CreateNewOrderHandler}
                />
            </Flex>
        </div>
    );
};
export default observer(NewOrder);

// const getBenGrants = async () => {
// 	if (!benId) return;
// 	await equityPlansStore.GetGrantsForBeneficiary(benId);
// };

// const getAvailableAmount = async () => {
// 	const { date, grantId, orderType, contactId } = order;
// 	if (!date || !grantId || !orderType || !contactId) return;
// 	const data = await equityPlansStore.ValidateTransaction({ date, grantId, orderType, contactId });
// 	console.log("​getAvailableAmount -> data", data);
// };

// useEffect(() => {
// 	// get beneficiary grants
// 	getBenGrants();
// }, [benId]);
