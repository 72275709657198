import { css } from "@emotion/css";
import appConfig from "../../../../../config/config";

const BeneficiarySummaryStyles = css({
    label: "BeneficiarySummary",
    padding: '3rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    border: '1px solid #D0D4E4',
    borderRadius: '16px',

    "&__subTitle": {
        fontSize: '1.8rem'
    },

    "&__title": {
        color: appConfig.style.colors.text2,
    },

    "&__chartWrap": {
        width: '100%',
        display: 'flex',
        gap: '2rem',
        justifyContent: 'space-around',
        alignItems: 'center'
    },

    "&__divider": {
        width: '1px',
        height: 'inherit',
        background: '#D0D4E4'
    },

    "&__summary": {
        display: 'flex',
        flexDirection: 'column',
        width: '20%',
        justifyContent: 'center',
        alignItems: 'center',

        "> p": {
            fontWeight: 'bold'
        },

        "> span": {
            marginBottom: '15px'
        }
    }
});

export default BeneficiarySummaryStyles;
