import { css } from '@emotion/css';
import Button from '../../../../Shared/Components/Button/Button';
import Title from '../../../../Shared/Components/Layout/Title';
import appConfig from '../../../../config/config';

type Props = {
	onBack: () => void;
	onNext: () => Promise<void>;
	isLoading: boolean;
};

const Style = css({
	height: 'calc(100% - 7.1rem)',
	display: 'flex',
	flexDirection: 'column',
	animation: 'fadeIn .5s ease-out',
	'&__content': {
		padding: '3rem 10rem',
		flex: 1,
		background: 'linear-gradient(0deg,#9ca7ff -23.09%,#f0f1ff 61.7%,hsla(0,0%,100%,0) 118.58%)',
		overflow: 'auto',
		height: 'calc(100% - 10rem)',
		'.title': {
			fontSize: '2rem',
			fontWeight: 700,
			marginBottom: '3rem',
		},
		'.sub-title': {
			fontSize: '1.6rem',
			fontWeight: 500,
			marginBottom: '3rem',
		},
		'.paragraphs-container': {
			display: 'flex',
			flexDirection: 'column',
			gap: '2rem',
			marginBottom: '3rem',
			'.paragraph': {
				position: 'relative',
				paddingLeft: '6rem',
				lineHeight: '3.5rem',
				// fontSize: '1.8rem',
				'&::before': {
					content: 'attr(data-index)',
					position: 'absolute',
					left: 0,
					top: '0.6rem',
					background: 'white',
					width: '3rem',
					height: '3rem',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					color: appConfig.style.colors.color1,
					borderRadius: '50%',
					border: `2px solid ${appConfig.style.colors.color1}`,
					fontSize: '1.4rem',
				},
				'&:not(:last-child)': {
					'&::after': {
						content: '""',
						position: 'absolute',
						left: 'calc(1.5rem - 1px)',
						top: '3.6rem',
						height: '100%',
						width: 2,
						background: appConfig.style.colors.color1,
					},
				},
			},
		},
		'ul:not(.paragraphs-container) li': {
			listStyle: 'inside',
			color: appConfig.style.colors.text4,
			// fontSize: '1.8rem',
			'&:not(:last-child)': {
				marginBottom: '1rem',
			},
		},
	},
	'&__footer': {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		padding: '2rem 10rem 3rem',
	},
});

const ProjectOverview = ({ onBack, onNext, isLoading }: Props) => {
	return (
		<div className={Style}>
			<div className={`${Style}__content`}>
				<div className="title">Before you start, a brief overview of the process</div>
				<ul className="paragraphs-container">
					<li className="paragraph" data-index={1}>
						<span className="bold text-color-1">Data collection - </span>we will begin with a few questions to gather financial information about
						the company, including historical financial statements, latest investments, cap table status, projections and other relevant documents.
						It's a very straightforward process and should take minutes to complete. If you encounter questions or need clarification on any topic,
						don't worry - just enter your comments accordingly, complete the wizard and 'Submit for review', and our valuation analyst will promptly
						assist you from there.
					</li>
					<li className="paragraph" data-index={2}>
						<span className="bold text-color-1">Analysis & valuation - </span>once all the necessary data has been provided, our valuation team will
						analyze the company business model, competitive landscape and potential growth, and select most appropriate valuation methodology based
						on the company's data, stage and characteristics.
					</li>
					<li className="paragraph" data-index={3}>
						<span className="bold text-color-1">Draft report - </span>we will valuate a Fair Market Value (FMV) of the company common stock based on
						selected methodology and provide a valuation summary. Once payment is settled, a draft valuation report will be uploaded, detailing all
						assumption, parameters and models used in the process.
					</li>
					<li className="paragraph" data-index={4}>
						<span className="bold text-color-1">Final report - </span>review the draft 409A valuation report with your board and upon approval, we
						will issue a final report you can use to set the exercise price for stock option or other equity awards.
					</li>
				</ul>
				<div className="sub-title">Your time is important to us. Please make sure the following materials are available prior you start:</div>
				<ul>
					<li>Articles of Association (AoA)</li>
					<li>Latest investments documents</li>
					<li>Updated Cap table and options ledger (ESOP) - for the first valuation of new altshare customers</li>
				</ul>
			</div>
			<div className={`${Style}__footer`}>
				<Button qaid="ProjectOverview.Button.Back" label="Back" onClick={onBack} inverse />
				<Button qaid="ProjectOverview.Button.Continue" label="Continue" onClick={onNext} isLoading={isLoading} />
			</div>
		</div>
	);
};

export default ProjectOverview;
