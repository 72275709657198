import { DateFormatsEnum, formatDate, formatDecimal, formatNumber, getPercentrage, getRandomNumberBetween } from '../../../Shared/Utilities';
import { Multiple, MultipleData, Volatility, WaterfallPeer } from '../../../Models/API/Waterfall/IForm';
import * as ExcelJS from 'exceljs';
import saveAs from 'file-saver';
import appConfig from '../../../config/config';
import { useCallback, useMemo } from 'react';
import { Breakpoint, ShareClassValuation, ShareClassValuationTable } from '../../../Models/API/Valuation/summary';
import { AsianPutDlom, BAndSAssumptions, ExportSummary, FinnertyDlom } from '../../../Models/API/Valuation/export-summary';
import { EquityValueEnum } from '../../../Models/API/Valuation/equity-value-enum';
import asiamPutDlomImg1 from '../../../Assets/images/export-summary/asianputdlom_img1.png';
import { formulaImages } from './formulaImages';

type Peer = Pick<WaterfallPeer, 'companyName' | 'ticker' | 'description'>;

type VolatilityProps = {
	volatilities: Volatility[];
	peers: Peer[];
};

type PeersMultipleProps = {
	multiples: Multiple[];
	multiplesData?: MultipleData[];
};

type ValuationSummaryProps = {
	shareClasses: ShareClassValuationTable[];
	valuationDate: Date;
	commonShareFairValue?: number;
	dlom: number;
};

export type ExportVolatilityProps = {
	projectName: string;
	valuationDate: Date;
	volatility: VolatilityProps;
	peersMultiple: PeersMultipleProps;
};

export type ExportSummaryProps = ExportSummary & {
	valuationDate: string;
};

const borderStyle: Partial<ExcelJS.Border> = {
	style: 'thin',
	color: {
		argb: '000000',
	},
};

const border = {
	left: borderStyle,
	bottom: borderStyle,
	top: borderStyle,
	right: borderStyle,
};

const borderSecondaryStyle: Partial<ExcelJS.Border> = {
	style: 'thin',
	color: {
		argb: appConfig.style.colors.table.replace('#', ''),
	},
};

const borderSecondary = {
	left: borderSecondaryStyle,
	bottom: borderSecondaryStyle,
	top: borderSecondaryStyle,
	right: borderSecondaryStyle,
};

const defaultWorkSheetProperties: Partial<ExcelJS.AddWorksheetOptions> = {
	pageSetup: {
		horizontalCentered: true,
		verticalCentered: true,
		fitToPage: true,
		orientation: 'landscape',
	},
	views: [{ showGridLines: false }],
};

const setCellWithPrimaryBackground = (cell: ExcelJS.Cell, value: ExcelJS.CellValue, border?: Partial<ExcelJS.Borders>) => {
	cell.value = value;
	cell.fill = {
		...cell.fill,
		type: 'pattern',
		pattern: 'solid',
		fgColor: {
			argb: appConfig.style.colors.color1.replace('#', ''),
		},
	};

	cell.font = {
		...cell.font,
		color: {
			argb: 'ffffff',
		},
		bold: true,
	};

	if (border) {
		cell.border = border;
	}
};

const setCellWithSecondaryBackground = (cell: ExcelJS.Cell, value: ExcelJS.CellValue, border?: Partial<ExcelJS.Borders>) => {
	cell.value = value;
	cell.fill = {
		...cell.fill,
		type: 'pattern',
		pattern: 'solid',
		fgColor: {
			argb: 'F0F2FF',
		},
	};

	setBold(cell);

	if (border) {
		cell.border = border;
	}
};

const setBold = (cell: ExcelJS.Cell) => {
	cell.font = {
		...cell.font,
		bold: true,
	};
};

const setFontSize = (cell: ExcelJS.Cell, size: number) => {
	cell.font = {
		...cell.font,
		size,
	};
};

const setNumberFormat = (cell: ExcelJS.Cell, format: string = '#,##0.000') => {
	cell.numFmt = format;
};

const buildVolatilitySheet = (wb: ExcelJS.Workbook, { volatilities, peers }: VolatilityProps) => {
	const worksheetName = 'Volatility';
	const ws = wb.addWorksheet(worksheetName, defaultWorkSheetProperties);

	ws.getColumn(1).width = 14;
	ws.getColumn(3).width = 28;
	ws.getColumn(4).width = 16;
	ws.getColumn(5).width = 60;

	(() => {
		const mainTitle = ws.getCell(1, 1);
		mainTitle.value = 'Volatility Data';
		mainTitle.font = {
			bold: true,
			size: 12,
		};
		mainTitle.alignment = {
			vertical: 'middle',
		};

		const peersTitle = ws.getCell(3, 3);
		peersTitle.value = 'Peers Volatility';
		peersTitle.font = {
			bold: true,
		};

		// Company table
		const titleCell = ws.getCell(4, 3);
		titleCell.alignment = {
			vertical: 'middle',
		};

		const subTitleCell = ws.getCell(4, 4);
		subTitleCell.alignment = {
			vertical: 'middle',
			horizontal: 'center',
		};

		setCellWithPrimaryBackground(titleCell, 'Comparable Companies', {
			...border,
			right: undefined,
		});
		setCellWithPrimaryBackground(subTitleCell, `${formatDecimal(volatilities[0]?.data?.[0].expectedTermsInYears)} Years`, {
			...border,
			left: undefined,
		});

		volatilities.forEach((volatility, idx) => {
			const isTotal = volatility.companyName === 'median' || volatility.companyName === 'average';
			const rowIdx = 5 + idx;
			const nameCell = ws.getCell(rowIdx, 3);
			const valueCell = ws.getCell(rowIdx, 4);

			if (isTotal) {
				setCellWithPrimaryBackground(nameCell, volatility.companyName.slice(0, 1).toUpperCase() + volatility.companyName.slice(1));
				setCellWithPrimaryBackground(valueCell, volatility.data[0]?.volatility);
			} else {
				nameCell.value = volatility.companyName;
				valueCell.value = volatility.data[0].volatility;
			}
			valueCell.numFmt = '0.00%';

			nameCell.border = {
				...border,
				right: undefined,
			};
			valueCell.border = {
				...border,
				left: undefined,
			};
			nameCell.alignment = {
				vertical: 'middle',
			};
			valueCell.alignment = {
				horizontal: 'center',
				vertical: 'middle',
			};
		});
	})();

	// Peers

	(() => {
		const rowStartIdx = 8 + volatilities.length;

		const setTableTitle = (cell: ExcelJS.Cell, value: string) => {
			setCellWithPrimaryBackground(cell, value, border);
			cell.alignment = {
				horizontal: 'center',
				vertical: 'middle',
			};
			cell.font = {
				...cell.font,
				size: 12,
			};
		};
		setTableTitle(ws.getCell(rowStartIdx, 3), 'Comparable Company');
		setTableTitle(ws.getCell(rowStartIdx, 4), 'Ticker');
		setTableTitle(ws.getCell(rowStartIdx, 5), 'Description');

		ws.eachRow((row) => {
			row.height = 22;
		});

		peers.forEach((peer, idx) => {
			const peerRowIdx = rowStartIdx + idx + 1;
			const nameCell = ws.getCell(peerRowIdx, 3);
			nameCell.value = peer.companyName;
			nameCell.border = border;
			nameCell.alignment = {
				horizontal: 'center',
				vertical: 'middle',
			};
			nameCell.font = {
				bold: true,
			};

			const tickerCell = ws.getCell(peerRowIdx, 4);
			tickerCell.value = peer.ticker;
			tickerCell.border = border;
			tickerCell.alignment = {
				horizontal: 'center',
				vertical: 'middle',
			};
			tickerCell.font = {
				bold: true,
			};

			const descCell = ws.getCell(peerRowIdx, 5);
			descCell.value = peer.description;
			descCell.border = border;
			descCell.alignment = {
				wrapText: true,
				vertical: 'top',
				horizontal: 'left',
			};

			ws.getRow(peerRowIdx).height = 50;

			if (idx % 2 === 1) {
				const fill: ExcelJS.Fill = {
					type: 'pattern',
					pattern: 'solid',
					fgColor: {
						argb: 'D9D9D9',
					},
				};
				nameCell.fill = fill;
				tickerCell.fill = fill;
				descCell.fill = fill;
			}
		});
	})();
};

const buildMultipleSheet = (
	wb: ExcelJS.Workbook,
	{ multiples, multiplesData, projectName, valuationDate }: Pick<ExportVolatilityProps, 'projectName' | 'valuationDate'> & PeersMultipleProps
) => {
	const worksheetName = 'Peers multiple';
	const ws = wb.addWorksheet(worksheetName, {
		...defaultWorkSheetProperties,
		properties: {
			tabColor: { argb: 'ffffff' },
		},
	});
	ws.getColumn(1).width = 25;
	ws.getColumn(2).width = 33;
	ws.getColumn(3).width = 25;
	ws.getColumn(4).width = 15;
	ws.getColumn(5).width = 25;
	ws.getColumn(6).width = 17;
	ws.getColumn(7).width = 15;
	ws.getColumn(8).width = 15;

	(() => {
		const mainTitle = ws.getCell(1, 1);
		mainTitle.value = "Peers' multiple";
		mainTitle.font = {
			bold: true,
			size: 12,
		};
		mainTitle.alignment = {
			vertical: 'middle',
		};

		const nameTitleCell = ws.getCell(3, 1);
		nameTitleCell.value = 'Project name:';
		nameTitleCell.font = {
			bold: true,
		};
		nameTitleCell.alignment = {
			horizontal: 'right',
		};

		const nameValueCell = ws.getCell(3, 2);
		nameValueCell.value = projectName;

		const valuationDateCell = ws.getCell(4, 1);
		valuationDateCell.value = 'Valuation date:';
		valuationDateCell.font = {
			bold: true,
		};
		valuationDateCell.alignment = {
			horizontal: 'right',
		};

		const valuationDateValueCell = ws.getCell(4, 2);
		valuationDateValueCell.value = formatDate(valuationDate, DateFormatsEnum.NORMAL);
		valuationDateValueCell.numFmt = DateFormatsEnum.NORMAL;

		const tableTitles = ['Peers data', 'EV/ Sales', 'EV/ EBITDA', 'Market cap / Net income', 'EBITDA margin', 'Market cap ($M)'];
		tableTitles.forEach((title, idx) => {
			setCellWithPrimaryBackground(ws.getCell(5, 2 + idx), title, border);
		});

		multiples.forEach((multiple, idx) => {
			const isTotal = multiple.companyName === 'median' || multiple.companyName === 'average';

			const rowIdx = 6 + idx;
			const multipleDataCellRef = rowIdx + multiples.length + 6;
			const nameCell = ws.getCell(rowIdx, 2);
			nameCell.border = border;

			if (isTotal) {
				setCellWithPrimaryBackground(nameCell, multiple.companyName.slice(0, 1).toUpperCase() + multiple.companyName.slice(1), border);
				['C', 'D', 'E'].forEach((col, idx) => {
					const cell = ws.getCell(rowIdx, 3 + idx);
					setCellWithPrimaryBackground(
						cell,
						{
							date1904: true,
							formula: `=AVERAGE(${col}6:${col}${rowIdx - 1})`,
						},
						border
					);
				});

				setCellWithPrimaryBackground(
					ws.getCell(rowIdx, 6),
					'',
					idx === multiples.length - 1 ? { bottom: borderStyle, left: borderStyle } : { top: borderStyle, left: borderStyle }
				);
				setCellWithPrimaryBackground(
					ws.getCell(rowIdx, 7),
					'',
					idx === multiples.length - 1 ? { bottom: borderStyle, right: borderStyle } : { top: borderStyle, right: borderStyle }
				);
				ws.getRow(rowIdx).eachCell((cell, cellIdx) => {
					if (cellIdx > 2) {
						cell.numFmt = '#,##0.00';
					}
					cell.alignment = {
						horizontal: 'left',
					};
				});
			} else {
				nameCell.value = multiple.companyName;
				[
					{ from: 'C', to: 'F' },
					{ from: 'D', to: 'F' },
					{ from: 'E', to: 'G' },
				].forEach((col, idx) => {
					ws.getCell(rowIdx, 3 + idx).value = {
						date1904: true,
						formula: `=IF(AND(${col.to}${multipleDataCellRef}>0,${col.from}${multipleDataCellRef}>0),${col.to}${multipleDataCellRef}/${col.from}${multipleDataCellRef},IF(AND(${col.to}${multipleDataCellRef}<>0,${col.from}${multipleDataCellRef}<>0),"(NEG)",""))`,
					};
				});

				ws.getCell(rowIdx, 6).value = {
					date1904: true,
					formula: `=IF(AND(D${multipleDataCellRef}<>0,C${multipleDataCellRef}<>0),D${multipleDataCellRef}/C${multipleDataCellRef},"")`,
				};
				ws.getCell(rowIdx, 7).value = {
					date1904: true,
					formula: `=IF(G${multipleDataCellRef}<>0,G${multipleDataCellRef},"")`,
				};
				ws.getRow(rowIdx).eachCell((cell, cellIdx) => {
					if (cellIdx > 2 && !cell.address.startsWith('F')) {
						setNumberFormat(cell);
					} else if (cell.address.startsWith('F')) {
						setNumberFormat(cell, '0.00%');
					}
					cell.border = {
						left: borderStyle,
						right: borderStyle,
					};
					cell.alignment = {
						horizontal: 'left',
					};
				});
			}
		});
	})();

	(() => {
		const dataStartRowIdx = 10 + multiples.length;

		const valuationDateCell = ws.getCell(dataStartRowIdx, 1);
		valuationDateCell.value = 'Valuation date:';
		valuationDateCell.font = {
			bold: true,
		};
		valuationDateCell.alignment = {
			horizontal: 'right',
		};

		const valuationDateValueCell = ws.getCell(dataStartRowIdx, 2);
		valuationDateValueCell.value = formatDate(valuationDate, DateFormatsEnum.NORMAL);
		valuationDateValueCell.numFmt = DateFormatsEnum.NORMAL;
		const dataTableTitles = ['Peer Company', 'Sales ($M TTM)', 'EBITDA ($M TTM)', 'Net income ($M TTM)', 'EV ($M)', 'Market cap ($M)', 'Ticker'];
		dataTableTitles.forEach((title, idx) => {
			setCellWithPrimaryBackground(ws.getCell(dataStartRowIdx + 1, 2 + idx), title, border);
		});

		multiplesData?.forEach((data, idx) => {
			const rowIdx = dataStartRowIdx + 2 + idx;
			const nameCell = ws.getCell(rowIdx, 2);
			nameCell.value = data.companyName;
			ws.getCell(rowIdx, 3).value = data.sales / 1000;
			ws.getCell(rowIdx, 4).value = data.ebitda / 1000;
			ws.getCell(rowIdx, 5).value = data.netIncome / 1000;
			ws.getCell(rowIdx, 6).value = data.enterpriseValue / 1000;
			ws.getCell(rowIdx, 7).value = data.marketCap;
			ws.getCell(rowIdx, 8).value = data.ticker;
			ws.getRow(rowIdx).eachCell((cell, cellIdx) => {
				if (cellIdx > 2) {
					setNumberFormat(cell, '#,##0.00');
				}
				cell.border = {
					left: borderStyle,
					right: borderStyle,
					bottom: idx === multiplesData.length - 1 ? borderStyle : undefined,
					top: idx === 0 ? borderStyle : undefined,
				};
				cell.alignment = { horizontal: cellIdx === 8 ? 'center' : 'left' };
			});
		});
	})();
};

const buildValuationSummarySheet = (wb: ExcelJS.Workbook, { shareClasses, valuationDate, commonShareFairValue }: ValuationSummaryProps) => {
	const worksheetName = 'Valuation summary';
	const ws = wb.addWorksheet(worksheetName, defaultWorkSheetProperties);

	ws.getColumn(1).width = 45;
	ws.getColumn(2).width = 22;
	ws.getColumn(3).width = 22;
	ws.getColumn(4).width = 15;
	ws.getColumn(5).width = 22;
	ws.getColumn(6).width = 22;
	ws.getColumn(7).width = 22;
	ws.getColumn(8).width = 15;
	ws.getColumn(9).width = 15;

	const mainTitleCell = ws.getCell(1, 1);
	mainTitleCell.value = 'Valuation Summary';
	setBold(mainTitleCell);
	setFontSize(mainTitleCell, 12);
	mainTitleCell.border = {
		bottom: borderSecondaryStyle,
	};

	const borders = { top: borderSecondaryStyle, bottom: borderSecondaryStyle };
	setCellWithSecondaryBackground(ws.getCell(3, 1), 'Company’s common share fair value ($)', borders);
	setCellWithSecondaryBackground(ws.getCell(3, 2), commonShareFairValue, borders);
	setNumberFormat(ws.getCell(3, 2));
	setCellWithSecondaryBackground(ws.getCell(3, 3), '', borders);
	setCellWithSecondaryBackground(ws.getCell(3, 4), 'Valuation Date', borders);
	setCellWithSecondaryBackground(ws.getCell(3, 5), formatDate(valuationDate, DateFormatsEnum.NORMAL), borders);
	setNumberFormat(ws.getCell(3, 5), DateFormatsEnum.NORMAL);
	setCellWithSecondaryBackground(ws.getCell(3, 6), '', borders);
	setCellWithSecondaryBackground(ws.getCell(3, 7), '', borders);

	const generalDataRow = ws.getRow(3);
	generalDataRow.eachCell((cell) => {
		setFontSize(cell, 12);
		cell.alignment = {
			vertical: 'middle',
			horizontal: 'left',
		};
	});
	generalDataRow.height = 28;

	const tableSectionInitialIdx = 7;

	const tableTitle = ws.getCell(tableSectionInitialIdx, 1);
	tableTitle.value = 'Share Class Valuation Table';
	setBold(tableTitle);
	setFontSize(tableTitle, 12);
	tableTitle.border = {
		bottom: borderSecondaryStyle,
	};

	const primaryBorders = { top: borderStyle, bottom: borderStyle };

	const tableTitles = [
		'Share Class',
		'Number of Shares Outstanding',
		'Amount of Shares as Converted Ratio',
		'%',
		'Issue Price per Share ($)',
		'Liquidation Preference Component ($M)',
		'Common Share Component ($M)',
		'Total Share Class Value ($M)',
		'Value per One Share Class ($)',
	];
	tableTitles.forEach((title, idx) => setCellWithPrimaryBackground(ws.getCell(tableSectionInitialIdx + 2, idx + 1), title, primaryBorders));

	const tableTitlesCells = ws.getRow(tableSectionInitialIdx + 2);
	tableTitlesCells.height = 34;
	tableTitlesCells.eachCell((cell) => {
		cell.alignment = {
			wrapText: true,
			vertical: 'middle',
			horizontal: 'left',
		};
	});

	const tableInitialIdx = tableSectionInitialIdx + 3;

	shareClasses.forEach((sc, idx) => {
		const rowIdx = tableInitialIdx + idx;

		ws.getCell(rowIdx, 1).value = sc.shareClass;
		sc.isCommon && setBold(ws.getCell(rowIdx, 1));

		ws.getCell(rowIdx, 2).value = sc.numberOfSharesOutstanding;
		setNumberFormat(ws.getCell(rowIdx, 2), '0,000');

		ws.getCell(rowIdx, 3).value = sc.AmountOfSharesAsConvertedRatio;
		setNumberFormat(ws.getCell(rowIdx, 3), '0,000');

		ws.getCell(rowIdx, 4).value = {
			date1904: true,
			formula: `=C${rowIdx}/$C$${tableInitialIdx + shareClasses.length + 1}`,
		};
		setNumberFormat(ws.getCell(rowIdx, 4), '0.00%');

		ws.getCell(rowIdx, 5).value = sc.issuePricePerShare;
		setNumberFormat(ws.getCell(rowIdx, 5));

		ws.getCell(rowIdx, 6).value = sc.liquidationPreferenceComponent;
		setNumberFormat(ws.getCell(rowIdx, 6));

		ws.getCell(rowIdx, 7).value = sc.commonShareComponent;
		setNumberFormat(ws.getCell(rowIdx, 7));

		ws.getCell(rowIdx, 8).value = {
			date1904: true,
			formula: `=SUM(F${rowIdx}:G${rowIdx})`,
		};
		setNumberFormat(ws.getCell(rowIdx, 8));

		ws.getCell(rowIdx, 9).value = sc.valuePerOneShareClass;
		setNumberFormat(ws.getCell(rowIdx, 9));

		const row = ws.getRow(rowIdx);
		row.height = 20;
		row.eachCell((cell) => {
			cell.value = cell.value === 0 ? '' : cell.value;
			cell.alignment = {
				...cell.alignment,
				horizontal: 'left',
				vertical: 'middle',
			};
		});
	});

	const totalRowIdx = tableInitialIdx + shareClasses.length + 1;
	ws.getRow(totalRowIdx).height = 22;

	setCellWithPrimaryBackground(ws.getCell(totalRowIdx, 1), 'Total', primaryBorders);

	setCellWithPrimaryBackground(
		ws.getCell(totalRowIdx, 2),
		{
			date1904: true,
			formula: `=SUM(B${tableInitialIdx}:B${shareClasses.length + tableInitialIdx - 1})`,
		},
		primaryBorders
	);
	setNumberFormat(ws.getCell(totalRowIdx, 2), '0,000');

	setCellWithPrimaryBackground(
		ws.getCell(totalRowIdx, 3),
		{
			date1904: true,
			formula: `=SUM(C${tableInitialIdx}:C${shareClasses.length + tableInitialIdx - 1})`,
		},
		primaryBorders
	);
	setNumberFormat(ws.getCell(totalRowIdx, 3), '0,000');

	setCellWithPrimaryBackground(
		ws.getCell(totalRowIdx, 4),
		{
			date1904: true,
			formula: `=SUM(D${tableInitialIdx}:D${shareClasses.length + tableInitialIdx - 1})`,
		},
		primaryBorders
	);
	setNumberFormat(ws.getCell(totalRowIdx, 4), '0.00%');

	setCellWithPrimaryBackground(ws.getCell(totalRowIdx, 5), '', primaryBorders);

	setCellWithPrimaryBackground(
		ws.getCell(totalRowIdx, 6),
		{
			date1904: true,
			formula: `=SUM(F${tableInitialIdx}:F${shareClasses.length + tableInitialIdx - 1})`,
		},
		primaryBorders
	);
	setNumberFormat(ws.getCell(totalRowIdx, 6));

	setCellWithPrimaryBackground(ws.getCell(totalRowIdx, 7), '', primaryBorders);

	setCellWithPrimaryBackground(
		ws.getCell(totalRowIdx, 8),
		{
			date1904: true,
			formula: `=SUM(H${tableInitialIdx}:H${shareClasses.length + tableInitialIdx - 1})`,
		},
		primaryBorders
	);
	setNumberFormat(ws.getCell(totalRowIdx, 8));

	setCellWithPrimaryBackground(ws.getCell(totalRowIdx, 9), '', primaryBorders);

	ws.getRow(totalRowIdx).eachCell((cell) => {
		cell.alignment = {
			vertical: 'middle',
			horizontal: 'left',
		};
	});

	const ordinaryClass = shareClasses.find((sc) => sc.isCommon);

	const dlomDataIdx = totalRowIdx + 5;
	setCellWithSecondaryBackground(ws.getCell(dlomDataIdx, 1), 'Value of Ordinary Shares', primaryBorders);
	setCellWithSecondaryBackground(ws.getCell(dlomDataIdx, 2), (ordinaryClass?.totalShareClassValue ?? 0) * 1000000, {
		...primaryBorders,
		right: borderStyle,
	});
	ws.getCell(dlomDataIdx, 2).numFmt = '$#,##0';

	ws.getCell(dlomDataIdx + 1, 1).value = 'No. of Ordinary Shares';
	ws.getCell(dlomDataIdx + 1, 2).value = ordinaryClass?.AmountOfSharesAsConvertedRatio;
	ws.getCell(dlomDataIdx + 1, 2).border = { ...primaryBorders, right: borderStyle };
	ws.getCell(dlomDataIdx + 1, 2).numFmt = '#,##0';

	setCellWithSecondaryBackground(ws.getCell(dlomDataIdx + 2, 1), 'Value per Ordinary Share on a Marketable Basis', primaryBorders);
	setCellWithSecondaryBackground(
		ws.getCell(dlomDataIdx + 2, 2),
		{ date1904: true, formula: `=B${dlomDataIdx}/B${dlomDataIdx + 1}` },
		{ ...primaryBorders, right: borderStyle }
	);
	ws.getCell(dlomDataIdx + 2, 2).numFmt = '$#,##0.00';

	ws.getCell(dlomDataIdx + 3, 1).value = 'Discount for Lack of Marketability (DLOM)';
	ws.getCell(dlomDataIdx + 3, 2).value = {
		formula: "='Asian Put DLOM'!B23",
		date1904: true,
	};
	ws.getCell(dlomDataIdx + 3, 2).numFmt = '$#,##0.00';
	ws.getCell(dlomDataIdx + 3, 2).border = { ...primaryBorders, right: borderStyle };

	setCellWithSecondaryBackground(ws.getCell(dlomDataIdx + 4, 1), 'Value per Ordinary Share After DLOM', primaryBorders);
	setCellWithSecondaryBackground(
		ws.getCell(dlomDataIdx + 4, 2),
		{ formula: `=B${dlomDataIdx + 2}-B${dlomDataIdx + 3}`, date1904: true },
		{ ...primaryBorders, right: borderStyle }
	);
	ws.getCell(dlomDataIdx + 4, 2).numFmt = '$#,##0.00';

	Array.from({ length: 5 }).forEach((_, idx) => {
		const row = ws.getRow(dlomDataIdx + idx);
		row.height = 20;
		row.eachCell((cell, cIdx) => {
			cell.alignment = {
				horizontal: cIdx === 1 ? 'left' : 'center',
				vertical: 'middle',
			};
			if (idx === 4) {
				cell.fill = {
					...cell.fill,
					type: 'pattern',
					pattern: 'solid',
					fgColor: {
						argb: appConfig.style.colors.color1.replace('#', ''),
					},
				};
			}
		});
	});
};

const buildBsAssumptionsSheet = (wb: ExcelJS.Workbook, data: BAndSAssumptions, valuationDate: string) => {
	const worksheetName = 'B&S assumptions';
	const ws = wb.addWorksheet(worksheetName, {
		...defaultWorkSheetProperties,
		properties: {
			defaultRowHeight: 22,
		},
	});

	ws.getColumn(1).alignment = {
		horizontal: 'center',
	};

	ws.getColumn(2).alignment = {
		horizontal: 'left',
	};

	const titleCell = ws.getCell(1, 1);
	titleCell.value = 'Black-Scholes Assumptions';
	titleCell.font = {
		bold: true,
		underline: true,
	};
	titleCell.alignment = {
		horizontal: 'left',
	};

	ws.getColumn(1).width = 25;
	ws.getColumn(2).width = 44;
	ws.getColumn(3).width = 14;
	ws.getColumn(4).width = 27;
	ws.getColumn(5).width = 16;
	ws.getColumn(6).width = 60;

	const parametersData = [
		['#', 'Parameters', 'Value'],
		[1, 'Company value $M (underlying asset)', data.companyValue$MUnderlyingAsset],
		[2, 'Time to liquidity event in years (expected term)', data.timeToLiquidityEvent],
		[3, 'Risk-free rate', data.rfr],
		[4, 'Volatility ', data.Volatility],
	];

	const parametersStartingIndex = 4;

	for (let i = parametersStartingIndex; i < parametersStartingIndex + parametersData.length; i++) {
		ws.insertRow(i, parametersData[i - parametersStartingIndex]);
		ws.getCell(i, 1).alignment = {
			horizontal: 'center',
		};
		ws.getCell(i, 1).font = {
			underline: i === parametersStartingIndex,
		};

		ws.getCell(i, 2).font = {
			bold: true,
			underline: i === parametersStartingIndex,
		};
		ws.getCell(i, 2).alignment = {
			horizontal: 'left',
		};
		ws.getCell(i, 3).font = {
			bold: true,
			underline: i === parametersStartingIndex,
		};
		ws.getCell(i, 3).alignment = {
			horizontal: 'left',
		};
	}

	setNumberFormat(ws.getCell('C5'), '#,##0.00');
	setNumberFormat(ws.getCell('C6'), '#,##0.00');
	setNumberFormat(ws.getCell('C7'), '0.00%');
	setNumberFormat(ws.getCell('C8'), '0.00%');

	const companyValueStartingIndex = parametersStartingIndex + parametersData.length + 3;

	ws.insertRow(companyValueStartingIndex, [1, 'Company value ($M)']);
	ws.getCell(`B${companyValueStartingIndex}`).font = {
		bold: true,
		underline: true,
	};

	ws.insertRow(companyValueStartingIndex + 1, [
		'',
		data.companyValue$M,
		'According to:',
		data.companyValueAccordingTo === EquityValueEnum.AccordingToBusinessValueAnalyses ? 'Business Value Analysis' : 'Last Investment Round',
	]);

	setNumberFormat(ws.getCell(`B${companyValueStartingIndex + 1}`));
	ws.getCell(`B${companyValueStartingIndex + 1}`).font = {
		bold: true,
	};

	ws.getCell(`C${companyValueStartingIndex + 1}`).alignment = {
		horizontal: 'right',
	};

	ws.getCell(`D${companyValueStartingIndex + 1}`).font = {
		bold: true,
	};

	const ttlStartingIndex = companyValueStartingIndex + 4;

	ws.insertRow(ttlStartingIndex, [2, 'Time to liquidity event in years']);
	ws.getCell(`B${ttlStartingIndex}`).font = {
		bold: true,
		underline: true,
	};

	ws.insertRow(ttlStartingIndex + 1, ['', data.timeToLiquidityEvent, 'Based on:', 'Management assumption']);

	setNumberFormat(ws.getCell(`B${ttlStartingIndex + 1}`));
	ws.getCell(`B${ttlStartingIndex + 1}`).font = {
		bold: true,
	};

	ws.getCell(`C${ttlStartingIndex + 1}`).alignment = {
		horizontal: 'right',
	};

	ws.getCell(`D${ttlStartingIndex + 1}`).font = {
		bold: true,
	};

	const rfrStartingIndex = ttlStartingIndex + 4;

	ws.insertRow(rfrStartingIndex, [3, 'Risk-free rate']);
	ws.getCell(`B${rfrStartingIndex}`).font = {
		bold: true,
		underline: true,
	};

	ws.insertRow(rfrStartingIndex + 1, [
		'',
		data.rfr,
		'Based on:',
		'Market Yield on U.S Treasury Securities as of =>',
		'',
		formatDate(valuationDate, DateFormatsEnum.NORMAL),
	]);

	setNumberFormat(ws.getCell(`B${rfrStartingIndex + 1}`), '0.00%');
	ws.getCell(`B${rfrStartingIndex + 1}`).font = {
		bold: true,
	};

	ws.getCell(`C${rfrStartingIndex + 1}`).alignment = {
		horizontal: 'right',
	};

	ws.getCell(`D${rfrStartingIndex + 1}`).font = {
		bold: true,
	};

	ws.getCell(`F${rfrStartingIndex + 1}`).font = {
		bold: true,
	};

	ws.mergeCells(`D${rfrStartingIndex + 1}:E${rfrStartingIndex + 1}`);

	setCellWithPrimaryBackground(ws.getCell(`D${rfrStartingIndex + 2}`), 'Time Period', { top: borderStyle, left: borderStyle, bottom: borderStyle });
	setCellWithPrimaryBackground(ws.getCell(`E${rfrStartingIndex + 2}`), 'Risk-free rate', { top: borderStyle, right: borderStyle, bottom: borderStyle });
	ws.getCell(`D${rfrStartingIndex + 2}`).alignment = {
		vertical: 'middle',
	};
	ws.getCell(`E${rfrStartingIndex + 2}`).alignment = {
		vertical: 'middle',
		horizontal: 'center',
	};

	data.marketYieldOnUSTreasurySecurities.forEach((m, idx) => {
		ws.getCell(`D${rfrStartingIndex + 3 + idx}`).value =
			m.expectedTerm < 1 ? `${m.expectedTerm * 12}-month${m.expectedTerm * 12 > 1 ? 's' : ''}` : `${m.expectedTerm}-year${m.expectedTerm > 1 ? 's' : ''}`;
		ws.getCell(`E${rfrStartingIndex + 3 + idx}`).value = m.riskFreeRate;
		ws.getCell(`D${rfrStartingIndex + 3 + idx}`).font = {
			bold: true,
		};
		setNumberFormat(ws.getCell(`E${rfrStartingIndex + 3 + idx}`), '0.00%');
	});

	const volatilityStartingIndex = rfrStartingIndex + 5 + data.marketYieldOnUSTreasurySecurities.length;

	ws.insertRow(volatilityStartingIndex, [4, 'Volatility']);
	ws.getCell(`B${volatilityStartingIndex}`).font = {
		bold: true,
		underline: true,
	};

	ws.insertRow(volatilityStartingIndex + 1, ['', data.Volatility, 'Based on:', 'Peers Volatility']);

	setNumberFormat(ws.getCell(`B${volatilityStartingIndex + 1}`), '0.00%');
	ws.getCell(`B${volatilityStartingIndex + 1}`).font = {
		bold: true,
	};

	ws.getCell(`C${volatilityStartingIndex + 1}`).alignment = {
		horizontal: 'right',
	};

	ws.getCell(`D${volatilityStartingIndex + 1}`).font = {
		bold: true,
	};

	ws.getCell(`F${volatilityStartingIndex + 1}`).font = {
		bold: true,
	};

	setCellWithPrimaryBackground(ws.getCell(`D${volatilityStartingIndex + 2}`), 'Comparable Companies', {
		top: borderStyle,
		left: borderStyle,
		bottom: borderStyle,
	});
	setCellWithPrimaryBackground(
		ws.getCell(`E${volatilityStartingIndex + 2}`),
		`${data.timeToLiquidityEvent} Year${data.timeToLiquidityEvent > 1 ? 's' : ''}`,
		{
			top: borderStyle,
			right: borderStyle,
			bottom: borderStyle,
		}
	);
	ws.getCell(`D${volatilityStartingIndex + 2}`).alignment = {
		vertical: 'middle',
	};
	ws.getCell(`E${volatilityStartingIndex + 2}`).alignment = {
		vertical: 'middle',
		horizontal: 'center',
	};

	data.peersVolatility.forEach((p, idx) => {
		ws.getCell(`D${volatilityStartingIndex + 3 + idx}`).value = p.companyName;
		ws.getCell(`E${volatilityStartingIndex + 3 + idx}`).value = p.volatility;
		ws.getCell(`D${volatilityStartingIndex + 3 + idx}`).font = {
			bold: true,
		};
		setNumberFormat(ws.getCell(`E${volatilityStartingIndex + 3 + idx}`), '0.00%');
	});

	(() => {
		const rowStartIdx = volatilityStartingIndex + 5 + data.peersVolatility.length;

		const setTableTitle = (cell: ExcelJS.Cell, value: string) => {
			setCellWithPrimaryBackground(cell, value, border);
			cell.alignment = {
				horizontal: 'center',
				vertical: 'middle',
			};
			cell.font = {
				...cell.font,
				size: 12,
			};
		};
		setTableTitle(ws.getCell(rowStartIdx, 4), 'Comparable Company');
		setTableTitle(ws.getCell(rowStartIdx, 5), 'Ticker');
		setTableTitle(ws.getCell(rowStartIdx, 6), 'Description');

		ws.eachRow((row) => {
			row.height = 22;
		});

		data.peersData.forEach((peer, idx) => {
			const peerRowIdx = rowStartIdx + idx + 1;
			const nameCell = ws.getCell(peerRowIdx, 4);
			nameCell.value = peer.companyName;
			nameCell.border = border;
			nameCell.alignment = {
				horizontal: 'center',
				vertical: 'middle',
			};
			nameCell.font = {
				bold: true,
			};

			const tickerCell = ws.getCell(peerRowIdx, 5);
			tickerCell.value = peer.ticker;
			tickerCell.border = border;
			tickerCell.alignment = {
				horizontal: 'center',
				vertical: 'middle',
			};
			tickerCell.font = {
				bold: true,
			};

			const descCell = ws.getCell(peerRowIdx, 6);
			descCell.value = peer.description;
			descCell.border = border;
			descCell.alignment = {
				wrapText: true,
				vertical: 'top',
				horizontal: 'left',
			};

			ws.getRow(peerRowIdx).height = 50;

			if (idx % 2 === 1) {
				const fill: ExcelJS.Fill = {
					type: 'pattern',
					pattern: 'solid',
					fgColor: {
						argb: 'D9D9D9',
					},
				};
				nameCell.fill = fill;
				tickerCell.fill = fill;
				descCell.fill = fill;
			}
		});
	})();
};

const buildBreakpointsAnalysisSheet = (wb: ExcelJS.Workbook, data: ShareClassValuation[]) => {
	const worksheetName = 'Breakpoint Analysis';
	const ws = wb.addWorksheet(worksheetName, {
		...defaultWorkSheetProperties,
		properties: {
			defaultRowHeight: 34,
			defaultColWidth: 30,
		},
	});

	const titleCell = ws.getCell(1, 1);
	titleCell.value = 'Breakpoint Analysis';
	titleCell.font = {
		bold: true,
		underline: true,
	};
	titleCell.alignment = {
		horizontal: 'left',
		vertical: 'middle',
	};

	ws.getRow(1).height = 34;

	ws.getColumn(1).width = 20;

	let strikePriceRow = ws.getRow(2);
	strikePriceRow.height = 22;
	// 	const ranges = buildTableData({
	// 		rows: shareClasses[0].breakPoints,
	// 		columns: shareClassesWaterfallTable,
	// 	});
	const ranges = data[0].breakPoints.map((bp) =>
		bp.isEndOfRange ? `${formatNumber(bp.rangeFrom)} - end of range` : `${formatNumber(bp.rangeFrom)} - ${formatNumber(bp.rangeTo)}`
	);

	strikePriceRow.values = ['Strike Price ($M)', ...ranges];
	strikePriceRow.eachCell((cell) => {
		setCellWithPrimaryBackground(cell, cell.value);
		cell.alignment = {
			vertical: 'middle',
		};
	});
	ws.getCell('A3').value = 'Description';
	ws.getCell('A3').font = {
		bold: true,
		underline: true,
	};
	ws.getRow(3).height = 34;

	data.forEach((shareClass, idx) => {
		const rowIdx = 4 + idx;
		const shareClassRow = ws.getRow(rowIdx);
		shareClassRow.values = [shareClass.name, ...shareClass.breakPoints.map((bp) => bp.waterfallDescription)];
		ws.getCell(rowIdx, 1).font = {
			bold: true,
		};
		shareClassRow.height = 30;
	});

	strikePriceRow = ws.getRow(ws.rowCount + 2);
	strikePriceRow.height = 22;
	strikePriceRow.values = ['Strike Price ($M)', ...ranges];
	strikePriceRow.eachCell((cell) => {
		setCellWithSecondaryBackground(cell, cell.value, { top: borderSecondaryStyle, bottom: borderSecondaryStyle });
		cell.alignment = {
			vertical: 'middle',
		};
	});

	ws.getCell(`A${3 + data.length + 3}`).value = 'Percentage';
	ws.getCell(`A${3 + data.length + 3}`).font = {
		bold: true,
		underline: true,
	};

	ws.getRow(3 + data.length + 3).height = 22;

	const rowCount = ws.rowCount + 1;
	data.forEach((shareClass, idx) => {
		const rowIdx = rowCount + idx;
		const shareClassRow = ws.getRow(rowIdx);
		shareClassRow.values = [shareClass.name, ...shareClass.breakPoints.map((bp) => (bp.proRataValue ? bp.proRataValue : ''))];
		shareClassRow.height = 30;
		shareClassRow.eachCell((cell, cIdx) => {
			cell.alignment = {
				horizontal: 'left',
			};
			if (cIdx === 1) {
				cell.font = {
					bold: true,
				};
			} else {
				cell.numFmt = '0.00%';
			}
		});
	});
};

const buildCallsDetailsSheet = (wb: ExcelJS.Workbook, data: ShareClassValuation[]) => {
	const worksheetName = 'Calls details';
	const ws = wb.addWorksheet(worksheetName, defaultWorkSheetProperties);

	const titleCell = ws.getCell(1, 1);
	titleCell.value = 'Calls set Details';
	titleCell.font = {
		bold: true,
		underline: true,
	};

	let currentRow = 3;

	data.forEach((sc) => {
		ws.insertRow(currentRow, ['', 'Share Class', sc.name]);
		ws.getCell(currentRow, 2).font = {
			bold: true,
		};
		ws.getCell(currentRow, 3).font = {
			bold: true,
		};
		ws.insertRow(currentRow + 2, ['', 'Formula', 'Option Value ($M)', 'Ordinary (%)', 'Ordinary ($M)']).eachCell((cell, cIdx) => {
			if (cIdx === 1) return;

			setCellWithPrimaryBackground(cell, cell.value, border);
			cell.alignment = {
				horizontal: 'center',
			};
		});
		sc.breakPoints.forEach((bp) => {
			ws.addRow([
				'',
				bp.isEndOfRange ? `${formatDecimal(bp.rangeFrom)} - end of range` : `${formatDecimal(bp.rangeFrom)} - ${formatDecimal(bp.rangeTo)}`,
				bp.allShareClassesValue,
				bp.proRataValue,
				bp.shareClassValue,
			]).eachCell((cell, cIdx) => {
				if (cIdx === 1) return;

				if (cIdx === 3 || cIdx === 5) {
					setNumberFormat(cell, '#,##0.00');
				} else if (cIdx === 4) {
					setNumberFormat(cell, '0.000%');
				}

				cell.alignment = {
					horizontal: 'center',
				};
				cell.border = border;
			});
		});
		ws.addRow([
			'',
			'Total',
			{
				formula: `=SUM(C${currentRow + 3}:C${currentRow + 2 + sc.breakPoints.length})`,
				date1904: true,
			},
			'',

			{
				formula: `=SUM(E${currentRow + 3}:E${currentRow + 2 + sc.breakPoints.length})`,
				date1904: true,
			},
		]).eachCell((cell, cIdx) => {
			if (cIdx === 1) return;
			if (cIdx === 3 || cIdx === 5) {
				setNumberFormat(cell, '#,##0.00');
			} else if (cIdx === 4) {
				setNumberFormat(cell, '0.000%');
			}

			setCellWithPrimaryBackground(cell, cell.value, border);
			cell.alignment = {
				horizontal: 'center',
			};
		});
		currentRow += 5 + sc.breakPoints.length;
	});

	ws.getColumn(1).width = 11;
	ws.getColumn(2).width = 20;
	ws.getColumn(3).width = 18;
	ws.getColumn(4).width = 14;
	ws.getColumn(5).width = 28;
};

const buildAsianPutDLOMSheet = (wb: ExcelJS.Workbook, data: AsianPutDlom) => {
	const worksheetName = 'Asian Put DLOM';
	const ws = wb.addWorksheet(worksheetName, defaultWorkSheetProperties);
	ws.properties.outlineLevelRow = 1;

	const titleCell = ws.getCell(1, 1);
	titleCell.value = 'Asian Put DLOM';
	titleCell.font = {
		bold: true,
		underline: true,
	};
	titleCell.alignment = {
		horizontal: 'left',
		vertical: 'middle',
	};

	ws.insertRow(3, ['Stock price', data.stockPrice]);

	ws.insertRow(4, ['Exercise price', data.exercisePrice]);
	ws.insertRow(5, ['Time', data.time]);
	ws.insertRow(6, ['Volatility', data.volatility]);
	ws.insertRow(7, ['Rf', data.rf]);
	ws.insertRow(8, ['Annual dividend yield', data.annualDividendYield]);

	for (let i = 3; i < 9; i++) {
		ws.getCell(i, 1).style = {
			border,
		};
		ws.getCell(i, 2).style = {
			border,
		};
	}

	ws.insertRow(9, ['Cost of carry']);
	ws.getCell(9, 2).value = {
		formula: '=B7-B8',
		date1904: true,
	};

	ws.insertRow(10, ['m1']);
	ws.getCell(10, 2).value = {
		formula: '=(B3*(EXP(B9*B5)-1))/(B9*B5)',
		date1904: true,
	};

	ws.insertRow(11, ['m2_1']);
	ws.getCell(11, 2).value = {
		formula: '=(2*EXP((2*B9+B6^2)*B5)*B3^2)/((B9+B6^2)*(2*B9+B6^2)*B5^2)',
		date1904: true,
	};

	ws.insertRow(12, ['m2_2']);
	ws.getCell(12, 2).value = {
		formula: '=(2*B3^2/(B9*B5^2))*((1/(2*B9+B6^2))-((EXP(B9*B5)/(B9+B6^2))))',
		date1904: true,
	};

	ws.insertRow(13, ['m2']);
	ws.getCell(13, 2).value = {
		formula: '=B11+B12',
		date1904: true,
	};

	ws.insertRow(14, ['(24.2) vol']);
	ws.getCell(14, 2).value = {
		formula: '=SQRT((1/B5)*LN(B13/B10^2))',
		date1904: true,
	};

	ws.insertRow(15, ['d1']);
	ws.getCell(15, 2).value = {
		formula: '=(LN(B10/B3)+((B5*B14^2)/2))/(B14*SQRT(B5))',
		date1904: true,
	};

	ws.insertRow(16, ['d2']);
	ws.getCell(16, 2).value = {
		formula: '=B15-(B14*SQRT(B5))',
		date1904: true,
	};

	ws.insertRow(17, ['nd1']);
	ws.getCell(17, 2).value = {
		formula: '=NORMSDIST(B15)',
		date1904: true,
	};
	ws.getCell(17, 3).value = {
		formula: '=NORMSDIST(-B15)',
		date1904: true,
	};

	ws.insertRow(18, ['nd2']);
	ws.getCell(18, 2).value = {
		formula: '=NORMSDIST(B16)',
		date1904: true,
	};
	ws.getCell(18, 3).value = {
		formula: '=NORMSDIST(-B16)',
		date1904: true,
	};

	ws.insertRow(19, ['call']);
	ws.getCell(19, 2).value = {
		formula: '=EXP(-B7*B5)*(B10*B17-B4*B18)',
		date1904: true,
	};

	ws.insertRow(20, ['put']);
	ws.getCell(20, 2).value = {
		formula: '=EXP(-B7*B5)*((B4*C18)-(B10*C17))',
		date1904: true,
	};
	ws.insertRow(21, ['']);

	setCellWithSecondaryBackground(ws.getCell(22, 1), 'DLOM', border);
	setCellWithSecondaryBackground(
		ws.getCell(22, 2),
		{
			date1904: true,
			formula: `=B20/B3`,
		},
		border
	);
	setNumberFormat(ws.getCell(22, 2), '0.000%');

	setCellWithSecondaryBackground(ws.getCell(23, 1), 'DLOM discount amount in $', border);
	setCellWithSecondaryBackground(
		ws.getCell(23, 2),
		{
			date1904: true,
			formula: `=B22*B3`,
		},
		border
	);
	setNumberFormat(ws.getCell(23, 2));

	ws.getCell(23, 1).font = {
		bold: false,
	};
	ws.getCell(23, 2).font = {
		bold: false,
	};

	setCellWithPrimaryBackground(ws.getCell(24, 1), 'Common Stock Price After DLOM', border);
	setCellWithPrimaryBackground(
		ws.getCell(24, 2),
		{
			date1904: true,
			formula: `=(1-B22)*B3`,
		},
		border
	);
	setNumberFormat(ws.getCell(24, 2));

	ws.getColumn(1).width = 34;
	ws.getColumn(2).width = 10;

	ws.getRow(2).height = 5;

	setNumberFormat(ws.getCell('B6'), '0.000%');

	const img1 = wb.addImage({
		base64: formulaImages.asianPutDLOM1,
		extension: 'jpeg',
	});

	ws.addImage(img1, {
		tl: { col: 4, row: 2 }, // Top-left corner at E3 (0-based index)
		ext: { width: 600, height: 300 },
	});

	const img2 = wb.addImage({
		base64: formulaImages.asianPutDLOM2,
		extension: 'jpeg',
	});

	ws.addImage(img2, {
		tl: { col: 4, row: 17 }, // Top-left corner at E3 (0-based index)
		ext: { width: 700, height: 200 },
	});
	ws.addImage(img2, {
		tl: { col: 4, row: 31 }, // Top-left corner at E3 (0-based index)
		ext: { width: 700, height: 200 },
	});

	const img3 = wb.addImage({
		base64: formulaImages.asianPutDLOM3,
		extension: 'jpeg',
	});

	ws.addImage(img3, {
		tl: { col: 15, row: 1 }, // Top-left corner at E3 (0-based index)
		ext: { width: 700, height: 500 },
	});

	for (let i = 9; i < 22; i++) {
		ws.getRow(i).outlineLevel = 1;
		ws.getRow(i).hidden = true;
	}
	ws.properties.outlineLevelRow = 1;
};

const buildFinnertyDLOMSheet = (wb: ExcelJS.Workbook, data: FinnertyDlom) => {
	const worksheetName = 'finnerty DLOM';
	const ws = wb.addWorksheet(worksheetName, defaultWorkSheetProperties);

	const addRowWithBorder = (data: any[]) => {
		const row = ws.addRow(data);
		row.eachCell((cell) => (cell.border = border));
		return row;
	};

	addRowWithBorder(['Common Sick Price (V0)', data.commonStockPrice, '']);
	setNumberFormat(ws.getCell(1, 2));
	addRowWithBorder(['Dividend Yield (q)', 0, '']);

	const row = addRowWithBorder(['Volatility (σ)', data.volatility, { formula: '=B3^2', date1904: true }]);
	setNumberFormat(ws.getCell(row.number, 2), '0.00%');
	setNumberFormat(ws.getCell(3, 3), '0.00%');

	addRowWithBorder(['Time Period (T)', data.timePeriod, '']);
	addRowWithBorder(['(σ)^2*T', { formula: '=C3*B4', date1904: true }, '']);
	addRowWithBorder(['e^((σ)^2*T)', { formula: '=EXP(B5)', date1904: true }, '']);
	addRowWithBorder(['', '', '']);
	addRowWithBorder(['ν√T^2', { formula: '=B5+LN(2*(B6-B5-1))-2*LN(B6-1)', date1904: true }, '']);
	const sqrtFormulaRow = addRowWithBorder(['ν√T', { formula: '=SQRT(B8)', date1904: true }, '']);
	sqrtFormulaRow.eachCell((cell) => {
		cell.fill = {
			...cell.fill,
			type: 'pattern',
			pattern: 'solid',
			fgColor: {
				argb: appConfig.style.colors.orange.replace('#', ''),
			},
		};
	});
	addRowWithBorder(['ν√T', { formula: '=SQRT(C3*B4+LN(2*((EXP(C3*B4))-(C3*B4)-1))-2*LN(EXP(C3*B4)-1))', date1904: true }, '']);
	addRowWithBorder(['', '', '']);
	addRowWithBorder(['N(ν√T / 2)', { formula: '=NORMSDIST(B10/2)', date1904: true, result: 1 }, { formula: '=NORMSDIST(B9/2)', date1904: true }]);
	addRowWithBorder(['N(-ν√T / 2)', { formula: '=NORMSDIST(-B10/2)', date1904: true }, { formula: '=NORMSDIST(-B9/2)', date1904: true }]);
	addRowWithBorder(['', '', '']);

	setCellWithSecondaryBackground(ws.getCell(15, 1), 'Marketability Discount (D(T)) = DLOM', border);
	setCellWithSecondaryBackground(
		ws.getCell(15, 2),
		{
			date1904: true,
			formula: `=EXP(-B2*B4)*(B12 - B13)`,
		},
		border
	);
	setNumberFormat(ws.getCell(15, 2), '0.000%');
	ws.getCell(15, 3).value = { formula: '=C12-C13', date1904: true };
	setNumberFormat(ws.getCell(15, 3), '0.00%');
	setCellWithSecondaryBackground(ws.getCell(15, 3), '', border);

	setCellWithSecondaryBackground(ws.getCell(16, 1), 'DLOM discount amount in $', border);
	setCellWithSecondaryBackground(
		ws.getCell(16, 2),
		{
			date1904: true,
			formula: `=B15*B1`,
		},
		border
	);
	setNumberFormat(ws.getCell(16, 2));
	setCellWithSecondaryBackground(ws.getCell(16, 3), '', border);

	ws.getCell(16, 1).font = {
		bold: false,
	};
	ws.getCell(16, 2).font = {
		bold: false,
	};

	setCellWithPrimaryBackground(ws.getCell(17, 1), 'Common Stock Price After DLOM', border);
	setCellWithPrimaryBackground(
		ws.getCell(17, 2),
		{
			date1904: true,
			formula: `=(1-B15)*B1`,
		},
		border
	);
	setNumberFormat(ws.getCell(17, 2));
	setCellWithPrimaryBackground(ws.getCell(17, 3), '', border);

	ws.getColumn(1).width = 40;
	ws.getColumn(2).width = 14;
	ws.getColumn(3).width = 12;

	const img1 = wb.addImage({
		base64: formulaImages.finnerty1,
		extension: 'jpeg',
	});

	ws.addImage(img1, {
		tl: { col: 10, row: 2 }, // Top-left corner at E3 (0-based index)
		ext: { width: 400, height: 150 },
		editAs: 'absolute',
	});

	const img2 = wb.addImage({
		base64: formulaImages.finnerty2,
		extension: 'jpeg',
	});

	ws.addImage(img2, {
		tl: { col: 8, row: 21 }, // Top-left corner at E3 (0-based index)
		ext: { width: 600, height: 50 },
		editAs: 'absolute',
	});

	const img3 = wb.addImage({
		base64: formulaImages.finnerty3,
		extension: 'jpeg',
	});

	ws.addImage(img3, {
		tl: { col: 6, row: 24 }, // Top-left corner at E3 (0-based index)
		ext: { width: 900, height: 250 },
		editAs: 'absolute',
	});

	const img4 = wb.addImage({
		base64: formulaImages.finnerty4,
		extension: 'jpeg',
	});

	ws.addImage(img4, {
		tl: { col: 17, row: 1 }, // Top-left corner at E3 (0-based index)
		ext: { width: 250, height: 200 },
		editAs: 'absolute',
	});
};

export const onExportVolatility = ({ volatility, peersMultiple, projectName, valuationDate }: ExportVolatilityProps) => {
	const wb = new ExcelJS.Workbook();
	const workbookName = `Altshare - 409A.xlsx`;

	volatility && buildVolatilitySheet(wb, volatility);
	peersMultiple && buildMultipleSheet(wb, { ...peersMultiple, projectName, valuationDate });

	wb.xlsx.writeBuffer({ useStyles: true }).then((buffer) => {
		saveAs(new Blob([buffer], { type: 'application/octet-stream' }), workbookName);
	});
};

export const onExportSummary = ({ summary, bAndS_Assumptions, valuationDate, valotilityAndMultiple, asianPutDLOM, finnertyDlomDTO }: ExportSummaryProps) => {
	const wb = new ExcelJS.Workbook();
	const workbookName = `Altshare - 409A Summary.xlsx`;

	buildValuationSummarySheet(wb, {
		shareClasses: summary.shareClassValuationTable,
		valuationDate: new Date(valuationDate),
		commonShareFairValue: summary.commonShareFairValuePerShare,
		dlom: asianPutDLOM.dlom,
	});
	buildBsAssumptionsSheet(wb, bAndS_Assumptions, valuationDate);
	buildBreakpointsAnalysisSheet(wb, summary.shareClassValuation);
	// calls details
	buildCallsDetailsSheet(wb, summary.shareClassValuation);

	buildMultipleSheet(wb, {
		multiples: valotilityAndMultiple.multiples,
		multiplesData: valotilityAndMultiple.multiples_data,
		projectName: summary.waterfallName,
		valuationDate: new Date(valuationDate),
	});

	buildAsianPutDLOMSheet(wb, asianPutDLOM);
	buildFinnertyDLOMSheet(wb, finnertyDlomDTO);

	wb.xlsx.writeBuffer({ useStyles: true }).then((buffer) => {
		saveAs(new Blob([buffer], { type: 'application/octet-stream' }), workbookName);
	});
};
