import { CapTableImportColumnType } from "../../../Screens/CapTable/types";

export type Column = {
    uniqueId?: string;
    id: number;
    title: string;
    type: CapTableImportColumnType;
    cells: Cell[];
};

export type Cell = {
	value: string;
	referenceId: number;
	shareholder_type?: ShareholderTypeEnum;
};

export enum ShareholderTypeEnum {
	UNKNOWN = 0,
	PERSON = 1,
	ORG = 2,
	UNCHECK = 3,
}

export type ImportCapTable = {
    columns: Column[];
};
