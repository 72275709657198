import { ClickAwayListener } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import Flex from '../../../../Shared/Components/Layout/Flex';
import CustomSelect from '../../../../Shared/Components/Select/CustomSelect';
import appConfig from '../../../../config/config';
import ProjectsList from '../ProjectsList';
import MainMenuStyle from './index.style';
import useRootStore from '../../../../Shared/Hooks/useRootStore';

type Props = {
	showVersionsDialog: boolean;
	setShowVersionsDialog: Dispatch<SetStateAction<boolean>>;
};

const MainMenu = ({ showVersionsDialog, setShowVersionsDialog }: Props) => {
	const { dataCollectionStore } = useRootStore();

	useEffect(() => {
		if (dataCollectionStore.projects === undefined) return;
		// setShowVersionsDialog(dataCollectionStore.projects.length > 1);
	}, [dataCollectionStore.projects]);

	const handleToggleDialog = (event: React.MouseEvent) => {
		event.stopPropagation();
		setShowVersionsDialog((prev) => !prev);
	};

	const handleCloseDialog = () => setShowVersionsDialog(false);

	return (
		<div className={`${MainMenuStyle}__menu`}>
			<Flex className={`${MainMenuStyle}__menu-side-a`} align="center" justify="start" gap="2.6rem">
				<CustomSelect
					qaid="CapTable.Select.Version"
					onClick={handleToggleDialog}
					className={`${MainMenuStyle}__version-select`}
					isOpen={showVersionsDialog}
				>
					<Flex flex={0} align="center" justify="start" gap="0.4rem">
						{/* Untitled name <span style={{ color: appConfig.style.colors.text2 }}>(draft)</span> */}
						Select 409A project
					</Flex>
				</CustomSelect>
				<ClickAwayListener onClickAway={handleCloseDialog}>
					<div className={`${MainMenuStyle}__projects-container`}>{showVersionsDialog && <ProjectsList />}</div>
				</ClickAwayListener>
			</Flex>
		</div>
	);
};

export default observer(MainMenu);
