import { observer } from 'mobx-react-lite';
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { Routes } from '../../../../../../Routes';
import Button from '../../../../../../Shared/Components/Button/Button';
import Flex from '../../../../../../Shared/Components/Layout/Flex';
import Spinner from '../../../../../../Shared/Components/Spinner/Spinner';
import { ForwardedRef, MultiStepElementProps } from '../../../../../../Shared/Hooks/useMultiStepForm';
import useRootStore from '../../../../../../Shared/Hooks/useRootStore';
import SelectDataSource from '../../../Settings/SelectDataSource';
import NewEquityPlanStyle from './index.style';

const SelectSourceStep = forwardRef<ForwardedRef, MultiStepElementProps>((props, forwardedRef) => {
	const { equityPlansStore, companyStore } = useRootStore();
	const [isLoading, setIsLoading] = useState<boolean>(false);

	useImperativeHandle(forwardedRef, () => ({
		async onValidate() {
			return true;
		},
	}));

	useEffect(() => {
		(async () => {
			setIsLoading(true);
			const res = await equityPlansStore.getEquitySettings();
			setIsLoading(false);
		})();
	}, []);

	const onProceed = async () => {
		if (companyStore.company.pivotalEquityPlans) return window.location.replace(Routes.trusteeData.index);

		props.onNextStep?.();
	};

	return (
		<div className={NewEquityPlanStyle}>
			{isLoading ? (
				<div className={`${NewEquityPlanStyle}__loading-spinner`}>
					<Spinner incorporated center />
				</div>
			) : (
				<>
					<SelectDataSource />
					{/* {companyStore.company.pivotalEquityPlans ? (
						<>
							<div className={`${NewEquityPlanStyle}__settingSection`}>
								<p>
									<span style={{ fontWeight: 600 }}>Summary:</span> as of {formatDate(trusteeStore.dataAsOfDate)}
								</p>
								<div style={{ display: 'flex', flexDirection: 'row' }}>
									<NumberInput qaid="trusteeTotalPool" value={trusteeStore.poolSummary.totalPool ?? 0} label="Pool" isViewMode />
									<NumberInput qaid="trusteeUnallocated" value={trusteeStore.poolSummary.unallocated ?? 0} label="Unallocated" isViewMode />
									<NumberInput qaid="trusteeAllocated" value={trusteeStore.poolSummary.allocated ?? 0} label="Allocated" isViewMode />
								</div>
							</div>
						</>
					) : (
						<AddEditEquityPlan registration data={loadedPlan} ref={ref} />
					)} */}
				</>
			)}
			<div className="buttonsContainer">
				<Flex justify="end" gap="1rem" className={`${NewEquityPlanStyle}__buttonsContainer`}>
					<span>The equity awards data will be automatically synced into the Cap table </span>
					<Button qaid="Registration.Button.Next" className={'buttonsContainer__button'} onClick={onProceed}>
						Next
					</Button>
				</Flex>
			</div>
		</div>
	);
});

export default observer(SelectSourceStep);
