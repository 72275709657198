import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { ClickAwayListener } from "@mui/material";
import classNames from "classnames";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { Fade } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { IC_EDIT2, IC_TODAY_CALENDAR } from "../../../Assets";
import Clickable from "../../../Shared/Components/Clickable/Clickable";
import DatePickerInput from "../../../Shared/Components/Input/DatePickerInput";
import Flex from "../../../Shared/Components/Layout/Flex";
import Select from "../../../Shared/Components/Select/Select";
import Tooltip from "../../../Shared/Components/Tooltip";
import useRootStore from "../../../Shared/Hooks/useRootStore";
import appConfig from "../../../config/config";
import ActionsMenu, { MenuActionType } from "./ActionsMenu/ActionsMenu";
import MainMenuStyle from "./MainMenu.style";
import { Product } from "../../../Models/API/enums";
import { equityMenuActions, trusteeMenuActions } from "./ActionsMenu/ItemsByProduct";
import { isNumber } from "../../Utilities";
import { GrantsTableTab } from "../../../Models/App/EquityPlans/GrantTableTabs";

interface MenuProps {
    menuActions?: MenuActionType[];
    product: Product;
}

const MainMenu = (props: MenuProps) => {
    const { equityPlansStore, trusteeStore } = useRootStore();

    const [isActionsMenuOpen, setIsActionsMenuOpen] = useState<boolean>(false);
    const [selectedDate, setSelectedDate] = useState(new Date());

    var menuItems: MenuActionType[] = [];
    var asOfDate: Date | undefined;
    var setStoreDate: any;

    switch (props.product) {
        case Product.EquityPlans:
            menuItems = equityMenuActions;
            asOfDate = equityPlansStore.dataAsOfDate;
            // setStoreDate = equityPlansStore.dataAsOfDate
            break;
        case Product.TrusteeEquity:
            menuItems = trusteeMenuActions;
            asOfDate = trusteeStore.dataAsOfDate;

            break;
    }

    const setDate = (date: Date | undefined) => {

        if (!date) return;

        setStoreDate = date;
        switch (props.product) {
            case Product.EquityPlans:
                equityPlansStore.dataAsOfDate = date;
                break;
            case Product.TrusteeEquity:
                trusteeStore.dataAsOfDate = date;
                break;
        }
    };

    const resetDate = () => {

        switch (props.product) {
            case Product.EquityPlans:
                equityPlansStore.dataAsOfDate = new Date();
                break;
            case Product.TrusteeEquity:
                trusteeStore.dataAsOfDate = new Date();
                break;
        }
    }

    const displayDates = window.location.pathname !== '/ESOP/reports'
        && window.location.pathname !== '/trustee/reports'
        && window.location.pathname !== '/ESOP/settings';

    return (
        <>
            <div className={classNames(`${MainMenuStyle}__backdrop`)}></div>
            <div className={`${MainMenuStyle}__menu`}>
                <div className={`${MainMenuStyle}__menu-side-a`}>
                    {displayDates && <div className={`${MainMenuStyle}__menu-left-side`}>
                        {props.product === Product.EquityPlans && (
                            <div style={{ width: "32rem" }}>
                                <Select
                                    options={equityPlansStore.companyPlans}
                                    value={equityPlansStore.selectedPlan}
                                    onChange={(e) => {
                                        isNumber(e) && (equityPlansStore.selectedPlan = e)
                                        equityPlansStore.selectedTab === GrantsTableTab.Promised && equityPlansStore.getPromisedsList();
                                    }}
                                    qaid="EquityPlans.Select.Plan"
                                    defaultValue="Select plan..."
                                />
                            </div>
                        )}
                        <div style={{ width: "20rem" }}>
                            <DatePickerInput
                                qaid="EquityPlans.Input.EquityPlansDate"
                                value={asOfDate}
                                containerStyle={{ margin: 0 }}
                                onChange={setDate}
                                style={{ background: appConfig.style.colors.color1Secondary }}
                            />
                        </div>
                        <Tooltip title="Reset date as of today">
                            <Clickable
                                qaid="EquityPlans.Button.Today"
                                onClick={resetDate}
                            >
                                <img
                                    src={IC_TODAY_CALENDAR}
                                    alt="today"
                                    className={`${MainMenuStyle}__reset-icon`}
                                />
                            </Clickable>
                        </Tooltip>
                    </div>}
                </div>
                <Flex
                    className={`${MainMenuStyle}__menu-side-b`}
                    flex={0}>
                    <ClickAwayListener onClickAway={() => setIsActionsMenuOpen(false)}>
                        <div
                            className={classNames(`${MainMenuStyle}__btn-actions`, {
                                clicked: isActionsMenuOpen,
                            })}
                            onClick={() => setIsActionsMenuOpen((state) => !state)}
                            data-qaid="EquityPlans.Button.Actions"
                        >
                            Actions <MoreHorizIcon />
                        </div>
                    </ClickAwayListener>
                    <Fade
                        in={isActionsMenuOpen}
                        unmountOnExit
                    >
                        <ActionsMenu actions={menuItems} />
                    </Fade>
                </Flex>
            </div>
        </>
    );
};

export default observer(MainMenu);
