import { css } from '@emotion/css';
import {
	IC_EQUITY_BACKGROUND,
	IC_EXPENSING_LOGO,
	IC_PREVIEW_BACKGROUND_EXPENSING,
	IC_PREVIEW_BACKGROUND_WATERFALL,
	IC_VALUATION_BACKGROUND,
	IC_VALUATION_MINIFIED_LOGO_WHITE,
	IC_WATERFALL_LOGO,
	IMG_OPTION_REG_INTRO,
} from '../../../Assets';
import { WelcomePageProps } from '../../../Models/App/welcome-page';
import appConfig from '../../../config/config';

export const WaterfallWelcomeProps: WelcomePageProps = {
	logo: IC_WATERFALL_LOGO,
	title: `Model the best exit strategy 
of the company`,
	benefits: [
		<>
			<span className="bold">Generate customizable waterfall scenarios</span> to reflect various liquidation preferences & exit strategies, giving you a
			clear view of potential outcomes
		</>,
		<>
			<span className="bold">Make informed decisions faster</span> - instantly calculate distributions and visualize financial impacts with just a few
			clicks
		</>,
		<>
			<span className="bold">Easy navigation</span> with intuitive wizard designed for both finance experts and founders, simplifying complex analyses
		</>,
		<>
			<span className="bold">Secure & Reliable</span> - trust in our top-notch security measures to protect your sensitive financial data, with reliable
			performance for all your scenario modeling needs.
		</>,
		<>
			<span className="bold">Comprehensive IRR & ROI Analysis</span> - evaluate your investments with detailed Internal Rate of Return (IRR) and Return on
			Investment (investment multiple) calculations, providing you with a complete picture of your financial performance and potential returns
		</>,
	],
	previewImage: IC_PREVIEW_BACKGROUND_WATERFALL,
};

export const ExpensingWelcomeProps: WelcomePageProps = {
	logo: IC_EXPENSING_LOGO,
	title: `Your trusted solution for
stock-based compensation expensing`,
	benefits: [
		<>
			Stay compliant with <span className="bold">US GAAP & IFRS</span>
		</>,
		<>Create accurate reports tailored to your business, in just few clicks</>,
		<>
			Real time integration with external financial data to determine option fair value using <span className="bold">Black-Scholes</span> method
		</>,
		<>Synchronized with your equity plans data to create always up to date reports</>,
		<>Seamless integration with 409A valuation</>,
		<>Support multiple currencies and exchange rate conversions</>,
		<>
			Always <span className="bold">auditor-ready</span> in minutes
		</>,
	],
	previewImage: IC_PREVIEW_BACKGROUND_EXPENSING,
};

export const DataCollectionWelcomeProps: WelcomePageProps = {
	logo: IC_VALUATION_MINIFIED_LOGO_WHITE,
	title: `Stay compliant with 409A valuation powered by 
best-in-class software and industry expertise`,
	benefits: [
		<>Few days turnaround time</>,
		<>Highest accuracy by unique methodology to determine most relevant peers to calculate the volatility</>,
		<>Customer-centric process & maximal transparency</>,
		<>Deep dive into your specific legal conditions of each share class</>,
		<>Adapted for tax and accounting purposes</>,
		<>Flexible pricing model</>,
	],
	previewImage: IC_VALUATION_BACKGROUND,
};

export const EquityPlansWelcomeProps: WelcomePageProps = {
	logo: IMG_OPTION_REG_INTRO,
	title: 'Equity plans',
	benefits: [
		<>
			<span className="bold">Manage the entire options/RSU equity awards cycle -</span> from board approval to grant, exercise etc.
		</>,
		<>
			<span className="bold">Issue new equity awards in minutes - </span> upload grants directly from your excel file using our AI powered equity event
			wizard
		</>,
		<>
			<span className="bold">Visualize data and simplify the administration -</span> use comprehensive dashboards
		</>,
		<>
			<span className="bold">Stay compliant and meet IRS tax requirements -</span> automatic ISO/NSO split, 83(b) election, Form 3921
		</>,
		<>
			<span className="bold">Create electronic securities and define your e-signature flows -</span> grant letters, notice of exercise etc.
		</>,
		<>
			<span className="bold">Generate excel reports instantly - </span> customizable to your needs
		</>,
		<span className="bold">Real time synchronized with the Cap table and 409A valuation</span>,
	],
	previewImage: IC_EQUITY_BACKGROUND,
};
