/* eslint-disable no-lone-blocks */

import { RootStore } from "./RootStore";

import { makeAutoObservable, runInAction, toJS, transaction } from "mobx";
import { UserPersonalInformation } from "../Models/API/All/user-personal-information";

import ExistingUser, { ExistingUserExtended } from "../Models/API/All/existing-user";
import { CapTableValuationType, eInterestType } from "../Models/API/CapTable/convertible-loan-transaction-data";
import { LoadPersonResultInfo } from "../Models/API/CapTable/load-person-result-info";
import { EntityTypeEnum, PersonTypesEnum, TransactionTypesEnum, WarrantsType } from "../Models/API/enums";
import { AddShareholderResult2, GetShareholderByDetailsResult, GetShareholderState } from "../Models/App/CapTable/AddShareHolderState";
import { IssueTransaction } from "../Models/App/CapTable/IssueTransaction";
import { SecondaryTransaction } from "../Models/App/CapTable/SecondaryTransaction";
import { WarrantsTransaction } from "../Models/App/CapTable/WarrantsTransaction";
import { ExecuteResponse } from "../Services/BaseService";
import CapTableService, { ExistingUserAction } from "../Services/CapTableService";
import UserService from "../Services/UserService";
import { ExtraAssetTypes, TransactionType } from "../Shared/Config";
import { capTableAssetTypeSwitch, isNullOrUndefined } from "../Shared/Utilities";
import { Transaction } from "../Models/App/CapTable/Transaction";
import { IContact } from "../Models/API/Contact/contact";
import { convertContactToShareholer } from "../utils/mapper";
import { ProjectPersonInfo } from "../Models/API/CapTable/project-person-info";
import { CapTablePermission } from "../Models/API/UsersAndPermissions/permissions-enum";
import { CreateShareCertificateType } from "../Models/API/CapTable/document-types";
import { SyncTrusteePayload } from "../Models/App/CapTable/sync-trustee";

export default class ShareHolderStore {
	capTableService: CapTableService = {} as any;
	userService: UserService = null as any;

	personId?: number = 0;
	shareholderData?: LoadPersonResultInfo;
	userPersonalInformation?: UserPersonalInformation;
	isUserPersonalInformationValid = true;
	transactionType: TransactionType = null;
	editTransaction?: IssueTransaction | SecondaryTransaction | WarrantsTransaction;
	private accessLevel: CapTablePermission = CapTablePermission.no_access;
	private isCreated: boolean = false;

	get currentProjectId() {
		return this.rootStore.capTableStore.currentProjectId;
	}

	get permissions() {
		return this.accessLevel;
	}

	get isEligibleToInvite() {
		return this.accessLevel === CapTablePermission.full_access || this.accessLevel === CapTablePermission.editor;
	}

	get isShareholderCreated() {
		return this.isCreated;
	}

	set isShareholderCreated(isCreated: boolean) {
		this.isCreated = isCreated;
	}

	get projectPersonId() {
		return this.personId || this.shareholderData?.person?.projectPersonID;
	}

	setProjectPersonId(id?: number) {
		this.personId = id;
	}

	setPermissions(value: CapTablePermission) {
		this.accessLevel = value;
	}

	constructor(private rootStore: RootStore) {
		makeAutoObservable(this);
		// Track company change because services use companyID in the common values and they need to be reinstanciated after company change
		rootStore.companyStore.onCompanyChange.subscribe(this.initServices);
	}

	resetStoreToDefaultValues = () => {
		// this.contactId = 0;
		this.shareholderData = undefined;
		this.userPersonalInformation = undefined;
		this.isUserPersonalInformationValid = true;
		this.transactionType = null;
		this.editTransaction = undefined;
		this.setPermissions(CapTablePermission.no_access);
	};

	// This will be called autmatically when company changes
	private initServices = () => {
		if (this.rootStore.companyStore.companyId) {
			this.capTableService = new CapTableService(this.rootStore.companyStore.companyId);
			this.userService = new UserService(this.rootStore.companyStore.companyId);
			RootStore.subscribeToLoading([this.capTableService, this.userService], this.rootStore);
		}
	};

	init = async (contactId: number) => {
		// this.contactId = 0;
		this.shareholderData = undefined;
		this.userPersonalInformation = undefined;
		this.transactionType = null;
		this.editTransaction = undefined;
		this.setPermissions(CapTablePermission.no_access);

		if (contactId) {
			const personData = await this.capTableService.getUserProjectPerson(contactId, this.rootStore.capTableStore.currentProjectId);
			if (personData.isSuccess && personData.data) {
				// this.contactId = personData.data.person.projectPersonID;

				await this.loadShareholderData(contactId);
			}
		}
	};

	get transactions() {
		return this.shareholderData?.transactions;
	}

	async loadShareholderData(projectPersonId: number | undefined = this.projectPersonId, isUpdate: boolean = true, projectId: number = this.currentProjectId) {
		const res = await this.capTableService.LoadProjectPerson({
			projectID: projectId,
			projectPersonID: projectPersonId,
			filteredDate: this.rootStore.capTableStore.filteredDate,
		});

		if (res.isSuccess && isUpdate) {
			this.shareholderData = { ...res.data, transactions: this.buildTransactions(res.data) };
			this.setPermissions(res.data.person?.authorizationID ?? CapTablePermission.no_access);
		}
		return res;
	}

	createCertificateLetter(data: CreateShareCertificateType) {
		return this.capTableService.createDocumentCertificate(data);
	}

	cancelCertificateLetter(documentId: string, projectID: number) {
		return this.capTableService.cancelDocumentCertificate(documentId, projectID);
	}

	deleteCertificateLetter(documentId: string, projectID: number, transactionId: number, transactionType: TransactionTypesEnum) {
		return this.capTableService.deleteDocumentCertificate(documentId, projectID, transactionId, transactionType);
	}

	async getAssetsSold(personId: number = 0, date: Date = new Date()) {
		return (await this.capTableService.getAssetsSold(personId, date, this.currentProjectId)).data;
	}

	getOrAddShareHolderAdHoc2 = async (shareholder: UserPersonalInformation): Promise<AddShareholderResult2> => {
		return this.capTableService.getOrAddShareHolderAdHoc2(shareholder, this.currentProjectId);
	};

	addPerson(contact: IContact, projectId: number = this.currentProjectId, permissions: CapTablePermission = this.permissions) {
		return this.capTableService.AddPerson(convertContactToShareholer(contact), projectId, permissions);
	}

	syncTrustreeAndShareholder = (data: SyncTrusteePayload) => {
		return this.capTableService.syncTrustreeAndShareholder(data);
	};
    
	updatePerson(type: number, projectInfo: ProjectPersonInfo | undefined = this.shareholderData?.person, currentProjectId: number = this.currentProjectId) {
		if (isNullOrUndefined(projectInfo?.projectPersonID) || isNullOrUndefined(projectInfo?.sourceUserID)) return;
		return this.capTableService.UpdatePerson(type, currentProjectId, projectInfo?.sourceUserID!, projectInfo?.projectPersonID!);
	}

	removeShareholder = async () => {
		await this.capTableService.RemovePerson(this.projectPersonId ?? 0, this.currentProjectId);
		await this.rootStore.capTableStore.reloadCurrentProject();
	};

	async addUpdateIssueTransaction(tx: IssueTransaction) {
		if (isNullOrUndefined(this.projectPersonId)) return;

		let res: ExecuteResponse<any> | undefined;
		if (tx.transactionId) {
			// NOTE:
			// Exception are S.A.F.E and Convertible loan
			// The server did not want to change functions to receive it as part of the IssueTransaction
			// although the UI does holds it as part of issue tx
			if (tx.assetId === ExtraAssetTypes.ConvertibleLoan) {
				res = await this.capTableService.UpdateConvertibleLoanTransaction({
					transaction: {
						projectPersonId: this.projectPersonId,
						transactionDate: new Date(tx.date || +new Date()),
						projectId: this.currentProjectId,
						discount: tx.discount,
						valuationCap: tx.valuationCap,
						valuationCapType: tx.valuationCapType,
						moneyInvested: tx.moneyInvested,
						interestRate: tx.interestRate,
						interestType: tx.interestEnabled ? (tx.isCompunded ? eInterestType.Compunded : eInterestType.Annual) : undefined,
						maturityDate: tx.maturityEnabled ? new Date(tx.maturityDate as Date) : undefined,
						transactionId: tx.transactionId,
					},
				});
			} else if (tx.assetId === ExtraAssetTypes.SAFE) {
				res = await this.capTableService.UpdateSafeTransaction({
					transaction: {
						projectPersonId: this.projectPersonId,
						transactionDate: new Date(tx.date || +new Date()),
						projectId: this.currentProjectId,
						discount: tx.discount,
						valuationCap: tx.valuationCap,
						valuationCapType: tx.valuationCapType,
						moneyInvested: tx.moneyInvested,
						transactionId: tx.transactionId,
					},
				});
			} else {
				res = await this.capTableService.UpdateShareHolderIssueTransaction({
					projectID: this.currentProjectId,

					// TODO:
					// check for bsa/warrants
					//  update Enum, num of warrants and ex. price

					issueTransaction: {
						shareClassID: tx.assetId ?? 0,
						discountValue: tx.discount,
						discountTypeID: tx.discount ? 1 : null,
						numberOfSharesOutstanding: tx.numberOfShares,
						warrantsTypeEnum: tx.warrantsType,
						numberOfWarrants: tx.numberOfWarrants,
						warrantsExercisePrice: tx.exPrice,
						pricePerShare: tx.pricePerShare,
						transactionDate: tx.date,
						projectPersonID: this.projectPersonId,
						shareHolderIssueTransactionID: tx.transactionId,
					},
				});
			}
		} else {
			if (tx.assetId === ExtraAssetTypes.ConvertibleLoan) {
				res = await this.capTableService.AddConvertibleLoanTransaction({
					transaction: {
						projectPersonId: this.projectPersonId,
						transactionDate: tx.date,
						projectId: this.currentProjectId,
						discount: tx.discount,
						valuationCap: tx.valuationCap,
						valuationCapType: tx.valuationCapType,
						moneyInvested: tx.moneyInvested,
						interestRate: tx.interestRate,
						interestType: tx.interestEnabled ? (tx.isCompunded ? eInterestType.Compunded : eInterestType.Annual) : undefined,
						maturityDate: tx.maturityEnabled ? tx.maturityDate : undefined,
					},
				});
			} else if (tx.assetId === ExtraAssetTypes.SAFE) {
				res = await this.capTableService.AddSafeTransaction({
					transaction: {
						projectPersonId: this.projectPersonId,
						transactionDate: tx.date,
						projectId: this.currentProjectId,
						discount: tx.discount,
						valuationCap: tx.valuationCap,
						valuationCapType: tx.valuationCapType,
						moneyInvested: tx.moneyInvested,
					},
				});
			} else {
				res = await this.capTableService.AddShareHolderIssueTransaction({
					projectID: this.currentProjectId,

					issueTransaction: {
						shareClassID: tx.assetId ?? 0,
						discountValue: tx.discount,
						discountTypeID: tx.discount ? 1 : null,
						numberOfSharesOutstanding: tx.numberOfShares,
						warrantsTypeEnum: tx.warrantsType,
						numberOfWarrants: tx.numberOfWarrants,
						warrantsExercisePrice: tx.exPrice,
						pricePerShare: tx.pricePerShare,
						transactionDate: tx.date,
						projectPersonID: this.projectPersonId,
						shareHolderIssueTransactionID: 0,
					},
				});
			}
		}

		if (res && res.isSuccess) {
			this.resetEditTransaction();
			await this.loadShareholderData(this.projectPersonId);
			await this.rootStore.capTableStore.reloadCurrentProject();
		}
	}

	async addUpdateSecondaryTransaction(tx: SecondaryTransaction) {
		if (isNullOrUndefined(this.projectPersonId)) return;

		if (tx.transactionId) {
			await this.capTableService.UpdateShareHolderSecondaryTransaction({
				projectID: this.currentProjectId,
				sourceUserID: this.projectPersonId,
				secondaryTransaction: {
					shareHolderSecondaryTransactionID: tx.transactionId,
					numberOfSharesOutstanding: tx.numberOfShares,
					numberOfWarrants: tx.numberOfWarrants,
					warrantsExercisePrice: tx.pricePerWarrant,
					pricePerShare: tx.pricePerShare,
					transactionDate: tx.date,
					projectPersonID: tx.buyerId,
					sourceProjectPersonID: tx.sellerId,
					shareClassID: tx.assetBoughtId,
					sourceShareClassID: tx.assetSoldId,
					warrantsTypeEnum: tx.warrantsType,
				},
			});
		} else {
			await this.capTableService.AddShareHolderSecondaryTransaction({
				projectID: this.currentProjectId,
				//sourceUserID: this.userId,
				secondaryTransaction: {
					numberOfSharesOutstanding: tx.numberOfShares,
					numberOfWarrants: tx.numberOfWarrants,
					warrantsTypeEnum: tx.warrantsType,
					warrantsExercisePrice: tx.pricePerWarrant,
					pricePerShare: tx.pricePerShare,
					transactionDate: tx.date,
					projectPersonID: tx.buyerId,
					sourceProjectPersonID: tx.sellerId,
					shareClassID: tx.assetBoughtId,
					sourceShareClassID: tx.assetSoldId,
				},
			});
		}
		await this.loadShareholderData(this.projectPersonId);
		this.resetEditTransaction();
		await this.rootStore.capTableStore.reloadCurrentProject();
	}

	async addUpdateWarrantsTransaction(tx: WarrantsTransaction) {
		if (isNullOrUndefined(this.projectPersonId)) return;

		let res: ExecuteResponse<any> | undefined;

		if (tx.transactionId) {
			res = await this.capTableService.UpdateShareHolderWarrantsTransaction({
				projectID: this.currentProjectId,
				warrantsTransaction: {
					warrantsTypeEnum: tx.warrantsType,
					numberOfWarrants: tx.numberOfWarrants,
					warrantsExercisePrice: tx.exPrice,
					transactionDate: tx.date,
					shareHolderWarrantsTransactionID: tx.transactionId,
					shareClassID: tx.shareClassID,
					projectPersonID: this.projectPersonId,
				},
			});
		} else {
			res = await this.capTableService.AddShareHolderWarrantsTransaction({
				projectID: this.currentProjectId,
				warrantsTransaction: {
					warrantsTypeEnum: tx.warrantsType,
					numberOfWarrants: tx.numberOfWarrants,
					warrantsExercisePrice: tx.exPrice,
					transactionDate: tx.date,
					shareClassID: tx.shareClassID,
					projectPersonID: this.projectPersonId,
				},
			});
		}
		if (res && res.isSuccess) {
			await this.loadShareholderData(this.projectPersonId);
			this.resetEditTransaction();
			await this.rootStore.capTableStore.reloadCurrentProject();
		}
	}

	async removeIssueTransaction(transaction: Transaction) {
		if (isNullOrUndefined(this.projectPersonId)) return;

		const res = await capTableAssetTypeSwitch(
			transaction.assetId,
			async () => {
				// SAFE
				return this.capTableService.RemoveSafeTransaction(transaction.transactionId, this.currentProjectId);
			},
			async () => {
				// Convertible
				return this.capTableService.RemoveConvertibleLoanTransaction(transaction.transactionId, this.currentProjectId);
			},
			async () => {
				// Class
				return this.capTableService.RemoveShareHolderIssueTransaction(transaction.transactionId, this.currentProjectId);
			}
		);

		if (res?.isSuccess) {
			await this.loadShareholderData(this.projectPersonId);
			await this.rootStore.capTableStore.reloadCurrentProject();
		}
	}

	async removeSecondaryTransaction(txId: number) {
		if (isNullOrUndefined(this.projectPersonId)) return;

		const res = await this.capTableService.RemoveShareHolderSecondaryTransaction(txId, this.currentProjectId);
		if (res.isSuccess) {
			await this.loadShareholderData(this.projectPersonId);
			await this.rootStore.capTableStore.reloadCurrentProject();
		}
	}

	async removeWarrantsTransaction(txId: number) {
		if (isNullOrUndefined(this.projectPersonId)) return;

		const res = await this.capTableService.RemoveShareHolderWarrantsTransaction(txId, this.currentProjectId);
		if (res.isSuccess) {
			await this.loadShareholderData(this.projectPersonId);
			await this.rootStore.capTableStore.reloadCurrentProject();
		}
	}

	resetEditTransaction() {
		runInAction(() => {
			this.editTransaction = undefined;
			this.transactionType = 0;
		});
	}

	setEditTransaction(txId: number, type: TransactionTypesEnum, assetId?: number) {
		if (this.shareholderData) {
			this.transactionType = type;
			switch (type) {
				case TransactionTypesEnum.IssueTransaction:
					{
						capTableAssetTypeSwitch<void>(
							assetId,
							() => {
								// SAFE
								const tx = this.shareholderData?.safeTransactionList?.find((x) => x.transactionId === txId);
								if (!tx) return;
								const _editTx: IssueTransaction = {
									date: tx?.transactionDate as Date,
									discountEnabled: false,
									warrantsEnabled: false,
									discount: tx?.discount ?? undefined,
									moneyInvested: tx?.moneyInvested,
									assetId: ExtraAssetTypes.SAFE,
									valuationCap: tx?.valuationCap ?? undefined,
									transactionId: tx?.transactionId ?? 0,
									typeId: TransactionTypesEnum.IssueTransaction,
									valuationCapType: tx.valuationCapType,
								};

								this.editTransaction = _editTx;
							},
							() => {
								// Convertible
								const tx = this.shareholderData?.convertibleLoansTransactionList?.find((x) => x.transactionId === txId);
								if (!tx) return;
								const _editTx: IssueTransaction = {
									date: tx?.transactionDate as Date,
									discountEnabled: false,
									warrantsEnabled: false,
									discount: tx?.discount ?? undefined,
									moneyInvested: tx?.moneyInvested,
									assetId: ExtraAssetTypes.ConvertibleLoan,
									valuationCap: tx?.valuationCap ?? undefined,
									transactionId: tx?.transactionId ?? 0,
									maturityDate: tx?.maturityDate ?? undefined,
									interestRate: tx?.interestRate,
									isAnnualy: tx?.interestType === eInterestType.Annual,
									isCompunded: tx?.interestType === eInterestType.Compunded,
									maturityEnabled: tx?.maturityDate ? true : false,
									interestEnabled: tx?.interestRate ? true : false,
									typeId: TransactionTypesEnum.IssueTransaction,
									valuationCapType: tx.valuationCapType,
								};
								this.editTransaction = _editTx;
							},
							() => {
								// Class
								const tx = this.shareholderData?.personIssueTransactionList?.find((x) => x.shareHolderIssueTransactionID === txId);
								if (!tx) return;
								const _editTx: IssueTransaction = {
									date: tx?.transactionDate as Date,
									discountEnabled: tx?.discountValue ? true : false,
									warrantsEnabled: tx?.numberOfWarrants ? true : false,
									discount: tx?.discountValue,
									//discount: tx?.discountValue, // Calculated
									exPrice: tx?.warrantsExercisePrice === 0 ? undefined : tx?.warrantsExercisePrice,
									interestEnabled: false,
									assetId: tx?.shareClassID,
									numberOfShares: tx?.numberOfSharesOutstanding,
									pricePerShare: tx?.pricePerShare,
									numberOfWarrants: tx?.numberOfWarrants === 0 ? undefined : tx?.numberOfWarrants,
									transactionId: tx?.shareHolderIssueTransactionID ?? 0,
									warrantsType: tx?.warrantsTypeEnum,
									typeId: TransactionTypesEnum.IssueTransaction,
									valuationCapType: CapTableValuationType.None,
								};

								if (tx?.pricePerShare && _editTx.discount) {
									_editTx.discount = ((tx.pricePerShare - _editTx.discount) / tx.pricePerShare) * 100;
								}
								this.editTransaction = _editTx;
							}
						);
					}
					break;
				case TransactionTypesEnum.SecondaryTransaction:
					{
						const tx = this.shareholderData.personSecondaryTransactionList?.find((x) => x.shareHolderSecondaryTransactionID === txId);

						// projectPersonID: tx.entityType === 'seller' ? (tx.secondUserId ?? 0) : this.userId,
						// sourceProjectPersonID: tx.entityType === 'buyer' ? (tx.secondUserId ?? 0) : this.userId,
						const entityType = tx?.sourceProjectPersonID === this.projectPersonId ? EntityTypeEnum.Seller : EntityTypeEnum.Buyer;

						const _editTx: SecondaryTransaction = {
							date: tx?.transactionDate as Date,

							warrantsEnabled: tx?.numberOfWarrants ? true : false,
							warrantsType: tx?.warrantsTypeEnum,

							assetBoughtId: tx?.shareClassID,
							assetSoldId: tx?.sourceShareClassID,
							numberOfShares: tx?.numberOfSharesOutstanding,
							pricePerShare: tx?.pricePerShare,
							numberOfWarrants: tx?.numberOfWarrants === 0 ? undefined : tx?.numberOfWarrants,
							transactionId: tx?.shareHolderSecondaryTransactionID ?? 0,
							pricePerWarrant: tx?.warrantsExercisePrice,

							entityType: entityType,
							buyerId: (entityType === EntityTypeEnum.Seller ? tx?.projectPersonID : tx?.sourceProjectPersonID) ?? 0,
							sellerId: (entityType === EntityTypeEnum.Seller ? tx?.sourceProjectPersonID : tx?.projectPersonID) ?? 0,

							typeId: TransactionTypesEnum.SecondaryTransaction,
						};
						if (entityType === EntityTypeEnum.Buyer) {
							// Get user details by sourceProjectPersonID
						} else {
							// Get user details by ProjectPersonID
						}

						this.editTransaction = _editTx;
					}
					break;
				case TransactionTypesEnum.ExerciseWarrantsTransaction:
					{
						const tx = this.shareholderData.personWarrantsTransactionList?.find((x) => x.shareHolderWarrantsTransactionID === txId);

						const _editTx: WarrantsTransaction = {
							date: tx?.transactionDate as Date,
							exPrice: tx?.warrantsExercisePrice === 0 ? undefined : tx?.warrantsExercisePrice,
							shareClassID: tx?.shareClassID ?? 0,
							numberOfWarrants: tx?.numberOfWarrants === 0 ? undefined : tx?.numberOfWarrants,
							transactionId: tx?.shareHolderWarrantsTransactionID ?? 0,
							warrantsType: tx?.warrantsTypeEnum,
							typeId: TransactionTypesEnum.ExerciseWarrantsTransaction,
						};

						this.editTransaction = _editTx;
					}
					break;
				default:
			}
		}
	}

	get getEditIssueTransaction(): (transaction: Transaction) => IssueTransaction | undefined {
		return (transaction: Transaction) => {
			return capTableAssetTypeSwitch<IssueTransaction>(
				transaction.assetId,
				() => {
					// SAFE
					const tx = this.shareholderData?.safeTransactionList?.find((x) => x.transactionId === transaction.transactionId);
					if (!tx) return {} as IssueTransaction;
					return {
						date: tx?.transactionDate as Date,
						discountEnabled: false,
						warrantsEnabled: false,
						discount: tx?.discount ?? undefined,
						moneyInvested: tx?.moneyInvested,
						assetId: ExtraAssetTypes.SAFE,
						valuationCap: tx?.valuationCap ?? undefined,
						transactionId: tx?.transactionId ?? 0,
						typeId: TransactionTypesEnum.IssueTransaction,
						valuationCapType: tx.valuationCapType,
					};
				},
				() => {
					// Convertible
					const tx = this.shareholderData?.convertibleLoansTransactionList?.find((x) => x.transactionId === transaction.transactionId);
					if (!tx) return {} as IssueTransaction;
					return {
						date: tx?.transactionDate as Date,
						discountEnabled: false,
						warrantsEnabled: false,
						discount: tx?.discount ?? undefined,
						moneyInvested: tx?.moneyInvested,
						assetId: ExtraAssetTypes.ConvertibleLoan,
						valuationCap: tx?.valuationCap ?? undefined,
						transactionId: tx?.transactionId ?? 0,
						maturityDate: tx?.maturityDate ?? undefined,
						interestRate: tx?.interestRate,
						isAnnualy: tx?.interestType === eInterestType.Annual,
						isCompunded: tx?.interestType === eInterestType.Compunded,
						maturityEnabled: tx?.maturityDate ? true : false,
						interestEnabled: tx?.interestRate ? true : false,
						typeId: TransactionTypesEnum.IssueTransaction,
						valuationCapType: tx.valuationCapType,
					};
				},
				() => {
					// Class
					const tx = this.shareholderData?.personIssueTransactionList?.find((x) => x.shareHolderIssueTransactionID === transaction.transactionId);
					if (!tx) return {} as IssueTransaction;
					return {
						date: tx?.transactionDate as Date,
						discountEnabled: tx?.discountValue ? true : false,
						warrantsEnabled: tx?.numberOfWarrants ? true : false,
						//discount: tx?.discountValue, // Calculated
						exPrice: tx?.warrantsExercisePrice === 0 ? undefined : tx?.warrantsExercisePrice,
						interestEnabled: false,
						assetId: tx?.shareClassID,
						numberOfShares: tx?.numberOfSharesOutstanding,
						pricePerShare: tx?.pricePerShare,
						numberOfWarrants: tx?.numberOfWarrants === 0 ? undefined : tx?.numberOfWarrants,
						transactionId: tx?.shareHolderIssueTransactionID ?? 0,
						warrantsType: tx?.warrantsTypeEnum,
						typeId: TransactionTypesEnum.IssueTransaction,
						discountPrice: tx?.pricePerShare && tx.discountValue ? tx.pricePerShare - (tx.pricePerShare * tx.discountValue) / 100 : undefined,
						discount: tx?.discountValue,
						valuationCapType: CapTableValuationType.None,
					};
				}
			);
		};
	}

	get getEditSecondaryTransaction(): (transaction: Transaction) => SecondaryTransaction | undefined {
		return (transaction: Transaction) => {
			const tx = this.shareholderData?.personSecondaryTransactionList?.find((x) => x.shareHolderSecondaryTransactionID === transaction.transactionId);

			// projectPersonID: tx.entityType === 'seller' ? (tx.secondUserId ?? 0) : this.userId,
			// sourceProjectPersonID: tx.entityType === 'buyer' ? (tx.secondUserId ?? 0) : this.userId,
			const entityType = tx?.sourceProjectPersonID === this.projectPersonId ? EntityTypeEnum.Seller : EntityTypeEnum.Buyer;

			const _editTx: SecondaryTransaction = {
				date: tx?.transactionDate as Date,

				warrantsEnabled: tx?.numberOfWarrants ? true : false,
				warrantsType: tx?.warrantsTypeEnum,

				assetBoughtId: tx?.shareClassID,
				assetSoldId: tx?.sourceShareClassID,
				numberOfShares: tx?.numberOfSharesOutstanding,
				pricePerShare: tx?.pricePerShare,
				numberOfWarrants: tx?.numberOfWarrants === 0 ? undefined : tx?.numberOfWarrants,
				transactionId: tx?.shareHolderSecondaryTransactionID ?? 0,
				pricePerWarrant: tx?.warrantsExercisePrice,

				entityType: entityType,
				// sellerId: (entityType === EntityTypeEnum.Seller ? tx?.projectPersonID : tx?.sourceProjectPersonID) ?? 0,
				// buyerId: (entityType === EntityTypeEnum.Seller ? tx?.sourceProjectPersonID : tx?.projectPersonID) ?? 0,
				sellerId: tx?.sourceProjectPersonID,
				buyerId: tx?.projectPersonID,

				typeId: TransactionTypesEnum.SecondaryTransaction,
			};
			return _editTx;
		};
	}

	get getEditExerciseWarransTransaction(): (transaction: Transaction) => WarrantsTransaction | undefined {
		return (transaction: Transaction) => {
			const tx = this.shareholderData?.personWarrantsTransactionList?.find((x) => x.shareHolderWarrantsTransactionID === transaction.transactionId);

			const warrantTransaction: WarrantsTransaction = {
				date: tx?.transactionDate as Date,
				exPrice: tx?.warrantsExercisePrice === 0 ? undefined : tx?.warrantsExercisePrice,
				shareClassID: tx?.shareClassID ?? 0,
				numberOfWarrants: tx?.numberOfWarrants === 0 ? undefined : tx?.numberOfWarrants,
				transactionId: tx?.shareHolderWarrantsTransactionID ?? 0,
				warrantsType: tx?.warrantsTypeEnum,
				typeId: TransactionTypesEnum.ExerciseWarrantsTransaction,
			};

			return warrantTransaction;
		};
	}

	private buildTransactions = (data?: LoadPersonResultInfo): Transaction[] | undefined => {
		if (isNullOrUndefined(data)) return undefined;

		let transactions: Transaction[] = [];

		if (data.personIssueTransactionList) {
			transactions = transactions.concat(
				data.personIssueTransactionList.map((x) => {
					const numberOfShares = x.numberOfSharesOutstanding ?? 0;

					const pricePerShare = x.pricePerShare ?? 0;
					const discount = x.discountValue ?? 0;

					const moneyInvested = x.discountValue
						? (pricePerShare - (pricePerShare / 100) * discount) * numberOfShares
						: numberOfShares * pricePerShare;

					const tx: Transaction = {
						assetId: x.shareClassID,
						shareClassName: x.shareClassName,
						exPrice: x.warrantsExercisePrice,
						date: new Date(x.transactionDate ?? new Date()),
						buyer: x.firstName_Buyer ? `${x.firstName_Buyer} ${x.lastName_Buyer}` : undefined,
						seller: x.firstName_Seller ? `${x.firstName_Seller} ${x.lastName_Seller}` : undefined,
						transactionId: x.shareHolderIssueTransactionID,
						typeId: TransactionTypesEnum.IssueTransaction,
						numberOfShares,
						numberOfWarrants: x?.warrantsTypeEnum === WarrantsType.Warrants ? x.numberOfWarrants : undefined,
						numberOfBsa: x?.warrantsTypeEnum === WarrantsType.Bsa ? x.numberOfWarrants : undefined,
						warrantsType: x?.warrantsTypeEnum,
						pricePerShare,
						discount: x.discountValue,
						moneyInvested: moneyInvested,
						disabled: x.optionTransactionID ? true : false,
						valuationCapType: CapTableValuationType.None,
						documents: x?.documents,
						projectID: data.person?.projectID,
						projectPersonId: data.person?.projectPersonID,
						recordId: x.recordId,
					};

					if (data?.warrantsCanceledList) {
						const canceledtx = data?.warrantsCanceledList.find((z) => z.shareClassId === x.shareClassID);
						if (canceledtx) {
							tx.canceledDate = canceledtx.balanceDate;
							tx.canceledWarrants = canceledtx.numberOfWarrants;
						}
					}
					return tx;
				})
			);
		}

		if (data.safeTransactionList) {
			transactions = transactions.concat(
				data.safeTransactionList.map((x) => ({
					projectId: this.currentProjectId,
					assetId: ExtraAssetTypes.SAFE,
					date: new Date(x.transactionDate ?? new Date()),
					moneyInvested: x.moneyInvested,
					transactionId: x.transactionId ?? 0,
					typeId: TransactionTypesEnum.IssueTransaction,
					projectPersonId: x.projectPersonId,
					discount: x.discount ?? 0,
					valuationCapType: x.valuationCapType,
					valuationCap: x.valuationCap,
				}))
			);
		}
		if (data.convertibleLoansTransactionList) {
			transactions = transactions.concat(
				data.convertibleLoansTransactionList.map((x) => ({
					projectID: this.currentProjectId,
					assetId: ExtraAssetTypes.ConvertibleLoan,

					date: new Date(x.transactionDate ?? new Date()),
					moneyInvested: x.moneyInvested,
					transactionId: x.transactionId ?? 0,
					typeId: TransactionTypesEnum.IssueTransaction,
					discount: x.discount ?? 0,
					projectPersonId: x.projectPersonId,
					interestType: x.interestType,
					valuationCapType: x.valuationCapType,
					valuationCap: x.valuationCap,
				}))
			);
		}

		if (data.personSecondaryTransactionList) {
			transactions = transactions.concat(
				data.personSecondaryTransactionList.map((x) => ({
					projectID: this.currentProjectId,
					transactionId: x.shareHolderSecondaryTransactionID ?? 0,
					exPrice: x.warrantsExercisePrice,
					typeId: TransactionTypesEnum.SecondaryTransaction,
					date: x.transactionDate as Date,
					buyer: x.firstName_Buyer ? `${x.firstName_Buyer} ${x.lastName_Buyer}` : undefined,
					seller: x.firstName_Seller ? `${x.firstName_Seller} ${x.lastName_Seller}` : undefined,
					numberOfShares: x.numberOfSharesOutstanding,
					pricePerShare: x.pricePerShare,
					numberOfWarrants: x.numberOfWarrants,
					warrantsTypeEnum: x.warrantsTypeEnum,
					moneyInvested: (x.numberOfSharesOutstanding ?? 0) * (x.pricePerShare ?? 0),
					assetId: x.sourceShareClassID,
					shareClassName: x.shareClassName,
					projectPersonId: x.sourceProjectPersonID,
					secondProjectPersonId: x.projectPersonID,
					valuationCapType: CapTableValuationType.None,
					valuationCap: 0,
					documents: x?.documents,
				}))
			);
		}

		if (data.personWarrantsTransactionList) {
			transactions = transactions.concat(
				data.personWarrantsTransactionList.map((x) => ({
					assetId: x.shareClassID,
					exPrice: x.warrantsExercisePrice,
					date: x.transactionDate as Date,
					buyer: x.firstName_Buyer ? `${x.firstName_Buyer} ${x.lastName_Buyer}` : undefined,
					seller: x.firstName_Seller ? `${x.firstName_Seller} ${x.lastName_Seller}` : undefined,
					transactionId: x.shareHolderWarrantsTransactionID ?? 0,
					typeId: TransactionTypesEnum.ExerciseWarrantsTransaction,
					numberOfShares: x.numberOfSharesOutstanding,
					shareClassName: x.shareClassName,
					numberOfWarrants: x.numberOfWarrants,
					warrantsTypeEnum: x.warrantsTypeEnum,
					pricePerShare: x.pricePerShare ?? 0,
					moneyInvested: (x.numberOfSharesOutstanding ?? 0) * (x.pricePerShare ?? 0),
					valuationCapType: CapTableValuationType.None,
					valuationCap: 0,
				}))
			);
		}

		const invalidTxInfo = data?.shareHolderTransactionBalanceList?.find((x) => x.isValid === false);
		if (invalidTxInfo) {
			for (const tx of transactions) {
				let involvedTransactionsIds: number[] = [];
				switch (tx.typeId) {
					case TransactionTypesEnum.IssueTransaction:
						involvedTransactionsIds = invalidTxInfo.accumulatedIssueTransactionIds;
						break;
					case TransactionTypesEnum.SecondaryTransaction:
						involvedTransactionsIds = invalidTxInfo.accumulatedSecondaryTransactionIds;
						break;
					case TransactionTypesEnum.ExerciseWarrantsTransaction:
						involvedTransactionsIds = invalidTxInfo.accumulatedWarrantsTransactionIds;
						break;
					default:
						break;
				}
				if (involvedTransactionsIds.indexOf(tx.transactionId) > -1) {
					tx.errors = invalidTxInfo.errorMessageList.map((x) => ({
						message: x.message,
						code: x.option,
					}));
				}
			}
		}

		return transactions;
	    return transactions;
    };
}
