import { css } from "@emotion/css";
import appConfig from "../../../../../config/config";

const BeneficiaryDetailsStyles = css({
    label: "BeneficiaryDetails",
    display: "flex",
    flexDirection: "column",
    // "flex": 1,
    // "boxShadow": appConfig.style.boxShadow.card1,
    borderRadius: "5px",

    ".column": {
        display: "flex",
        flexDirection: "row",
        // width: "26rem",
        margin: "14px",
        // background: "red",
    },

    // ".header": {
    // },
    "&__contact-section": {
        display: "flex",
        flexDirection: "column",
        border: `1px solid ${appConfig.style.colors.table}`,
        padding: "3.6rem 4.4rem",
        borderRadius: 16,
        position: "relative",
        transition: "all .3s",
        // overflow: "hidden",
        flex: "0 0 33.3rem",
        "&.expanded": {
            flex: "0 0 43.3rem",
        },
    },
    "&__title-container": {
        display: "flex",
        alignItems: "center",
        gap: "2rem",
        textTransform: "capitalize",
        ".img-sh-type": {
            width: "5.4rem",
        },
        marginBottom: "2.4rem",
    },

    "&__title": {
        color: appConfig.style.colors.text2,
    },

    // ".edit-Btn": {
    // 	border: "1px solid black",
    // 	borderRadius: "5px",
    // 	// backgroundColor: "#baba",
    // 	padding: "0 1rem",
    // 	cursor: "pointer",
    // 	boxShadow: appConfig.style.boxShadow.card1,
    // 	// backgroundColor: "green"
    // },

    // ".edit-Btn:hover": {
    // 	backgroundColor: "#bafa",
    // },

    // ".shake": {
    // 	backgroundColor: "#bafa",
    // 	boxShadow: appConfig.style.boxShadow.card3,
    // 	animation: "shake 0.2s ease-in-out 4",
    // },

    "&__form": {
        display: "grid",
        gridTemplateColumns: "repeat(5, 1fr)",
        width: "100%",
        gridColumnGap: "2.4rem",
        gridRowGap: "3.4rem",
    },

    ".notes": {
        display: "flex",
        flex: 1,
        border: "1px solid #baba",
        borderRadius: "3px",
        padding: "0.6rem",
        marginTop: "1.6rem",
    },

    ".input": {
        margin: "0 1.6rem",
        width: "20rem",
    },

    ".tableContainer": {
        padding: "2rem",
    },

    ".buttons": {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        padding: "1.6rem",
    },
});

export default BeneficiaryDetailsStyles;
