import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { IBeneficiary } from "../../../../../Models/App/EquityPlans/Beneficiaries";
import Button from "../../../../../Shared/Components/Button/Button";
import InputTXT from "../../../../../Shared/Components/Input/TextInput";
import DatePckr from "../../../../../Shared/Components/Input/DatePickerInput";
import Select from "../../../../../Shared/Components/Select/Select";
import useRootStore from "../../../../../Shared/Hooks/useRootStore";
import BenDetailsStyle from "./BeneficiaryDetails.style";
import { CompanyCustomData } from "../../../../../Models/App/EquityPlans/Company";
import useModal from "../../../../../Shared/Hooks/useModal";
import { EntityTrackHistoryType } from "../../../../../Models/App/EquityPlans/General";
import Menu from "../../../../../Shared/Components/Menu/index";
import { IC_EDIT2, IC_TRASH2, IC_USER_PROFILE_PURPLE, IC_INVITE, IC_WATERFALL_SUMMARY_GRAY } from "../../../../../Assets";
import Flex from "../../../../../Shared/Components/Layout/Flex";
import useEsopModals from "../../../useEsopModals";
import Title from "../../../../../Shared/Components/Layout/Title";
import classNames from "classnames";
import Spinner from "../../../../../Shared/Components/Spinner/Spinner";
import PhoneInput from "../../../../../Shared/Components/Input/PhoneInput";
import { useAppendState } from "../../../../../Shared/Hooks/useAppendState";
import { employmentStatus, genderOptions } from "../../../../../Shared/StaticData/equityPlans";
import { ModalBodyProps } from "../../../../../Shared/Components/Modal/types";
import Drawer from "../../../General/Drawer/Drawer";

interface BenDeProps {
	type: 'new' | 'detailed' | 'grant' | 'basic' | 'edit';
	isEdit: boolean;
}

const BeneficiaryDetails = (props: BenDeProps) => {
	const { equityPlansStore, contactStore } = useRootStore();
	const { showSuccessModal, showErrorModal, showDeleteBeneficiary, openTotalEquityAwardsModal } = useEsopModals();
	const { showModal } = useModal();
	const [isEdit, setIsEdit] = useState(props.isEdit);
	const [initialData, setInitialData] = useState<IBeneficiary>({} as IBeneficiary);
	const [shake, setShake] = useState(false);
	const [isUpdating, setIsUpdating] = useState<boolean>(false);
	const [beneficiary, setBeneficiary, onInputHandler] = useAppendState<IBeneficiary>(equityPlansStore.beneficiaryData);

	const editHandler = () => {
		setIsEdit(true);
	};

	const deleteHandler = () => {
		console.log('Deleting Beneficiary...', beneficiary.beneficiaryId);
		showModal({
			body: (
				<>
					<div className="">
						<p>Deleting This Beneficiary WIll result in deleting all of his:</p>
						<p>X Grants</p>
						<p>X Grant Orders</p>
						<p>X Grant Cancelations</p>
						Do You Wish To Proceed?
					</div>
					<div className="">
						<Button qaid="" cancel inverse square label="No" />
						<Button qaid="" inverse square label="Yes. Delete All" onClick={() => equityPlansStore.DeleteBeneficiary()} />
					</div>
				</>
			),
		});
	};

	const invitationHandler = async () => {
		if (!beneficiary.privateEmail) {
			showErrorModal(undefined, 'No Valid Email Address for this beneficiary');
			return;
		}

		const res = await equityPlansStore.InviteToEmployeePortal(beneficiary.sourceContactId);
		if (res?.data) {
			showSuccessModal();
		} else {
			showErrorModal();
		}
	};

	useEffect(() => {
		return () => {
			equityPlansStore.beneficiaryData = {} as IBeneficiary;
			setBeneficiary({} as IBeneficiary);
		};
	}, []);

	const updateBenData = async () => {
		setIsUpdating(true);
		if (initialData.privateEmail && initialData.privateEmail !== beneficiary.privateEmail) {
			contactStore.updateContactEmail(beneficiary.sourceContactId, beneficiary.privateEmail).then((res) => {
				if (res.errorMessage) showErrorModal(res.errorMessage);
			});
		}
		const { data, errorMessage } = await equityPlansStore.UpdateBeneficiary(beneficiary!.beneficiaryId, beneficiary!!);
		if (data?.beneficiaryId) {
			showSuccessModal();
			setIsEdit(false);
		} else if (errorMessage) {
			showErrorModal(errorMessage);
		}
		setIsUpdating(false);
	};

	const cancelUpdate = async () => {
		setIsEdit(false);
		equityPlansStore.beneficiaryData = initialData;
		setBeneficiary({} as IBeneficiary);
	};

	const updateSiteHandler = (newData: CompanyCustomData, isModify: boolean, updateGrants: boolean) => {
		// const data: EntityHistoryUpdate = {
		//     entityTrackHistoryType: EntityTrackHistoryType.Site,
		//     entityTrackParams: newData,
		//     isModify: false,
		//     updateGrants: false
		// }
		// equityPlansStore.UpdateBeneficiaryCCD(135, benData.beneficiaryId, newData, CustomDataTypes.Site, startDate, endDate)

		equityPlansStore.UpdateBeneficiaryEntityHistory(EntityTrackHistoryType.Site, newData, isModify, updateGrants);
	};

	const getHistoryHandler = (hsEnt: EntityTrackHistoryType) => {
		equityPlansStore.GetBeneficiaryTrackHistory(beneficiary.beneficiaryId, hsEnt);
	};

	const updateHistoryHandler = (hsEnt: EntityTrackHistoryType, newData: CompanyCustomData, isModify: boolean, updateGrants: boolean) => {
		equityPlansStore.UpdateBeneficiaryEntityHistory(hsEnt, newData, isModify, updateGrants);
	};

	// const siteHistoryHandler = () => {
	//     equityPlansStore.GetBeneficiaryTrackHistory(benData.beneficiaryId, EntityTrackHistoryType.Site)
	// }

	// const departmentHistoryHandler = () => {

	// }

	useEffect(() => {
		setInitialData(equityPlansStore.beneficiaryData);
	}, [equityPlansStore.beneficiaryData?.beneficiaryId]);

	if (!beneficiary.firstName) return <></>;

	const fullName = `${beneficiary?.firstName} ${beneficiary?.lastName}`;

	return (
		<>
			<div className={BenDetailsStyle}>
				<div className={`${BenDetailsStyle}__title-container`}>
					<img className="img-sh-type" src={IC_USER_PROFILE_PURPLE} alt={fullName} />
					<Title>
						{fullName} <span className={`${BenDetailsStyle}__title`}>({beneficiary.employeeNumber})</span>
					</Title>
					<Menu
						items={[
							{
								label: 'Edit',
								icon: IC_EDIT2,
								qaid: 'EditBeneficiary.Button.Edit',
								onClick: editHandler,
							},
							{
								label: 'Delete',
								icon: IC_TRASH2,
								qaid: 'EditBeneficiary.Button.DeleteBeneficiary',
								onClick: () => showDeleteBeneficiary(beneficiary.beneficiaryId),
							},
						]}
					/>
				</div>

				<Drawer
					isOpen={isEdit}
					children={
						<div className={classNames(`${BenDetailsStyle}__contact-section`, { expanded: isEdit })}>
							{isUpdating && <Spinner attachParent center />}
							<div className={`${BenDetailsStyle}__form`}>
								<InputTXT
									qaid=""
									label="First name"
									name="firstName"
									value={beneficiary.firstName}
									onChange={onInputHandler}
									isViewMode={!isEdit}
								/>
								<InputTXT
									qaid=""
									label="Last name"
									name="lastName"
									value={beneficiary.lastName}
									onChange={onInputHandler}
									isViewMode={!isEdit}
								/>
								<InputTXT
									qaid=""
									label="Employee number"
									name="employeeNumber"
									value={beneficiary.employeeNumber}
									onChange={onInputHandler}
									isViewMode={!isEdit}
								/>

								<PhoneInput
									onChange={(mobile: string, mobilePrefix?: string) => {
										setBeneficiary((prevBeneficiary) => ({
											...prevBeneficiary,
											mobilePrefix: mobile ? mobilePrefix : undefined,
											mobilePhone: mobile || undefined,
										}));
									}}
									label="Mobile"
									qaid=""
									value={beneficiary.mobilePhone}
									mobilePrefix={beneficiary.mobilePrefix}
									containerClassName="mb-0"
									isViewMode={!isEdit}
								/>
								{/* <InputTXT qaid="" label="Mobile" name="mobilePhone" value={beneficiary.mobilePhone} onChange={onInputHandler} isViewMode={!isEdit} /> */}
								<InputTXT
									qaid=""
									label="Business email"
									value={beneficiary.privateEmail}
									name="privateEmail"
									onChange={onInputHandler}
									isViewMode={!isEdit}
								/>
								<Select
									label="Site"
									value={beneficiary.site}
									name="site"
									onChange={onInputHandler}
									options={equityPlansStore.sites ?? []}
									qaid=""
									isViewMode={!isEdit}
								/>
								<Select
									label="Department"
									value={beneficiary.departmentEmp}
									name="departmentEmp"
									onChange={onInputHandler}
									options={equityPlansStore.departments ?? []}
									qaid=""
									isViewMode={!isEdit}
								/>
								<Select
									label="Sub department"
									value={beneficiary.subDepartmentEmp}
									name="subDepartmentEmp"
									onChange={onInputHandler}
									options={equityPlansStore.subDepartments ?? []}
									qaid=""
									isViewMode={!isEdit}
								/>
								<InputTXT
									qaid=""
									label="Level"
									value={beneficiary.level?.toString()}
									name="level"
									onChange={onInputHandler}
									isViewMode={!isEdit}
								/>
								<InputTXT
									qaid=""
									label="Role"
									value={beneficiary.role?.toString()}
									name="role"
									onChange={onInputHandler}
									isViewMode={!isEdit}
								/>
								<InputTXT qaid="" label="ID" value={beneficiary.id?.toString()} name="id" onChange={onInputHandler} isViewMode={!isEdit} />
								<Select
									qaid=""
									label="Gender"
									options={genderOptions}
									name="gender"
									onChange={onInputHandler}
									value={beneficiary.gender}
									isViewMode={!isEdit}
								/>
								<Select
									qaid=""
									label="Employee status"
									options={[
										{ label: 'Employee', value: 0 },
										{ label: 'Ex. Employee', value: 1 },
										{ label: 'Other', value: 2 },
									]}
									name="employementStatus"
									height={10}
									value={beneficiary.employementStatus}
									onChange={onInputHandler}
									isViewMode={!isEdit}
									disabled={isEdit}
								/>
								<DatePckr
									label="Start date"
									value={beneficiary.employmentStartDate?.toString()}
									name="employmentStartDate"
									onChange={onInputHandler}
									isViewMode={!isEdit}
									qaid=""
								/>
								<DatePckr
									label="End date"
									value={beneficiary.employmentEndDate?.toString()}
									name="employmentEndDate"
									onChange={onInputHandler}
									isViewMode={!isEdit}
									qaid=""
								/>
								<InputTXT
									qaid=""
									label="Address"
									value={beneficiary.address?.toString()}
									name="address"
									onChange={onInputHandler}
									isViewMode={!isEdit}
								/>
							</div>
							{isEdit && (
								<Flex justify="end" gap="1.2rem" margin="auto 0 0 0" flex={0}>
									<Button qaid="" label="Cancel" cancel onClick={cancelUpdate} />
									<Button qaid="" label="Save Changes" onClick={updateBenData} isLoading={isUpdating} />
								</Flex>
							)}
						</div>
					}
					header={'Personal info'}
				/>
			</div>
		</>
	);
};

export default observer(BeneficiaryDetails);
