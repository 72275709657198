import { useEffect, useRef, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { IC_G_GOOGLE } from "../../../../Assets";
import { Routes } from "../../../../Routes";
import TextInput from "../../../../Shared/Components/TextInput";
import useComplexState from "../../../../Shared/Hooks/useComplexState";
import useRootStore from "../../../../Shared/Hooks/useRootStore";
import { useTrans } from "../../../../Shared/Components/Trans";
import Button from "../../../../Shared/Components/Button/Button";
import { emptyCache, encryptAes, isNullOrUndefined } from "../../../../Shared/Utilities";
import { useGoogleLogin } from "@react-oauth/google";
import { commonValidators } from "../../../../Shared/ObjectValidator";
import { useObjectValidation } from "../../../../Shared/Hooks/useObjectValidation";
import useScreenSize from "../../../../Shared/Hooks/useScreenSize";
import classNames from "classnames";
import Flex from "../../../../Shared/Components/Layout/Flex";
import Clickable from "../../../../Shared/Components/Clickable/Clickable";
import { AnimatePresence, motion } from "framer-motion";
import ErrorMessage from "../../../../Shared/Components/Layout/ErrorMessage";
import useRecaptcha from "../../../../Shared/Hooks/useRecaptcha";
import { UserStatus } from "../../../../Models/API/enums";

interface User {
    email?: string;
    privateEmail?: string;
    password?: string;
}

interface IProps {
    readonly isPageVisible: boolean;
    readonly navToAccountRegistration: () => void;
}

export const AccountLogin = (props: IProps) => {
    const { auth, appState } = useRootStore();
    const { isMobile, isTablet } = useScreenSize();
    const { t } = useTrans();
    const [userData, setUserData] = useComplexState<User>({});
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const history = useHistory();
    const location = useLocation();
    const isinvitationEmpExsistUser = location.pathname.includes(Routes.account.inviteEmpExsistUser);
    const [errorMsg, setErrorMsg] = useState<JSX.Element | string>();
    const [googleError, setGoogleError] = useState<string>();
    const [isValidUser, userValState, _, setValidationState] = useObjectValidation(userData, {
        email: [commonValidators.required(), commonValidators.emailValidator],
        password: [commonValidators.required()],
        privateEmail: isinvitationEmpExsistUser ? [commonValidators.required(), commonValidators.emailValidator] : [],
    });
    const mounted = useRef(true);
    const { execute } = useRecaptcha();

    const [isPageVisible, setIsPageVisible] = useState<boolean>(true);

    const navToAccountRegistration = () => {
        setIsPageVisible(false);

        setTimeout(() => {
            history.push(Routes.account.accountRegistration);
        }, 500);
    };

    const login = useGoogleLogin({
        onSuccess: async (codeResponse) => {
            loginGoogle(codeResponse.access_token);
        },
        onError: (error) => console.log("Login Failed:", error),
        hint: "",
        prompt: "select_account",
    });

    useEffect(() => {
		return () => {
			mounted.current = false;
			setUserData({} as User);
		};
	}, []);

    const handleLogin = async () => {
        setIsSubmitted(true);

        await execute(
            async (token) => {
                setIsLoading(true);

                if (!isValidUser || !userData.email || !userData.password) {
                    setIsLoading(false);
                    return;
                }

                const res = await auth.login({
                    recaptcha: token,
                    email: userData.email,
                    password: encryptAes(userData.password),
                    externalAuthId: null,
                    privateEmail: userData.privateEmail,
                });

                if (res.isSuccess) {
                    setIsLoading(false);
                    if (res.data.IsExternalAuthId) {
                        setValidationState({
                            email: { message: "Using a Gmail address? Continue with Google below", isValid: false },
                        });

                        return;
                    }

                    if (isNullOrUndefined(res.data?.guid)) {
                        setErrorMsg(t("An error occurred please try again later"));

                        return;
                    }

                    if (isinvitationEmpExsistUser) {
                        history.push(Routes.account.otpLoginInvitation + "/" + res.data?.guid);

                        return;
                    }
                    history.push(Routes.account.otpLogin + "/" + res.data?.guid);
                } else {
                    setErrorMsg(t("loginAndRegister.theEmailAddressOrPasswordIsIncorrect"));
                }
            },
            () => {
                setErrorMsg("Error occurred");
            }
        );

        setIsLoading(false);
    };

    const loginGoogle = (accessToken: string) =>
        execute(async (token) => {
            appState.isLoading = true;
			const res = await auth.loginGoogle({
				recaptcha: token,
				email: null,
				password: null,
				externalAuthId: accessToken,
			});

			if (!res.isSuccess) {
				setGoogleError('server error');
			}
			appState.isLoading = false;

            if (res.data?.status === UserStatus.PendingForAccountSetupForGoogle) {
                history.push(`${Routes.account.accountSetup}/gsign`);
            } else if (res.data?.status === UserStatus.PendingForCompanyRegist) {
                history.push(Routes.account.companySetup);
            } else if (res.data?.status === UserStatus.PendingForPaymentPlan) {
                history.push(Routes.payment.pricing);
            }
        });

    return (
		<AnimatePresence>
			{isPageVisible && (
				<motion.section
					initial={{ opacity: 0 }}
					animate={{ opacity: 1, transition: { duration: 1 } }}
					exit={{ opacity: 0 }}
					className={classNames({
						'form-account-box login-box': true,
						mobile: isMobile,
					})}
				>
					<>
						<div
							className={classNames({
								'form-header': true,
								mobile: isMobile,
							})}
						>
							<div className="form-login-title">Welcome back!</div>
							<div className="form-sub-title">Log in to your account</div>
						</div>
						<motion.div
							initial={{ opacity: 0 }}
							animate={{ opacity: 1 }}
							transition={{ duration: 0.5 }}
							exit={{ opacity: 0 }}
							className={classNames({
								'login-account-form': true,
								mobile: isMobile,
								tablet: isTablet,
							})}
						>
							<form className="loginFields">
								<div className="login-row">
									<Flex className="form-input-group required" position="relative">
										<TextInput
											qaid="AccountLogin.Input.BusinessEmail"
											className="account-input"
											placeholder="Business e-mail *"
											value={userData.email}
											hasError={isSubmitted && Boolean(userValState?.email?.message)}
											autocomplete
											onChange={(e) => {
												setUserData({ email: e });
											}}
											onEnter={async () => await handleLogin()}
										/>
										<div className="form-error">{isSubmitted && userValState?.email?.message}</div>
									</Flex>
								</div>
								<div className="account-input-row-1">
									<Flex align="center" position="relative" width={'100%'}>
										<TextInput
											className={classNames({
												'account-input account-input-password': true,
												mobile: isMobile,
											})}
											autocomplete
											placeholder="Password * "
											qaid="AccountLogin.Input.Password"
											hasError={isSubmitted && Boolean(userValState?.password?.message)}
											value={userData.password}
											isPassword
											onChange={(e) => {
												setErrorMsg('');
												setIsSubmitted(false);
												setUserData({ password: e });
											}}
											onEnter={async () => await handleLogin()}
										/>
										<div className="form-error">{isSubmitted && userValState?.password?.message}</div>

										<ErrorMessage top={isMobile ? '11rem' : '8rem'} width="100%">
											{errorMsg}
										</ErrorMessage>
									</Flex>

									<div className="account-forgot-pass">
										<Link className="account-link" key="registration" to={Routes.account.forgotPass}>
											Forgot password?
										</Link>
									</div>
								</div>
								{isinvitationEmpExsistUser && (
									<>
										<div className="login-row">
											<div className="form-input-group required">
												<TextInput
													qaid="AccountLogin.Input.PrivateEmail"
													className="account-input"
													placeholder="Private e-mail*"
													hasError={isSubmitted && Boolean(userValState?.privateEmail?.message)}
													value={userData.privateEmail}
													autoFocus
													onChange={(e) => {
														setUserData({ privateEmail: e });
													}}
													onEnter={async () => await handleLogin()}
												/>
											</div>
										</div>
										<div className="form-error">{isSubmitted && userValState?.privateEmail?.message}</div>
									</>
								)}

								<div className="buttons">
									<Button
										qaid="AccountLogin.Button.Login"
										className={classNames({
											'account-orange-butt': true,
											'first-phase': true,
											mobile: isMobile,
										})}
										isLoading={isLoading}
										position="center"
										onClick={handleLogin}
									>
										Log in
									</Button>
								</div>
							</form>

							<div className="form-or-section-login">
								<div className="or-sign">Or</div>
								<div className="goog-sign">
									<Button qaid="AccountLogin.Button.Google" onClick={() => login()} position="center" className="g-butt">
										<img className="ic" src={IC_G_GOOGLE} alt="" />
										&nbsp; Continue with Google
									</Button>
								</div>
							</div>
							<div className="text-danger text-center">{googleError}</div>
						</motion.div>
					</>
					<div className="account-footer">
						<div>Don't have an account yet? &nbsp;</div>
						<div>
							<Clickable className="account-link" qaid="AccountPage.link.CreateNewUser" onClick={navToAccountRegistration}>
								Sign up
							</Clickable>
						</div>
					</div>
				</motion.section>
			)}
		</AnimatePresence>
	);
};

export default AccountLogin;
