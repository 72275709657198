import { observer } from "mobx-react-lite";
import { BenTable, IBeneficiary } from "../../../../../Models/App/EquityPlans/Beneficiaries";
import DoughnutChart from "../../../../../Shared/Components/WidgetDashboard/shared/components/DoughnutChart/DoughnutChart";
import { defaultColors } from "../../../../../Shared/Components/WidgetDashboard/shared/components/DoughnutChart/DoughnutChart.style";
import { formatDecimal } from "../../../../../Shared/Utilities";
import BeneficiarySummaryStyles from "./BeneficiarySummary.style";
import BenChartLegend from "./ChartLegend/BenChartLegend";
import Title from "../../../../../Shared/Components/Layout/Title";

interface Props {
    data: BenTable | IBeneficiary;
    isSummary?: boolean;
}

const BeneficiarySummary = (props: Props) => {
    const minPercentage = 0.01;
    const labels = ["Unvested", "Exercisable", "Saleable", "Sold", "Canceled"];
    const total = props.data.unvested + props.data.exercisable + props.data.shares + props.data.sold + props.data.canceled;

    const figures = () => {
        if (!props.data) {
            return [];
        }
        else {
            let values = [props.data.unvested, props.data.exercisable, props.data.shares, props.data.sold, props.data.canceled];

            const percentages = values.map(value => {
                const percentage = (value / total) * 100;
                return value === 0 ? percentage : Math.max(parseFloat(percentage.toFixed(10)), minPercentage);
            });

            const sumPercentages = percentages.reduce((sum, value) => sum + +(formatDecimal(value)), 0);
            const remainder = 100 - sumPercentages;

            if (remainder > 0) {
                percentages[1] += remainder;
            }

            return percentages.map((item) => formatDecimal(item));
        }
    }

    const tooltipFigures = [props.data.unvested, props.data.exercisable, props.data.shares, props.data.sold, props.data.canceled];

    return (
        <div className={BeneficiarySummaryStyles}>
            {!props.isSummary && <div>
                <Title>{props.data.fullName?.firstName} {props.data.fullName?.lastName} <span className={`${BeneficiarySummaryStyles}__title`}>({props.data.employeeNumber})</span></Title><br />
                <div className={`${BeneficiarySummaryStyles}__subTitle`}>Total equity awards</div><br />
            </div>}
            <div style={{ display: 'flex' }}>
                <div className={`${BeneficiarySummaryStyles}__chartWrap`}>
                    <DoughnutChart
                        ap={{
                            labels: labels,
                            figures: figures(),
                            tooltipFigures: tooltipFigures,
                            colors: defaultColors,
                            centerTotal: total
                        }}
                    />
                    <BenChartLegend
                        className="ChartLegend"
                        labels={labels}
                        figures={figures()}
                        colors={defaultColors}
                        total={total}
                        amounts={tooltipFigures}
                    />
                </div>
                {props.isSummary && <div className={`${BeneficiarySummaryStyles}__divider`} />}
                {props.isSummary && <div className={`${BeneficiarySummaryStyles}__summary`}>
                    <p>Oustanding options</p>
                    <span>{formatDecimal(props.data.outstandingOptions)}</span>
                    <p>Outstanidng RSU</p>
                    <span>{formatDecimal(props.data.outstandingRSU)}</span>
                </div>}
            </div>
        </div>
    );
};

export default observer(BeneficiarySummary);