import { ArcElement, Chart as ChartJS, Legend, Tooltip } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import { AltProps } from "../../dependencies/types/types";
import { Wrapper, defaultColors } from "./DoughnutChart.style";
import { formatNumber } from "../../../../../Utilities";
import useRootStore from "../../../../../Hooks/useRootStore";

interface Props {
    colors?: string[];
    labels: string[];
    figures: number[] | string[];
    tooltipFigures?: number[];
    className?: string;
    centerTotal?: number;
    capTablePie?: boolean;
    expensingPie?: boolean;
}

export interface DoughnutChartProps extends AltProps<Props> { }

ChartJS.register(ArcElement, Tooltip, Legend);

const DoughnutChart = ({ ap }: DoughnutChartProps) => {
    const { colors, labels, figures, tooltipFigures, className, centerTotal, capTablePie, expensingPie } = ap;
    const { appState } = useRootStore();

    // //minimum size pie
    const percentageThreshold = 0.05;
    const totalSum = tooltipFigures?.reduce((acc, val) => acc + val, 0);
    const thresholdValue = (totalSum ?? 0) * percentageThreshold;
    const adjustedData = tooltipFigures?.map(val => val === 0 ? 0 : (val < thresholdValue ? thresholdValue : val));

    const chartData = {
        labels: labels,
        datasets: [
            {
                data: adjustedData,
                backgroundColor: colors ?? defaultColors,
                borderColor: 'white',
                borderWidth: 1,
            },
        ],
    };

    const options: any = {
        responsive: true,
        maintainAspectRatio: true,
        circumference: 360,
        rotation: 0,
        cutout: '60%',
        elements: {
            arc: {
                borderWidth: 0,
            },
        },

        plugins: {
            legend: {
                display: false,
            },
            tooltip: {
                enabled: false,
                external: (context: any) => {
                    const tooltipEl = document.getElementById('custom-tooltip') as HTMLDivElement;
                    if (!tooltipEl) return;

                    const { tooltip, chart } = context;

                    if (!tooltip.dataPoints) {
                        tooltipEl.style.opacity = '0';
                        return;
                    }

                    const tooltipData = tooltip.dataPoints
                        .map((dp: any) => {
                            const color = dp.dataset.backgroundColor[dp.dataIndex] || '#000000';

                            const colorBox = `<span style="display: inline-block; width: 12px; height: 12px; background-color: ${color}; border: 1px solid white;"></span>`;

                            const percentage = `${figures[dp.dataIndex]}%`;
                            const formattedNumber = formatNumber(tooltipFigures ? tooltipFigures[dp.dataIndex] : 0);

                            if (tooltipFigures && tooltipFigures?.length > 0 && capTablePie) {
                                return `<div>
                                <div style="width: 100%; display: flex; align-items: center; gap: 10px;">
                                    ${colorBox}
                                    <span>${dp.label}</span>
                                </div>
                                <p style="width: 100%; height: 1px; margin: 8px 0; background: repeating-linear-gradient(to right, white 0px, white 3px, transparent 3px, transparent 6px);"></p>
                                <div>Shares (fully diluted):</div>
                                <div style="display: flex; justify-content: space-between; font-weight: 400; margin-top: 5px;">
                                    <span>${formattedNumber}</span>
                                    <span>${percentage}</span>
                                </div>
                            </div>`;
                            }

                            return `<div>
                                    <div style="width: 100%; display: flex; align-items: center; gap: 10px;">
                                        ${colorBox}
                                        <span>${dp.label}</span>
                                    </div>
                            <p style="width: 100%; height: 1px; margin: 8px 0; background: repeating-linear-gradient(to right, white 0px, white 3px, transparent 3px, transparent 6px);"></p>
                            <div style="display: flex; width: 100%; justify-content: space-between; gap: 10px;">
                                <span>${formattedNumber}</span>
                                <span>${percentage}</span>
                            </div>
                        </div>`;
                        })
                        .join('<br />');

                    tooltipEl.innerHTML = tooltipData;
                    tooltipEl.style.opacity = '1';
                    const canvas = context.chart.canvas;
                    const canvasRect = canvas.getBoundingClientRect();
                    const tooltipX = tooltip.caretX + canvasRect.left;
                    const tooltipY = tooltip.caretY + canvasRect.top;
                    tooltipEl.style.left = `${tooltipX}px`;
                    tooltipEl.style.top = `${tooltipY}px`;
                    tooltipEl.style.backgroundColor = '#4D4D4D';
                    tooltipEl.style.color = 'white';
                    tooltipEl.style.padding = '10px';
                    tooltipEl.style.borderRadius = '5px';

                    const closeTooltip = () => {
                        tooltipEl.style.opacity = '0';
                    };

                    document.addEventListener('mousemove', (event: MouseEvent) => {
                        const canvasRect = canvas.getBoundingClientRect();
                        const tooltipRect = tooltipEl.getBoundingClientRect();
                        const mouseInsideCanvas =
                            event.clientX >= canvasRect.left &&
                            event.clientX <= canvasRect.right &&
                            event.clientY >= canvasRect.top &&
                            event.clientY <= canvasRect.bottom;
                        const mouseInsideTooltip =
                            event.clientX >= tooltipRect.left &&
                            event.clientX <= tooltipRect.right &&
                            event.clientY >= tooltipRect.top &&
                            event.clientY <= tooltipRect.bottom;

                        if (!mouseInsideCanvas && !mouseInsideTooltip) {
                            tooltipEl.style.opacity = '0';
                        } else {
                            tooltipEl.style.opacity = '1';
                        }
                    });

                    canvas.addEventListener('mouseout', closeTooltip);
                },
            },
        },
    };

    const centerdText = {
        id: 'centerdText',
        afterDatasetsDraw(chart: any, args: any, plugins: any) {
            if (!centerTotal) return '';
            const { ctx, data } = chart;

            const xCenter = chart.getDatasetMeta(0).data[0]?.x;
            const yCenter = chart.getDatasetMeta(0).data[0]?.y;
            ctx.save();

            ctx.font = '1.4rem sans-serif';
            ctx.fillStyle = '#979DB5';
            ctx.textAlign = 'center';
            ctx.fillText('Total', xCenter, yCenter - 10);

            ctx.font = 'bold 1.4rem sans-serif';
            ctx.fillStyle = '#4d4d4d';
            ctx.textAlign = 'center';

            ctx.fillText(formatNumber(centerTotal), xCenter, yCenter + 10);
            ctx.restore();
        }
    }

    return (
        <Wrapper className={className}>
            <Doughnut options={options} data={chartData} plugins={[centerdText]} />
            <div
                id="custom-tooltip"
                style={{
                    position: 'fixed',
                    opacity: '0',
                    pointerEvents: 'none',
                    transition: 'opacity 0.2s',
                    zIndex: 10,
                }}
            ></div>
        </Wrapper>
    );
};

export default DoughnutChart;
