import styled from "@emotion/styled";
import { breakpoints } from "../../../../../../config/style/theme";

export const LEGEND_WIDTH = "30rem";
export const LEGEND_WIDTH_XL = "34rem";
const LEGEND_ITEM_BOTTOM_SPACING = "1.6rem";
const LEGEND_LABEL_FONT_SIZE = "1.4rem";

export const StyledChartLegend = styled.ul`
    label: ChartLegend;
    display: grid;
    // row-gap: ${LEGEND_ITEM_BOTTOM_SPACING};
    padding: unset;
    // margin: 0 auto;
    width: 55rem;
    overflow-y: auto;
    padding-right: 1.6rem;
`;

export const ChartLegendItem = styled.li`
    label: ChartLegendItem;
    display: flex;
    align-items: center;
    gap: 1rem;
    font-size: ${LEGEND_LABEL_FONT_SIZE};
    padding: 7px 0;

    svg {
        font-size: 2rem;
        border-radius: 10px;
    }
`;

export const ChartLegendItemLabel = styled.span`
    flex-grow: 1;
`;

export const ChartLegendItemFigure = styled.span`
    label: ChartLegendItem__Figure;
    width: 8rem;
    text-align: right;
`;

export const ChartLegendItemAmount = styled.span`
    label: ChartLegendItem__Amount;
`;
