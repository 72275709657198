import { forwardRef, useContext, useEffect, useImperativeHandle, useState } from 'react';
import { ForwardedRef } from '../../../../../../Shared/Hooks/useMultiStepForm';
import { CapTableImportStep } from '../../index.style';
import { ImportContext } from '../../ImportContext';
import { CapTableImportColumnType } from '../../../../types';
import ImportCapTable from '../../ImportCapTable';
import { css } from '@emotion/css';
import Button from '../../../../../../Shared/Components/Button/Button';
import Select from '../../../../../../Shared/Components/Select/Select';
import { getUniqueId, isNullOrUndefined, isNumber } from '../../../../../../Shared/Utilities';
import { Cell, ShareholderTypeEnum } from '../../../../../../Models/App/CapTable/ImportCapTable';

const Style = css({
	label: 'Shareholders',
	display: 'flex',
	flexDirection: 'column',
	'&__text': {
		marginTop: '0.8rem',
		marginBottom: '2.8rem',
		width: '87%',
	},
	'&__change': {
		marginBottom: '2rem',
		'&.select': {
			marginBottom: '2.8rem',
			width: '70%',
		},
	},
});

const Shareholders = forwardRef<ForwardedRef, unknown>((_, forwardedRef) => {
	const { columns, onFileUpload, file, setColumns } = useContext(ImportContext);
	const cols = columns?.filter((col) => col.type === CapTableImportColumnType.shareholders) || [];
	const [isChange, setIsChange] = useState<boolean>(false);

	// useEffect(() => {
	//     if (isNullOrUndefined(columns)) return;
	// }, [columns]);

	const [selectedCol, setSelectedCol] = useState<number>(cols[0]?.id ?? -1);

	useImperativeHandle(forwardedRef, () => ({
		async onValidate() {
			const selectedRows = cols[0].cells
				.filter((cell) => cell.shareholder_type === ShareholderTypeEnum.ORG || cell.shareholder_type === ShareholderTypeEnum.PERSON)
				.map((cell) => cell.referenceId);
			setColumns((prevState) => {
				const res = prevState?.map((col) => ({
					...col,
					cells: col.cells.filter((cell) => selectedRows.includes(cell.referenceId)),
				}));
				return res;
			});
			return true;
		},
	}));

	const onChangeHandler = async (value: number | string) => {
		if (!file || selectedCol === -1 || !isNumber(value)) return;
		try {
			setSelectedCol(value);
			const res = await onFileUpload(file, undefined, value);
			setColumns(res.data?.columns.map((col) => ({ ...col, uniqueId: getUniqueId() })));
		} catch (error: any) {
			setSelectedCol(-1);
		} finally {
		}
	};

	return (
		<CapTableImportStep size="md" className={Style} scroll>
			<div className="bold">Imported cap table</div>
			<div className={`${Style}__text`}>
				We've recognized that the below column contains the shareholders names.
				<br />
				Please review and uncheck the rows that do not indicate the relevant shareholders names.
				<br />
				If it's not correct, press Change & select from the list the right column title, representing shareholders names.
			</div>
			{isChange ? (
				<Select
					qaid=""
					options={columns?.map((col) => ({ value: col.id, label: col.title }))}
					value={selectedCol}
					className={`${Style}__change select`}
					onChange={onChangeHandler}
				/>
			) : (
				<Button qaid="" inverse label="Change" className={`${Style}__change`} onClick={() => setIsChange(true)} />
			)}
			<ImportCapTable
				columns={cols}
				focus={isChange ? undefined : 'border'}
				highlight={CapTableImportColumnType.shareholders}
				onSelect={(cells) => {
					setColumns((prevState) => {
						const res = prevState?.map((col) => {
							if (col.type !== CapTableImportColumnType.shareholders) return col;

							return {
								...col,
								cells,
							};
						});
						return res;
					});
				}}
			/>
		</CapTableImportStep>
	);
});

export default Shareholders;
