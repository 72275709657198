import { observer } from 'mobx-react-lite';
import useRootStore from './useRootStore';
import {
	CapTablePermission,
	ExpensingPermission,
	FourONinePermission,
	OptionsPermission,
	WaterfallPermission,
} from '../../Models/API/UsersAndPermissions/permissions-enum';
import { EmployeesWebsitePermission, PaymentPlanEnum } from '../../Models/API/enums';
import { RootStore, RootStoreContext } from '../../Store/RootStore';
import { useContext, useEffect, useMemo, useState } from 'react';
import { autorun, reaction, toJS } from 'mobx';
import { UserPermissions } from '../../Models/API/UsersAndPermissions/user-permissions-info';
import { IPaymenPlan } from '../../Models/App/PaymentModels';

const productsByPlan = {
	capTable: [PaymentPlanEnum.Starter, PaymentPlanEnum.Growth, PaymentPlanEnum.Scale, PaymentPlanEnum.Enterprise],
	equityPlans: [PaymentPlanEnum.Starter, PaymentPlanEnum.Growth, PaymentPlanEnum.Scale, PaymentPlanEnum.Enterprise],
	equityPlansTrustee: [PaymentPlanEnum.Starter, PaymentPlanEnum.Growth, PaymentPlanEnum.Scale, PaymentPlanEnum.Enterprise],
	funding: [PaymentPlanEnum.Starter, PaymentPlanEnum.Growth, PaymentPlanEnum.Scale, PaymentPlanEnum.Enterprise],
	expensing: [PaymentPlanEnum.Starter, PaymentPlanEnum.Growth, PaymentPlanEnum.Scale, PaymentPlanEnum.Enterprise],
	waterfall: [PaymentPlanEnum.Scale, PaymentPlanEnum.Enterprise],
	valuation: [PaymentPlanEnum.Growth, PaymentPlanEnum.Scale, PaymentPlanEnum.Enterprise],
	dataCollection: [PaymentPlanEnum.Growth, PaymentPlanEnum.Scale, PaymentPlanEnum.Enterprise],
	docs: [PaymentPlanEnum.Growth, PaymentPlanEnum.Scale, PaymentPlanEnum.Enterprise],
	employeeWebsite: [PaymentPlanEnum.Starter, PaymentPlanEnum.Growth, PaymentPlanEnum.Scale, PaymentPlanEnum.Enterprise],
};

type ProductPermissions = {
	disabled: boolean;
	included: boolean;
};

type DataType = Record<keyof typeof productsByPlan, ProductPermissions>;

const usePermissions = (permissions: UserPermissions | undefined, plan: IPaymenPlan) => {
	const rootStore = useContext(RootStoreContext);

	const getCalculatedData = (permissions: UserPermissions | undefined, currentPlan: IPaymenPlan) => {
		const data: DataType = {
			waterfall: {},
			capTable: {},
			equityPlans: {},
			funding: {},
			employeeWebsite: {},
			valuation: {},
			docs: {},
			equityPlansTrustee: {},
			dataCollection: {},
			expensing: {},
		} as DataType;

		data.waterfall.included = !!currentPlan?.waterfall;
		data.capTable.included = !!currentPlan?.capTableManagement;
		data.equityPlans.included = !!currentPlan?.optionsManagement;
		data.funding.included = !!currentPlan?.fundingManagement;
		data.employeeWebsite.included = !!currentPlan?.employeeInterface;
		data.expensing.included = !!currentPlan?.expensingManagement;
		data.valuation.included = !!currentPlan?.valuationReport;
		data.dataCollection.included = !!currentPlan?.valuationReport;

		data.capTable.disabled = permissions?.capTablePermission === CapTablePermission.no_access;
		data.waterfall.disabled = permissions?.waterfallPermission === WaterfallPermission.no_access;
		data.expensing.disabled = permissions?.optionPermission === OptionsPermission.NotAllowed;
		data.dataCollection.disabled = permissions?.fourONinePermission === FourONinePermission.NotAllowed;
		data.expensing.disabled = permissions?.expensingPermission === ExpensingPermission.NotAllowed;
		data.valuation.disabled =
			permissions?.fourONinePermission !== FourONinePermission.Chief && permissions?.fourONinePermission !== FourONinePermission.Valuation;

		data.equityPlans.disabled =
			!process.env.REACT_APP_IS_OPTIONS_ACTIVE ||
			(permissions ? !(permissions?.optionPermission <= OptionsPermission.editor && permissions?.optionPermission >= CapTablePermission.admin) : true);

		data.funding.disabled =
			!process.env.REACT_APP_IS_FUNDING_ACTIVE || (permissions ? permissions?.capTablePermission > CapTablePermission.full_access : true);

		data.docs.disabled = permissions
			? !(permissions?.optionPermission <= OptionsPermission.editor && permissions?.optionPermission >= CapTablePermission.admin)
			: true;

		data.employeeWebsite.disabled = permissions ? !(permissions?.employeeManagementPermission > EmployeesWebsitePermission.no_access) : true;

		data.equityPlansTrustee.disabled = Boolean(!process.env.REACT_APP_IS_OPTIONS_ACTIVE || !permissions?.optionPermission);
		return { permissions: data };
	};

	const cachedData = useMemo(() => {
		return getCalculatedData(permissions, plan);
	}, [permissions, plan]);

	return cachedData;
};

export default usePermissions;
