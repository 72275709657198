import React, { useEffect, useRef, useState } from 'react';
import { css } from '@emotion/css';
import NewBeneficiaryDetails from '../../../Sections/Beneficiaries/NewBeneficiary/NewBeneficiaryDetails/NewBeneficiaryDetails';
import useRootStore from '../../../../../Shared/Hooks/useRootStore';
import { observer } from 'mobx-react-lite';
import Select from '../../../../../Shared/Components/Select/Select';
import Flex from '../../../../../Shared/Components/Layout/Flex';
import AddButton from '../../../../../Shared/Components/Button/AddButton';
import NumberInput from '../../../../../Shared/Components/Input/NumberInput';
import TextInput from '../../../../../Shared/Components/Input/TextInput';
import Button from '../../../../../Shared/Components/Button/Button';
import { PromisedType } from '../../../../../Models/App/EquityPlans/Promised';
import useGrant from '../../../Sections/Grants/GrantDetails/useGrant';
import { NumberOption } from '../../../../../Models/API/All/NumberOption';
import { GrantTypes } from '../../../../../Models/App/EquityPlans/Grants';
import { InputRefs, InputValidationRef, useFormValidation } from '../../../../../Shared/Hooks/useFormValidation';
import { useAppendState } from '../../../../../Shared/Hooks/useAppendState';
import { commonValidators } from '../../../../../Shared/ObjectValidator';
import Tooltip from '../../../../../Shared/Components/Tooltip';

const Style = css({
	label: 'AddNewPromised',
	display: 'flex',
	flexDirection: 'column',
	height: '100%',
	padding: '7rem 8rem 4rem',

	'&__title': {
		fontSize: '2.5rem',
		marginBottom: '4.8rem',
		fontWeight: 500,
	},

	'&__inputs-container': {
		display: 'grid',
		gridTemplateColumns: 'repeat(4, 1fr)',
		gridColumnGap: '2.4rem',
		gridRowGap: '3.4rem',
		marginBottom: '4rem',
	},

	'&__add-ben-btn': {
		alignSelf: 'flex-end',
		marginBottom: '0.4rem',
	},
});

interface Props {
	onSave: (promised: PromisedType) => Promise<void>;
}

const AddNewPromised = (props: Props) => {
	const { equityPlansStore, companyStore } = useRootStore();
	const [showAddBnficry, setShowAddBnficry] = useState(false);
	const [promised, _, onInputChange] = useAppendState<PromisedType>({
		promisedId: 0,
		isGrant: false,
		beneficiaryId: undefined,
		planId: undefined,
		companyId: companyStore.companyId,
		grantNumber: undefined,
		awardTypes: undefined,
		amountPromised: undefined,
		vestingStartDate: undefined,
		taxTrack: undefined,
		department: undefined,
		site: undefined,
		subDepartment: undefined,
	});
	const [isGrantNumberExists, setIsGrantNumberExists] = useState<boolean>(false);
	const { formValidationState, inputRefs, validateForm } = useFormValidation({
		form: promised,
		schema: {
			beneficiaryId: [commonValidators.required()],
			planId: [commonValidators.required()],
			grantNumber: [
				commonValidators.requiredIf(() => !equityPlansStore.companyData?.autoGenerateGrantNumber),
				commonValidators.customValidation(!isGrantNumberExists, 'Grant number already exists'),
			],
			awardTypes: [commonValidators.required()],
			amountPromised: [commonValidators.required(), commonValidators.graterThan(0)],
			// taxTrack: [commonValidators.required()],
		},
		deps: [isGrantNumberExists, equityPlansStore.companyData?.autoGenerateGrantNumber],
	});

	const plans =
		equityPlansStore.companyData?.plans?.map((pln) => {
			return { label: pln.name, value: pln.planId };
		}) || [];

	const handleBenCreate = async (id: number) => {
		equityPlansStore.newGrant.beneficiaryId = id;
		setShowAddBnficry(false);
	};

	const setAvialableAwardTypes = () => {
		const options = equityPlansStore.companyData?.plans?.filter((pln) => pln.planId === promised.planId);
		let newOptions: NumberOption[] = [];
		options?.forEach((opt) => {
			if (opt.allowRsu) newOptions.push({ label: 'RSU', value: GrantTypes.RSU });
			if (opt.allowSop) newOptions.push({ label: 'Options', value: GrantTypes.Options });
		});
		return newOptions;
	};

	const onCheckValidate = () => {
		if (!validateForm()) return;
		props.onSave(promised);
	};

	const onValidateGrantNumber = async (e: any) => {
		if (!promised.grantNumber) return;
		const res = await equityPlansStore.CheckIfGrantNumberExists(promised.grantNumber);
		setIsGrantNumberExists(res.data);
	};

	return (
		<div className={Style}>
			<div className={`${Style}__title`}>
				Add new promised award{' '}
				<Tooltip
					title={
						<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
							<span>Promised equity award that has been offered</span>
							<span>to beneficiary, but was not granted yet</span>
						</div>
					}
				/>
			</div>
			{showAddBnficry && <NewBeneficiaryDetails isFromGrant onCancel={() => setShowAddBnficry(false)} onSave={handleBenCreate} />}
			<div className={`${Style}__inputs-container`}>
				{!showAddBnficry && (
					<>
						<Select
							label="Beneficiary"
							qaid="AddPromised.Select.Beneficiary"
							options={equityPlansStore.beneficiariesList ?? []}
							value={promised.beneficiaryId}
							name="beneficiaryId"
							onChange={onInputChange}
							style={{ gridArea: '1 / 1 / 1 / 3' }}
							error={formValidationState?.beneficiaryId?.message}
							ref={(el: InputValidationRef) => (inputRefs.beneficiaryId = el)}
							required
						/>
						<Flex justify="start">
							<AddButton className={`${Style}__add-ben-btn`} onClick={() => setShowAddBnficry(true)} qaid="AddBen" label="Add New" />
						</Flex>
						<div></div>
					</>
				)}

				{/* Plan */}
				<Select
					label="Equity plan"
					qaid="AddPromised.Select.EquityPlan"
					options={plans}
					name="planId"
					onChange={onInputChange}
					value={promised.planId}
					error={formValidationState?.planId?.message}
					ref={(el: InputValidationRef) => (inputRefs.planId = el)}
					required
					className="mb-0"
				/>

				{/* Award Type */}
				<Select
					label="Award type"
					qaid="AddPromised.Select.AwardType"
					options={setAvialableAwardTypes()}
					name="awardTypes"
					onChange={onInputChange}
					value={promised.awardTypes}
					error={formValidationState?.awardTypes?.message}
					ref={(el: InputValidationRef) => (inputRefs.awardTypes = el)}
					required
					className="mb-0"
				/>

				{/* Grant Number */}
				{equityPlansStore.companyData?.autoGenerateGrantNumber ? (
					<TextInput qaid="AddGrant.Input.GrantNumber" disabled label="Grant number" value="Auto-generated" />
				) : (
					<TextInput
						label="Grant number"
						qaid="AddPromised.Input.GrantNumber"
						name="grantNumber"
						value={promised.grantNumber}
						onChange={onInputChange}
						onBlur={onValidateGrantNumber}
						error={formValidationState?.grantNumber?.message}
						ref={(el: InputValidationRef) => (inputRefs.grantNumber = el)}
						required
						containerClassName="mb-0"
					/>
				)}

				{/* Granted */}
				<NumberInput
					label="Amount promised"
					qaid="AddPromised.Input.GrantNumber"
					name="amountPromised"
					value={promised.amountPromised}
					onChange={onInputChange}
					error={formValidationState?.amountPromised?.message}
					ref={(el: InputValidationRef) => (inputRefs.amountPromised = el)}
					required
					containerClassName="mb-0"
				/>

				{/* Tax Track */}
				{/* <Select
					label="Tax track"
					options={equityPlansStore.taxTracks}
					onChange={onInputChange}
					name="taxTrack"
					value={promised.taxTrack}
					qaid="AddPromised.Input.TaxTrack"
					error={formValidationState?.taxTrack?.message}
					ref={(el: InputValidationRef) => (inputRefs.taxTrack = el)}
					required
					className="mb-0"
				/> */}

				{/* Department */}
				<Select
					label="Department"
					options={equityPlansStore.departments}
					onChange={onInputChange}
					value={promised.department}
					name="department"
					qaid="AddPromised.Input.Department"
					className="mb-0"
				/>

				{/* Sub-department */}
				<Select
					label="Sub-department"
					options={equityPlansStore.subDepartments}
					onChange={onInputChange}
					value={promised.subDepartment}
					name="subDepartment"
					qaid="AddPromised.Input.Department"
					className="mb-0"
				/>

				{/* Site */}
				<Select
					label="Site"
					options={equityPlansStore.sites}
					onChange={onInputChange}
					value={promised.site}
					name="site"
					qaid="AddPromised.Input.Site"
					className="mb-0"
				/>
			</div>
			<Button
				qaid="AddPromised.Button.AddGrant"
				label="Add"
				onClick={onCheckValidate}
				// isLoading={isLoading}
				// disabled={props.disabled}
				position="end"
			/>
		</div>
	);
};

export default observer(AddNewPromised);
