import { BaseService } from "./BaseService";

import type { IAddress, IAddressRegistration, IAddressUpdate, ICompany, ICompanyRegistration, ICompanyUpdate } from "../Models/API/Company";
import { ICompanyContact, ICompanyContactRegistration } from "../Models/API/Company/company-contact";
import { companyApi } from './Axios'

export default class CompanyService extends BaseService {
	endPoints = {
		CreateCompany: '/Company',
		GetCompany: (companyId: number) => `/Company/${companyId}`,
		UpdateCompany: (companyId: number) => `/Company/${companyId}`,
		DeleteCompany: (companyId: number) => `/Company/${companyId}`,
		GetCompanies: '/Company/Companies',
		AddAddress: (companyId: number) => `/Address/${companyId}`,
		GetAddresses: (companyId: number) => `/Address/${companyId}`,
		UpdateAddress: (addressId: number) => `/Address/${addressId}`,
		DeleteAddress: (companyId: number, addressId: number) => `/Address/${companyId}/${addressId}`,
		GetCompanyContact: (companyId: number) => `/CompanyContact/${companyId}`,
		UpdateCompanyContact: (companyId: number, companyContactId: number) => `/CompanyContact/${companyId}/${companyContactId}`,
		UpdateCompanyDataSource: (companyId: number) => `/Company/${companyId}/dataSource`,
		UpdateCapTableDataSource: (companyId: number) => `/Company/${companyId}/capTableDataSource`,
	};

	companyID: number;
	odooTransactionId?: number;

	constructor(companyID: number, odooTransactionId?: number) {
		super();
		this.companyID = companyID;
		this.odooTransactionId = odooTransactionId;
	}

	async createCompany(data: ICompanyRegistration) {
		try {
			const response = await this.httpCompany.post<ICompany>(this.endPoints.CreateCompany, { ...data });

			if (response.status === 403) {
				return {
					error: 'Company name already exist',
				};
			}

			return {
				token: response.headers.authorization,
				data: response.data,
			};
		} catch (error) {
			return {
				error: 'Error Occur while creating company',
			};
		}
	}

	async getCompany(companyId: number) {
		return await this.safeExecuteAsync(async () => {
			return (await this.httpCompany.get<ICompany>(this.endPoints.GetCompany(companyId))).data;
		});
	}

	async updateCompany(companyId: number, data: ICompanyUpdate) {
		return await this.safeExecuteAsync(async () => {
			return (await this.httpCompany.patch<ICompany>(this.endPoints.UpdateCompany(companyId), data)).data;
		});
	}

	async deleteCompany(companyId: number) {
		return await this.safeExecuteAsync(async () => {
			return (await this.httpCompany.delete(this.endPoints.DeleteCompany(companyId))).data;
		});
	}

	async getCompanies() {
		return await this.safeExecuteAsync(async () => {
			return (await this.httpCompany.get<ICompany[]>(this.endPoints.GetCompanies)).data;
		});
	}

	async addAddress(companyId: number, data: IAddressRegistration) {
		return await this.safeExecuteAsync(async () => {
			return (await this.httpCompany.post<IAddressRegistration>(this.endPoints.AddAddress(companyId), data)).data;
		});
	}

	async getAddresses(companyId: number) {
		return await this.safeExecuteAsync(async () => {
			return (await this.httpCompany.get<IAddress[]>(this.endPoints.GetAddresses(companyId))).data;
		});
	}

	async updateAddress(addressId: number, data: IAddressUpdate) {
		return await this.safeExecuteAsync(async () => {
			return (await this.httpCompany.put<IAddress>(this.endPoints.UpdateAddress(addressId), data)).data;
		});
	}

	async deleteAddress(companyId: number, addressId: number) {
		return await this.safeExecuteAsync(async () => {
			return await (
				await this.httpCompany.delete(this.endPoints.DeleteAddress(companyId, addressId))
			).data;
		});
	}

	async getCompanyContact(companyId: number) {
		return await this.safeExecuteAsync(async () => {
			return (await this.httpCompany.get<ICompanyContact>(this.endPoints.GetCompanyContact(companyId))).data;
		});
	}

	async updateCompanyContact(companyId: number, companyContactId: number, data: ICompanyContactRegistration) {
		return await this.safeExecuteAsync(async () => {
			return (await this.httpCompany.put<ICompanyContact>(this.endPoints.UpdateCompanyContact(companyId, companyContactId), data)).data;
		});
	}

	async updateCompanyEquitySource(fromPivotal: boolean, companyId: number) {
		const res = await companyApi.post(this.endPoints.UpdateCompanyDataSource(companyId), { fromPivotal });
		console.log('UpdateCompanyDataSource RES', res);
		return res;
	}

	async updateCapTableEquitySource(fromPivotal: boolean, companyId: number) {
		const res = await companyApi.post(this.endPoints.UpdateCapTableDataSource(companyId), { fromPivotal });
		console.log('UpdateCapTableDataSource RES', res);
		return res;
	}
}
