import CloseIcon from "@mui/icons-material/Close";
import WestIcon from "@mui/icons-material/West";
import { ReactElement, SyntheticEvent, cloneElement, forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import ReactDOM from "react-dom";
import { useTranslation } from "react-i18next";
import { IC_CONFIRM_CIRCLE, IC_DELETE_CIRCLE, IC_ERROR_CIRCLE, IC_SUCCESS_MODAL, IC_WARNING_CIRCLE } from "../../../Assets";
import { isString } from "../../Utilities";
import Button from "../Button/Button";
import { inputClassName } from "../Input/Input.Style";
import Title from "../Layout/Title";
import Container from "./Modal.Style";
import { ModalElementProps, ModalRef } from "./types";
import classNames from "classnames";

const Modal = forwardRef<ModalRef, ModalElementProps>(
	(
		{
			maximize = false,
			fullscreen,
			height,
			modalId = 0,
			onCancel,
			onConfirm,
			title,
			width,
			maxWidth,
			maxHeight,
			isShow,
			type = 'dialog',
			timeout = type === 'success' ? 3000 : undefined,
			isLast = false,
			isMust = false,
			showClose = true,
			showBack = false,
			isFrameless,
			removeModal,
			hideModal,
			displayModal,
			isFirst = true,
			onModalClose,
			qaid,
			showProgressBar = !!timeout,
			allowScrolling = true,
			promptPlahceholder = 'Text...',
			className = '',
			confirmButton,
			cancelButton,
			onCloseClick,
			body = <></>,
			isStandalone = false,
			background,
		}: ModalElementProps,
		forwardedRef
	) => {
		// isMust = timeout ? true : isMust;
		const bodyEl = document.querySelector('.Modals') as HTMLDivElement;
		const modalRef = useRef<HTMLDivElement>(null);
		const { t } = useTranslation();
		const [isLoading, setIsLoading] = useState<boolean>(false);
		const resolver = useRef<Function>();
		const inputRef = useRef<HTMLInputElement>(null);

		const isPrompt = type === 'prompt';
		const isConfirm = type === 'confirm' || isPrompt;
		const isWarning = type === 'warning';
		const isDelete = type === 'delete';
		const isDialog = type === 'dialog';
		const isSuccess = type === 'success';
		const isError = type === 'error';

		const showCancelButton = !!cancelButton?.label || isConfirm || isDelete || isError || isWarning;

		useImperativeHandle(forwardedRef, () => ({
			getResolver() {
				return new Promise(function (resolve) {
					resolver.current = resolve;
				});
			},
		}));

		const onHideHandler = (isCloseClick?: boolean) => {
			resolver.current && resolver.current(isCloseClick ? undefined : false);
			timeout ? onConfirm?.() : onCancel?.();
			isCloseClick && onCloseClick?.();
			removeModal?.();
			// setTimeout(() => {

			// }, 10);
		};

		const onConfirmHandler = async () => {
			try {
				if (isPrompt && !inputRef.current?.value) return;
				setIsLoading(true);
				await onConfirm?.(inputRef?.current?.value);
				if (resolver.current) resolver.current(true);
				removeModal?.();
			} catch (error) {
				throw error;
			} finally {
				setIsLoading(false);
			}
		};

		const inheritPayload = { modalId, removeModal, hideModal, displayModal, onHideHandler, onConfirmHandler };

		const BodyWithProps =
			typeof body === 'object' && body.type === 'div'
				? cloneElement(body as ReactElement, inheritPayload)
				: isString(body) // if body is a div or a string, don't inherit props
				? body
				: typeof body === 'function'
				? body(inheritPayload)
				: cloneElement(body as ReactElement, inheritPayload);

		useEffect(() => {
			if (timeout === undefined) return;
			const timeoutRef = setTimeout(() => {
				onConfirm?.();
				removeModal?.();
			}, timeout);
			return () => clearTimeout(timeoutRef);
		}, [timeout]);

		useEffect(() => {
			return () => {
				onModalClose?.();
				setIsLoading(false);
			};
		}, []);

		const onKeyDownHandle = (e: any) => {
			if (!isLast || e.key !== 'Escape') return;
			onHideHandler(e);
		};

		return ReactDOM.createPortal(
			<Container.Wrapper
				zIndex={1000 + modalId}
				isFirst={isFirst}
				data-qaid={qaid || `Modal-${modalId}`}
				onKeyDown={onKeyDownHandle}
				tabIndex={modalId}
				onClick={() => {
					if (!isMust || !modalRef.current) return onHideHandler();
					modalRef.current.style.animation = 'shake 0.2s ease-in-out 2';
					setTimeout(() => {
						if (modalRef.current) modalRef.current.style.animation = '';
					}, 400);
				}}
				hidden={!isShow}
				key={1000 + modalId}
				className={classNames({ [className]: !!className })}
				maximize={maximize}
				background={background}
			>
				<Container.Modal
					width={width}
					height={height}
					maxWidth={maxWidth}
					maxHeight={maxHeight}
					fullscreen={fullscreen}
					maximize={maximize}
					allowScrolling={allowScrolling}
					type={type}
					onClick={(e: any) => e.stopPropagation()}
					ref={modalRef}
					data-modal="modal"
					background={background}
				>
					{!isLast && <Container.Backdrop data-modal="backdrop" />}
					{showBack && (
						<Container.Back data-qaid="Modal.Button.Back" onClick={() => onHideHandler} data-modal="back">
							<WestIcon />
						</Container.Back>
					)}
					{!showBack && showClose && (isDialog || isWarning) && (
						<Container.Close data-qaid="Modal.Button.Close" onClick={(e) => onHideHandler(true)} data-modal="close">
							<CloseIcon style={{ width: '2rem', height: '2rem' }} />
						</Container.Close>
					)}
					{!!title && isDialog && <Title data-elid="title">{t(title)}</Title>}
					{isStandalone ? (
						BodyWithProps
					) : (
						<Container.Body
							data-modal="body"
							center={!isDialog}
							allowScrolling={allowScrolling}
							style={{
								padding: isFrameless || maximize ? 0 : !isDialog ? '5rem 4rem 3rem' : title ? 20 : timeout ? 0 : 40, // Daniel - to fix timeout = 0
								userSelect: !isDialog ? 'none' : 'unset',
								flexWrap: isConfirm ? 'wrap' : 'inherit',
							}}
						>
							{isDelete && <Container.Image src={IC_DELETE_CIRCLE} alt="delete" />}
							{isWarning && <Container.Image src={IC_WARNING_CIRCLE} alt="warning" />}
							{isError && <Container.Image src={IC_ERROR_CIRCLE} alt="error" />}
							{isConfirm && <Container.Image src={IC_CONFIRM_CIRCLE} alt="config" />}
							{isSuccess && (
								<>
									<Container.Image src={IC_SUCCESS_MODAL} alt="success" />
									<Title type="secondary" style={{ marginBottom: '2rem' }}>
										Success!
									</Title>
								</>
							)}
							{!!title && !isDialog && (
								<Title
									data-elid="title"
									style={{ marginBottom: '1.6rem' }}
									danger={isError}
									warning={isWarning}
									type={isConfirm ? 'secondary' : 'primary'}
								>
									{t(title)}
								</Title>
							)}
							{BodyWithProps}

							{isPrompt && (
								<Container.Input
									data-modal="input"
									className={inputClassName}
									data-qaid="Modal.Input.Prompt"
									placeholder={promptPlahceholder}
									ref={inputRef}
									onKeyDown={(e: any) => {
										if (e.key === 'Enter') {
											onConfirmHandler();
										}
									}}
								/>
							)}
						</Container.Body>
					)}
					{!!(onCancel || onConfirm || !isDialog) && !timeout && (
						<Container.Actions>
							{showCancelButton && (
								<Button
									data-modal="cancel-button"
									onClick={() => onHideHandler()}
									cancel
									inverse
									qaid="Modal.Button.Cancel"
									label={isWarning || isError ? 'general.close' : 'general.cancel'}
									{...cancelButton}
								/>
							)}
							{(!!onConfirm || isConfirm || isDelete || confirmButton) && (
								<Button
									data-modal="confirm-button"
									onClick={onConfirmHandler}
									isLoading={isLoading}
									qaid="Modal.Button.Confirm"
									label="general.yes"
									{...confirmButton}
								/>
							)}
						</Container.Actions>
					)}
					{!!timeout && showProgressBar && <Container.ProgressBar timeout={timeout} />}
				</Container.Modal>
			</Container.Wrapper>,
			bodyEl
		);
	}
);

export default Modal;
