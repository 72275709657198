import { makeAutoObservable, toJS } from 'mobx';
import { makePersistable } from 'mobx-persist-store';
import { ServerResponseApi } from '../Models/API/All/server-response';
import { DataCollectionCommentPayload } from '../Models/API/DataCollection/comment-payload';
import { DataCollectionComment, DataCollectionFieldTypeEnum } from '../Models/API/DataCollection/comment-response';
import { DataCollectionPayload } from '../Models/API/DataCollection/data-collection-payload';
import { DataCollectionProjectResponse } from '../Models/API/DataCollection/data-collection-project-response';
import { DataCollectionProjectPreview, DataCollectionStatusEnum } from '../Models/API/DataCollection/project-preview';
import { UploadDocumentPayload } from '../Models/API/DataCollection/upload-document-payload';
import { ValuationDocument } from '../Models/API/DataCollection/valuation-document';
import { FourONinePermission } from '../Models/API/UsersAndPermissions/permissions-enum';
import { HigherLevelResponse, HighLevelErrorResponse } from '../Services/Axios';
import { DataCollectionService } from '../Services/DataCollectionService';
import { formatDate } from '../Shared/Utilities';
import { RootStore } from './RootStore';

type CommentsByQuestionType = Record<DataCollectionFieldTypeEnum, DataCollectionComment[]>;

export default class DataCollectionStore {
	dataCollectionService: DataCollectionService = new DataCollectionService();
	projects: DataCollectionProjectPreview[] | undefined = undefined;
	projectId: string | null = null;
	dataCollection: DataCollectionPayload = {} as DataCollectionPayload;
	comments: DataCollectionComment[] | undefined = undefined;
	documents: ValuationDocument[] | undefined = undefined;
	dataCollectionId: string = '';
	isOwner: boolean = false;
	currentStatus: DataCollectionStatusEnum = DataCollectionStatusEnum.DataCollection;
	projectName: string = '';
	isIsraeliCustomer: boolean = false;
	lastViewedProjects: string[] = [];

	constructor(private rootStore: RootStore) {
		makeAutoObservable(this);
		makePersistable(this, {
			name: 'DataCollectionStore',
			properties: ['projects', 'projectId', 'comments', 'dataCollection', 'currentStatus', 'projectName', 'lastViewedProjects'],
			storage: window.sessionStorage,
			expireIn: 10800000, // 3 hours
		});
		rootStore.companyStore.onCompanyChange.subscribe(() => {
			if (!this.rootStore.companyStore.companyId) return;
			this.resetStoreToDefaultValues();
		});
	}

	get currentRole(): FourONinePermission {
		return this.rootStore.auth.permissions?.fourONinePermission ?? FourONinePermission.NoAccess;
	}

	set valuationProjectId(id: string | null) {
		this.projectId = id;
	}

	get valuationProjectId() {
		return this.projectId;
	}

	get isProjectDisabled() {
		return ![DataCollectionStatusEnum.DataCollection, DataCollectionStatusEnum.DataReview].includes(this.currentStatus);
	}

	get commentsByQuestion() {
		const defaultObj = Object.fromEntries(
			Object.values(DataCollectionFieldTypeEnum)
				.filter((value): value is DataCollectionFieldTypeEnum => typeof value === 'number')
				.map((key) => [key, [] as DataCollectionComment[]])
		) as unknown as CommentsByQuestionType;

		return (this.comments || [])?.reduce((acc, comment) => {
			acc[comment.fieldType].push(comment);
			return acc;
		}, defaultObj);
	}

	setLastViewedProject(id: string) {
		const data = [...this.lastViewedProjects];
		const index = data.indexOf(id);
		if (index > -1) {
			data.splice(index, 1);
			data.unshift(id);
		}
		this.lastViewedProjects = data;
		this.sortProjectsByLastViewed();
	}

	sortProjectsByLastViewed() {
		if (!this.projects) return;
		const idIndexMap = new Map(this.lastViewedProjects.map((id, index) => [id, index]));
		this.projects = this.projects.sort((a, b) => (idIndexMap.get(a.valuationProjectId) ?? 0) - (idIndexMap.get(b.valuationProjectId) ?? 0));
	}

	setDataCollection = <K extends keyof DataCollectionPayload>(key: K, value: DataCollectionPayload[K], isUpdate?: boolean) => {
		if (this.dataCollection) this.dataCollection[key] = value;
		else this.dataCollection = { [key]: value } as DataCollectionPayload;

		isUpdate && this.updateDataCollection();
	};

	resetValuationProject() {
		this.comments = undefined;
		this.documents = undefined;
		this.currentStatus = DataCollectionStatusEnum.DataCollection;
	}

	resetStoreToDefaultValues() {
		this.valuationProjectId = null;
		this.comments = undefined;
		this.documents = undefined;
		this.projects = undefined;
		this.dataCollectionId = '';
		this.dataCollection = {} as DataCollectionPayload;
		this.currentStatus = DataCollectionStatusEnum.DataCollection;
		this.lastViewedProjects = [];
	}

	// Valuation Project

	async createProject() {
		const res = await this.dataCollectionService.createProject();
		if (res.data?.data) {
			this.projects?.push(res.data.data);
			this.lastViewedProjects.unshift(res.data.data.valuationProjectId);
		}
		return res;
	}

	async getProjects() {
		const res = await this.dataCollectionService.getProjects();
		this.projects = res.data?.data;
		if (!this.lastViewedProjects.length && res.data) {
			this.lastViewedProjects = res.data.data.map((p) => p.valuationProjectId);
			this.sortProjectsByLastViewed();
		}
		return res;
	}

	takeProjectOwnership(valuationProjectId: string) {
		return this.dataCollectionService.takeProjectOwnership(valuationProjectId);
	}

	// Data Collection

	getSectorAndIndstryList() {
		return this.dataCollectionService.getSectorAndIndstryList();
	}

	getDataCollection(valuationProjectId: string) {
		this.projectId = valuationProjectId;
		return this._dataCollectionHandler(() => this.dataCollectionService.getDataCollection(valuationProjectId));
	}

	createDataCollection(data: Partial<DataCollectionPayload>) {
		return this._dataCollectionHandler(() => this.dataCollectionService.createDataCollection(data));
	}
	updateDataCollection(data: DataCollectionPayload = this.dataCollection) {
		return this._dataCollectionHandler(() => this.dataCollectionService.updateDataCollection(data));
	}

	submitDataCollection(dataCollectionId: string = this.dataCollectionId) {
		return this.dataCollectionService.submitDataCollection(dataCollectionId);
	}

	async markAsRead(dataCollectionId: string = this.dataCollectionId, ids: string[], type: DataCollectionFieldTypeEnum) {
		const res = await this.dataCollectionService.markAsRead(dataCollectionId, ids);
		if (!res.isSuccess) return;
		switch (type) {
			case DataCollectionFieldTypeEnum.General:
				this.projects = this.projects?.map((p) => {
					if (p.valuationProjectId === this.valuationProjectId) {
						p.unreadMessagesGeneral -= res.data.data.length;
					}
					return p;
				});
				return;
			default: {
				this.comments = this.comments?.map((comment) => {
					if (ids.includes(comment.id)) {
						comment.isRead = true;
					}
					return comment;
				});
			}
		}
	}

	sendNotification(valuationProjectId: string) {
		return this.dataCollectionService.sendNotification(valuationProjectId);
	}

	async setIsraeliCustomer(valuationProjectId: string) {
		const res = await this.dataCollectionService.setIsraeliCustomer(valuationProjectId);
		if (res.isSuccess) {
			this.isIsraeliCustomer = !this.isIsraeliCustomer;
		}
		return res;
	}

	async approveProject(valuationProjectId: string) {
		const res = await this.dataCollectionService.approveProject(valuationProjectId);
		if (res.isSuccess) {
			this.getDataCollection(valuationProjectId);
		}
	}

	private _dataCollectionHandler = async (
		callback: () => Promise<HighLevelErrorResponse | HigherLevelResponse<ServerResponseApi<DataCollectionProjectResponse>>>
	) => {
		const res = await callback();
		if (res.data?.data && this.valuationProjectId) {
			const { dataCollectionId, issues, comments, documents, ...rest } = res.data.data;
			this.dataCollection = {
				...rest,
				valuationProjectId: this.valuationProjectId,
			};
			this.dataCollectionId = res.data.data.dataCollectionId;
			this.comments = comments;
			this.documents = documents.sort((a, b) =>
				a.type === DataCollectionFieldTypeEnum.GrantStatus && b.type === DataCollectionFieldTypeEnum.RecentValuationReport
					? 1
					: a.type === DataCollectionFieldTypeEnum.GrantStatus || a.type === DataCollectionFieldTypeEnum.RecentValuationReport
					? 1
					: -1
			);
			this.isOwner = !!res.data.data.isOwner;
			this.currentStatus = res.data.data.valuationProjectStatus;
			this.projectName = `409A valuation as of ${formatDate(res.data.data.valuationDate) || '<valuation date>'}`;
			this.isIsraeliCustomer = res.data.data.isIsrealiCustomer;
			this.setLastViewedProject(this.valuationProjectId);
		}
		return res;
	};

	// Comment

	async addComment(data: DataCollectionCommentPayload) {
		const res = await this.dataCollectionService.addComment(data);
		res.data && this.comments?.push(res.data.data);
		return res;
	}

	// Document

	async uploadDocument(data: UploadDocumentPayload, documentId: string) {
		const res = await this.dataCollectionService.uploadDocument(data, documentId);
		const docIdx = this.documents?.findIndex((doc) => doc.id === res.data?.data.documentId) ?? -1;
		if (res.data && docIdx !== -1 && this.documents) {
			const splittedName = res.data.data.fileName.split('.');
			this.documents[docIdx] = {
				...this.documents[docIdx],
				fileName: res.data.data.fileName,
				filePath: res.data.data.filePath,
				id: res.data.data.documentId,
				extension: `.${splittedName[splittedName.length - 1]}`,
			};
		}
		return res;
	}

	getDocument(dataCollectionId: string, documentId: string) {
		return this.dataCollectionService.getDocument(dataCollectionId, documentId);
	}
}
