import { BaseService } from "./BaseService";
import { equityPlansApi, pivotalManagmentApi } from "./Axios";
import {
    UpcomingVestingsRes, ManagerReportsRes, GrantDocumentsRes, TrusteeBeneficiariesRes, TrusteeCancelationsRes, TrusteeGrantsRes, TrusteeOrdersRes, PagedTableReqParams, TrusteeReportParams, TrusteeReportRes, TrusteeFileReq, TerminationRequestRes, TrusteeMainSaaSDashboardRes,
} from "../Screens/TrusteeEquity/Types/ReqRes";
import { GrantDocumentStatus, TrusteeCompanyData } from "../Screens/TrusteeEquity/Types/trusteeTypes";
import { SubmitFileToDownloadType, SubmitFileType } from "../Models/API/Trustee/submit-file";

export interface ReqTypePivotal {
    companyId: number;
    productsSchema: number | string;
    trusteeCompanyId: string;
    token: string;
    reCaptchaToken: string;
}

export default class TrusteeService extends BaseService {
    companyId: number;

    constructor(companyID: number) {
        super();
        this.companyId = companyID;
    }

    base = "PivotalCFOSAASView/";
    endPoints = {
        Company: (companyId: number) => `Company/${companyId}`,

        PoolStatus: (date = new Date().toISOString()) => `${this.base}GetPivotalPoolStatus/${this.companyId}?AsOfDate=${date}`,
        CompanyData: () => `${this.base}GetPivotalCompanyData`,

        GrantsStatus: (date = new Date().toISOString()) => `${this.base}GetPivotalGrantsStatusDashboard/${this.companyId}?AsOfDate=${date}`,
        BeneficiariesStatus: (date = new Date().toISOString()) =>
            `${this.base}GetPivotalBeneficiaryTotals/${this.companyId}?AsOfDate=${date}`,
        UpcomingVestingsStatus: (date = new Date().toISOString()) => `${this.base}GetPivotalUpComingVestings/${this.companyId}`,
        GrantDocumentsStatus: (date = new Date().toISOString()) =>
            `${this.base}GetPivotalGrantDocuments/${this.companyId}?AsOfDate=${date}&PageNumber=1&ItemInPage=1`,

        Grants: () => `${this.base}GetPivotalGrants/${this.companyId}`,
        Beneficiaries: () => `${this.base}GetPivotalBeneficiarys/${this.companyId}`,
        Orders: () => `${this.base}GetPivotalOrders/${this.companyId}`,
        Cancelations: () => `${this.base}GetPivotalCancelletions/${this.companyId}`,
        Reports: () => `${this.base}GetPivotalReportsManagerCanSee/${this.companyId}`,
        SingleReport: () => `${this.base}GetPivotalReportResults/${this.companyId}`,
        SubmitFiles: () => `${this.base}SubmitFilesToTrustee/${this.companyId}`,
        GetHistorySubmitFiles: () => `${this.base}GetHistorySubmitFilesToTrustee/${this.companyId}`,
        GetSubmitFile: () => `${this.base}GetSubmitFileData/${this.companyId}`,

        GrantDocuments: () => `${this.base}GetPivotalGrantDocuments/${this.companyId}`,
        SignGrantDocuments: () => `${this.base}SignPivotalGrantDocuments/${this.companyId}`,
        GrantLetter: () => `${this.base}GetPivotalGrantLetter/${this.companyId}`,
        TerminationRequest: () => `${this.base}PostTerminationRequest/${this.companyId}`,

        MainDashboard: () => `${this.base}GetSAASMainDashboard/${this.companyId}`,
        TerminationTermination: () => `${this.base}PostCancelTerminationRequest/${this.companyId}`
    };

    async PutUserCompanyToken(stuff: ReqTypePivotal) {
        return await pivotalManagmentApi.put<{ companyId: string }>(this.endPoints.Company(this.companyId), stuff)
    }

    // Dashboard
    async GetPoolSummaryAsync(date: Date) {
        return await equityPlansApi.get(this.endPoints.PoolStatus(), { AsOfDate: date });
    }

    async GetPivotalCompanyDataAsync() {
        return await equityPlansApi.get<TrusteeCompanyData>(this.endPoints.CompanyData());
    }

    async GetGrantsSummaryAsync(date: Date) {
        return await equityPlansApi.get(this.endPoints.GrantsStatus(), { AsOfDate: date });
    }

    async GetBeneficiariesSummaryAsync(date: Date) {
        return await equityPlansApi.get(this.endPoints.BeneficiariesStatus(), { AsOfDate: date });
    }

    async GetGrantDocumentsSummaryAsync(date: Date) {
        return await equityPlansApi.get(this.endPoints.GrantDocumentsStatus(), { AsOfDate: date });
    }

    async GetUpcomingVestingsSummaryAsync(date: Date) {
        return await equityPlansApi.get<UpcomingVestingsRes>(this.endPoints.UpcomingVestingsStatus(), {
            PageNumber: 1,
            ItemInPage: 3,
            AsOfDate: date,
        });
    }

    async GetUpcomingVestingsSummaryByParamsAsync(params: Partial<PagedTableReqParams>) {
        console.log("GetUpcomingVestingsSummaryByParamsASYNC", params);

        return await equityPlansApi.get<UpcomingVestingsRes>(this.endPoints.UpcomingVestingsStatus(), params);
    }

    async PostTermintaionRequestAsync(remarks: string, beneficiaryId: string, asOfDate: Date) {
        const reqBody = {
            remarks,
            beneficiaryId,
            asOfDate,
        };
        return await equityPlansApi.post<TerminationRequestRes>(this.endPoints.TerminationRequest(), reqBody);
    }

    // General

    async GetTrusteeGrantsAsync(date: Date) {
        return await equityPlansApi.get<TrusteeGrantsRes>(this.endPoints.Grants(), { PageNumber: 1, ItemInPage: 5, AsOfDate: date });
    }

    async GetTrusteeGrantsByParamsAsync(params: Partial<PagedTableReqParams>) {
        params.AsOfDate = new Date().toISOString();

        return await equityPlansApi.get<TrusteeGrantsRes>(this.endPoints.Grants(), params);
    }

    async GetTrusteeBeneficiariesAsync(date: Date) {
        return await equityPlansApi.get<TrusteeBeneficiariesRes>(this.endPoints.Beneficiaries(), {
            PageNumber: 1,
            ItemInPage: 25,
            AsOfDate: date,
        });
    }

    async GetTrusteeBeneficiariesByParamsAsync(params: Partial<PagedTableReqParams>) {
        return await equityPlansApi.get<TrusteeBeneficiariesRes>(this.endPoints.Beneficiaries(), params);
    }

    async GetTrusteeOrdersAsync(date: Date) {
        return await equityPlansApi.get<TrusteeOrdersRes>(this.endPoints.Orders(), { PageNumber: 1, ItemInPage: 10, AsOfDate: date });
    }

    async GetTrusteeOrdersByParamsAsync(params: Partial<PagedTableReqParams>) {
        return await equityPlansApi.get<TrusteeOrdersRes>(this.endPoints.Orders(), params);
    }

    async GetTrusteeCancelationsAsync(date: Date) {
        return await equityPlansApi.get<TrusteeCancelationsRes>(this.endPoints.Cancelations(), {
            PageNumber: 1,
            ItemInPage: 25,
            AsOfDate: date,
        });
    }

    async GetTrusteeCancelationsByParamsAsync(params: Partial<PagedTableReqParams>) {
        return await equityPlansApi.get<TrusteeCancelationsRes>(this.endPoints.Cancelations(), params);
    }

    //      Grant Letter

    async GetTrusteeGrantDocumentsAsync(status: GrantDocumentStatus) {
        return await equityPlansApi.get<GrantDocumentsRes>(this.endPoints.GrantDocuments(), {
            DocumentType: status,
            PageNumber: 1,
            ItemInPage: 5000,
        });
    }

    async GetTrusteeGrantDocumentsByParamsAsync(params: Partial<PagedTableReqParams>) {
        return await equityPlansApi.get<GrantDocumentsRes>(this.endPoints.GrantDocuments(), params);
    }

    async GetSingleTrusteeGrantDocumentAsync(documentId: string) {
        return await equityPlansApi.get(this.endPoints.GrantLetter(), { DocumentId: documentId });
    }

    async SignTrusteeGrantDocuments(documentsIds: string[]) {
        return await equityPlansApi.post(this.endPoints.SignGrantDocuments(), { documents: documentsIds });
    }

    async GetTrusteeManagerReportsAsync() {
        return await equityPlansApi.get<ManagerReportsRes>(this.endPoints.Reports());
    }

    async GetSingleTrusteeReportAsync(params: TrusteeReportParams) {
        return await equityPlansApi.get<TrusteeReportRes>(this.endPoints.SingleReport(), params);
    }

    async PostTrusteeRequestAsync(data: TrusteeFileReq) {
        return await equityPlansApi.post(this.endPoints.SubmitFiles(), data);
    }

    async GetHistorySubmitFilesRequestAsync() {
        return await equityPlansApi.get<{ submitFiles: SubmitFileType[] }>(this.endPoints.GetHistorySubmitFiles());
    }

    async GetSubmitFileRequestAsync(KeyContactRequestId: string, submitFilesToTrusteeType: number) {
        return await equityPlansApi.get<SubmitFileToDownloadType>(`${this.endPoints.GetSubmitFile()}?KeyContactRequestId=${KeyContactRequestId}&SubmitFilesToTrusteeType=${submitFilesToTrusteeType}`);
    }

    // Grant Card
    async GetTrusteeGrantAndVestsAsync(grantId: string) {
        return await equityPlansApi.get<TrusteeGrantsRes>(this.endPoints.Grants(), {
            GrantId: grantId,
            AsOfDate: new Date().toISOString(),
        });
    }

    async GetTrusteeGrantOrdersAsync(grantId: string) {
        return await equityPlansApi.get<TrusteeOrdersRes>(this.endPoints.Orders(), {
            PageNumber: 1,
            ItemInPage: 5,
            GrantId: grantId,
            AsOfDate: new Date().toISOString(),
        });
    }

    async GetTrusteeGrantOrdersByParamsAsync(params: Partial<PagedTableReqParams>) {
        return await equityPlansApi.get<TrusteeOrdersRes>(this.endPoints.Orders(), params);
    }

    async GetTrusteeGrantCancelationsAsync(grantId: string) {
        return await equityPlansApi.get<TrusteeCancelationsRes>(this.endPoints.Cancelations(), {
            PageNumber: 1,
            ItemInPage: 5,
            GrantId: grantId,
            AsOfDate: new Date().toISOString(),
        });
    }

    async GetTrusteeGrantCancelationsByParamsAsync(params: Partial<PagedTableReqParams>) {
        return await equityPlansApi.get<TrusteeCancelationsRes>(this.endPoints.Cancelations(), params);
    }

    // Beneficiary Card
    async GetTrusteeBeneficiaryGrantsAsync(beneficiaryId: string) {
        return await equityPlansApi.get<TrusteeGrantsRes>(this.endPoints.Grants(), {
            PageNumber: 1,
            ItemInPage: 5,
            BeneficiaryId: beneficiaryId,
            AsOfDate: new Date().toISOString(),
        });
    }

    async GetTrusteeBeneficiaryGrantsByParamsAsync(params: Partial<PagedTableReqParams>) {
        return await equityPlansApi.get<TrusteeGrantsRes>(this.endPoints.Grants(), params);
    }

    async GetTrusteeBeneficiaryOrdersAsync(beneficiaryId: string) {
        return await equityPlansApi.get<TrusteeOrdersRes>(this.endPoints.Orders(), {
            PageNumber: 1,
            ItemInPage: 5,
            BeneficiaryId: beneficiaryId,
            AsOfDate: new Date().toISOString(),
        });
    }

    async GetTrusteeBeneficiaryOrdersByParamsAsync(params: Partial<PagedTableReqParams>) {
        return await equityPlansApi.get<TrusteeOrdersRes>(this.endPoints.Orders(), params);
    }

    async GetTrusteeBeneficiaryCancelationsAsync(beneficiaryId: string) {
        return await equityPlansApi.get<TrusteeCancelationsRes>(this.endPoints.Cancelations(), {
            PageNumber: 1,
            ItemInPage: 5,
            BeneficiaryId: beneficiaryId,
            AsOfDate: new Date().toISOString(),
        });
    }

    async GetTrusteeBeneficiaryCancelationsByParamsAsync(params: Partial<PagedTableReqParams>) {
        return await equityPlansApi.get<TrusteeCancelationsRes>(this.endPoints.Cancelations(), params);
    }

    async GetSaasMainDashboardTrusteeDataAsync() {
        return await equityPlansApi.get<TrusteeMainSaaSDashboardRes>(this.endPoints.MainDashboard());
    }

    async PostTerminationTerminationAsync(requestId: string) {
        return await equityPlansApi.post<{ status: boolean }>(this.endPoints.TerminationTermination(), { requestId })
    }
}
