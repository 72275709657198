import { FC, ReactNode } from "react";
import { Wrapper } from "./FundingDashboard.style";
import DoughnutChart from "../shared/components/DoughnutChart/DoughnutChart";
import PricePerShareIcon from "../shared/assets/icons/exchange_s_token_arrows.svg";
import PostRoundValuationIcon from "../shared/assets/icons/pie_chart.svg";
import { chartSettingsByOrder, defaultColors } from "../shared/components/DoughnutChart/DoughnutChart.style";
import WidgetDashboard from "../shared/components/WidgetDashboard/WidgetDashboard";
import PostRoundWidget from "./components/PostRoundWidget/PostRoundWidget";
import PreRoundWidget from "./components/PreRoundWidget/PreRoundWidget";

import ChartLegend from "../shared/components/ChartLegend/ChartLegend";
import SingleFigureWidget from "./components/SingleFigureWidget/SingleFigureWidget";
import { toJS } from "mobx";

export enum DashboardPreRoundEnum {
    'Outstanding options' = 1,
    'Unallocated options' = 2,
    'Current shareholders' = 3,
}

export enum DashboardPostRoundEnum {
    'Outstanding options' = 1,
    'Unallocated options' = 2,
    'Current shareholders' = 3,
    'New investors' = 4,
    'Converted SAFE / Note holders' = 5,
}

type PreFundingDashboardList = {
    dashboardPreRoundType: DashboardPostRoundEnum;
    percent: number;
    shares: number;
};

type PostFundingDashboardList = {
    dashboardPostRoundType: DashboardPostRoundEnum;
    percent: number;
    shares: number;
};

export type FundingDashboardData = {
    postRoundValuation: number | null;
    pricePerShare: number | null;
    preFundingDashboardList: PreFundingDashboardList[];
    postFundingDashboardList: PostFundingDashboardList[];
};

interface FundingDashboardProps {
    data: FundingDashboardData;
    onToggle?: (val: boolean) => void;
}

export interface FundingWidgetProps {
    title: string;
    chartNode: ReactNode;
    legendNode: ReactNode;
    isNoData: boolean;
}

const FundingDashboard: FC<FundingDashboardProps> = (props: FundingDashboardProps) => {
    const { data } = props;

    const sortedPostItems = data.postFundingDashboardList.sort((a, b) => {
        const aObj = chartSettingsByOrder.find((obj) => obj.key === a.dashboardPostRoundType);
        const bObj = chartSettingsByOrder.find((obj) => obj.key === b.dashboardPostRoundType);

        if (!aObj?.postOrder || !bObj?.postOrder) return 0;

        return aObj.postOrder - bObj.postOrder;
    });

    const sortedPreItems = data.preFundingDashboardList.sort((a, b) => {
        const aObj = chartSettingsByOrder.find((obj) => obj.key === a.dashboardPreRoundType);
        const bObj = chartSettingsByOrder.find((obj) => obj.key === b.dashboardPreRoundType);

        if (!aObj?.preOrder || !bObj?.preOrder) return 0;

        return aObj.preOrder - bObj.preOrder;
    });

    const preRoundWidgetLabels = sortedPreItems.map(
        (item: PreFundingDashboardList) => DashboardPreRoundEnum[item.dashboardPreRoundType]
    );
    const preRoundWidgetFigures = sortedPreItems.map((item: PreFundingDashboardList) =>
        Number(item.percent.toFixed(2))
    );
    const preRoundWidgetTooltipFigures = sortedPreItems.map((item: PreFundingDashboardList) =>
        Number(item.shares.toFixed(2))
    );
    const preRoundWidgetNoData = sortedPreItems.length <= 0;

    const postRoundWidgetLabels = sortedPostItems.map(
        (item: PostFundingDashboardList) => DashboardPostRoundEnum[item.dashboardPostRoundType]
    );
    const postRoundWidgetFigures = sortedPostItems.map((item: PostFundingDashboardList) =>
        Number(item.percent.toFixed(2))
    );
    const postRoundWidgetTooltipFigures = sortedPostItems.map((item: PostFundingDashboardList) =>
        Number(item.shares.toFixed(2))
    );
    const postRoundWidgetNoData = sortedPostItems.length <= 0;

    const postColors = sortedPostItems.map(
        (item) => chartSettingsByOrder.find((sett) => sett.key === item.dashboardPostRoundType)?.value ?? ""
    );
    const preColors = sortedPreItems.map(
        (item) => chartSettingsByOrder.find((sett) => sett.key === item.dashboardPreRoundType)?.value ?? ""
    );

    return (
        <WidgetDashboard onToggle={props.onToggle}>
            <Wrapper>
                <PreRoundWidget
                    title="Pre-round (fully diluted)"
                    chartNode={
                        <DoughnutChart
                            ap={{
                                labels: preRoundWidgetLabels,
                                figures: preRoundWidgetFigures,
                                tooltipFigures: preRoundWidgetTooltipFigures,
                                colors: preColors,
                                capTablePie: true
                            }}
                        />
                    }
                    legendNode={
                        <ChartLegend
                            labels={preRoundWidgetLabels}
                            figures={preRoundWidgetFigures}
                            colors={defaultColors}
                        />
                    }
                    isNoData={preRoundWidgetNoData}
                />

                <PostRoundWidget
                    title="Post-round (fully diluted)"
                    chartNode={
                        <DoughnutChart
                            ap={{
                                labels: postRoundWidgetLabels,
                                figures: postRoundWidgetFigures,
                                tooltipFigures: postRoundWidgetTooltipFigures,
                                colors: postColors,
                                capTablePie: true
                            }}
                        />
                    }
                    legendNode={
                        <ChartLegend
                            labels={postRoundWidgetLabels}
                            figures={postRoundWidgetFigures}
                            colors={postColors}
                        />
                    }
                    postRoundValuationWidget={
                        <SingleFigureWidget
                            ap={{
                                iconPath: PostRoundValuationIcon,
                                label: "Post-round valuation",
                                figure: `$${data.postRoundValuation ? data.postRoundValuation.toLocaleString() : "0"}M`,
                            }}
                        />
                    }
                    pricePerShareWidget={
                        <SingleFigureWidget
                            ap={{
                                iconPath: PricePerShareIcon,
                                label: "Price per share",
                                figure: `$${data.pricePerShare ? data.pricePerShare.toLocaleString() : "0"}`,
                            }}
                        />
                    }
                    isNoData={postRoundWidgetNoData}
                />
            </Wrapper>
        </WidgetDashboard>
    );
};

export default FundingDashboard;
