import { makeAutoObservable } from 'mobx';
import { GetGrantsFilterBy } from '../Models/API/enums';
import { BenTable, IBeneficiary, INewBeneficiary } from '../Models/App/EquityPlans/Beneficiaries';
import { CompanyCustomData, ICompanyDetails, IEquityPlan, ILog, IManualVestingSchedule, IPool } from '../Models/App/EquityPlans/Company';
import { CustomDataTypes } from '../Models/App/EquityPlans/equityEnums';
import { EntityTrackHistoryType, EntityType, IDashboardData, MultiType } from '../Models/App/EquityPlans/General';
import { IGrantCancelation } from '../Models/App/EquityPlans/GrantCancelations';
import { IBaseGrant, IModifiedGrant } from '../Models/App/EquityPlans/GrantEvents';
import { BenGrant, GrantTable, IGrant } from '../Models/App/EquityPlans/Grants';
import { IOrder, OrderType } from '../Models/App/EquityPlans/Order';
import { EntityHistoryUpdate } from '../Models/App/EquityPlans/reqModel/general';
import { EntitiyHistoryRecord } from '../Models/App/EquityPlans/resModel/general';
import EquityPlansService from '../Services/EquityPlansService';
import { nulldefined } from '../Shared/Interfaces/global';
import { isNullOrUndefined } from '../Shared/Utilities';
import { RootStore } from './RootStore';
// import { VestStep } from "../Screens/ESOP/Sections/Settings/VestingSchedule/NewVestingSchedule/NewVestingSchedule";
import { makePersistable } from 'mobx-persist-store';
import { NumberOption } from '../Models/API/All/NumberOption';
import { GrantIsoDataResponseType } from '../Models/API/EquityPlans/IsoNso';
import { GrantsTableTab } from '../Models/App/EquityPlans/GrantTableTabs';
import { ConvertPromisedRequestType, IPromisedTable, PromisedType } from '../Models/App/EquityPlans/Promised';
import importFields from '../Screens/ESOP/Sections/ImportFromExcel/helpers/fields';
import { InfoTypeEnum, InfoTypes } from '../Screens/ESOP/Sections/ImportFromExcel/helpers/types';
import { UpdatePlanRequest } from '../Models/App/EquityPlans/resModel/update-plan';
import { EditablePoolStep } from '../Models/App/EquityPlans/pool-step';
import { PoolStep } from '../Models/API/EquityPlans/pool-step';

interface ICompany {
	companyId: number;
}

interface ImportConfig {
	type: InfoTypes;
	originalData: Array<string[]>;
	// customData: Array<string[]> | undefined;
	headlineIndex: number;
	rowIndex: number;
	columnIndex: number;
}

export default class EquityPlansStore {
	constructor(private rootStore: RootStore) {
		makeAutoObservable(this);
		makePersistable(this, {
			name: 'EquityPlansStore',
			properties: ['selectedPlan', 'companyData'],
			storage: window.sessionStorage,
			expireIn: 10800000, // 3 hours
		});
		rootStore.companyStore.onCompanyChange.subscribe(this.initServices);
		this._dataAsOfDate = new Date();
	}

	equityPlansService: EquityPlansService = null as any;

	private initServices = () => {
		if (this.rootStore.companyStore.companyId) {
			this.resetStore();

			this.equityPlansService = new EquityPlansService(this.rootStore.companyStore.companyId);

			RootStore.subscribeToLoading([], this.rootStore);
		}
	};

	resetStore() {
		this.companyBeneficiaries = [];
		this.dataAsOfDate = new Date();
		this.selectedPlan = 0;
		this.companyData = undefined;
		this.companyBeneficiaries = [];
		this.companyGrants = undefined;
		this.companyOrders = undefined;
		this.companyCancelations = undefined;
		this.beneficiariesData = null;
		this.equityDashboard = {} as IDashboardData;
		this.isRegistrationCompleted = false;
		this.selectedBeneficiaryCard = null;
		this.poolSteps = undefined;
		this.isAddingPoolStep = false;
	}

	// Company

	_dataAsOfDate: Date;
	selectedPlan: number | undefined = 0;
	companyData: ICompanyDetails | nulldefined = null;
	companyBeneficiaries: BenTable[] | nulldefined = null;
	companyGrants: GrantTable[] | nulldefined = null;
	companyOrders: IOrder[] | nulldefined = null;
	companyCancelations: IGrantCancelation[] | nulldefined = null;
	beneficiariesData: IBeneficiary[] | null = null;
	dataLog: ILog[] | nulldefined = null;
	equityDashboard: IDashboardData = {} as IDashboardData;
	isRegistrationCompleted: boolean = false;
	isAddingPoolStep: boolean = false;
	poolSteps: PoolStep[] | undefined = undefined;

	newPool: Partial<IPool> = {};
	// equityPlan: IEquityPlanTemp | undefined;
	newVestSchedule: Partial<IManualVestingSchedule> = {};

	selectedTab: GrantsTableTab = GrantsTableTab.Grants;

	get tempCompanyId() {
		return this.rootStore.companyStore.companyId;
	}

	get companyActiveVests() {
		return this.companyData?.vestings
			?.filter((vst) => vst.active === true)
			.map((aVst) => {
				return { label: aVst.vestingName, value: aVst.vestingId };
			});
	}

	get companyPlans(): NumberOption[] {
		const plans =
			this.companyData?.plans?.map((pln) => {
				return { label: pln.name, value: pln.planId };
			}) || [];
		plans?.push({ label: 'All', value: 0 });
		return plans;
	}

	get dataAsOfDate() {
		return this._dataAsOfDate;
	}

	set dataAsOfDate(date: Date) {
		this._dataAsOfDate = date;
	}

	// Beneficiary
	beneficiaryData: IBeneficiary = {} as IBeneficiary;
	// newBeneficiary: Partial<INewBeneficiary> = {};
	beneficiaryGrants: GrantTable[] | null = null;
	beneficiaryGrantData: Partial<IGrant> = {};
	beneficiaryOrders: IOrder[] | nulldefined = null;
	benficiaryGrantCancelations: IGrantCancelation[] | nulldefined = null;
	benGrantCancelation: IGrantCancelation | nulldefined = null;
	selectedBeneficiaryCard: BenTable | nulldefined = null;

	//  Grant
	grant: IGrant | null = null;
	newGrant: Partial<IGrant> = {};
	grantOrders: IOrder[] = [{}] as IOrder[];
	grantCancelations: IGrantCancelation[] | nulldefined = null;

	//  Grant Events
	originalGrantEvents: IBaseGrant[] | nulldefined = null;
	modifiedGrantEvents: IModifiedGrant[] | nulldefined = null;

	//  Orders
	newOrder: Partial<IOrder> = {};
	newGrantCancelation: Partial<IGrantCancelation> = {};
	beneficiaryOrder: IOrder = {} as IOrder;

	// History
	historyTrackRecords: EntitiyHistoryRecord[] | undefined = [];

	// Import from excel
	importConfig: ImportConfig = {} as ImportConfig;

	// ISO
	isoTableData: GrantIsoDataResponseType | nulldefined = null;

	setImportType(type: InfoTypes) {
		this.importConfig.type = type;
	}

	setImportData(data: Array<string[]>) {
		this.importConfig.originalData = data;
	}

	get getSelectedBenRowData() {
		return this.selectedBeneficiaryCard;
	}

	set setSelectedBenRowData(ben: BenTable) {
		this.selectedBeneficiaryCard = ben;
	}

	get customImportData() {
		return [
			...this.importConfig.originalData.slice(0, this.importConfig.headlineIndex),
			...this.importConfig.originalData.slice(this.importConfig.headlineIndex + 1),
		];
	}

	get importFields() {
		if (!this.importConfig.type) return [];
		return importFields[InfoTypeEnum[this.importConfig.type]];
	}

	// selectable options

	// chcek
	get beneficiariesList() {
		if (!this.companyBeneficiaries) return [];
		return this.companyBeneficiaries?.map((ben) => {
			return {
				label: `${ben.fullName.firstName} ${ben.fullName.lastName}, Emp. Number: ${ben.employeeNumber}`,
				value: ben.beneficiaryId,
			};
		});
	}

	get beneficiariesFullName() {
		if (!this.companyBeneficiaries) return [];
		return this.companyBeneficiaries?.map((ben) => {
			return {
				fullName: ben.fullName,
				value: ben.beneficiaryId,
			};
		});
	}

	get beneficiaryGrantList() {
		return (
			this.beneficiaryGrants?.map((grt) => {
				return { label: grt.grantNumber, value: grt.grantId };
			}) ?? []
		);
	}

	get beneficiaryGrantsWithTotalRow() {
		if (!this.beneficiaryGrants) return [];

		let grantsList = this.beneficiaryGrants;

		const totalRow: GrantTable = this.beneficiaryGrants.reduce(
			(accumulator, currentObject) => {
				accumulator.granted += currentObject.granted || 0;
				accumulator.exercisable += currentObject.exercisable || 0;
				accumulator.unvested += currentObject.unvested || 0;
				accumulator.outstanding += currentObject.outstanding || 0;

				Object.keys(currentObject).forEach((key) => {
					if (!['granted', 'exercisable', 'unvested', 'outstanding'].includes(key)) {
						(accumulator[key as keyof GrantTable] as unknown) = null;
					}
				});

				return accumulator;
			},
			{
				granted: 0,
				exercisable: 0,
				unvested: 0,
				outstanding: 0,
				grantId: 0,
				planId: 0,
				beneficiaryId: 0,
				grantTemplateId: 0,
				fullName: { firstName: '', lastName: '' },
				employeeNumber: '',
				planName: '',
				grantType: 0,
				grantDate: new Date(),
				experationDate: new Date(),
				vested: 0,
				isSigned: false,
				isSignedLetterForEdit: false,
				grantNumber: '',
				exercisePrice: 0,
				vestStartDate: new Date(),
				vestSchedule: '',
				taxTrack: 0,
				exercised: 0,
				sellable: 0,
				sold: 0,
				canceled: 0,
				originalExpirationDate: new Date(),
				exercisePriceCurrency: 0,
				retentionDate: new Date(),
				documentDetailsDto: null,
				sourceContactId: 0,
			} as GrantTable
		);

		grantsList.push(totalRow);

		return this.beneficiaryGrants;
	}

	get sites() {
		return (
			this.companyData?.sites?.map((sit) => {
				return { value: sit.key, label: sit.value };
			}) ?? []
		);
	}

	get departments() {
		return (
			this.companyData?.departments?.map((dep) => {
				return { value: dep.key, label: dep.value };
			}) ?? []
		);
	}

	get subDepartments() {
		return (
			this.companyData?.subDepartments?.map((dep) => {
				return { value: dep.key, label: dep.value };
			}) ?? []
		);
	}

	get taxTracks() {
		return (
			this.companyData?.taxTracks?.map((dep) => {
				return { value: dep.key, label: dep.value };
			}) ?? []
		);
	}

	//Promised
	promisedRows: IPromisedTable[] = [];

	//  Company

	async CreateNewCompany(isAutoGenerated: boolean = false) {
		const res = await this.equityPlansService.CreateNewCompany(this.tempCompanyId, isAutoGenerated);
		if (res.isSuccess && this.companyData) {
			this.companyData.autoGenerateGrantNumber = isAutoGenerated;
		}
		return !isNullOrUndefined(res);
	}

	async GetBeneficiariesDashboardData() {
		const res = await this.equityPlansService.GetEquityDashboardBeneficiaries(this.tempCompanyId, this.dataAsOfDate, this.selectedPlan);
		// console.log("dashboard data", res);
		if (res.data) {
			this.companyBeneficiaries = res.data;
			this.GetDashboardData();
		}
	}

	async getEquitySettings() {
		const { data } = await this.equityPlansService.getEquitySettings(this.tempCompanyId);
		const isFullyRegistered = !!(data?.plans?.length && data.vestings?.length && data.taxTracks?.length);
		this.isRegistrationCompleted = isFullyRegistered;
		this.companyData = data;
		if (isNullOrUndefined(this.selectedPlan)) this.selectedPlan = 0; //data?.plans?.[0].planId;
		return data;
	}

	async GetDataLog(date: Date | string) {
		const { data } = await this.equityPlansService.GetCompanyLog(this.tempCompanyId, date);
		this.dataLog = data;
	}

	// async GetBeneficiariesForCompany() {
	// 	this.companyBeneficiaries = await this.equityPlansService.GetCompanyBeneficiaries(this.tempCompanyId);
	// }

	async GetGrantsForCompany(companyId = this.tempCompanyId) {
		const res = await this.equityPlansService.GetGrants(companyId, undefined, undefined, this.dataAsOfDate, this.selectedPlan);
		if (res.data) {
			this.companyGrants = res.data;
			this.GetDashboardData();
		}
	}

	async GetGrantCancelationsForCompany() {
		const res = await this.equityPlansService.GetGrantCancelations(
			this.tempCompanyId,
			GetGrantsFilterBy.Company,
			undefined,
			undefined,
			this.dataAsOfDate,
			this.selectedPlan
		);
		this.companyCancelations = res?.data;
	}

	// async AddLegalEntityToCompany(legalEntityName: string) {
	// 	console.log("Adding Legal entity", legalEntityName);
	// 	const data = await this.equityPlansService.CreateLegalEntity(this.tempCompanyId, 0, legalEntityName);
	// 	console.log("AddDepartmentToCompany", data);
	// }

	// async UpdateCompanyLegalEntity(companyId = this.tempCompanyId, legalEntityId: number, newName: string) {
	// 	await this.equityPlansService.UpdateLegalEntity(companyId, legalEntityId, newName);
	// }

	// Department

	async AddDepartmentToCompany(depName: string) {
		const data = await this.equityPlansService.CreateDepartment(this.tempCompanyId, 0, depName);
		return data;
	}

	async UpdateCompanyDepartment(departmentId: number, newName: string) {
		await this.equityPlansService.UpdateDepartment(this.tempCompanyId, departmentId, newName);
	}

	async AddSubDepartmentToCompany(depName: string) {
		const data = await this.equityPlansService.CreateDepartment(this.tempCompanyId, 1, depName);
		return data;
	}

	async DeleteDepartment(departmentId: number) {
		const res = await this.equityPlansService.DeleteDepartment(departmentId, this.tempCompanyId, false);
		if (res) {
			this.getEquitySettings();
		}
		return res;
	}

	async DeleteSubDepartment(departmentId: number) {
		const res = await this.equityPlansService.DeleteDepartment(departmentId, this.tempCompanyId, true);
		if (res) {
			this.getEquitySettings();
		}
		return res;
	}

	// Site

	async AddSiteToCompany(siteName: string) {
		return await this.equityPlansService.CreateSite(this.tempCompanyId, siteName);
	}

	async DeleteSite(siteId: number) {
		const res = await this.equityPlansService.DeleteSite(this.tempCompanyId, siteId);

		if (res) {
			this.getEquitySettings();
		}
		return res;
	}

	// Tax Track

	async AddTaxTrackToCompany(name: string) {
		const res = await this.equityPlansService.CreateTaxTrack(this.tempCompanyId, name);
		return res;
	}

	async DeleteTaxTrack(taxTrackId: number) {
		const res = await this.equityPlansService.DeleteTaxTrack(taxTrackId, this.tempCompanyId);

		if (res) {
			this.getEquitySettings();
		}
		return res;
	}

	// async UpdateCompanySubDepartment(companyId = this.tempCompanyId, subDepartmentId: number, newName: string) {
	// 	await this.equityPlansService.UpdateSubDepartment(companyId, subDepartmentId, newName);
	// }

	async getEquityPlan(planId: number) {
		return this.equityPlansService.GetSinglePlan(planId, this.tempCompanyId);
	}

	async getPlanPoolSteps(planId: number) {
		const res = await this.equityPlansService.getPlanPoolSteps(planId);
		if (res.data) {
			this.setPoolStepsByDate(res.data);
		}
	}

	async addPoolStep(poolStep: EditablePoolStep) {
		const res = await this.equityPlansService.addPoolStep(poolStep);
		if (res.data) {
			this.setPoolStepsByDate(res.data);
		}
		return res;
	}

	async updatePoolStep(poolStep: EditablePoolStep, poolStepId: number) {
		const res = await this.equityPlansService.updatePoolStep(poolStep, poolStepId);
		if (res.data) {
			this.setPoolStepsByDate(res.data);
		}
		return res;
	}

	async deletePoolStep(poolStepId: number, planId: number) {
		const res = await this.equityPlansService.deletePoolStep(poolStepId, planId);
		this.setPoolStepsByDate(res.data);
		return res;
	}

	private setPoolStepsByDate(poolSteps?: PoolStep[]) {
		this.poolSteps = poolSteps?.sort((a, b) => new Date(b.stepDate).getTime() - new Date(a.stepDate).getTime());
	}

	async addEquityPlan(data: IEquityPlan) {
		// await create pool
		if (!data.poolParams) data.poolParams = { stepType: 0 };

		const plan = { ...data, companyId: this.tempCompanyId };
		if (!plan.poolParams?.amount) return;
		plan.poolParams.boardApprovalDate = plan.planApprovalDateByBoard;
		if (plan.poolParams?.boardApprovalDate) plan.poolParams.date = plan.poolParams.boardApprovalDate;

		const res = await this.equityPlansService.createNewPlan(plan);
		if (res.data?.planId) {
			this.getEquitySettings();
		}

		return res;
	}

	async deleteEquityPlan(planId: number) {
		const res = await this.equityPlansService.deletePlan(planId, this.tempCompanyId);
		res.data && this.getEquitySettings();
		return res;
	}

	async updateEquityPlan(planId: number, data: UpdatePlanRequest) {
		const updtdPlan = { ...data, companyId: this.tempCompanyId };
		const res = await this.equityPlansService.updatePlan(planId, updtdPlan);

		if (res.data && this.companyData) {
			const pIdx = this.companyData.plans.findIndex((plan) => plan.planId === planId);
			this.companyData = {
				...this.companyData,
				plans: [...this.companyData.plans.slice(0, pIdx), res.data.plan, ...this.companyData.plans.slice(pIdx + 1)],
			};
		}

		return res;
	}

	async createPoolForPlan(pool: IPool) {
		const fPool = { pool, companyId: this.tempCompanyId };
	}

	AddVestingSchedule() {
		return this.equityPlansService.AddVestingSchedule({ ...this.newVestSchedule, companyId: this.tempCompanyId });
	}

	async AddRemoveVestFromCompany(add: boolean, vestingId: number) {
		if (add) {
			return await this.equityPlansService.ConnectVestToCompany({
				companyId: this.tempCompanyId,
				params: [{ companyId: this.tempCompanyId, vestingId }],
			});
		} else {
			return await this.equityPlansService.DisConnectVestFromCompany(vestingId, this.tempCompanyId);
		}
	}

	// setVestingScheule(vesting: Partial<IVestingSchedule>) {
	// 	this.newVestSchedule = vesting;
	// }

	// dashboard

	async GetDashboardOrders() {
		const data = await this.equityPlansService.GetCompanyOrders(this.tempCompanyId, this.dataAsOfDate, this.selectedPlan);
		this.companyOrders = data.data;
	}

	async GetDashboardData() {
		const res = await this.equityPlansService.GetDashboardData(this.tempCompanyId, this.dataAsOfDate, this.selectedPlan);
		if (res.data) {
			this.equityDashboard = res.data;
		}
	}

	//  Beneficiary
	async GetBeneficiary(benficiaryId: number, planId?: number) {
		const data = await this.equityPlansService.GetBeneficiary(this.tempCompanyId, benficiaryId, this.dataAsOfDate, planId || 0);
		this.beneficiaryData = data.data;
	}

	async GetGrantsForBeneficiary(beneficiaryId: number) {
		const data = await this.equityPlansService.GetBeneficiaryGrants(beneficiaryId, this.tempCompanyId);
		this.beneficiaryGrants = data.data;
	}

	async GetOrdersForBeneficiary(beneficiaryId: number) {
		const data = await this.equityPlansService.GetOrders(GetGrantsFilterBy.Contact, this.tempCompanyId, beneficiaryId);
		this.beneficiaryOrders = data.data;
	}

	async GetCancelationsForBeneficiary(beneficiaryId: number) {
		const data = await this.equityPlansService.GetGrantCancelations(this.tempCompanyId, GetGrantsFilterBy.Contact, beneficiaryId, undefined);
		this.benficiaryGrantCancelations = data.data;
	}

	async GetGrantCancelation(cancelId: number) {
		const data = await this.equityPlansService.GetSingleGrantCancelation(this.tempCompanyId, cancelId);
		this.benGrantCancelation = data.data;
	}

	async UpdateBeneficiary(benficiaryId: number, data: IBeneficiary) {
		const benData = { ...data };
		const res = await this.equityPlansService.UpdateBeneficiary(this.tempCompanyId, benficiaryId, benData);
		// this.beneficiaryData = data
		if (res.data?.beneficiaryId) {
			this.GetBeneficiariesDashboardData();
		}
		return res;
	}

	async UpdateBeneficiaryCCD(
		companyId: number,
		beneficiaryId: number,
		data: CompanyCustomData,
		dataType: CustomDataTypes,
		startDate: string | Date,
		endDate?: string | Date
	) {
		const fData = { ...data, date: startDate };
		this.equityPlansService.SetBeneficiaryCCD(this.tempCompanyId, beneficiaryId, fData, dataType, startDate, endDate);
	}

	async UpdateMultipleBeneficiaries(uType: MultiType, ids: number[]) {
		return await this.equityPlansService.UpdateMultipleBeneficiaries(uType, ids, this.tempCompanyId);
	}

	async UpdateBeneficiaryEntityHistory(eType: EntityTrackHistoryType, ccData: CompanyCustomData, isModify: boolean, updateGrants: boolean) {
		const data: EntityHistoryUpdate = {
			entityTrackHistoryType: eType,
			entityTrackParams: ccData,
			isModify,
			updateGrants,
		};
		const res = this.equityPlansService.SetBeneficiaryEntityHistory(this.tempCompanyId, this.beneficiaryData.beneficiaryId, data);
	}

	checkIfUserExist(employeeNumber?: string) {
		if (isNullOrUndefined(employeeNumber)) return { data: true };
		return this.equityPlansService.CheckIfUserExists(this.tempCompanyId, employeeNumber);
	}

	async AddBeneficiary(beneficiary: INewBeneficiary) {
		const newBen = { ...beneficiary, companyId: this.tempCompanyId };
		const res = await this.equityPlansService.CreateBeneficiary(newBen);

		await this.GetBeneficiariesDashboardData();
		return res;
	}

	async AddGrantToBeneficiary() {
		const benId = this.beneficiaryData.beneficiaryId;
		const companyId = this.beneficiaryData.companyId;

		const fulldata = {
			...this.newGrant,
			beneficiaryId: benId,
			companyId,
			exersicePrice: this.newGrant.exercisePrice,
		};
		const res = await this.equityPlansService.CreateSingleGrant(fulldata);

		if (res.data?.beneficiaryId) {
			this.GetGrantsForBeneficiary(benId);
			this.GetBeneficiariesDashboardData();
			this.GetGrantsForCompany();
			this.GetBeneficiary(benId);
		}
		return res;
	}

	async DeleteBeneficiary() {
		this.equityPlansService.RemoveBeneficiary(this.beneficiaryData.beneficiaryId, this.beneficiaryData.companyId);
		this.GetBeneficiariesDashboardData();
	}

	async DeleteBeneficiary_Qa(beneficiaryId: number) {
		return await this.equityPlansService.RemoveBeneficiary(beneficiaryId, this.tempCompanyId);
	}

	async InviteToEmployeePortal(sourceContactId: number) {
		// if (!this.beneficiaryData.businessEmail) return;
		return await this.equityPlansService.InviteToEmployeeManagment(sourceContactId, this.tempCompanyId);
	}

	//  Grant

	ResetGrant() {
		this.newGrant = {};
	}

	ValidateGrant() {
		return this.equityPlansService.ValidateGrant({
			...this.beneficiaryGrantData,
			companyId: this.tempCompanyId,
		});
	}

	CheckIfGrantNumberExists(gNumber = this.newGrant.grantNumber!) {
		return this.equityPlansService.CheckIfGrantNumberExists(this.tempCompanyId, gNumber);
	}

	async CheckIso(grant: any = this.newGrant) {
		return await this.equityPlansService.CheckIsoReq({
			// ...this.newGrant,
			...grant,
			companyId: this.tempCompanyId,
		});
	}

	async GetGrantIsoData(grantId: number) {
		const res = await this.equityPlansService.GetGrantIsoDataReq(grantId, this.tempCompanyId);
		this.isoTableData = res.data;
	}

	async CheckEditISOGrant(grant: Partial<IGrant>) {
		return await this.equityPlansService.CheckEditISOGrantReq({ ...grant }, this.tempCompanyId);
	}

	async CreateGrant() {
		const res = await this.equityPlansService.CreateGrant({
			...this.newGrant,
			companyId: this.tempCompanyId,
		});
		return res;
	}

	async UpdateGrant() {
		const data = { ...this.beneficiaryGrantData };
		const res = await this.equityPlansService.UpdateBenGrant(data, this.tempCompanyId);

		if (res.data) this.GetGrantsForCompany();
		return res;
	}

	async DeleteGrant(grantId: number) {
		const res = await this.equityPlansService.DeleteGrant(grantId, this.tempCompanyId);
		if (res.data === true) this.GetGrantsForCompany();
		return res;
	}

	async CreateSingleGrant() {
		const data = this.beneficiaryGrantData as BenGrant;
		const fullData = {
			...data,
			companyId: this.tempCompanyId,
			contactId: 2759,
			vestingId: 1,
			site: { key: 5, value: 'The Lame Site', companyId: this.tempCompanyId },
			expirationDate: new Date(),
		};
		const res = await this.equityPlansService.CreateSingleGrant(fullData);
	}

	async GetSingleGrant(grantId: number) {
		const res = await this.equityPlansService.GetGrant(grantId, this.tempCompanyId);
		if (res.data) {
			this.beneficiaryGrantData = res.data;
		}
	}

	async GetOrdersForSingleGrant(beneficiaryId: number, grantId: number) {
		const data = await this.equityPlansService.GetOrders(2, this.tempCompanyId, beneficiaryId, grantId);
		this.grantOrders = data.data;
	}

	async GetCancelationOrdersForSingleGrant(beneficiaryId: number, grantId: number) {
		const res = await this.equityPlansService.GetGrantCancelations(this.tempCompanyId, GetGrantsFilterBy.Grant, beneficiaryId, grantId);
		this.grantCancelations = res.data;
	}

	async ValidateExpDate(granted: number, grantDate: Date, vestingStartDate: Date, vestingId: number) {
		return await this.equityPlansService.GetLastVestDate(granted, grantDate, vestingStartDate, vestingId, this.tempCompanyId);
	}

	// Order

	async TypesAndAmountsForOrder(grantId: number, date: Date) {
		return await this.equityPlansService.GetAmountsAndOrderTypes(this.tempCompanyId, grantId, date);
	}

	async ValidateOrderAmount(grantId: number, asOfDate: Date, orderType: OrderType) {
		return await this.equityPlansService.GetAmountForOrderType(this.tempCompanyId, grantId, asOfDate, orderType);
	}

	async CreateNewOrderForBeneficiary(grantId: number) {
		const data = {
			...this.newOrder,
			companyId: this.tempCompanyId,
			grantId: grantId,
			contactId: this.beneficiaryData.beneficiaryId,
		};
		const res = await this.equityPlansService.CreateNewGrantOrder(data);
		if (res.data?.grantId) {
			this.GetOrdersForBeneficiary(data.contactId);
		}
		return res;
	}

	async CreateNewOrder(grantId: number, beneficiaryId: number) {
		const data = {
			...this.newOrder,
			companyId: this.tempCompanyId,
			grantId: grantId,
			contactId: beneficiaryId,
		};
		return await this.equityPlansService.CreateNewGrantOrder(data);
	}

	async CreateNewQaOrder(grantId: number, beneficiaryId: number, orderType: OrderType) {
		const data = {
			...this.newOrder,
			companyId: this.tempCompanyId,
			grantId: grantId,
			contactId: beneficiaryId,
			orderType,
		};
		return await this.equityPlansService.CreateNewGrantOrder(data);
	}

	async GetBeneficiaryOrder(orderId: number) {
		const res = await this.equityPlansService.GetOrderById(orderId, this.tempCompanyId);
		this.beneficiaryOrder = res.data;
	}

	ValidateTransaction(data: Partial<IOrder>) {
		return this.equityPlansService.ValidateTransaction(data);
	}

	//  Grant Cancelation

	async CancelSingleGrant(grantId: number, beneficiaryId?: number) {
		const data = {
			...this.newGrantCancelation,
			companyId: this.tempCompanyId,
			grantId,
			beneficiaryId,
		};

		const res = await this.equityPlansService.CreateGrantCancelation(data);
		// this.GetCancelationsForBeneficiary(data.contactId!);
		return res;
	}

	async CancelBeneficiaryGrant() {
		const data = {
			...this.newGrantCancelation,
			companyId: this.tempCompanyId,
		};

		const res = await this.equityPlansService.CreateGrantCancelation(data);
		// this.GetCancelationsForBeneficiary(data.contactId!);
		return res;
	}

	async TerminateEmployeeQa(beneficiaryId?: number) {
		const data = { ...this.newGrantCancelation };
		data.contactId = beneficiaryId;
		data.companyId = this.tempCompanyId;

		if (!data.companyId || !data.contactId) return;

		const res = await this.equityPlansService.CreateGrantCancelation(data);
		this.GetGrantsForCompany();
		this.GetGrantsForBeneficiary(data.contactId);
		return res;
	}

	async CancelGrantQA() {
		const data = { ...this.newGrantCancelation };
		data.contactId = this.beneficiaryGrantData.beneficiaryId;
		data.grantId = this.beneficiaryGrantData.grantId;
		data.companyId = this.tempCompanyId;

		if (!data.companyId || !data.contactId || !data.grantId) return;

		const res = await this.equityPlansService.CreateGrantCancelation(data);
		this.GetGrantsForCompany();
		return res;
	}

	//  Grant Events

	async GetOriginalGrantEvents(grantId: number) {
		const res = await this.equityPlansService.GetUnModifiedGrantEvents(this.tempCompanyId, grantId);
		this.originalGrantEvents = res.data;
	}

	// async GetModifiedGrantEvents(grantId: number) {
	// 	const data = await this.equityPlansService.GetModifiedGrantEvents(this.tempCompanyId, grantId);
	// 	this.modifiedGrantEvents = data;
	// 	console.log("Get_MODIFIED_GrantEvents data store", data);
	// 	// data?.forEach(vest => {
	// 	//   if (vest.vestingHistories) {
	// 	//     console.log('HISTORY', vest.vestingHistories)
	// 	//   }
	// 	// })
	// }

	//    General     ***

	async GetGrantTrackHistory(grantId: number, hstryType: EntityTrackHistoryType) {
		return await this.equityPlansService.GetTrackHistory(hstryType, EntityType.Grant, grantId, this.tempCompanyId);
	}

	async GetBeneficiaryTrackHistory(beneficiaryId: number, hstryType: EntityTrackHistoryType) {
		const res = await this.equityPlansService.GetTrackHistory(hstryType, EntityType.Beneficiary, beneficiaryId, this.tempCompanyId);
		this.historyTrackRecords = res.data;
	}

	async GetSaaSMainDashboardData() {
		return await this.equityPlansService.GetSaaSMainDashboardData(this.tempCompanyId);
	}

	async GetCfoLogEvents(page: number, size: number = 10) {
		if (!this.tempCompanyId) return { isSuccess: false, data: null };
		return await this.equityPlansService.GetCfoLogEvents(page, size);
	}

	async getBeneficiaryByContactId(contactId: number) {
		return this.equityPlansService.getBeneficiaryByContactId(this.tempCompanyId, contactId);
	}

	createGrantLetter(grantId: number, templateId: string, variablesCollection: string[] = []) {
		return this.equityPlansService.createGrantLetter(grantId, templateId, variablesCollection);
	}

	setSelectedTab = (tab: GrantsTableTab) => {
		this.selectedTab = tab;
	};

	async getPromisedsList() {
		const res = await this.equityPlansService.getPromisedsList(this.rootStore.companyStore.companyId, this.rootStore.equityPlansStore.selectedPlan || 0);
		let promisedArr: IPromisedTable[] = [];

		if (res.data) {
			promisedArr = res.data.map((promise) => {
				const currentBen = this.companyBeneficiaries?.find((ben) => ben.beneficiaryId === promise.beneficiaryId);
				return {
					...promise,
					fullName: {
						firstName: currentBen?.fullName.firstName || '',
						lastName: currentBen?.fullName.lastName || '',
						employeeNumber: currentBen?.employeeNumber || '',
					},
				};
			});
		}

		this.promisedRows = promisedArr;
	}

	createPromised(promised: PromisedType) {
		return this.equityPlansService.createPromised(promised);
	}

	editPromised(promised: PromisedType) {
		return this.equityPlansService.editPromised(promised);
	}

	deletePromised(promised: PromisedType) {
		return this.equityPlansService.deletePromised(promised);
	}

	convertPromisedToGrant(promised: ConvertPromisedRequestType) {
		return this.equityPlansService.convertPromisedToGrant(promised);
	}

	getUpcomingVestingEvents() {
		return this.equityPlansService.getUpcomingVestingEvents(this.tempCompanyId, this.selectedPlan || 0, this.dataAsOfDate);
	}

	getGrantStatusReport(filter: string) {
		return this.equityPlansService.getGrantStatusReport(this.tempCompanyId, filter);
	}

	getExerciseOrdersReport(filter: string) {
		return this.equityPlansService.getExerciseOrdersReport(this.tempCompanyId, filter);
	}

	getCancellationsReport(filter: string) {
		return this.equityPlansService.getCancellationsReport(this.tempCompanyId, filter);
	}

	getPoolStatusReport(filter: string) {
		return this.equityPlansService.getPoolStatusReport(this.tempCompanyId, filter);
	}

	getGrantsAndVestingsReport(filter: string) {
		return this.equityPlansService.getGrantsAndVestingsReport(this.tempCompanyId, filter);
	}
}
