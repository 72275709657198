import { IC_COMPANY_CIRCLE, IC_USER_CIRCLE } from '../../../Assets';
import { PreFundingShareClassHolderList } from '../../../Models/API/CapTable/financial-round-response';
import { OptionsPreviewInfo } from '../../../Models/API/CapTable/options-preview-info';
import { ShareClassHolderPreviewInfo } from '../../../Models/API/CapTable/share-class-holder-preview-info';
import { ShareClassInfo } from '../../../Models/API/CapTable/share-class-info';
import { PersonTypesEnum, ShareClassTypeOptions } from '../../../Models/API/enums';
import Clickable from '../../../Shared/Components/Clickable/Clickable';
import Flex from '../../../Shared/Components/Layout/Flex';
import OverflowText from '../../../Shared/Components/OverflowText';
import Tooltip from '../../../Shared/Components/Tooltip';
import { formatNumber, getPercentrage, getUniqueId, isNullOrUndefined } from '../../../Shared/Utilities';
import appConfig from '../../../config/config';
import { CapTableCell, CapTableColType, CapTableColumn, CapTableData, CapTableGenerateProps, CapTableShareClassType } from '../types';

const emptyCell = { value: '' };
const emptyCol = {
	colQaid: '',
	id: '',
	title: '',
	type: CapTableColType.commonShares,
	cells: [],
};

export const generateCapTableData = ({
	shareClassHolderList = [],
	personsList = [],
	shareClasses,
	optionsData,
	isExerciseExpanded,
	onEditShareholder,
	prevShareClassHolderList,
	prevOptionsData,
	editableClasses,
	safeTransactions = [],
	onShowExerciseList,
}: CapTableGenerateProps): CapTableData => {
	const shareholders = personsList.map((p) => p.personID ?? -1);
	// const shareholdersWithTransactions = filterShareholder(shareClassHolderList, !!strictMode);

	const commonClassId = shareClasses?.find((sc) => sc.shareClassTypeID === ShareClassTypeOptions.Common)?.shareClassID;

	const normalShareClasses = shareClasses?.filter(
		(sc) => sc.shareClassTypeID === ShareClassTypeOptions.Normal || sc.shareClassTypeID === ShareClassTypeOptions.Safe
	);

	const shareClassesColumns =
		normalShareClasses?.map((sc) => getShareClassCol(shareClassHolderList, prevShareClassHolderList, sc, shareholders, editableClasses)) || [];
	const totalBsa = calculateTotal(shareClassHolderList, 'numberOfBSA', commonClassId);
	const totalWarrants = calculateTotal(shareClassHolderList, 'numberOfWarrants', commonClassId);
	const totalOptions =
		shareClassHolderList.map((x) => x.options).reduce((x, y) => x + y, 0) + (optionsData?.unAllocated ?? 0) + (optionsData?.outstanding ?? 0);

	let totalExercisedList = 0;

	if (isExerciseExpanded) {
		totalExercisedList = optionsData?.exerciseDetails?.exercisesList?.reduce((acc, ex: any) => acc + ex.outstanding, 0) ?? 0;
	}

	// Share tables
	const totalOptionsAndWarrants =
		shareClassHolderList.reduce((acc, obj) => acc + obj.numberOfWarrants + obj.converted + obj.options + obj.numberOfBSA, 0) +
		(optionsData?.unAllocated ?? 0) +
		(optionsData?.outstanding ?? 0);

	const totalShares = shareClassHolderList.reduce((acc, obj) => acc + obj.numberOfSharesOutstanding, 0) + (optionsData?.exercise ?? 0);

	const commonColUniqueId = getUniqueId();

	const data: CapTableColumn[] = [
		{
			id: getUniqueId(),
			colQaid: 'CapTable.Column.Shareholders',
			title: 'Shareholders',
			type: CapTableColType.shareholders,
			isSearchable: true,
			cells: [
				...shareholders.map((personId) => {
					const p = personsList.find((person) => person.personID === personId);
					//  ||
					// (personId === -1
					// 	? { personID: -1, firstName: "W/O Others", lastName: "", personType: PersonTypesEnum.None, sourceUserID: 0 }
					// 	: undefined);

					if (isNullOrUndefined(p)) {
						return emptyCell;
					}

					let fullName =
						p.personType === PersonTypesEnum.LegalCompany ? p.firstName || '' : p.personID === -1 ? 'Unassigned' : `${p.firstName} ${p.lastName}`;

					const holder = shareClassHolderList.find((holder) => holder.personID === personId);

					const isSafeOnly =
						!(holder?.numberOfBSA || holder?.numberOfSharesOutstanding || holder?.numberOfWarrants || holder?.converted || holder?.options) &&
						safeTransactions.some((t) => t.projectPersonId === p.personID);

					if (isSafeOnly) fullName += ' (SAFE)';

					return {
						value: fullName,
						referenceId: p.personID,
						render: () =>
							onEditShareholder && p.personID !== -1 ? (
								<Clickable
									align="center"
									justify="start"
									gap="1.2rem"
									qaid="CapTable.Button.EditShareholder"
									className="sh-name"
									width="18rem"
									onClick={() => p.personID && onEditShareholder?.(p.sourceUserID, p.personID)}
								>
									<img
										src={p.personType === PersonTypesEnum.Person ? IC_USER_CIRCLE : IC_COMPANY_CIRCLE}
										alt={fullName}
										style={{ width: '3rem' }}
									/>
									<OverflowText>{fullName}</OverflowText>
								</Clickable>
							) : (
								<Flex align="center" justify="start" gap="1.2rem" className="text-ellipsis" width="18rem">
									<img
										src={p.personType === PersonTypesEnum.Person || p.personID === -1 ? IC_USER_CIRCLE : IC_COMPANY_CIRCLE}
										alt={fullName}
										style={{ width: '3rem' }}
									/>
									<OverflowText>{fullName}</OverflowText>
								</Flex>
							),
					};
				}),
				{
					value: 'Exercised options',
					render: () => (
						<div className="flex gap-1 align-center">
							<span>Exercised options</span>
							<Tooltip title="The exercised options amount includes only the option holders and vested RSU holders who are not defined as shareholders on the cap table." />
						</div>
					),
					onClick: onShowExerciseList,
				},
				{
					value: 'Outstanding options',
					render: () => (
						<div className="flex gap-1 align-center">
							<span>Outstanding options</span>
							<Tooltip title="The outstanding amount includes options and unvested RSU’s of holders which are not defined as shareholders on the cap table." />
						</div>
					),
				},
				{ value: 'Unallocated options' },
				{ value: 'Total' },
			],
		},
		{
			id: commonColUniqueId,
			colQaid: 'CapTable.Column.CommonShares',
			title: 'Common shares',
			subtitle: totalWarrants ? 'Shares' : undefined,
			type: CapTableColType.commonShares,
			shareClassType: CapTableShareClassType.shares,
			cells: [
				...shareholders.map((sh) =>
					getShareClassCellData(shareClassHolderList, prevShareClassHolderList, sh, commonClassId, 'numberOfSharesOutstanding')
				),
				{
					value: isExerciseExpanded ? '' : formatNumber(optionsData?.exercise),
				},
				...addEmptyCells(2),
				{
					value: formatNumber(calculateTotal(shareClassHolderList, 'numberOfSharesOutstanding', commonClassId, optionsData?.exercise)),
				},
			],
		},
		{
			...(totalWarrants
				? {
						id: commonColUniqueId,
						colQaid: 'CapTable.Column.CommonSharesWarrants',
						title: 'Common shares',
						subtitle: 'Warrants',
						type: CapTableColType.commonShares,
						shareClassType: CapTableShareClassType.warrants,
						cells: [
							...shareholders.map((sh) =>
								getShareClassCellData(shareClassHolderList, prevShareClassHolderList, sh, commonClassId, 'numberOfWarrants')
							),
							...addEmptyCells(1),
							...addEmptyCells(1),
							...addEmptyCells(1),
							{
								value: formatNumber(totalWarrants),
							},
						],
				  }
				: emptyCol),
		},
		{
			...(totalBsa
				? {
						id: commonColUniqueId,
						type: CapTableColType.commonShares,
						title: 'Common shares',
						subtitle: 'BSA',
						colQaid: 'CapTable.Column.CommonSharesBsa',
						shareClassType: CapTableShareClassType.bsa,
						cells: [
							...shareholders.map((sh) =>
								getShareClassCellData(shareClassHolderList, prevShareClassHolderList, sh, commonClassId, 'numberOfBSA')
							),
							...addEmptyCells(3),
							{
								value: formatNumber(totalBsa),
							},
						],
				  }
				: emptyCol),
		},
		{
			id: getUniqueId(),
			type: CapTableColType.options,
			title: 'Options',
			colQaid: 'CapTable.Column.Options',
			cells: [
				...shareholders.map((sh) => getShareClassCellData(shareClassHolderList, prevShareClassHolderList, sh, commonClassId, 'options')),
				emptyCell,
				{
					value: formatNumber((optionsData?.outstanding ?? 0) - (isExerciseExpanded ? totalExercisedList : 0)),
				},
				compareData(
					prevShareClassHolderList?.length ? prevOptionsData?.unAllocated : undefined,
					optionsData?.unAllocated,
					!!prevShareClassHolderList?.length || !!prevOptionsData?.unAllocated
				),
				// {
				// 	value: formatNumber(optionsData?.unAllocated),
				// 	render: prevOptionsData ? () => <div>test</div> : undefined,
				// },
				{
					value: formatNumber(totalOptions),
				},
			],
		},
		...shareClassesColumns.flatMap((col) => col),
		...getTotalIssuedCol(shareClassHolderList, shareholders, totalShares, 'numberOfSharesOutstanding', optionsData).flatMap((col) => col),
		...getTotalOptionsAndWarrantsCol(shareClassHolderList, shareholders, totalOptionsAndWarrants, totalShares, optionsData).flatMap((col) => col),
		...getTotalFullyDilutedCol(shareClassHolderList, shareholders, totalOptionsAndWarrants, totalShares, optionsData).flatMap((col) => col),
	];

	return {
		columns: data.filter((col) => col.id),
		totalShareholders: shareholders.length,
	};
};

const filterShareholder = (shareClassHolderList: ShareClassHolderPreviewInfo[] | undefined, isStrict: boolean) => {
	if (isNullOrUndefined(shareClassHolderList)) return [];

	const holderList = isStrict
		? shareClassHolderList.filter((holder) =>
				Boolean(holder.numberOfBSA || holder.numberOfSharesOutstanding || holder.numberOfWarrants || holder.converted || holder.options)
		  )
		: shareClassHolderList;

	return Array.from(new Set(holderList.map((p) => p.personID ?? -1).sort((a, b) => b - a)));
};

const getShareClassCol = (
	shareClassHolderList: ShareClassHolderPreviewInfo[] | undefined,
	prevShareClassHolderList: PreFundingShareClassHolderList[] | undefined,
	shareClass: ShareClassInfo,
	shareholdersWithOrders: number[],
	editableClasses: number[] = []
) => {
	if (isNullOrUndefined(shareClass) || isNullOrUndefined(shareClassHolderList)) return emptyCol;

	const showWarrants = Boolean(shareClass.numberOfWarrants);
	const showConverted = Boolean((shareClass.convertionValueEx ?? 0) > 0);
	const showBsa = Boolean(shareClass.numberOfBSA);

	const id = getUniqueId();

	const isDisabled = !!editableClasses.length && !editableClasses.some((id) => shareClass.shareClassID === id);

	const getShareClassCellDataByKey = (sh: number, key: keyof ShareClassHolderPreviewInfo) =>
		getShareClassCellData(shareClassHolderList, prevShareClassHolderList, sh, shareClass.shareClassID, key, isDisabled, !!prevShareClassHolderList?.length);

	const shares: CapTableColumn = {
		id,
		type: CapTableColType.class,
		title: shareClass.shareClass || '',
		subtitle: 'Shares',
		colQaid: `CapTable.Column.Class-${shareClass.shareClass}-Shares`,
		shareClassInfo: shareClass,
		isDisabled,
		shareClassType: CapTableShareClassType.shares,
		cells: [
			...shareholdersWithOrders.map((sh) => getShareClassCellDataByKey(sh, 'numberOfSharesOutstanding')),
			emptyCell,
			emptyCell,
			emptyCell,
			{
				value: formatNumber(calculateTotal(shareClassHolderList, 'numberOfSharesOutstanding', shareClass.shareClassID)),
			},
		],
	};

	const warrants: CapTableColumn = showWarrants
		? {
				id,
				colQaid: `CapTable.Column.Class-${shareClass.shareClass}-Warrants`,
				title: shareClass.shareClass || '',
				subtitle: 'Warrants',
				type: CapTableColType.class,
				shareClassInfo: shareClass,
				shareClassType: CapTableShareClassType.warrants,
				cells: [
					...shareholdersWithOrders.map((sh) => getShareClassCellDataByKey(sh, 'numberOfWarrants')),
					emptyCell,
					emptyCell,
					emptyCell,
					{
						value: formatNumber(calculateTotal(shareClassHolderList, 'numberOfWarrants', shareClass.shareClassID)),
					},
				],
		  }
		: emptyCol;

	const bsa: CapTableColumn = showBsa
		? {
				id,
				colQaid: `CapTable.Column.Class-${shareClass.shareClass}-Bsa`,
				title: shareClass.shareClass || '',
				subtitle: 'BSA',
				type: CapTableColType.class,
				shareClassInfo: shareClass,
				isDisabled,
				shareClassType: CapTableShareClassType.bsa,
				cells: [
					...shareholdersWithOrders.map((sh) => getShareClassCellDataByKey(sh, 'numberOfBSA')),
					emptyCell,
					emptyCell,
					emptyCell,
					{
						value: formatNumber(calculateTotal(shareClassHolderList, 'numberOfBSA', shareClass.shareClassID)),
					},
				],
		  }
		: emptyCol;

	const converted: CapTableColumn = showConverted
		? {
				id,
				colQaid: `CapTable.Column.Class-${shareClass.shareClass}-Converted`,
				title: shareClass.shareClass || '',
				subtitle: 'Converted',
				type: CapTableColType.class,
				shareClassInfo: shareClass,
				isDisabled,
				shareClassType: CapTableShareClassType.converted,
				cells: [
					...shareholdersWithOrders.map((sh) => getShareClassCellDataByKey(sh, 'converted')),
					emptyCell,
					emptyCell,
					emptyCell,
					{
						value: formatNumber(calculateTotal(shareClassHolderList, 'converted', shareClass.shareClassID)),
					},
				],
		  }
		: emptyCol;

	return [shares, warrants, bsa, converted];
};

const calculateTotal = (
	shareClassHolderList: ShareClassHolderPreviewInfo[],
	key: keyof ShareClassHolderPreviewInfo,
	shareClassId?: number,
	acc: number = 0
) => {
	if (isNullOrUndefined(shareClassId)) return 0;
	const value = shareClassHolderList
		.filter((x) => x.shareClassID === shareClassId)
		.map((x) => +(x[key] ?? 0))
		.reduce((x, y) => x + y, acc);

	return value ?? 0;
};

const getShareClassCellData = (
	shareClassHolderList: ShareClassHolderPreviewInfo[],
	prevShareClassHolderList: PreFundingShareClassHolderList[] | undefined,
	personId: number,
	shareClassId: number | undefined,
	key: keyof ShareClassHolderPreviewInfo,
	isDisabled: boolean = false,
	isNew: boolean = false
): CapTableCell => {
	if (isNullOrUndefined(shareClassId)) return emptyCell;

	const data = (shareClassHolderList.find((sc) => sc.personID === personId && sc.shareClassID === shareClassId)?.[key] as number) ?? 0;
	const oldData = (prevShareClassHolderList?.find((sc) => sc.personID === personId && sc.shareClassID === shareClassId)?.[key] as number) ?? 0;
	return compareData(
		isNullOrUndefined(prevShareClassHolderList) ? undefined : oldData,
		data,
		isNew || (isDisabled ? false : !!prevShareClassHolderList?.length)
	);
};

const getTotalCellData = (
	shareClassHolderList: ShareClassHolderPreviewInfo[],
	personId: number,
	key: keyof ShareClassHolderPreviewInfo,
	total?: number
): CapTableCell => {
	const res = shareClassHolderList.filter((sch) => sch.personID === personId).reduce((acc, obj) => acc + (obj[key] as number), 0);

	return {
		value: total ? getPercentrage(res / total) : formatNumber(res),
		referenceId: personId,
	};
};

const getTotalIssuedCol = (
	shareClassHolderList: ShareClassHolderPreviewInfo[],
	shareholdersWithOrders: number[],
	totalShares: number,
	key: keyof ShareClassHolderPreviewInfo,
	optionsData?: OptionsPreviewInfo
): CapTableColumn[] => {
	const id = getUniqueId();

	const shares = {
		id,
		colQaid: `CapTable.Column.TotalIssuedShares`,
		title: 'Total issued shares',
		subtitle: 'Shares',
		type: CapTableColType.issuedShares,
		cells: [
			...shareholdersWithOrders.map((sh) => getTotalCellData(shareClassHolderList, sh, key)),
			{
				value: formatNumber(optionsData?.exercise),
			},
			...addEmptyCells(2),
			{
				value: formatNumber(totalShares),
			},
		],
	};

	const totalHoldingPercent = {
		id,
		colQaid: `CapTable.Column.TotalIssuedShares%`,
		title: 'Total issued shares',
		subtitle: 'Total holdings (%)',
		type: CapTableColType.issuedShares,
		cells: [
			...shareholdersWithOrders.map((sh) => getTotalCellData(shareClassHolderList, sh, key, totalShares)),
			{
				value: getPercentrage((optionsData?.exercise ?? 0) / totalShares),
			},
			...addEmptyCells(2),
			{
				value: totalShares ? '100%' : '0',
			},
		],
	};

	return [shares, totalHoldingPercent];
};

const calculateOptionsAndWarrants = (
	shareClassHolderList: ShareClassHolderPreviewInfo[],
	personId: number,
	total?: number,
	totalFully?: number,
	isTotalFullyCol?: boolean
): CapTableCell => {
	const optionsAndWarrants = shareClassHolderList
		.filter((sch) => sch.personID === personId)
		.reduce((acc, obj) => acc + (obj.numberOfWarrants + obj.converted + obj.options + obj.numberOfBSA), 0);

	const shares = isNullOrUndefined(total)
		? 0
		: shareClassHolderList.filter((sch) => sch.personID === personId).reduce((acc, obj) => acc + obj.numberOfSharesOutstanding, 0);

	if (isTotalFullyCol) {
		const fullyDeluted = optionsAndWarrants + shares;

		return {
			value: totalFully ? getPercentrage(fullyDeluted / (totalFully ?? 0)) : formatNumber(fullyDeluted),
			referenceId: personId,
		};
	}
	return {
		value: total ? getPercentrage(optionsAndWarrants / (totalFully ?? 0)) : formatNumber(optionsAndWarrants),
		referenceId: personId,
	};
};

const getTotalOptionsAndWarrantsCol = (
	shareClassHolderList: ShareClassHolderPreviewInfo[],
	shareholdersWithOrders: number[],
	totalOptionsAndWarrants: number,
	totalShares: number,
	optionsData?: OptionsPreviewInfo
): CapTableColumn[] => {
	const totalFullyDeluted = totalShares + totalOptionsAndWarrants;
	const id = getUniqueId();

	const options = {
		id,
		type: CapTableColType.optionsAndWarrants,
		title: 'Total options & warrants',
		subtitle: 'Options & warrants',
		colQaid: `CapTable.Column.OptionsWarrants`,
		cells: [
			...shareholdersWithOrders.map((sh) => calculateOptionsAndWarrants(shareClassHolderList, sh)),
			...addEmptyCells(1),
			{
				value: formatNumber(optionsData?.outstanding),
			},
			{
				value: formatNumber(optionsData?.unAllocated),
			},
			{
				value: formatNumber(totalOptionsAndWarrants),
			},
		],
	};

	const totalHoldingPercent = {
		id,
		type: CapTableColType.optionsAndWarrants,
		title: 'Total options & warrants',
		subtitle: 'Fully diluted (%)',
		colQaid: `CapTable.Column.OptionsWarrants%`,
		cells: [
			...shareholdersWithOrders.map((sh) => calculateOptionsAndWarrants(shareClassHolderList, sh, totalOptionsAndWarrants, totalFullyDeluted)),
			...addEmptyCells(1),
			{
				value: getPercentrage((optionsData?.outstanding ?? 0) / totalFullyDeluted),
			},
			{
				value: getPercentrage((optionsData?.unAllocated ?? 0) / totalFullyDeluted),
			},
			{
				value: getPercentrage((totalOptionsAndWarrants ?? 0) / totalFullyDeluted),
			},
		],
	};

	return [options, totalHoldingPercent];
};

const getTotalFullyDilutedCol = (
	shareClassHolderList: ShareClassHolderPreviewInfo[],
	shareholdersWithOrders: number[],
	totalOptionsAndWarrants: number,
	totalShares: number,
	optionsData?: OptionsPreviewInfo
) => {
	const totalFullyDeluted = totalShares + totalOptionsAndWarrants;
	const id = getUniqueId();

	const shares = {
		id,
		colQaid: `CapTable.Column.TotalFullyDiluted`,
		title: 'Total fully diluted',
		subtitle: 'Total fully diluted',
		type: CapTableColType.fullyDiluted,
		cells: [
			...shareholdersWithOrders.map((sh) => calculateOptionsAndWarrants(shareClassHolderList, sh, totalOptionsAndWarrants, undefined, true)),
			{
				value: formatNumber(optionsData?.exercise),
			},
			{
				value: formatNumber(optionsData?.outstanding),
			},
			{
				value: formatNumber(optionsData?.unAllocated),
			},
			{
				value: formatNumber(totalFullyDeluted),
			},
		],
	};

	const totalHoldingPercent = {
		id,
		type: CapTableColType.fullyDiluted,
		colQaid: `CapTable.Column.TotalFullyDiluted%`,
		title: 'Total fully diluted',
		subtitle: 'Total holdings (%)',
		cells: [
			...shareholdersWithOrders.map((sh) => calculateOptionsAndWarrants(shareClassHolderList, sh, totalOptionsAndWarrants, totalFullyDeluted, true)),
			{
				value: getPercentrage((optionsData?.exercise ?? 0) / totalFullyDeluted),
			},
			{
				value: getPercentrage((optionsData?.outstanding ?? 0) / totalFullyDeluted),
			},
			{
				value: getPercentrage((optionsData?.unAllocated ?? 0) / totalFullyDeluted),
			},
			{
				value: totalFullyDeluted ? '100%' : '0',
			},
		],
	};

	return [shares, totalHoldingPercent];
};

const addEmptyCells = (number: number = 1) => {
	return [...Array(number)].map(() => emptyCell);
};

const compareData = (oldData: number | undefined, data: number | undefined, isNew: boolean = false): CapTableCell => {
	return {
		value: formatNumber(data),
		render: !isNew
			? undefined
			: () =>
					oldData ? (
						oldData === data ? (
							<span>{formatNumber(oldData, false)}</span>
						) : (
							<Flex align="center" justify="center" direction="column">
								<span
									style={{
										textDecoration: 'line-through',
										fontSize: '1.2rem',
									}}
								>
									{formatNumber(oldData, false)}
								</span>
								<span
									style={{
										color: appConfig.style.colors.success,
									}}
								>
									{formatNumber(data, false)}
								</span>
							</Flex>
						)
					) : (
						<span style={{ color: appConfig.style.colors.success }}>{formatNumber(data, false)}</span>
					),
	};
};
