import { css } from "@emotion/css";
import appConfig from "../../../../config/config";

const DrawerStyles = css({
    display: "flex",
    flexDirection: "column",
    // boxShadow: appConfig.style.boxShadow.card1,
    margin: "1.4rem 0 0 0",
    // padding: "1rem",
    // paddingBottom: "2.8rem",
    borderRadius: "4px",
    // borderBottom: `1px solid ${appConfig.style.colors.table}`,

    "&.company-registration": {
        position: "relative",
        borderRadius: "8px",
        border: "1px solid var(--border-3-field, #BAC0D9)",
        background: "#FFF",
        padding: "1rem 0",
    },

    ".drawer-header": {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        // fontSize: "1.6rem",
        alignItems: "center",
        // fontWeight: 500,
        // margin: "1.4rem 0",
        paddingBottom: "1.4rem",
        borderBottom: `1px solid ${appConfig.style.colors.table}`,
        padding: "1.4rem 0",

        "&.company-registration": {
            fontSize: "1.4rem",
            fontWeight: 500,
            margin: "1rem",
            paddingBottom: "1rem",
            whiteSpace: "nowrap",
        },
    },

    ".header-details": {
        display: "flex",
        flexDirection: "row",
        // backgroundColor: "#eaeaea",
        // width: "44rem",
        justifyContent: "space-between",
        // padding: "5px 10px",
        borderRadius: "5px",
        cursor: "pointer",

        "&.company-registration": {
            display: "flex",
            flexDirection: "row",
            width: "100%",
            padding: "0",
            background: "#FFF",
            color: "var(--text-2-secondary, #979DB5)",
        },
    },
    ".add-button": {
        display: "flex",
        justifyContent: "start",
        margin: "0 1rem 1rem 1rem",
        fontSize: "1.2rem",
        textAlign: "left",
        width: "auto",
    },

    ".chevron": {
        cursor: "pointer",
    },

    ".drawer-content-wrapper": {
        position: "relative",
        overflow: "hidden",
        background: "#FFF",
        paddingBottom: "2rem",
        paddingTop: "1rem",

        "&.open": {
            zIndex: "1",
        },
    },
});

export default DrawerStyles;
