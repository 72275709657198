import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

const useQueryParams = <T extends string, K extends string>() => {
	const [queryParams, setQueryParams] = useState<Record<T, K>>({} as Record<T, K>);
	const location = useLocation();

	useEffect(() => {
		const searchParams = new URLSearchParams(location.search);
		const paramsObject: Record<T, K> = {} as Record<T, K>;
		searchParams.forEach((value, key) => {
			paramsObject[key as T] = value as K;
		});
		setQueryParams(paramsObject);
	}, [location]);

	return queryParams;
};

export default useQueryParams;
