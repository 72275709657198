import { observer } from 'mobx-react-lite';
import { createRef, SyntheticEvent, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { IC_APPROVAL_CIRCLE_GRAY, IC_ISRAEL, IC_MSG_GRAY, IC_SUCCESS_MODAL } from '../../../../Assets';
import appConfig from '../../../../config/config';
import { DataCollectionFieldTypeEnum } from '../../../../Models/API/DataCollection/comment-response';
import { DataCollectionStatusEnum } from '../../../../Models/API/DataCollection/project-preview';
import { Routes } from '../../../../Routes';
import ActionsMenu from '../../../../Shared/Components/ActionsMenu/ActionsMenu';
import Button from '../../../../Shared/Components/Button/Button';
import Title from '../../../../Shared/Components/Layout/Title';
import Container from '../../../../Shared/Components/Modal/Modal.Style';
import ProgressBar from '../../../../Shared/Components/ProgressBar';
import useEffectOnce from '../../../../Shared/Hooks/useEffectOnce';
import useModal from '../../../../Shared/Hooks/useModal';
import useMultiStepForm, { ForwardedRef, MultiStepFormItem } from '../../../../Shared/Hooks/useMultiStepForm';
import useRootStore from '../../../../Shared/Hooks/useRootStore';
import { isNumber } from '../../../../Shared/Utilities';
import { WaterfallFooter } from '../../../Waterfall/Components/AddEditWaterfall/index.style';
import { ActionsMenuStyle } from '../../../Waterfall/Components/WaterfallHeader/WaterfallHeader.style';
import { DataCollectionPermissionsEnum, hasProjectPermissions, validateDataCollectionBeforeSubmit } from '../../helpers/utils';
import AddDocuments from './Steps/AddDocuments';
import GeneralInfo from './Steps/GeneralInfo';
import ImportCapTable from './Steps/ImportCapTable';

const AddEditDataCollection = () => {
	const { dataCollectionStore, companyStore } = useRootStore();
	const params = useParams<{ step: string; valuationProjectId: string }>();
	const history = useHistory();
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [lastAllowedStep, setLastAllowedStep] = useState<number>(-1);
	const ref = createRef<ForwardedRef>();
	const { showModal, showAsyncModal } = useModal();
	const { step, onNextStep, onPrevStep, goTo, currentStepIdx, steps, isLastStep, isFirstStep } = useMultiStepForm([
		{
			element: <GeneralInfo ref={ref} />,
			caption: 'General information',
		},
		{
			element: <ImportCapTable ref={ref} />,
			caption: 'Import cap table',
		},
		{
			element: <AddDocuments ref={ref} />,
			caption: 'Add documents',
		},
	]);

	useEffect(() => {
		const step = +params.step;

		if (!isNaN(step)) goTo(step);

		return () => {
			// history.push(Routes.waterfall.index);
			dataCollectionStore.resetValuationProject();
			setIsLoading(false);
		};
	}, []);

	useEffect(() => {
		if (dataCollectionStore.valuationProjectId) {
			// dataCollectionStore.getDataCollection(dataCollectionStore.valuationProjectId);
		} else {
			dataCollectionStore.valuationProjectId = params.valuationProjectId;
		}
	}, [dataCollectionStore.valuationProjectId]);

	useEffectOnce(
		() => {
			if (dataCollectionStore.documents === undefined) return;
			handleStepsValidation();
		},
		[dataCollectionStore.documents],
		!!dataCollectionStore.documents
	);

	useEffect(() => {
		if (!dataCollectionStore.valuationProjectId) return;
		history.replace(`${Routes.dataCollection.form}/${dataCollectionStore.valuationProjectId}/${currentStepIdx}`);
	}, [currentStepIdx, dataCollectionStore.valuationProjectId]);

	const onStepClickHandler = async (targetStepIdx: number) => {
		const isStepAllowed = checkIfStepAllowed(targetStepIdx);
		if (hasProjectPermissions(dataCollectionStore.currentRole, DataCollectionPermissionsEnum.SKIP_STEPS)) {
			return goTo(targetStepIdx);
		}

		if (isFirstStep && isStepAllowed) {
			return onSubmit(undefined, true, targetStepIdx);
		}

		if (isNextStep(targetStepIdx)) {
			return onSubmit(undefined, true);
		}
		if (!isStepAllowed) {
			return goTo(lastAllowedStep);
		}
		const stepsBetween = steps.slice(currentStepIdx + 1, targetStepIdx);
		const isSkippable = stepsBetween.every((step) => step.isRequired === false); // If all between steps are not mandatory, skip

		setLastAllowedStep(targetStepIdx);

		if (isSkippable) {
			await onSubmit(undefined, true, targetStepIdx);
			return goTo(targetStepIdx);
		}
	};

	const isSubmitAvailable =
		isLastStep &&
		hasProjectPermissions(dataCollectionStore.currentRole, DataCollectionPermissionsEnum.SUBMIT_FOR_REVIEW) &&
		[DataCollectionStatusEnum.DataCollection].includes(dataCollectionStore.currentStatus);

	const isNextStep = (targetStepIdx: number): boolean => {
		return targetStepIdx - 1 === currentStepIdx;
	};

	const checkIfStepAllowed = (targetStepIdx: number): boolean => {
		return targetStepIdx <= lastAllowedStep;
	};

	const onSubmit = async (e?: SyntheticEvent, isStepClick?: boolean, step?: number) => {
		e?.preventDefault();
		if (isLoading) return;

		setIsLoading(true);
		const isValid = await ref.current?.onValidate();
		setIsLoading(false);

		if (!isValid) return;

		handleStepsValidation();
		// setLastAllowedStep(step ?? ((prev) => prev + 1));

		if (isNumber(step)) return goTo(step);

		if (!isLastStep) {
			return onNextStep(); // If not last step, continue to next step
		}

		onSaveHandler();
	};

	const handleStepsValidation = () => {
		setLastAllowedStep(
			dataCollectionStore.documents?.length &&
				dataCollectionStore.documents?.some((doc) => doc.fileName && doc.type !== DataCollectionFieldTypeEnum.GrantStatus)
				? 2
				: dataCollectionStore.dataCollection.capTableId
				? 1
				: dataCollectionStore.dataCollection.valuationDate
				? 0
				: -1
		);
	};

	const onSaveHandler = async () => {
		const onComplete = () => {
			ref.current?.onValidate();
			history.push(Routes.dataCollection.index);
		};
		onComplete();
	};

	const onSubmitDataCollection = async () => {
		if (!dataCollectionStore.dataCollectionId) return;
		setIsLoading(true);

		ref.current?.onValidate();

		const stepsValidation = validateDataCollectionBeforeSubmit(dataCollectionStore.dataCollection, dataCollectionStore.documents);

		if (stepsValidation.includes(false)) {
			const isConfirmed = await showAsyncModal({
				type: 'warning',
				title: 'Please note',
				body: () => (
					<>
						<div className="bold mb-3">It seems you haven't addressed all the data collection questions.</div>
						<div>
							Would you like to continue and complete your answers first, or alternatively{' '}
							<span className="semi-bold">submit an incomplete form</span> & altshare valuation team will return to you with comments?
						</div>
					</>
				),
				width: '64rem',
				confirmButton: {
					label: 'Submit',
				},
				cancelButton: {
					label: 'Continue',
				},
				showClose: true,
			});

			if (!isConfirmed) {
				setIsLoading(false);
				// return goTo(stepsValidation[0] ? (secondStepValidation ? 3 : 1) : 0);
				return;
			}
		}

		const res = await dataCollectionStore.submitDataCollection(dataCollectionStore.dataCollectionId);
		if (res.errorMessage) {
			return showModal({
				type: 'error',
				title: 'Submit for review failed',
				body: res.error?.data.validationErrors?.join(', ') || res.errorMessage,
			});
		}
		setIsLoading(false);

		showModal({
			body: (props) => (
				<div className="flex flex-column align-center text-center">
					<Container.Image src={IC_SUCCESS_MODAL} alt="success" />
					<Title type="secondary" className="mt-2 mb-4">
						409A data submitted successfully
					</Title>
					altshare valuation analyst will promptly review the submitted data, address each of your comments respectively and back in case any further
					clarifications are required.
					<br />
					<br />
					The valuation process will start immediately after all the data is finalized, and upon completion, typically in few days, you will receive
					email notification informing that the draft report is ready for your review.
					<Button qaid="" label="OK" onClick={props.removeModal} style={{ marginTop: '3rem' }} />
				</div>
			),
			onModalClose: () => {
				history.push(Routes.dataCollection.index);
			},
			width: '65rem',
		});
	};

	return (
		<>
			<ProgressBar
				pageCaptions={steps.map((step: MultiStepFormItem) => step.caption || '')}
				onStepClickHandler={onStepClickHandler}
				currentStepIdx={currentStepIdx}
				title={
					<>
						<span style={{ color: appConfig.style.colors.text2 }}>Valuation name:</span>{' '}
						<span className="semi-bold">{dataCollectionStore.projectName}</span>
					</>
				}
				lastCompletedIdx={lastAllowedStep}
				actions={
					<div className={ActionsMenuStyle}>
						{isSubmitAvailable && (
							<Button qaid="ProgressBar.Button.Submit" onClick={onSubmitDataCollection} label="Submit for review" isLoading={isLoading} />
						)}
						<Button qaid="ProgressBar.Button.Quit" onClick={onSaveHandler} label="Save & Quit" cancel />
						{hasProjectPermissions(dataCollectionStore.currentRole, DataCollectionPermissionsEnum.RUN_MENU_ACTIONS) &&
							dataCollectionStore.isOwner && (
								<ActionsMenu
									actions={[
										{
											icon: IC_APPROVAL_CIRCLE_GRAY,
											label: dataCollectionStore.isProjectDisabled ? 'Re-open' : 'Approve data',
											onClick: () => {
												showModal({
													type: 'warning',
													title: 'Are you sure?',
													body: dataCollectionStore.isProjectDisabled
														? 'If you Re-open the data collection the customer will be able to change the data from his side.'
														: 'Once you approve the data collection, the customer will not be able to change the data from his side. You can always re-open the data collection',
													onConfirm: () => {
														dataCollectionStore.dataCollection.valuationProjectId &&
															dataCollectionStore.approveProject(dataCollectionStore.dataCollection.valuationProjectId);
													},
													confirmButton: {
														label: dataCollectionStore.isProjectDisabled ? 'Continue' : 'Confirm',
													},
												});
											},
											qaid: dataCollectionStore.isProjectDisabled
												? 'AddEditDataCollection.Button.Reopen'
												: 'AddEditDataCollection.Button.ApproveData',
										},
										{
											icon: IC_MSG_GRAY,
											label: 'Send notification',
											onClick: () => {
												showModal({
													type: 'warning',
													title: 'Are you sure?',
													body: 'The customer will receive an email that there are comments you have added for his reference regarding data collection.',
													onConfirm: () => {
														if (dataCollectionStore.dataCollection.valuationProjectId === null) return;
														dataCollectionStore.sendNotification(dataCollectionStore.dataCollection.valuationProjectId);
													},
													confirmButton: {
														label: 'Continue',
													},
												});
											},
											qaid: 'AddEditDataCollection.Button.SendNotification',
										},
										{
											icon: IC_ISRAEL,
											label: 'Israeli customer',
											onClick: () => {
												if (dataCollectionStore.isIsraeliCustomer) {
													return showModal({
														type: 'warning',
														title: 'Please note',
														body: 'The data collection has already been marked as an Israeli customer.',
													});
												}
												showModal({
													type: 'warning',
													title: 'Are you sure?',
													body: 'Please note that the payment process with an Israeli customer should be done outside of the platform.',
													onConfirm: () => {
														if (dataCollectionStore.dataCollection.valuationProjectId === null) return;
														dataCollectionStore.setIsraeliCustomer(dataCollectionStore.dataCollection.valuationProjectId);
													},
													confirmButton: {
														label: 'Continue',
													},
												});
											},
											qaid: 'AddEditDataCollection.Button.IsraeliCustomer',
										},
									]}
								/>
							)}
					</div>
				}
				isLastStepHighlighed
			/>
			{step}
			<WaterfallFooter>
				{!isFirstStep && (
					<Button qaid="AddEditWaterfall.Button.Back" type="button" disabled={isLoading} inverse onClick={onPrevStep} label="general.back" />
				)}

				<Button
					style={{ marginLeft: 'auto' }}
					qaid="AddEditWaterfall.Button.Next"
					isLoading={isLoading}
					onClick={isSubmitAvailable ? onSubmitDataCollection : onSubmit}
					label={isSubmitAvailable ? 'Submit for review' : isLastStep ? 'Close' : 'Next'}
				/>
			</WaterfallFooter>
		</>
	);
};

export default observer(AddEditDataCollection);
