import { makeAutoObservable, runInAction, toJS } from 'mobx';
import { IC_EXPAND, IC_EYE_ORANGE, IC_PLANE, IC_SAFE } from '../Assets';
import { IContactUs } from '../Models/App';
import { AddOnsEnum, IBillingInfo, ICheckout, ICustomerDetails, IInvoice, IPaymenPlan, IPaymentPlans } from '../Models/App/PaymentModels';

import PaymentService from '../Services/PaymentService';
import { RootStore } from './RootStore';
import { PaymentCurrencyEnum, PaymentPlanEnum, UserStatus } from '../Models/API/enums';
import { isNullOrUndefined } from '../Shared/Utilities';
import { Routes } from '../Routes';
import { makePersistable } from 'mobx-persist-store';

export const PLAN_ID_PAYMENT = 'planIdPayment';
export const CURRENCY_ID_PAYMENT = 'currencyIdPayment';
export const BUTTON_ACTIVE = 'active';
export const BUTTON_CONTACTUS_WHITE = 'contactUsWhite';
export const BUTTON_UPGRADE = 'upgrade';
export const COMPANY_ID_KEY = 'companyId';

export default class PaymentsStore {
	paymentService: PaymentService = new PaymentService(0);
	initiated = false;
	plans: IPaymentPlans | undefined = undefined;
	currentPlan: IPaymenPlan = {} as IPaymenPlan;
	selectedPlan: IPaymenPlan = {} as IPaymenPlan;

	displayPlans: IPaymenPlan[] = [];
	customerDetails?: ICustomerDetails | null;
	invoices: IInvoice[] = [];
	selectedInvoice: IInvoice | null = null;
	private isShowPlanUsageDialog: boolean = false;
	billingContact?: IBillingInfo;
	currencyId: number = 0;

	get selectPlanDetails() {
		return this.selectedPlan;
	}

	get currentPlanDetails() {
		return this.currentPlan;
	}

	setSelectedPlan(plan: IPaymenPlan) {
		this.selectedPlan = plan;
	}

	set showPlanUsageDialog(value: boolean) {
		this.isShowPlanUsageDialog = value;
	}

	setSelectedInvoice(invoice: IInvoice) {
		this.selectedInvoice = invoice;
	}

	constructor(private rootStore: RootStore) {
		makeAutoObservable(this);
		makePersistable(this, {
			name: 'PaymentStore',
			properties: ['selectedPlan', 'currentPlan'],
			storage: window.sessionStorage,
			expireIn: 10800000, // 3 hours
		});

		// Track company change because services use companyID in the common values and they need to be reinstanciated after company change
		rootStore.companyStore.onCompanyChange.subscribe(this.initServices);
	}

	private initServices = async () => {
		if (this.rootStore.companyStore.company.companyId) {
			this.paymentService = new PaymentService(this.rootStore.companyStore.companyId);
			const res = await this.getCurrentPlan();
			if (res.data) this.currentPlan = res.data;

			RootStore.subscribeToLoading([this.paymentService], this.rootStore);

			if (this.initiated) {
				this.init();
			}
		}
	};

	get isProductIncluded() {
		const productsByPlan = {
			capTable: [PaymentPlanEnum.Starter, PaymentPlanEnum.Growth, PaymentPlanEnum.Scale, PaymentPlanEnum.Enterprise],
			equityPlans: [PaymentPlanEnum.Starter, PaymentPlanEnum.Growth, PaymentPlanEnum.Scale, PaymentPlanEnum.Enterprise],
			funding: [PaymentPlanEnum.Starter, PaymentPlanEnum.Growth, PaymentPlanEnum.Scale, PaymentPlanEnum.Enterprise],
			expensing: [PaymentPlanEnum.Starter, PaymentPlanEnum.Growth, PaymentPlanEnum.Scale, PaymentPlanEnum.Enterprise],
			waterfall: [PaymentPlanEnum.Scale, PaymentPlanEnum.Enterprise],
			valuation: [PaymentPlanEnum.Growth, PaymentPlanEnum.Scale, PaymentPlanEnum.Enterprise],
			docs: [PaymentPlanEnum.Growth, PaymentPlanEnum.Scale, PaymentPlanEnum.Enterprise],
		};

		return Object.keys(productsByPlan).reduce((acc, key) => {
			return { ...acc, [key]: productsByPlan[key as keyof typeof productsByPlan].includes(this.currentPlan.code) };
		}, {} as Record<string, boolean>);
	}

	resetStoreToDefaultValues = () => {
		this.displayPlans = [];
		this.currencyId = 0;
		this.selectedPlan = {} as IPaymenPlan;
		this.billingContact = undefined;
		this.customerDetails = undefined;
		this.invoices = [];
		this.selectedInvoice = null;
		this.isShowPlanUsageDialog = false;
	};

	createPaymentIntent = async (planId: number, reCaptchaToken: string) => {
		let res = await this.paymentService.CreatePaymentIntent(this.rootStore.companyStore.companyId, planId, reCaptchaToken);
		return res.data;
	};

	customerSubscribePlan = async (checkoutInfo: ICheckout, planId: number, reCaptchaToken: string, isNewCompany: boolean) => {
		let res;

		if (isNewCompany) {
			res = await this.paymentService.newPlanCustomerSubscribe(this.rootStore.companyStore.companyId, planId, reCaptchaToken, checkoutInfo);
			!this.rootStore.auth.isUserActive &&
				this.updateBillingInfo({
					companyName: checkoutInfo.companyName,
					firstName: this.rootStore.auth.userInfo.firstName,
					lastName: this.rootStore.auth.userInfo.lastName,
					firstAddressLine: checkoutInfo.firstAddressLine,
					secondAddressLine: checkoutInfo.secondAddressLine,
					email: checkoutInfo.email,
					city: checkoutInfo.city,
					zipCode: checkoutInfo.zipCode,
					country: checkoutInfo.country,
					recaptchaToken: reCaptchaToken,
				});
		} else {
			res = await this.paymentService.updatePlanCustomerSubscribe(this.rootStore.companyStore.companyId, this.selectPlanDetails.planId, reCaptchaToken);
			this.updateBillingInfo({
				companyName: checkoutInfo.companyName,
				firstName: this.rootStore.auth.userInfo.firstName,
				lastName: this.rootStore.auth.userInfo.lastName,
				firstAddressLine: checkoutInfo.firstAddressLine,
				secondAddressLine: checkoutInfo.secondAddressLine,
				email: checkoutInfo.email,
				city: checkoutInfo.city,
				zipCode: checkoutInfo.zipCode,
				country: checkoutInfo.country,
				recaptchaToken: reCaptchaToken,
			});
		}

		if (res.statusCode === 206) {
			return 206;
		}

		return res.data?.data;
	};

	customerCardUpdate = async (planId: number, reCaptchaToken: string) => {
		let res;

		res = await this.paymentService.updatCustomerCard(this.rootStore.companyStore.companyId as number, planId, reCaptchaToken);

		return res.data;
	};

	plansDetails = async (name: keyof IPaymentPlans) => {
		return this.plans?.[name];
	};

	getPlans = async (): Promise<IPaymentPlans | undefined> => {
		if (!this.rootStore.companyStore.companyId) return;
		if (this.plans) return this.plans;

		const res = await this.paymentService.getPlans(this.rootStore.companyStore.companyId);

		this.plans = res.data;

		return res.data;
	};

	getInvoices = async () => {
		const res = await this.paymentService.getInvoices(this.rootStore.companyStore.companyId);
		this.invoices = res.data;
		return this.invoices;
	};

	updateBillingInfo = async (info: IBillingInfo) => {
		const res = await this.paymentService.updateBillingInfo(info, this.rootStore.companyStore.companyId);
		if (res.isSuccess) {
			this.customerDetails = res.data;
		}
	};

	// setSelectedPlanAndCurrency = () => {
	// 	let planSelected;

	// 	if (!this.rootStore.auth.isUserActive && this.paymentPlans && this.currencyId) {
	// 		const currencyIdForRefresh = sessionStorage.getItem(CURRENCY_ID_PAYMENT);
	// 		if (currencyIdForRefresh) {
	// 			this.currencyId = parseInt(currencyIdForRefresh);
	// 		}

	// 		// const plansByCurrency: IPaymenPlan[] = this.paymentPlans[this.currencyId];
	// 		const planIdForRefresh = sessionStorage.getItem(PLAN_ID_PAYMENT);

	// 		if (planIdForRefresh) {
	// 			plansByCurrency.forEach((plan) => {
	// 				plan.isDefault = false;

	// 				if (plan.planId === parseInt(planIdForRefresh)) {
	// 					plan.isDefault = true;
	// 					planSelected = plan;
	// 				}
	// 			});
	// 		} else {
	// 			planSelected = plansByCurrency.find((x: IPaymenPlan) => x.isDefault === true);
	// 		}
	// 	} else {
	// 		for (const planNum in this.paymentPlans) {
	// 			planSelected = this.paymentPlans[planNum].find((x: IPaymenPlan) => x.isDefault === true);

	// 			if (planSelected) {
	// 				this.currencyId = planSelected.currency || 0;
	// 				break;
	// 			}
	// 		}
	// 	}

	// 	// this.selectedPlan = planSelected;
	// };

	// setPlansButtonStatus = (proccessType?: string) => {
	// 	if (!this.paymentPlans) return;

	// 	const plans: IPaymenPlan[] = this.paymentPlans[this.currencyId];

	// 	const currentUrl = window.location.href;

	// 	if (!this.rootStore.auth.isUserActive && !proccessType && currentUrl.includes(Routes.account.createCompanyProfile)) {
	// 		const selectedPlanId = this.selectedPlan?.planId || -1;

	// 		plans.forEach((plan) => {
	// 			if (plan.name !== "Custom") {
	// 				if (plan.planId === selectedPlanId) {
	// 					plan.buttonStatus = BUTTON_ACTIVE;
	// 				} else if (plan.planId! < selectedPlanId) {
	// 					plan.buttonStatus = BUTTON_CONTACTUS_WHITE;
	// 				} else if (plan.planId! > selectedPlanId) {
	// 					plan.buttonStatus = BUTTON_UPGRADE;
	// 				}
	// 			}
	// 		});
	// 	}
	// };

	updateBillingContactDetails = async () => {
		if (!this.rootStore.auth.isUserActive) {
			if (!this.billingContact) {
				// await this.updateBillingContactFromCompanyProfile();
			}
		} else {
			if (!this.billingContact) {
				this.updateBillingContactFromCustomer();
			}
		}
	};

	getCustomerDetails = async (): Promise<ICustomerDetails> => {
		const res = await this.paymentService.getCustomerDetails(this.rootStore.companyStore.companyId);
		this.customerDetails = res.data;
		return res.data;
	};

	updateBillingContactFromCustomer = async () => {
		const res = await this.paymentService.getCustomerDetails(this.rootStore.companyStore.companyId);

		if (res.data) {
			this.customerDetails = res.data;

			this.billingContact = {
				companyName: this.customerDetails.customerDetails?.companyName,
				email: this.customerDetails.customerDetails?.email,
				firstName: this.customerDetails.customerDetails?.firstName,
				lastName: this.customerDetails.customerDetails?.lastName,
				phone: this.customerDetails.customerDetails?.phone,
			};
		} else {
			// this.updateBillingContactFromCompanyProfile();
		}
	};

	getCurrentPlan = async () => {
		return this.paymentService.getCurrentPlan(this.rootStore.companyStore.companyId);
	};

	getBillingInfo = async () => {
		if (!this.rootStore.companyStore.companyId) {
			return;
		}

		await this.rootStore.companyStore.getCompany(this.rootStore.companyStore.companyId);
		// await this.rootStore.companyStore.getCompanyContact();

		const companyProfile = this.rootStore.companyStore.companyProfile;
		// const companyContactProfile = this.rootStore.companyStore.companyContactProfile;
		const userEmail = this.rootStore.auth.userEmail;

		const billingInfo: ICheckout = {
			email: userEmail,
			companyName: companyProfile?.companyLegalName,
		};

		return billingInfo;

		// this.billingContact = {
		// 	companyName: companyProfile?.companyLegalName,
		// 	email: companyContactProfile?.businessEmail,
		// 	firstName: companyContactProfile?.firstName,
		// 	lastName: companyContactProfile?.lastName,
		// 	phone: companyContactProfile?.mobile,
		// };
	};

	updateBillingContactForm = async (billingContact: IBillingInfo, reCaptchaToken: string) => {
		this.billingContact = billingContact;

		if (this.rootStore.auth.isUserActive) {
			let res = await this.paymentService.updateBillingContact(this.rootStore.companyStore.companyId, billingContact, reCaptchaToken);
			const custDetails = toJS(this.customerDetails);
			custDetails && (custDetails.customerDetails = res.data.customerDetails);
			this.customerDetails = custDetails;
		}
	};

	getPlanUsage = async () => {
		const res = await this.paymentService.getPlanUsage(this.rootStore.companyStore.companyId);
		return res.data;
	};

	getDashboardData = async () => {
		if (!this.rootStore.companyStore.companyId) return;
		return this.paymentService.getDashboardData(this.rootStore.companyStore.companyId);
	};

	updateCustomerCard = async (reCaptchaToken: string) => {
		return this.paymentService.updateCurstomerCard(reCaptchaToken, this.rootStore.companyStore.companyId);
	};

	// setPlansToDisplay = (proccessType?: string) => {
	// 	if (!this.paymentPlans) return;

	// 	this.displayPlans = [...this.paymentPlans[this.currencyId]];

	// 	if (this.selectedPlan?.name === "Starter") {
	// 		const starterPlan = this.displayPlans.find((x) => x.name === "Starter");

	// 		if (starterPlan) {
	// 			this.selectedPlan = starterPlan;
	// 			starterPlan.isDefault = true;
	// 			this.setPlansButtonStatus(proccessType);
	// 		}
	// 	}
	// };

	// updateCurrency = (currencyId: number) => {
	// 	this.currencyId = currencyId;
	// 	sessionStorage.setItem(CURRENCY_ID_PAYMENT, currencyId.toString());
	// 	this.setPlansToDisplay();
	// };

	removePaymentSessionStorage = (currencyId: number) => {};

	// setSelectedPlan = (planId: number = 0) => {
	// 	sessionStorage.setItem(PLAN_ID_PAYMENT, planId.toString());
	// this.selectedPlan = this.paymentPlans[this.currencyId].find((x: IPaymenPlan) => x.planId === planId);
	// };

	async init() {
		this.initiated = true;
	}

	getAddOnsPlans(type?: AddOnsEnum) {
		return this.paymentService.getAddOnsPlans(type);
	}
	purchaseAddon(addOnId: number, quantity: number, recaptchaToken: string) {
		return this.paymentService.purchaseAddon(addOnId, quantity, recaptchaToken);
	}

	getValuationAddOn(valuationProjectId: string) {
		return this.paymentService.getValuationAddOn(valuationProjectId);
	}

	purchaseValuationAddon(addOnId: number, valuationProjectId: string, recaptchaToken: string) {
		return this.paymentService.purchaseValuationAddon(addOnId, valuationProjectId, recaptchaToken);
	}

	getPreviewInvoice(planId: PaymentPlanEnum) {
		return this.paymentService.getPreviewInvoice(planId);
	}
}
