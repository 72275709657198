import { ReactElement } from 'react';
import { ErrorMessage, OptionsPreviewInfo, PersonCapTablePreviewInfo, ShareClassHolderPreviewInfo, ShareClassInfo } from '../../../Models/API/CapTable';
import { AvailableAmountsResultInfo } from '../../../Models/API/CapTable/available-amounts-result-info';
import { PreFundingShareClassHolderList } from '../../../Models/API/CapTable/financial-round-response';
import { SafeTransactionData } from '../../../Models/API/CapTable/safe-transaction-data';
import { IContact, IContactCreate } from '../../../Models/API/Contact/contact';
import { CapTableFullscreenMode } from '../../../Models/API/enums';
import { ModalBodyProps } from '../../../Shared/Components/Modal/types';

export enum CapTableImportColumnType {
	shareholders = 0,
	commonShares = 1,
	class = 2,
	warrants = 3,
	bsa = 4,
	converted = 5,
	option = 6,
	none = 7,
}

export enum CapTableColType {
	shareholders = 0,
	commonShares = 1,
	options = 2,
	class = 3,
	issuedShares = 4,
	optionsAndWarrants = 5,
	fullyDiluted = 6,
}

export enum CapTableShareClassType {
	shares = 0,
	warrants = 1,
	bsa = 2,
	converted = 4,
}

export type CapTableCell = {
	value: string;
	render?: () => ReactElement;
	referenceId?: number;
	onClick?: () => void;
};

export type SortByKeys = CapTableColType.issuedShares | CapTableColType.optionsAndWarrants | CapTableColType.fullyDiluted | CapTableColType.shareholders;

export type SortByType = { key: SortByKeys; isDesc: boolean };

export interface CapTableColumn {
	id: string;
	type: CapTableColType;
	cells: CapTableCell[];
	title: string;
	subtitle?: string;
	isSearchable?: boolean;
	isDisabled?: boolean;
	shareClassInfo?: ShareClassInfo;
	shareClassType?: CapTableShareClassType.bsa | CapTableShareClassType.shares | CapTableShareClassType.warrants | CapTableShareClassType.converted;
	colQaid: string;
}

export interface CapTableFilteredData {
	[key: string]: CapTableColumn[];
}

export interface AddShareholderProps {
	isCommonSharesEmpty?: boolean;
	minNumberOfShares?: number;
	numberOfRegisteredShares?: number;
	onAddCommonShares?: (number: number) => void;
}

export interface MainCapTableProps extends AddShareholderProps {
	data: CapTableData;
	onAddEditRound?: (shareClassId?: number) => void;
	// onEditRound: (classPreviewInfo: ShareClassPreviewInfo) => void;
	onUpdateClass?: (shareClassInfo?: ShareClassInfo, isPublishedMode?: boolean) => void;
	onAddShareholder?: (props: AddShareholderProps) => void;
	onAddOptions?: () => void;
	isViewMode?: boolean;
	isPublishMode?: boolean;
	onFullscreen?: (val: CapTableFullscreenMode) => void;
	title?: () => ReactElement | undefined;
	canEditCommon?: boolean;
	errors?: ErrorMessage[];
	onSortBy?: (sortBy: SortByKeys) => void;
	sortBy?: SortByType;
	fullscreenMode?: CapTableFullscreenMode;
	isSearchDisabled?: boolean;
}

export interface MergeContactProps extends ModalBodyProps {
	contact: IContactCreate;
	contacts: IContact[];
	onCreate: (contact: IContactCreate) => void;
	onMerge: (contactId: number, contact?: IContactCreate) => Promise<void>;
}

export interface AddEditShareholderProps extends AddShareholderProps, ModalBodyProps {
	contactId?: number;
	projectId?: number;
	isView?: boolean;
}

export interface EditCommonSharesProps extends AddShareholderProps, ModalBodyProps {
	onCancel?: () => void;
	isEdit?: boolean;
}

export interface AddEditShareClassProps extends ModalBodyProps {
	onAdd: (shareClass: ShareClassInfo) => Promise<void>;
	onDelete?: (id: number) => Promise<void>;
	getAvailableAmounts: (shareClassId: number, date: Date) => Promise<AvailableAmountsResultInfo>;
	shareClassInfo?: ShareClassInfo;
	isViewMode?: boolean;
	isImport?: boolean;
}

export interface CapTableGenerateProps {
	shareClassHolderList?: ShareClassHolderPreviewInfo[];
	personsList?: PersonCapTablePreviewInfo[];
	shareClasses?: ShareClassInfo[];
	optionsData?: OptionsPreviewInfo;
	isExerciseExpanded?: boolean;
	onEditShareholder?: (contactId: number, projectId: number) => void;
	prevShareClassHolderList?: PreFundingShareClassHolderList[];
	prevOptionsData?: OptionsPreviewInfo;
	editableClasses?: number[];
	strictMode?: boolean;
	safeTransactions?: SafeTransactionData[];
	onShowExerciseList?: () => void;
}

export interface CapTableData {
	columns: CapTableColumn[];
	totalShareholders: number;
}
