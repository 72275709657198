import { css } from '@emotion/css';
import { breakpoints } from '../../../../config/style/theme';
import appConfig from '../../../../config/config';

export const SelectedPlanStyle = css({
	label: 'SelectedPlan',
	flex: 1,
	displayf: 'flex',
	flexDirection: 'column',
	justifyContent: 'start',
	alignItems: 'start',
	height: '100%',

	'.topContainer': {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'start',
		justifyContent: 'start',
		flex: 0,
		height: 'fit-content',
		marginTop: '5rem',
		// marginTop: "5.2rem",

		'&__logo': {
			height: '7.8rem',
			width: 'auto',
			marginBottom: '1rem',
		},

		'&__title': {
			fontSize: '2.5rem',
			fontWeight: 'bold',
			color: '#8B96E9',
		},

		'&__subTitle': {
			marginTop: '0.4rem',
			fontSize: '1.6rem',
			'&.comment': {
				whiteSpace: 'nowrap',
				color: appConfig.style.colors.text2,
			},
		},

		'.priceContainer': {
			display: 'flex',
			justifyContent: 'start',
			flex: 0,

			'&__price': {
				fontSize: '4.6rem',
				fontWeight: 500,
			},

			'&__length': {
				fontSize: '2rem',
				marginTop: '1.6rem',
				color: '#4D4D4D',
				whiteSpace: 'nowrap',
			},
		},
	},

	'.divider': {
		width: '100%',
		borderTopColor: appConfig.style.colors.color1Secondary,
		'&:not(.clean)': {
			margin: '2rem 0',
		},
		'&.clean': {
			margin: 0,
		},
	},

	'.bottomContainer': {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'start',
		justifyContent: 'start',
		overflowY: 'auto',
		height: 'fit-content',

		'.stakeholdersContainer': {
			display: 'flex',
			gap: '2rem',
			fontSize: '1.6rem',
			marginBottom: '1.5rem',
			width: '100%',

			'&__icon': {
				height: '2.4rem',
				width: '2.4rem',
			},

			'&__tooltip': {
				marginLeft: 'auto',
				alignSelf: 'center',
			},
		},

		'&__title': {
			display: 'none',
		},

		'&__subTitle': {
			whiteSpace: 'nowrap',
			marginBottom: '1.5rem',
			color: '#8B96E9',
			fontSize: '1.4rem',
		},

		'.featureContainer': {
			justifyContent: 'start',
			alignContent: 'start',
			gap: '1.2rem',
			marginBottom: '1.3rem',
			width: '100%',
			'&__tooltip': {
				marginLeft: 'auto',
				alignSelf: 'center',
			},

			'&__icon': {
				height: '2.2rem',
				width: 'auto',
			},

			'&__text': {
				fontSize: '1.3rem',
			},
		},

		'.customContainer': {
			display: 'flex',
			gap: '2rem',
			fontSize: '1.6rem',

			'.title': {
				fontSize: '1.9rem',
				fontWeight: 'bold',
				marginBottom: '2rem',
				display: 'block',
			},
		},
	},

	'.invoiceContainer': {
		display: 'flex',
		flexDirection: 'column',
		width: '100%',
		color: '#4D4D4D',
		'&__title': {
			fontWeight: 700,
			fontSize: '1.6rem',
		},
		'&__paymentsContiner': {
			display: 'flex',
			flexDirection: 'column',
			border: `1px solid ${appConfig.style.colors.table}`,
			borderRadius: 8,
			marginTop: '2rem',
			padding: '2rem',
			gap: '2rem',
		},
		'&__payment': {
			display: 'flex',
			justifyContent: 'space-between',
			'&:not(.total)': {
				'.desc': {
					paddingRight: '0.8rem',
					color: appConfig.style.colors.text2,
				},
			},
			'&.total': {
				fontWeight: 700,
			},
		},
	},

	[`@media screen and (max-width: ${breakpoints.md})`]: {
		'.topContainer': {
			display: 'none',
		},

		'.divider': {
			display: 'none',
		},

		'.bottomContainer': {
			'&__title': {
				display: 'block',
				fontSize: '1.4rem',
				fontWeight: 'bold',
				color: '#4D4D4D',
				margin: '2.3rem 0 1.9rem 0',
			},
		},
	},
});
