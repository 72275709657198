import classNames from "classnames";
import ActionsMenuStyle from "./ActionsMenu.style";
import Image from "../../Image";
import { useHistory } from "react-router-dom";
import { isNullOrUndefined } from "../../../Utilities";
import { equityMenuActions, trusteeMenuActions } from "../ActionsMenu/ItemsByProduct"
import { useEffect, useState } from "react";
import useRootStore from "../../../Hooks/useRootStore";


export type MenuActionType = {
	label: string;
	icon: string;
	separator?: boolean;
	background?: boolean;
	onClick?: (params?: unknown) => void;
	qaid: string;
	disabled?: boolean;
	width?: string | number;
	navigateTo?: string;
};

export interface MainMenuProps {
	actions: MenuActionType[];
}

const ActionsMenu = ({ actions }: MainMenuProps) => {
	const { companyStore } = useRootStore()
	const history = useHistory()
	const [menuItems, setMenuItems] = useState<MenuActionType[]>(actions)

	const setMenuItemsHandler = () => {
		// if (props.product === Product.EquityPlans || props.product === Product.TrusteeEquity) {
		companyStore.company.pivotalEquityPlans ? setMenuItems(trusteeMenuActions) : setMenuItems(equityMenuActions);
		// }
	};

	useEffect(() => {
		setMenuItemsHandler()
	}, [companyStore.company.pivotalEquityPlans])


	return (
		<div className={ActionsMenuStyle}>
			{menuItems && menuItems
				.filter((action) => !action.disabled)
				.map(({ label, icon, separator, background, qaid, onClick, width, navigateTo }) => (
					<div
						key={label}
						className={classNames(`${ActionsMenuStyle}__item`, { separator, bc: background })}
						onClick={() =>
							isNullOrUndefined(navigateTo)
								? onClick
								: history.push(navigateTo)
						}
						data-qaid={qaid}
					>
						<Image width={width} src={icon} alt={label} />
						{label}
					</div>
				))}
		</div>
	);
};

export default ActionsMenu;
