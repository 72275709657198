import { CSSProperties, Dispatch, SetStateAction, useState } from 'react';
import CheckBox from '../../../../../Shared/Components/CheckBox/CheckBox';
import AutoCompleteInput from '../../../../../Shared/Components/Input/AutoCompleteInput';
import Tooltip from '../../../../../Shared/Components/Tooltip';
import { NumberOption } from '../../../../../Models/API/All/NumberOption';
import { isString } from '../../../../../Shared/Utilities';

export type SyncTrustreeProps = {
	syncedId?: number | null;
	setSyncedId?: Dispatch<SetStateAction<number | null>>;
	syncOptions?: NumberOption[];
	isViewMode?: boolean;
	style?: CSSProperties;
};

const SyncTrustree = ({ setSyncedId, syncOptions, syncedId, isViewMode, style }: SyncTrustreeProps) => {
	const [isSync, setIsSync] = useState<boolean>(!!syncedId);

	return (
		<>
			{!isViewMode && (
				<CheckBox
					qaid="AddShareholder.CheckBox.Sync"
					label={
						<div className="align-center gap-1">
							Sync personal options data
							<Tooltip
								placement="top"
								title={
									<div className="text-center">
										Sync options data with associated beneficiary entity
										<br />
										from altshare stock admin platform
									</div>
								}
								className="ml-2"
							/>
						</div>
					}
					isChecked={isSync}
					onClick={(val) => {
						setIsSync(val);
						!val && setSyncedId?.(null);
					}}
					className="align-center"
					style={style}
				/>
			)}
			{(!!syncedId || !isViewMode) && (
				<AutoCompleteInput
					qaid="AddShareholder.Select.BeneficiarySync"
					options={syncOptions}
					value={syncedId ?? undefined}
					onChange={(value) => !isString(value) && value && setSyncedId?.(value)}
					style={{ opacity: isSync ? 1 : 0, transition: 'opacity .1s linear' }}
					placeholder="Select beneficiary name..."
					isViewMode={isViewMode}
					label={isViewMode ? 'Synced beneficiary' : undefined}
				/>
			)}
		</>
	);
};

export default SyncTrustree;
