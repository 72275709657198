import { css } from '@emotion/css';
import appConfig from '../../../../../../config/config';

const NewEquityPlanStyle = css({
	label: 'NewEquityPlan',
	display: 'flex',
	flexDirection: 'column',
	padding: '6rem 7.7rem 6.7rem 7.7rem',
	width: '100rem',

	'.innerButtons': {
		position: 'relative',
	},

	'&__loading-spinner': {
		minHeight: '40rem',
	},

	'&__settingSection': {
		width: '100%',
		maxWidth: '120rem',
		alignSelf: 'center',
		paddingTop: '4rem',
		marginTop: '2rem',

		'> h2': {
			fontSize: '2.2rem',
		},
	},
	'&__buttonsContainer': {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		'> span': {
			fontSize: '1.4rem',
			flex: 1,
			alignSelf: 'flex-start',
			color: appConfig.style.colors.text2,
		},
	},
});

export default NewEquityPlanStyle;
