import { Dispatch, SetStateAction } from "react";
import { IContact, IContactCreate, IContactDetails } from "../../../../../../../Models/API/Contact/contact";
import AutoCompleteInput from "../../../../../../../Shared/Components/Input/AutoCompleteInput";
import PhoneInput from "../../../../../../../Shared/Components/Input/PhoneInput";
import TextInput from "../../../../../../../Shared/Components/Input/TextInput";
import Flex from "../../../../../../../Shared/Components/Layout/Flex";
import Select from "../../../../../../../Shared/Components/Select/Select";
import { shareholderRoleOptions } from "../../../../../../../Shared/Config";
import { ObjectValidationStatus } from "../../../../../../../Shared/ObjectValidator";
import { countries } from "../../../../../../../Shared/StaticData/countries";
import { isNullOrUndefined, isNumber } from "../../../../../../../Shared/Utilities";
import CompanyUserAutoComplete from "../../../../../../../Shared/Components/CompanyUserAutoComplete";
import { InputRefs, InputValidationRef } from "../../../../../../../Shared/Hooks/useFormValidation";
import AddShareholderStyle from "../AddShareholder.style";
import { AppendStateInputHandlerType } from "../../../../../../../Shared/Hooks/useAppendState";
import useRootStore from "../../../../../../../Shared/Hooks/useRootStore";

interface Errors extends Omit<ObjectValidationStatus<Partial<IContactCreate>>, "contactDetails"> {
    contactDetails?: ObjectValidationStatus<IContactDetails> | null;
}

interface Props {
    contact: IContactCreate;
    onInputHandler: AppendStateInputHandlerType;
    setContact: Dispatch<SetStateAction<IContactCreate>>;
    formValidationState?: Errors | null;
    onSuggestionSelected?: (contact: IContact) => void;
    isViewMode?: boolean;
    expand?: boolean;
    disabled?: boolean;
    inputRefs: InputRefs<IContactCreate>;
    contactInputRefs?: InputRefs<IContactDetails>;
    isEmailRequired?: boolean;
}

const LegalEntityForm = ({
    contact,
    setContact,
    onInputHandler,
    formValidationState,
    onSuggestionSelected,
    isViewMode,
    expand,
    disabled,
    contactInputRefs,
    inputRefs,
    isEmailRequired,
}: Props) => {
    const {
        contactStore: { isContactUser },
    } = useRootStore();

    return (
		<div className={`${AddShareholderStyle}__form`}>
			<div className={`${AddShareholderStyle}__form-row`}>
				<CompanyUserAutoComplete
					searchBy="legalEntityCompanyName"
					label="Organization name"
					onSuggestionSelected={onSuggestionSelected}
					required
					value={contact.companyName}
					name="companyName"
					onChange={onInputHandler}
					error={formValidationState?.companyName?.message}
					isViewMode={isViewMode}
					disabled={disabled}
					ref={(el: InputValidationRef) => (inputRefs.companyName = el)}
				/>
				<Select
					qaid="AddShareholder.Select.Role"
					options={shareholderRoleOptions}
					label="Relationship"
					onChange={onInputHandler}
					name="contactDetails.role"
					value={contact.contactDetails?.role}
					error={formValidationState?.contactDetails?.role?.message}
					ref={(el: InputValidationRef) => {
						if (contactInputRefs) contactInputRefs.role = el;
					}}
					className="mb-0"
					isViewMode={isViewMode}
					disabled={disabled}
					required
				/>
				<AutoCompleteInput
					label="Country of incorporation"
					qaid="AddShareholder.Input.Nationality"
					name="contactDetails.nationality"
					value={contact.contactDetails?.nationality}
					onChange={onInputHandler}
					options={countries}
					autoComplete="nope"
					containerClassName="mb-0"
					isViewMode={isViewMode}
					disabled={disabled}
				/>
				<TextInput
					label="Corporate number"
					qaid="AddShareholder.Input.CorporateNumber"
					name="contactDetails.employeeNumber"
					value={contact.contactDetails?.employeeNumber}
					onChange={onInputHandler}
					isViewMode={isViewMode}
					disabled={disabled}
				/>
			</div>

			{!expand && (
				<Flex justify="start" margin="2rem 0 0 0" align="end" width="fit-content" className="bold" height="2.4rem">
					Contact info
				</Flex>
			)}

			<div className={`${AddShareholderStyle}__form-row mt`}>
				<TextInput
					label="First name"
					qaid="AddShareholder.Input.FirstName"
					name="firstName"
					value={contact.firstName}
					onChange={onInputHandler}
					containerClassName="mb-0"
					error={formValidationState?.firstName?.message}
					isViewMode={isViewMode}
					disabled={disabled}
					ref={(el: InputValidationRef) => (inputRefs.firstName = el)}
					required={isEmailRequired}
				/>
				<TextInput
					label="Last name"
					qaid="AddShareholder.Input.LastName"
					name="lastName"
					value={contact.lastName}
					onChange={onInputHandler}
					containerClassName="mb-0"
					error={formValidationState?.lastName?.message}
					isViewMode={isViewMode}
					disabled={disabled}
					ref={(el: InputValidationRef) => (inputRefs.lastName = el)}
					required={isEmailRequired}
				/>
				<TextInput
					label="Business email"
					qaid="AddShareholder.Input.Email"
					name="email"
					value={contact.email === 'email@email.com' ? undefined : contact.email}
					onChange={onInputHandler}
					containerClassName="mb-0"
					error={formValidationState?.email?.message}
					isViewMode={isViewMode}
					disabled={disabled}
					ref={(el: InputValidationRef) => (inputRefs.email = el)}
					required={isEmailRequired}
				/>
				<PhoneInput
					onChange={(mobile: string, mobilePrefix?: string) => {
						setContact((prevContact) => ({
							...prevContact,
							mobilePrefix: mobile ? mobilePrefix : undefined,
							mobile: mobile || undefined,
						}));
					}}
					label="Mobile"
					qaid="AddShareholder.Input.Mobile"
					value={contact.mobile}
					mobilePrefix={contact.mobilePrefix}
					containerClassName="mb-0"
					isViewMode={isViewMode}
					disabled={disabled}
					error={formValidationState?.mobile?.message}
				/>
			</div>
		</div>
	);
};

export default LegalEntityForm;
