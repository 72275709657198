export type DataCollectionComment = {
	dataCollectionId: string;
	fieldType: DataCollectionFieldTypeEnum;
	commentDescription: string;
	isValuationTeam: boolean;
	isRead: boolean;
	createDate: number;
	id: string;
};

export enum DataCollectionFieldTypeEnum {
	None,
	ValuationDate,
	InvestmentExist,
	InvestmentType,
	CompanyProfitable,
	AboutUs,
	NextRoundValuation,
	CapTable,
	IssuanceDocument,
	ArticleOfAssociation,
	FinancialStatement,
	DiscountCashFlow,
	Dcf,
	SafeOrConvertible,
	TimeToLiquidity,
	CompanyProfitableInFuture,
	GrantStatus,
	General,
	NextFinancialRoundDate,
	IsOutstandingWarrants,
	IsSecondaryTransaction,
	IsWarrantsGranted = 30,
	ShareClassIndication,
	AmountOfSafe,
	AmountOfNextRound,
	SectorandIndustry,
	FourYearForcast,
	RecentValuationReport,
	EffectiveTaxRate,
	AccountingBalanceCash,
	AccountingBalanceLoans,
	AccountingBalanceLeases,
	EstimationNextRound,
}
