import { css } from '@emotion/css';
import { observer } from 'mobx-react-lite';
import { ReactElement, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
	IC_EDIT2,
	IC_LETTER_CANCELLED,
	IC_LETTER_READY,
	IC_LETTER_SENT,
	IC_LETTER_SIGNED,
	IC_QA_CANCEL,
	IC_QA_EXERCISE,
	IC_QA_SELL,
	IC_SEND_FOR_SIGNATURE,
	IC_SEND_FOR_SIGNATURE_ACTIVE,
	IC_TRASH2,
	IC_USER_CIRCLE
} from '../../../../Assets';
import { IContact } from '../../../../Models/API/Contact/contact';
import { ElectronicSignatureStatus } from '../../../../Models/API/Document/eletronic-signature-status-enum';
import { ElectronicSignature } from '../../../../Models/API/Document/eletronics-signature';
import { ElectronicSignaturePayload } from '../../../../Models/API/Document/send-for-signature-payload';
import { TemplateTypeEnum } from '../../../../Models/API/Document/template-type-enum';
import { GrantDocumentDetails, GrantTable } from '../../../../Models/App/EquityPlans/Grants';
import { OrderType } from '../../../../Models/App/EquityPlans/Order';
import { Routes } from '../../../../Routes';
import AddButton from '../../../../Shared/Components/Button/AddButton';
import Button from '../../../../Shared/Components/Button/Button';
import Clickable from '../../../../Shared/Components/Clickable/Clickable';
import ContactsEmailFillTable from '../../../../Shared/Components/ContactsEmailFillTable';
import Image from '../../../../Shared/Components/Image';
import Flex from '../../../../Shared/Components/Layout/Flex';
import Table, { TableColumn } from '../../../../Shared/Components/Table/Table';
import { Cell } from '../../../../Shared/Components/Table/Table.Style';
import useModal from '../../../../Shared/Hooks/useModal';
import useRootStore from '../../../../Shared/Hooks/useRootStore';
import { grantTypes } from '../../../../Shared/StaticData/equityPlans';
import { formatDate, formatNumber, getCurrency, isDateValid } from '../../../../Shared/Utilities';
import appConfig from '../../../../config/config';
import DocumentPreviewInfo from '../../../Documents/Components/Preview/DocumentPreviewInfo';
import useDocument from '../../../Documents/helpers/hooks/useDocument';
import useEsopModals from '../../useEsopModals';
import TableActions from '../TableActions/TableActions';

const style = css({
	'.actions': {
		opacity: 0,
		visibility: 'hidden',
		position: 'absolute',
		background: '#ffffff',
		transition: 'opacity .3s',
		right: 0,
		height: '100%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		paddingLeft: '1rem',
	},
	'.benName': {
		cursor: 'pointer',
	},
	'&:hover': {
		'.actions': {
			opacity: 1,
			visibility: 'visible',
		},
		'.benName': {
			// transform: "scale(1.05)",
			// textDecoration: "underline",
		},
	},
	'&__ben-cel': {
		'&:hover': {
			color: appConfig.style.colors.color1,
			textDecoration: 'underline',
		},
	},
});

interface Props {
	header: ReactElement;
	expand?: boolean;
}

const GrantsTable = ({ header, expand }: Props) => {
	const history = useHistory();
	const [isSendForSignature, setIsSendForSignature] = useState<boolean>(false);
	const [isSendingDocs, setIsSendingDocs] = useState<boolean>(false);
	const { equityPlansStore, documentsStore, contactStore } = useRootStore();
	const { showAsyncModal } = useModal();

	const { openGrntCard, qA_newOrderHandler, showCancelationModal, showDeleteGrantModal, openBeneficiaryModal, showAddNewGrantWithBeneficiary } =
		useEsopModals();
	// const [isDeleting, setIsDeleteing] = useState<boolean>(false);
	const [openedMenuId, setOpenedMenuId] = useState<number>(-1);
	const [hoveredMenuId, setHoveredMenuId] = useState<number>(-1);
	const [selectedDocs, setSelectedDocs] = useState<GrantDocumentDetails[]>([]);
	const { getPreviewInfo, previewDocument } = useDocument();
	const [tooltips, setTooltips] = useState<Record<string, ElectronicSignature>>({});

	const grantColumns: TableColumn<GrantTable>[] = [
		{
			label: 'Grant number',
			name: 'grantNumber',
			sortable: true,
			isOverflow: true,
			render: (grnt, gNum: string) => {
				const activeDocument = grnt.documentDetailsDto?.find(doc => !doc.isCancelled);
				const canceledDocument = grnt.documentDetailsDto?.find(doc => doc.isCancelled);
				return (
					<Cell style={{ position: 'relative' }} isOverflow>
						<Clickable qaid="GrantsTable.Button.GrantNumber" className="text-ellipsis" applyStyle={hoveredMenuId === grnt.grantId} justify="start">
							{gNum}
						</Clickable>
						<TableActions
							cssClass="actions"
							autoClose={() => openedMenuId !== grnt.grantId || hoveredMenuId !== grnt.grantId}
							items={[
								{
									label: "Edit",
									icon: IC_EDIT2,
									qaid: 'GrantTable.QA.Edit',
									onClick: () => openGrntCard(grnt.grantId, grnt.beneficiaryId, true),
									disabled: !!activeDocument,
									customTooltip: !!activeDocument ? 'Grant cannot be edit since there is a grant letter linked to it' : "Edit"
								},
								{
									label: "Delete",
									icon: IC_TRASH2,
									qaid: 'GrantTable.QA.Delete',
									onClick: () => showDeleteGrantModal(grnt.grantId),
									disabled: !!activeDocument || !!canceledDocument,
									customTooltip: !!activeDocument || !!canceledDocument ? 'Grant cannot be deleted since there is a grant letter linked to it' : "Delete"
								},
								{
									label: 'Exercise',
									icon: IC_QA_EXERCISE,
									qaid: 'GrantTable.QA.Exercise',
									hide: grnt.grantType === 1,
									onClick: () => qA_newOrderHandler(OrderType.Exercise, grnt.grantId, grnt.beneficiaryId),
								},
								{
									label: 'Sale',
									icon: IC_QA_SELL,
									qaid: 'GrantTable.QA.Sell',
									onClick: () => qA_newOrderHandler(OrderType.Sell, grnt.grantId, grnt.beneficiaryId),
								},
								{
									label: 'Cancel',
									icon: IC_QA_CANCEL,
									qaid: 'GrantTable.QA.Cancel',
									onClick: () => showCancelationModal(false, false, grnt.grantId, grnt.beneficiaryId),
								},
							]}
						/>
					</Cell>
				);
			},
			style: { flex: '1 0 18rem' },
		},
		{
			label: 'Beneficiary',
			name: 'fullName',
			format: (val: { firstName: string; lastName: string }) => `${val?.firstName} ${val?.lastName}`,
			sortable: true,
			// style: { flex: 3 },
			render: (obj, val: { firstName: string; lastName: string }) => {
				return (
					<Cell
						className={`${style}__ben-cel`}
						onMouseEnter={() => setHoveredMenuId(-1)}
						onMouseLeave={() => setHoveredMenuId(obj.grantId)}
						onClick={(e) => {
							e.stopPropagation();
							openBeneficiaryModal(obj.beneficiaryId);
						}}
					>
						{val?.firstName ? (
							<>
								<Image width="3.1rem" src={IC_USER_CIRCLE} alt="user" />
								<span className="benName">{`${val?.firstName}  ${val?.lastName}`}</span>
							</>
						) : (
							<></>
						)}
					</Cell>
				);
			},
			style: { flex: '1 0 16rem' },
		},
		{
			label: 'Grant letter',
			name: 'isSigned',
			align: 'center',
			justify: 'center',
			// style: { flex: "0 0 11.5rem" },
			style: { flex: '1 0 12rem', display: 'flex' },
			render(obj, value) {
				if (!obj.documentDetailsDto || !obj.documentDetailsDto.length) return;
				const latestDocument = obj.documentDetailsDto?.find(doc => !doc.isCancelled) || obj.documentDetailsDto[obj.documentDetailsDto.length - 1];
				const tooltipData = tooltips[latestDocument.documentId];

				return (
					<Image
						className="clickable"
						onClick={(e) => {
							e.stopPropagation();
							previewDocument(latestDocument.documentId, latestDocument.templateId, latestDocument.documentName);
						}}
						tooltipPlacement="right"
						key={latestDocument.documentId}
						tooltip={
							latestDocument.isCancelled ? (
								'Canceled'
							) : latestDocument.electronicSignatureStatus === ElectronicSignatureStatus.DocumentCreated ? (
								'Not sent yet'
							) : (
								<DocumentPreviewInfo {...getPreviewInfo(tooltipData, latestDocument.templateId, true)} />
							)
						}
						width="4.5rem"
						src={
							latestDocument.isCancelled || latestDocument.isDeleted
								? IC_LETTER_CANCELLED
								: latestDocument.electronicSignatureStatus === ElectronicSignatureStatus.DocumentCreated
									? IC_LETTER_READY
									: latestDocument.electronicSignatureStatus === ElectronicSignatureStatus.ElectronicSignatureCreated
										? IC_LETTER_SENT
										: latestDocument.electronicSignatureStatus === ElectronicSignatureStatus.ElectronicSignatureSigned
											? IC_LETTER_SIGNED
											: undefined
						}
					/>
				);
			},
		},
		{
			label: 'Award type',
			name: 'grantType',
			sortable: true,
			style: { flex: '1 0 12rem' },
			format(val) {
				return grantTypes[val]?.label;
			},
		},
		{
			label: 'Grant date',
			name: 'grantDate',
			format: (val: any) => formatDate(val),
			sortType: 'date',
			style: { flex: '1 0 13rem' },
			sortable: true,
		},
		{
			label: 'Exercise price',
			name: 'exercisePrice',
			sortType: 'number',
			style: { flex: '1 0 13rem' },
			sortable: true,
			render: (val) => val.beneficiaryId === -1 ? undefined
				: <span>{val.grantType === 1 ? '-------' : `${getCurrency(val.exercisePriceCurrency).symbol} ${formatNumber(val.exercisePrice)}`}</span>
		},
		{
			label: 'Vesting start date',
			name: 'vestStartDate',
			format: (value, row) => row?.beneficiaryId === -1 ? undefined : formatDate(value),
			sortType: 'date',
			style: { flex: '1 0 13rem' },
			sortable: true,
		},
		{
			label: 'Equity plan',
			name: 'planName',
			sortable: true,
			style: { flex: '1 0 12rem' },
		},
		{
			label: 'Granted',
			name: 'granted',
			sortType: 'number',
			sortable: true,
			format: (val) => formatNumber(val),
		},
		{
			label: 'Unvested',
			name: 'unvested',
			sortable: true,
			sortType: 'number',
			format: (val) => formatNumber(val),
		},
		{
			label: 'Exercisable',
			name: 'exercisable',
			sortable: true,
			sortType: 'number',
			format: (val) => formatNumber(val),
			// tooltip: "Vested as of selected date",
		},
		{
			label: 'Saleable',
			name: 'sellable',
			sortable: true,
			sortType: 'number',
			format: (val) => formatNumber(val),
		},
		{
			label: 'Sold',
			name: 'sold',
			sortable: true,
			sortType: 'number',
			format: (val) => formatNumber(val),
		},
		{
			label: 'Canceled',
			name: 'canceled',
			sortable: true,
			sortType: 'number',
			format: (val) => formatNumber(val),
		},
		{
			name: 'outstanding',
			label: 'Outstanding',
			sortable: true,
			sortType: 'number',
			style: { background: appConfig.style.colors.total },
			format: (val) => formatNumber(val),
		},
	];

	useEffect(() => {
		equityPlansStore.GetGrantsForCompany();
	}, [equityPlansStore.dataAsOfDate, equityPlansStore.selectedPlan]);

	useEffect(() => {
		if (
			equityPlansStore.companyGrants?.some((g) =>
				g.documentDetailsDto?.some((d) => d.electronicSignatureStatus !== ElectronicSignatureStatus.DocumentCreated)
			)
		) {
			documentsStore.getTemplates();
		}
	}, [equityPlansStore.companyGrants]);

	const isDocCreated = (grant: GrantTable) => {
		return grant.documentDetailsDto?.[grant.documentDetailsDto?.length - 1].electronicSignatureStatus === ElectronicSignatureStatus.DocumentCreated;
	};

	const onSelectHandler = (grants: GrantTable[]) => {
		setSelectedDocs(grants.map((s) => s.documentDetailsDto?.[s.documentDetailsDto.length - 1]) as GrantDocumentDetails[]);
	};

	const onSendForSignature = async () => {
		const templates = (await documentsStore.getTemplates()).data?.data;
		if (!templates) return;

		const contacts = selectedDocs.reduce(
			(acc, doc) => {
				const grant = equityPlansStore.companyGrants?.find((g) => g.grantId === doc.recordId);
				if (!grant?.sourceContactId) return acc;

				const contact = contactStore.getContactById(grant?.sourceContactId);
				if (contact) {
					!contact?.email && acc.editableContacts.push(contact.contactId);
					acc.contacts.push(contact);
				}

				return acc;
			},
			{ editableContacts: [] as number[], contacts: [] as IContact[] }
		);

		if (contacts.editableContacts.length) {
			const isCompleted = await showAsyncModal({
				body: (props) => (
					<ContactsEmailFillTable
						title="Please confirm email addresses for all beneficiaries you send grant letter to:"
						actionLabel="Send for signature"
						{...props}
						{...contacts}
					/>
				),
				width: '90%',
				maxWidth: '100rem',
				maxHeight: '95rem',
				isFrameless: true,
			});

			if (!isCompleted) return;
		}

		const signatures = selectedDocs.reduce((acc, selectedDoc) => {
			const template = templates.find((template) => template.templateId === selectedDoc.templateId);

			if (template) {
				acc.push({
					rule: template.signinRule,
					document: {
						documentName: selectedDoc.documentName,
						id: selectedDoc.documentId,
						recordId: selectedDoc.recordId,
						securityId: selectedDoc.ShareCertificateId
					},
					signerDetailsList: template.signers.map((signer) => {
						let contactId = signer.contactId;

						if (contactId === null) {
							contactId =
								equityPlansStore.companyGrants?.find((g) => g.documentDetailsDto?.some((d) => d.documentId === selectedDoc.documentId))
									?.sourceContactId ?? 0;
						}

						return {
							contactId,
							isDesignatedCompanySignatory: signer.isDesignatedCompanySignatory,
							positionForSignature: signer.positionForSignature,
						};
					}),
				});
			}
			return acc;
		}, [] as ElectronicSignaturePayload[]);

		if (!signatures.length) return;

		await documentsStore.sendForSignature({ type: TemplateTypeEnum.GrantLetter, electronicSignatureDetailsList: signatures });
		await equityPlansStore.GetGrantsForCompany();
		setIsSendForSignature(false);
	};

	const onTooltipIntersection = async (grant: GrantTable) => {
		if (!grant.documentDetailsDto) return;


		const latestDocument = grant.documentDetailsDto?.find(doc => !doc.isCancelled) || grant.documentDetailsDto[grant.documentDetailsDto.length - 1];

		if (latestDocument.electronicSignatureStatus === ElectronicSignatureStatus.DocumentCreated) return;

		const res = await documentsStore.getDocumentSignatures(latestDocument.documentId);
		if (res.data) {
			setTooltips((prev) => ({ ...prev, [latestDocument.documentId]: res.data.data }));
		}
	}

	return (
		<Table
			showTotal={!isSendForSignature}
			rowClassName={style}
			columns={grantColumns}
			scrollAfterRows={expand ? 16 : 7}
			rows={isSendForSignature ? equityPlansStore.companyGrants?.filter((g) => isDocCreated(g)) : equityPlansStore.companyGrants}
			onRowClick={(obj) => openGrntCard(obj.grantId, obj.beneficiaryId)}
			searchBy={['grantNumber', 'fullName']}
			exportToExcel={{ fileName: 'Grants', sheetName: 'Grants' }}
			fullscreen
			renderBottom={
				isSendForSignature ? (
					<Flex align="center" justify="end">
						<Button
							width="20rem"
							qaid="GrantsTable.Button.SendForSignature"
							isLoading={isSendingDocs}
							label="Send for signature"
							style={{ alignSelf: 'center' }}
							disabled={!selectedDocs.length}
							onClick={async () => {
								setIsSendingDocs(true);
								await onSendForSignature();
								setIsSendingDocs(false);
							}}
						/>
					</Flex>
				) : (
					<Flex align="center" justify="start">
						<AddButton onClick={showAddNewGrantWithBeneficiary} qaid="GrantsTable.Button.AddNewGrant" label="Add new grant" />
					</Flex>
				)
			}
			onRowSelect={isSendForSignature ? (selected) => onSelectHandler(selected as GrantTable[]) : undefined}
			filterBy={grantColumns.filter((col) => col.name !== 'actions').map((col) => col.name as keyof GrantTable)}
			customHeaderRender={header}
			onRowEnter={(row) => setHoveredMenuId(row.grantId)}
			onRowLeave={() => setHoveredMenuId(-1)}
			onRowIntersection={onTooltipIntersection}
			intersectionSettings={{ triggerOnce: false }}
			customActionsRender={
				<>
					<Image
						className="clickable"
						width="5.7rem"
						src={isSendForSignature ? IC_SEND_FOR_SIGNATURE_ACTIVE : IC_SEND_FOR_SIGNATURE}
						data-qaid="GrantsTable.Button.SelectAvailableSignatures"
						onClick={() => setIsSendForSignature((prev) => !prev)}
						tooltip="Send for signature"
					/>
				</>
			}
		/>
	);
};
export default observer(GrantsTable);
