import { css } from '@emotion/css';
import appConfig from '../../../../../../config/config';
import TextInput from '../../../../../../Shared/Components/Input/TextInput';
import Select from '../../../../../../Shared/Components/Select/Select';
import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { ModalBodyProps } from '../../../../../../Shared/Components/Modal/types';
import useRootStore from '../../../../../../Shared/Hooks/useRootStore';
import { TemplateTypeEnum } from '../../../../../../Models/API/Document/template-type-enum';
import Button from '../../../../../../Shared/Components/Button/Button';
import { Transaction } from '../../../../../../Models/App/CapTable/Transaction';
import { CreateShareCertificateType } from '../../../../../../Models/API/CapTable/document-types';
import { IContact, IContactCreate } from '../../../../../../Models/API/Contact/contact';
import { ExecuteResponse } from '../../../../../../Services/BaseService';
import { commonValidators } from '../../../../../../Shared/ObjectValidator';
import { TransactionTypesEnum } from '../../../../../../Models/API/enums';
import Image from '../../../../../../Shared/Components/Image';
import { IC_QUESTION_MARK_ORANGE, IC_QUESTION_MARK_ORANGE_FILL } from '../../../../../../Assets';
import CompanyUserAutoComplete from '../../../../../../Shared/Components/CompanyUserAutoComplete';
import useModal from '../../../../../../Shared/Hooks/useModal';
import Flex from '../../../../../../Shared/Components/Layout/Flex';
import { InputValidationRef, useFormValidation } from '../../../../../../Shared/Hooks/useFormValidation';
import { useHistory } from 'react-router-dom';
import { Routes } from '../../../../../../Routes';

type Props = ModalBodyProps & {
	obj: Transaction;
	contact: IContactCreate;
	updateContact: (contact: Partial<IContactCreate>, isUpdate?: boolean) => Promise<ExecuteResponse<IContact> | undefined>;
	setReupdateContact?: (toUpdateContact: boolean) => void;
};

const modalStyle = css({
	padding: '6rem 7.7rem',
	whiteSpace: 'nowrap',

	'>h3': {
		fontSize: '2.5rem',
		margin: '3rem 0',
	},

	'.wrapDivider': {
		display: 'flex',
		flexDirection: 'column',
		// alignItems: 'center',
		justifyContent: 'space-between',
		fontWeight: '500',
		marginBottom: '3rem',
		gap: '5px',
	},

	'.titleWrap': {
		display: 'flex',
		justifyContent: 'space-between',
		marginBottom: '4rem',
		alignItems: 'center'
	},

	'.uploadText': {
		color: appConfig.style.colors.color1,
		cursor: 'pointer'
	},

	'.divider': {
		backgroundColor: appConfig.style.colors.table,
		width: '100%',
		height: '0.1rem',
	},

	'.wrapInputs': {
		display: 'grid',
		gridTemplateColumns: 'repeat(2, 1fr)',
		gridGap: '2.4rem',
		marginBottom: '3rem',
	},

	'.errorMsg': {
		color: appConfig.style.colors.danger,
	},
});

const CreateShareCertificate: React.FC<Props> = ({ obj, onConfirmHandler, contact, updateContact, setReupdateContact }) => {
	const [shareCertificateLetterDetails, setShareCertificateLetterDetails] = React.useState<{
		templateId: string;
		userEmail: string;
		firstName: string;
		lastName: string;
	}>({
		templateId: '',
		userEmail: contact.email || '',
		firstName: contact.firstName || '',
		lastName: contact.lastName || '',
	});
	const history = useHistory();

	const { formValidationState, inputRefs, validateForm } = useFormValidation({
		form: shareCertificateLetterDetails,
		schema: {
			firstName: [commonValidators.required()],
			lastName: [commonValidators.required()],
			templateId: [commonValidators.required()],
			userEmail: [commonValidators.required(), commonValidators.emailValidator],
		},
	});
	const { shareHolderStore, documentsStore, equityPlansStore } = useRootStore();
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const { showModal } = useModal();

	React.useEffect(() => {
		documentsStore.getTemplates();
	}, []);

	const onCreateLette = async () => {
		if (!validateForm()) return;

		const personId = obj.typeId === TransactionTypesEnum.SecondaryTransaction ? obj.secondProjectPersonId : obj.projectPersonId;
		if (personId === undefined || obj.projectID === undefined) return;

		setIsLoading(true);
		if (!contact.email) {
			await updateContact({
				email: shareCertificateLetterDetails.userEmail,
				contactId: contact.contactId,
				firstName: shareCertificateLetterDetails.firstName,
				lastName: shareCertificateLetterDetails.lastName,
			});
			setReupdateContact?.(true);
		}

		const data: CreateShareCertificateType = {
			projectID: obj.projectID,
			templateId: shareCertificateLetterDetails.templateId,
			projectPersonID: personId,
			transactionId: obj.transactionId,
			transactionType: obj.typeId,
			email: shareCertificateLetterDetails.userEmail,
		};

		const res = await shareHolderStore.createCertificateLetter(data);
		setIsLoading(false);

		res.data?.isSucceeded && onConfirmHandler?.();
	};

	const onSuggestionSelected = async (contactSelected: IContact) => {
		const res = await shareHolderStore.addPerson(contactSelected);

		if (res.data) {
			showModal({
				type: 'confirm',
				title: 'Update Contact',
				body: () => (
					<Flex direction="column" justify="center">
						<div>
							You’ve selected to add
							<span className="bold">
								{contactSelected.isLegalEntity
									? ` ${contactSelected.organizationName} `
									: ` ${contactSelected.firstName} ${contactSelected.lastName} `}
							</span>
							{contactSelected.isLegalEntity ? 'which' : 'who'} already exists in the company records.
						</div>
						<div>Do you want to proceed and merge?</div>
					</Flex>
				),
				confirmButton: {
					label: 'Proceed',
				},
				onConfirm: () => {
					setShareCertificateLetterDetails({
						...shareCertificateLetterDetails,
						userEmail: contactSelected.email || '',
						firstName: contactSelected.firstName || '',
						lastName: contactSelected.lastName || '',
					});
				},
			});
		}
	};

	return (
		<div className={modalStyle}>
			<div className='titleWrap'>
				<h3>Create share certificate</h3>
				{/* Linoy: AN1-4525 */}
				{/* <span className='uploadText'>Upload existing?</span> */}
			</div>
			<div className="wrapDivider">
				<span>Share certificate details</span>
				<div className="divider"></div>
			</div>
			<div className="wrapInputs">
				<TextInput qaid="CreateCertificateLetter.Input.CertificateId" label="Certificate ID" placeholder="Auto-generated" value={''} disabled />
				<Select
					qaid="CreateCertificateLetter.Select.Template"
					label="Template"
					value={shareCertificateLetterDetails.templateId}
					options={documentsStore.getTemplatesOptionsByType(TemplateTypeEnum.ShareCertificate)}
					name="templateId"
					onChange={(value) => setShareCertificateLetterDetails({ ...shareCertificateLetterDetails, templateId: value as string })}
					required
					customAction={{ children: <div style={{ color: appConfig.style.colors.color1 }}>Create new template</div>, onClick: () => history.push(`${Routes.docs.index}?tab=templates`) }}
					error={formValidationState?.templateId?.message}
					ref={(el: InputValidationRef) => (inputRefs.templateId = el)}
				/>
				{!contact.isLegalEntity && (
					<TextInput
						qaid="CreateCertificateLetter.Input.userEmail"
						label="Shareholder email"
						name="userEmail"
						value={shareCertificateLetterDetails.userEmail}
						onChange={(value) => setShareCertificateLetterDetails({ ...shareCertificateLetterDetails, userEmail: value })}
						required
						disabled={!!contact.email}
						error={formValidationState?.userEmail?.message}
						ref={(el: InputValidationRef) => (inputRefs.userEmail = el)}
					/>
				)}
			</div>
			{contact.isLegalEntity && (
				<>
					<div className="wrapDivider">
						<span>
							Authorized signatory{' '}
							<Image
								width="2rem"
								pointer
								src={IC_QUESTION_MARK_ORANGE}
								srcHover={IC_QUESTION_MARK_ORANGE_FILL}
								alt=""
								tooltip="The authorized signatory will be assigned to accept 
								and e-sign share certificates issued for this Legal entity"
							/>
						</span>
						<div className="divider"></div>
					</div>
					<div className="wrapInputs">
						<CompanyUserAutoComplete
							searchBy="firstName"
							label="First name"
							name="firstName"
							value={shareCertificateLetterDetails.firstName}
							onChange={(value) => setShareCertificateLetterDetails({ ...shareCertificateLetterDetails, firstName: value || '' })}
							onSuggestionSelected={onSuggestionSelected}
							disabled={!!contact.firstName}
							required
							error={formValidationState?.firstName?.message}
							ref={(el: InputValidationRef) => (inputRefs.firstName = el)}
						/>
						<TextInput
							qaid="CreateCertificateLetter.Input.lastName"
							label="Last name"
							name="lastName"
							value={shareCertificateLetterDetails.lastName}
							onChange={(value) => setShareCertificateLetterDetails({ ...shareCertificateLetterDetails, lastName: value })}
							required
							disabled={!!contact.lastName}
							error={formValidationState?.lastName?.message}
							ref={(el: InputValidationRef) => (inputRefs.lastName = el)}
						/>
						<TextInput
							qaid="CreateCertificateLetter.Input.userEmail"
							label="Business email"
							name="userEmail"
							value={shareCertificateLetterDetails.userEmail}
							onChange={(value) => setShareCertificateLetterDetails({ ...shareCertificateLetterDetails, userEmail: value })}
							required
							disabled={!!contact.email}
							error={formValidationState?.userEmail?.message}
							ref={(el: InputValidationRef) => (inputRefs.userEmail = el)}
						/>
					</div>
				</>
			)}
			<div className="divider"></div>
			<br />
			{/* <span className="errorMsg">{shareCertificateLetterDetails.errorMessage}</span> */}
			<Button
				style={{ marginRight: '0px', marginLeft: 'auto' }}
				label="Create"
				onClick={() => onCreateLette()}
				qaid="CreateShareCerficiate.Button.Create"
				isLoading={isLoading}
			/>
		</div>
	);
};

export default observer(CreateShareCertificate);
