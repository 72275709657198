import { observer } from 'mobx-react-lite';
import { forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { DataCollectionFieldTypeEnum } from '../../../../../../Models/API/DataCollection/comment-response';
import { DataCollectionInvestmentTypeEnum } from '../../../../../../Models/API/DataCollection/data-collection-investment-type-enum';
import DatePickerInput from '../../../../../../Shared/Components/Input/DatePickerInput';
import { TextAreaInput } from '../../../../../../Shared/Components/Input/Input.Style';
import NumberInput from '../../../../../../Shared/Components/Input/NumberInput';
import RadioButton from '../../../../../../Shared/Components/RadioButton/RadioButton';
import Spinner from '../../../../../../Shared/Components/Spinner/Spinner';
import useGeneralModal from '../../../../../../Shared/Hooks/useGeneralModal';
import { ForwardedRef } from '../../../../../../Shared/Hooks/useMultiStepForm';
import useRootStore from '../../../../../../Shared/Hooks/useRootStore';
import { isNumber } from '../../../../../../Shared/Utilities';
import { WaterfallStep } from '../../../../../Waterfall/Components/AddEditWaterfall/index.style';
import { DataCollectionPermissionsEnum, hasProjectPermissions } from '../../../../helpers/utils';
import { RefsType } from '../../../../types';
import Question from '../../../Question';
import DataCollectionGeneralInfoStyle from './index.style';
import Select from '../../../../../../Shared/Components/Select/Select';
import { IndustryEnum } from '../../../../../../Models/App/Valuation/industry-enum';
import { SectorEnum } from '../../../../../../Models/App/Valuation/sector-enum';
import { Industry } from '../../../../../../Models/API/Waterfall/IForm';
import { NumberOption } from '../../../../../../Models/API/All/NumberOption';
import { industriesLabels, sectorLabels } from '../../../../../Valution/utils';

const GeneralInfo = forwardRef<ForwardedRef, unknown>((_, forwardedRef) => {
	const { dataCollectionStore } = useRootStore();
	const data = dataCollectionStore.dataCollection;
	const isDisabled =
		!hasProjectPermissions(dataCollectionStore.currentRole, DataCollectionPermissionsEnum.UPDATE_PROJECT) || dataCollectionStore.isProjectDisabled;
	const questionsRef = useRef<RefsType>({} as RefsType);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const industryRef = useRef<Industry[]>();
	const { showErrorModal } = useGeneralModal();

	useEffect(() => {
		(async () => {
			setIsLoading(true);
			const res = await dataCollectionStore.getSectorAndIndstryList();
			industryRef.current = res.data?.industries;
			setIsLoading(false);
		})();
		return () => setIsLoading(false);
	}, []);

	useImperativeHandle(forwardedRef, () => ({
		onValidate: async () => {
			if (!data.valuationDate) {
				showErrorModal('Please select a valuation date first.', 'Data is missing');
				return false;
			}

			const promises = Object.keys(questionsRef.current || []).map((key) => {
				const typedKey = key as unknown as keyof RefsType;

				const comment = questionsRef.current?.[typedKey].getData();
				if (!comment || !dataCollectionStore.valuationProjectId) return;

				return dataCollectionStore.addComment({
					fieldType: +typedKey,
					commentDescription: comment,
					valuationProjectId: dataCollectionStore.valuationProjectId,
				});
			});

			await Promise.all([...promises, isDisabled ? undefined : dataCollectionStore.updateDataCollection()]);

			return true;
		},
	}));

	useEffect(() => {
		if (data?.investmentType === DataCollectionInvestmentTypeEnum.Equity) {
			dataCollectionStore.setDataCollection('nextRoundValuation', null);
		}
	}, [data?.investmentType]);

	useEffect(() => {
		dataCollectionStore.valuationProjectId && dataCollectionStore.getDataCollection(dataCollectionStore.valuationProjectId);
	}, [dataCollectionStore.valuationProjectId]);

	if (!data || isLoading)
		return (
			<WaterfallStep>
				<Spinner center incorporated />
			</WaterfallStep>
		);

	const industryOptions: NumberOption[] = [
		// {
		// 	label: 'All industries',
		// 	value: -1,
		// },
		...(industryRef.current?.map((industry) => ({
			value: IndustryEnum[industry.industry],
			label: industriesLabels[industry.industry],
		})) || []),
	];

	const sectorOptions: NumberOption[] =
		industryRef.current
			?.find((industry) => IndustryEnum[industry.industry] === data.industry)
			?.sectors?.map((sector) => ({
				value: SectorEnum[sector],
				label: sectorLabels[sector],
			})) || [];

	if (isLoading) {
		return (
			<WaterfallStep small>
				<Spinner incorporated center />
			</WaterfallStep>
		);
	}

	return (
		<WaterfallStep small className={DataCollectionGeneralInfoStyle}>
			<span className={`${DataCollectionGeneralInfoStyle}__title`}>General Info</span>
			<Question
				title="For which date does your company need the 409A valuation?"
				info=""
				renderActions={
					<DatePickerInput
						containerClassName={`${DataCollectionGeneralInfoStyle}__input-container`}
						qaid=""
						placeholder="Select..."
						value={data?.valuationDate ?? undefined}
						name="valuationDate"
						onChange={(value) => dataCollectionStore.setDataCollection('valuationDate', value ?? null, true)}
						disabled={isDisabled}
						maxDate={new Date()}
					/>
				}
				ref={(el) => {
					if (questionsRef.current && el) {
						questionsRef.current[DataCollectionFieldTypeEnum.ValuationDate] = el;
					}
				}}
				fieldType={DataCollectionFieldTypeEnum.ValuationDate}
			/>
			<Question
				title="Has your company raised money six months prior the valuation date?"
				info=""
				renderActions={
					<div className={`${DataCollectionGeneralInfoStyle}__radio-container`}>
						<RadioButton
							qaid=""
							name="investmentExist"
							value={true}
							checked={data.investmentExist === true}
							label="Yes"
							onChange={(value) => dataCollectionStore.setDataCollection('investmentExist', value)}
							disabled={isDisabled}
						/>
						<RadioButton
							qaid=""
							name="investmentExist"
							value={false}
							checked={data.investmentExist === false}
							label="No"
							onChange={(value) => dataCollectionStore.setDataCollection('investmentExist', value)}
							disabled={isDisabled}
						/>
					</div>
				}
				ref={(el) => {
					if (questionsRef.current && el) {
						questionsRef.current[DataCollectionFieldTypeEnum.InvestmentExist] = el;
					}
				}}
				fieldType={DataCollectionFieldTypeEnum.InvestmentExist}
			/>
			{data.investmentExist ? (
				<>
					<Question
						title="Was the money raised through?"
						info=""
						renderActions={
							<div className={`${DataCollectionGeneralInfoStyle}__radio-container`}>
								<RadioButton
									qaid=""
									name="investmentType"
									value={DataCollectionInvestmentTypeEnum.Equity}
									checked={data.investmentType === DataCollectionInvestmentTypeEnum.Equity}
									label="Issue shares"
									onChange={(value) => dataCollectionStore.setDataCollection('investmentType', value)}
									disabled={isDisabled}
								/>
								<RadioButton
									qaid=""
									name="investmentType"
									value={DataCollectionInvestmentTypeEnum.SAFE}
									checked={data.investmentType === DataCollectionInvestmentTypeEnum.SAFE}
									label="SAFE"
									onChange={(value) => dataCollectionStore.setDataCollection('investmentType', value)}
									disabled={isDisabled}
								/>
								<RadioButton
									qaid=""
									name="investmentType"
									value={DataCollectionInvestmentTypeEnum.ConvertibleLoan}
									checked={data.investmentType === DataCollectionInvestmentTypeEnum.ConvertibleLoan}
									label="Convertible loan"
									onChange={(value) => dataCollectionStore.setDataCollection('investmentType', value)}
									disabled={isDisabled}
								/>
							</div>
						}
						ref={(el) => {
							if (questionsRef.current && el) {
								questionsRef.current[DataCollectionFieldTypeEnum.InvestmentType] = el;
							}
						}}
						fieldType={DataCollectionFieldTypeEnum.InvestmentType}
					/>
					{isNumber(data.investmentType) &&
						[DataCollectionInvestmentTypeEnum.SAFE, DataCollectionInvestmentTypeEnum.ConvertibleLoan].includes(data.investmentType) && (
							<>
								<Question
									title="What was the total amount which was raised through SAFE/ Convertible debt?"
									info=""
									renderActions={
										<NumberInput
											qaid=""
											name="amountOfSafe"
											value={data.amountOfSafe ?? undefined}
											onChange={(value) => value !== undefined && dataCollectionStore.setDataCollection('amountOfSafe', value)}
											number="float"
											prefix="$"
											disabled={isDisabled}
										/>
									}
									ref={(el) => {
										if (questionsRef.current && el) {
											questionsRef.current[DataCollectionFieldTypeEnum.AmountOfSafe] = el;
										}
									}}
									fieldType={DataCollectionFieldTypeEnum.AmountOfSafe}
								/>
								<Question
									title="What is your estimation regarding the next round company valuation?"
									info=""
									renderActions={
										<NumberInput
											qaid=""
											name="estimationNextRound"
											value={data.estimationNextRound ?? undefined}
											onChange={(value) => value !== undefined && dataCollectionStore.setDataCollection('estimationNextRound', value)}
											number="float"
											placeholder="Type in years..."
											disabled={isDisabled}
										/>
									}
									ref={(el) => {
										if (questionsRef.current && el) {
											questionsRef.current[DataCollectionFieldTypeEnum.EstimationNextRound] = el;
										}
									}}
									fieldType={DataCollectionFieldTypeEnum.EstimationNextRound}
								/>
								<Question
									title="What is the target amount for the next funding round?"
									info=""
									renderActions={
										<NumberInput
											qaid=""
											name="amountOfNextRound"
											value={data.amountOfNextRound ?? undefined}
											onChange={(value) => value !== undefined && dataCollectionStore.setDataCollection('amountOfNextRound', value)}
											number="float"
											prefix="$"
											disabled={isDisabled}
										/>
									}
									ref={(el) => {
										if (questionsRef.current && el) {
											questionsRef.current[DataCollectionFieldTypeEnum.AmountOfNextRound] = el;
										}
									}}
									fieldType={DataCollectionFieldTypeEnum.AmountOfNextRound}
								/>
								<Question
									title="When is the next financial round anticipated?"
									info=""
									renderActions={
										<DatePickerInput
											qaid=""
											name="nextFinancialRoundDate"
											value={data.nextFinancialRoundDate ?? undefined}
											onChange={(value) => value !== undefined && dataCollectionStore.setDataCollection('nextFinancialRoundDate', value)}
											disabled={isDisabled}
										/>
									}
									ref={(el) => {
										if (questionsRef.current && el) {
											questionsRef.current[DataCollectionFieldTypeEnum.NextFinancialRoundDate] = el;
										}
									}}
									fieldType={DataCollectionFieldTypeEnum.NextFinancialRoundDate}
								/>
							</>
						)}
				</>
			) : data.investmentExist === false ? (
				<>
					<Question
						title="Has your company generated revenues in the last 12 months?"
						info=""
						renderActions={
							<div className={`${DataCollectionGeneralInfoStyle}__radio-container`}>
								<RadioButton
									qaid=""
									name="companyProfitable"
									value={true}
									checked={data.companyProfitable === true}
									label="Yes"
									onChange={(value) => dataCollectionStore.setDataCollection('companyProfitable', value)}
									disabled={isDisabled}
								/>
								<RadioButton
									qaid=""
									name="companyProfitable"
									value={false}
									checked={data.companyProfitable === false}
									label="No"
									onChange={(value) => dataCollectionStore.setDataCollection('companyProfitable', value)}
									disabled={isDisabled}
								/>
							</div>
						}
						ref={(el) => {
							if (questionsRef.current && el) {
								questionsRef.current[DataCollectionFieldTypeEnum.CompanyProfitable] = el;
							}
						}}
						fieldType={DataCollectionFieldTypeEnum.CompanyProfitable}
					/>
					{data.companyProfitable === true && (
						<Question
							title="Has your company generated operational profit in the last 12 months or projected to generate a profit in the next 1-2 years?"
							info=""
							renderActions={
								<div className={`${DataCollectionGeneralInfoStyle}__radio-container`}>
									<RadioButton
										qaid=""
										name="companyProfitableInFuture"
										checked={data.companyProfitableInFuture === true}
										value={true}
										label="Yes"
										onChange={(value) => dataCollectionStore.setDataCollection('companyProfitableInFuture', value)}
										disabled={isDisabled}
									/>
									<RadioButton
										qaid=""
										name="companyProfitableInFuture"
										checked={data.companyProfitableInFuture === false}
										value={false}
										label="No"
										onChange={(value) => dataCollectionStore.setDataCollection('companyProfitableInFuture', value)}
										disabled={isDisabled}
									/>
								</div>
							}
							ref={(el) => {
								if (questionsRef.current && el) {
									questionsRef.current[DataCollectionFieldTypeEnum.CompanyProfitable] = el;
								}
							}}
							fieldType={DataCollectionFieldTypeEnum.None}
						/>
					)}
					<Question
						title="What is the company effective tax rate?"
						info=""
						renderActions={
							<NumberInput
								qaid=""
								value={data.effectiveTaxRate ?? undefined}
								name="effectiveTaxRate"
								onChange={(value) => dataCollectionStore.setDataCollection('effectiveTaxRate', value ?? null)}
								containerClassName={`${DataCollectionGeneralInfoStyle}__input-container`}
								number="float"
								disabled={isDisabled}
								percentage
							/>
						}
						ref={(el) => {
							if (questionsRef.current && el) {
								questionsRef.current[DataCollectionFieldTypeEnum.EffectiveTaxRate] = el;
							}
						}}
						fieldType={DataCollectionFieldTypeEnum.EffectiveTaxRate}
					/>
					<Question
						title="Please provide the following accounting balance ($) as of the valuation date"
						info=""
						renderActions={
							<>
								<NumberInput
									qaid=""
									label="Cash"
									value={data.accountingBalanceCash ?? undefined}
									name="accountingBalanceCash"
									onChange={(value) => dataCollectionStore.setDataCollection('accountingBalanceCash', value ?? null)}
									containerClassName={`${DataCollectionGeneralInfoStyle}__input-container`}
									number="float"
									placeholder="Type..."
									disabled={isDisabled}
								/>
								<NumberInput
									qaid=""
									label="Loans & debts instruments"
									value={data.accountingBalanceLoans ?? undefined}
									name="effectiveTaxRate"
									onChange={(value) => dataCollectionStore.setDataCollection('accountingBalanceLoans', value ?? null)}
									containerClassName={`${DataCollectionGeneralInfoStyle}__input-container`}
									number="float"
									placeholder="Type..."
									disabled={isDisabled}
								/>
								<NumberInput
									qaid=""
									label="Leases"
									value={data.accountingBalanceLeases ?? undefined}
									name="accountingBalanceLeases"
									onChange={(value) => dataCollectionStore.setDataCollection('accountingBalanceLeases', value ?? null)}
									containerClassName={`${DataCollectionGeneralInfoStyle}__input-container`}
									number="float"
									placeholder="Type..."
									disabled={isDisabled}
								/>
							</>
						}
						ref={(el) => {
							if (questionsRef.current && el) {
								questionsRef.current[DataCollectionFieldTypeEnum.AccountingBalanceCash] = el;
							}
						}}
						fieldType={DataCollectionFieldTypeEnum.AccountingBalanceCash}
					/>
				</>
			) : (
				<></>
			)}
			<Question
				title="What’s the company estimation regarding the time in years for an exit (M&A, IPO) or other sell of the company assets?"
				info=""
				renderActions={
					<NumberInput
						qaid=""
						value={data.timeToLiquidity ?? undefined}
						name="timeToLiquidity"
						onChange={(value) => dataCollectionStore.setDataCollection('timeToLiquidity', value ?? null)}
						containerClassName={`${DataCollectionGeneralInfoStyle}__input-container`}
						number="float"
						placeholder="Type in years..."
						disabled={isDisabled}
					/>
				}
				ref={(el) => {
					if (questionsRef.current && el) {
						questionsRef.current[DataCollectionFieldTypeEnum.TimeToLiquidity] = el;
					}
				}}
				fieldType={DataCollectionFieldTypeEnum.None}
			/>
			<Question
				title="Are there any outstanding warrants?"
				info=""
				renderActions={
					<div className={`${DataCollectionGeneralInfoStyle}__radio-container`}>
						<RadioButton
							qaid=""
							name="isOutstandingWarrants"
							checked={data.isOutstandingWarrants === true}
							value={true}
							label="Yes"
							onChange={(value) => dataCollectionStore.setDataCollection('isOutstandingWarrants', value)}
							disabled={isDisabled}
						/>
						<RadioButton
							qaid=""
							name="isOutstandingWarrants"
							checked={data.isOutstandingWarrants === false}
							value={false}
							label="No"
							onChange={(value) => dataCollectionStore.setDataCollection('isOutstandingWarrants', value)}
							disabled={isDisabled}
						/>
					</div>
				}
				ref={(el) => {
					if (questionsRef.current && el) {
						questionsRef.current[DataCollectionFieldTypeEnum.IsOutstandingWarrants] = el;
					}
				}}
				fieldType={DataCollectionFieldTypeEnum.IsOutstandingWarrants}
			/>
			{data.isOutstandingWarrants && (
				<Question
					title="Were the warrants actually granted?"
					info=""
					renderActions={
						<div className={`${DataCollectionGeneralInfoStyle}__radio-container`}>
							<RadioButton
								qaid=""
								name="isWarrantsGranted"
								checked={data.isWarrantsGranted === true}
								value={true}
								label="Yes"
								onChange={(value) => dataCollectionStore.setDataCollection('isWarrantsGranted', value)}
								disabled={isDisabled}
							/>
							<RadioButton
								qaid=""
								name="isWarrantsGranted"
								checked={data.isWarrantsGranted === false}
								value={false}
								label="No"
								onChange={(value) => dataCollectionStore.setDataCollection('isWarrantsGranted', value)}
								disabled={isDisabled}
							/>
						</div>
					}
					ref={(el) => {
						if (questionsRef.current && el) {
							questionsRef.current[DataCollectionFieldTypeEnum.IsWarrantsGranted] = el;
						}
					}}
					fieldType={DataCollectionFieldTypeEnum.IsWarrantsGranted}
				/>
			)}
			{data.isWarrantsGranted && (
				<Question
					title="Indicate which share class the warrants relate to (e,g., Ordinary, Preferred A)."
					info=""
					renderActions={
						<TextAreaInput
							data-qaid=""
							value={data.shareClassIndication ?? undefined}
							onChange={(e) => dataCollectionStore.setDataCollection('shareClassIndication', e.target.value)}
							style={{ resize: 'none', height: '100%', width: '100%' }}
							rows={2}
							maxLength={500}
							placeholder="Type text here..."
							name=""
							disabled={isDisabled}
						/>
					}
					ref={(el) => {
						if (questionsRef.current && el) {
							questionsRef.current[DataCollectionFieldTypeEnum.ShareClassIndication] = el;
						}
					}}
					fieldType={DataCollectionFieldTypeEnum.None}
				/>
			)}
			<Question
				title="Has the company had any secondary transactions?"
				info=""
				renderActions={
					<div className={`${DataCollectionGeneralInfoStyle}__radio-container`}>
						<RadioButton
							qaid=""
							name="isSecondaryTransaction"
							checked={data.isSecondaryTransaction === true}
							value={true}
							label="Yes"
							onChange={(value) => dataCollectionStore.setDataCollection('isSecondaryTransaction', value)}
							disabled={isDisabled}
						/>
						<RadioButton
							qaid=""
							name="isSecondaryTransaction"
							checked={data.isSecondaryTransaction === false}
							value={false}
							label="No"
							onChange={(value) => dataCollectionStore.setDataCollection('isSecondaryTransaction', value)}
							disabled={isDisabled}
						/>
					</div>
				}
				ref={(el) => {
					if (questionsRef.current && el) {
						questionsRef.current[DataCollectionFieldTypeEnum.IsSecondaryTransaction] = el;
					}
				}}
				fieldType={DataCollectionFieldTypeEnum.IsSecondaryTransaction}
			/>
			<Question
				title="Please select in which sector and industry your company operates"
				info=""
				renderActions={
					<div className={`${DataCollectionGeneralInfoStyle}__select-container`}>
						<Select
							label="Industry"
							qaid=""
							value={data.industry}
							options={industryOptions}
							onChange={(value) => isNumber(value) && dataCollectionStore.setDataCollection('industry', value)}
							disabled={isDisabled}
						/>
						<Select
							label="Sector"
							qaid=""
							value={data.sector}
							options={sectorOptions}
							onChange={(value) => isNumber(value) && dataCollectionStore.setDataCollection('sector', value)}
							disabled={isDisabled}
						/>
					</div>
				}
				ref={(el) => {
					if (questionsRef.current && el) {
						questionsRef.current[DataCollectionFieldTypeEnum.SectorandIndustry] = el;
					}
				}}
				fieldType={DataCollectionFieldTypeEnum.SectorandIndustry}
			/>
			<Question
				title="Add a link to your company About Us page or write a short description (Maximum 500 characters)"
				info=""
				renderActions={
					<TextAreaInput
						data-qaid=""
						value={data.aboutUs ?? undefined}
						onChange={(e) => dataCollectionStore.setDataCollection('aboutUs', e.target.value)}
						style={{ resize: 'none', height: '100%', width: '100%' }}
						rows={4}
						maxLength={500}
						placeholder="Type text here..."
						name=""
						disabled={isDisabled}
					/>
				}
				fieldType={DataCollectionFieldTypeEnum.None}
			/>
		</WaterfallStep>
	);
});

export default observer(GeneralInfo);
