import { css } from '@emotion/css';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import appConfig from '../../../../../../config/config';
import { PoolStep } from '../../../../../../Models/API/EquityPlans/pool-step';
import AddButton from '../../../../../../Shared/Components/Button/AddButton';
import { ModalBodyProps } from '../../../../../../Shared/Components/Modal/types';
import Table, { TableColumn } from '../../../../../../Shared/Components/Table/Table';
import useModal from '../../../../../../Shared/Hooks/useModal';
import useRootStore from '../../../../../../Shared/Hooks/useRootStore';
import { formatDate, formatNumber } from '../../../../../../Shared/Utilities';
import AddPoolStep from './AddPoolStep';
import PoolStepsHistory from './PoolStepsHistory';

type Props = {
	planId: number;
	approvalDate: Date;
} & ModalBodyProps;

const TitleStyle = css({
	// marginBottom: '0.4rem',
	display: 'flex',
	justifyContent: 'space-between',
	color: appConfig.style.colors.text2,
	fontSize: '1.3rem',
	alignItems: 'flex-end',

	'@media (-webkit-device-pixel-ratio: 1.5)': {
		fontSize: '1.4rem',
	},

	'&__info': {
		width: '1.8rem',
	},

	'> span': {
		flex: 1,
		textAlign: 'initial',
	},
});

const TableStyle = css({
	width: '100%',
	'&__actions': {
		marginTop: 0,
	},
});

const FormStyle = css({
	display: 'grid',
	gridTemplateColumns: 'repeat(3, 1fr)',
	gridRowsColumns: 'repeat(3, 1fr)',
	gridColumnGap: '2.4rem',
	gridRowGap: '1.6rem',
	'&__comment': { gridArea: '1 / 3 / 4 / 4' },
	'&__buttons': { gridArea: '4 / 3 / 4 / 4' },
	'&__totalPool': { gridArea: '2 / 2 / 4 / 2' },
});

const PoolStepsTable = ({ planId, hideModal, displayModal, approvalDate }: Props) => {
	const { equityPlansStore } = useRootStore();
	const { showModal } = useModal();

	useEffect(() => {
		equityPlansStore.getPlanPoolSteps(planId);
	}, [planId]);

	const columns: TableColumn<PoolStep>[] = [
		{
			name: 'stepDate',
			label: 'Date',
			format: (val) => formatDate(val),
		},
		{
			name: 'totalPool',
			label: 'Total pool',
			format: (val) => formatNumber(val),
		},
		{
			name: 'comment',
			label: 'Comment',
		},
	];

	const totalPool = equityPlansStore.poolSteps?.[0].totalPool ?? 0;

	const onShowHistory = () => {
		if (!equityPlansStore.poolSteps) return;

		hideModal?.();
		showModal({
			body: <PoolStepsHistory planId={planId} approvalDate={approvalDate} />,
			onModalClose: displayModal,
			width: '100rem',
			height: '60rem',
		});
	};

	return (
		<div className="flex flex-column w-100 flex-start gap-1">
			<Table
				rows={equityPlansStore.poolSteps?.slice(0, 1)}
				columns={columns}
				className={TableStyle}
				headerSize={1}
				customHeaderRender={<div className={TitleStyle}>Pool size</div>}
				customActionsRender={
					<span className="link" onClick={onShowHistory}>
						History
					</span>
				}
			/>
			{equityPlansStore.isAddingPoolStep ? (
				<AddPoolStep planId={planId} className={FormStyle} totalPool={totalPool} approvalDate={approvalDate} />
			) : (
				<AddButton
					label="Increase/Decrease pool"
					qaid="PoolStepsList.Button.AddPoolStep"
					onClick={() => (equityPlansStore.isAddingPoolStep = true)}
					width="fit-content"
				/>
			)}
		</div>
	);
};

export default observer(PoolStepsTable);
