import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import useRootStore from "../../Shared/Hooks/useRootStore";
import Templates from "./Templates";
import { css } from "@emotion/css";
import Tabs from "./Components/Tabs";
import Signatures from "./Signatures";
import useQueryParams from '../../Shared/Hooks/useQueryParams';
import { useParams } from 'react-router-dom';
import { DocumentsTab } from '../../Store/DocumentsStore';

const Style = css({
	label: 'Documents',
	padding: '4rem 10rem',
});

const checkIfTabIsValid = (tab: unknown): tab is DocumentsTab => {
	if (typeof tab !== 'string') return false;
	const validTabs: DocumentsTab[] = ['signatures', 'templates'];
	return validTabs.includes(tab as DocumentsTab);
};

const Documents = () => {
	const { companyStore, documentsStore } = useRootStore();
	const params = useQueryParams<'tab', DocumentsTab>();

	useEffect(() => {
		documentsStore.getTemplates();
	}, [companyStore.companyId]);

	useEffect(() => {
		if (checkIfTabIsValid(params.tab)) {
			documentsStore.setSelectedTab(params.tab);
		}
	}, [params.tab]);

	return <section className={Style}>{documentsStore.selectedTab === 'signatures' ? <Signatures /> : <Templates />}</section>;
};

export default observer(Documents);
