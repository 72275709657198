import { useRef, useState } from "react";
import { observer } from "mobx-react-lite";

import useRootStore from "../../../../../../Shared/Hooks/useRootStore";
import Button from "../../../../../../Shared/Components/Button/Button";
import NewVestStyles from "./NewVestingSchedule.style";
import { IManualVestingSchedule, IVestStep, IVestingSchedule, RoundingFormat, VestingFormat } from "../../../../../../Models/App/EquityPlans/Company";
import { isNullOrUndefined, isNumber } from "../../../../../../Shared/Utilities";
import Flex from "../../../../../../Shared/Components/Layout/Flex";
import TextInput from "../../../../../../Shared/Components/Input/TextInput";
import Text from "../../../../../../Shared/Components/Layout/Text";
import { InputRefs, InputValidationRef, useFormValidation } from "../../../../../../Shared/Hooks/useFormValidation";
import { commonValidators } from "../../../../../../Shared/ObjectValidator";
import { MultiStepElementProps } from "../../../../../../Shared/Hooks/useMultiStepForm";
import { StepDivider, StepTitle } from "../../../Registration/Registration.Style";
import VestingStep from "./VestingStep";
import { NumberOption } from "../../../../../../Models/API/All/NumberOption";
import Select from "../../../../../../Shared/Components/Select/Select";
import { CompanyRegistrationEnum } from "../../../Registration/Registration";

const roundTypes: NumberOption[] = [
	{ value: RoundingFormat.Up, label: "Up" },
	{ value: RoundingFormat.Down, label: "Down" },
	{ value: RoundingFormat.Standard, label: "Standard" },
];

const stepFormatTypes: NumberOption[] = [
	{ value: VestingFormat.GroupRemindersplaceonLast, label: "Group Reminders place on Last" },
	{ value: VestingFormat.GroupRemindersplaceonfirst, label: "Group Reminders place on First" },
	{ value: VestingFormat.StandardGiveWholeShare, label: "Standard Give Whole Share" },
	{ value: VestingFormat.SplitReminderUpForntVestings, label: "Split Reminder Up Fornt Vestings" },
];

interface IProps extends MultiStepElementProps {
	vestingSchedule?: IVestingSchedule;
	onSave?: () => any
}

const VestingSchedule = (props: IProps) => {
	const { equityPlansStore } = useRootStore();

	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [stepsList, setStepsList] = useState<IVestStep[]>([]);
	const [totalPercent, setTotalPercent] = useState<number>(0);
	const [isStepOnEdit, setIsStepOnEdit] = useState<boolean>(false);
	const [isStepHasError, setIsStepHasError] = useState<boolean>(false);

	const inputRefs = useRef<InputRefs<Partial<IManualVestingSchedule>>>({} as InputRefs<Partial<IManualVestingSchedule>>);

	const vest = equityPlansStore.newVestSchedule;

	const { validateForm, formValidationState } = useFormValidation({
		form: vest,
		schema: {
			vestingName: [commonValidators.required()],
			roundingMethod: [commonValidators.required()],
			vestingFormat: [commonValidators.required()],
		},
		refs: inputRefs.current,
	});

	const onStepHasError = (hasError: boolean) => {
		setIsStepHasError(hasError);
	};

	const onInputHandler = (value: string | number | undefined | boolean, name: string | undefined) => {
		if (isNullOrUndefined(name)) return;

		equityPlansStore.newVestSchedule = {
			...equityPlansStore.newVestSchedule,
			[name]: value,
		};
	};

	const onChangeTotalPrecent = (value: number) => {
		setTotalPercent((prevState) => prevState + value);
	};

	const onAddStep = (vestStep: IVestStep) => {
		onChangeTotalPrecent(vestStep.percent);
		setStepsList((prevState) => [...prevState, vestStep]);
	};

	const onRemoveStep = (vestStepId: string) => {
		const selectedVestStep = stepsList.find((step) => step.id === vestStepId);
		if (isNullOrUndefined(selectedVestStep)) return;

		onChangeTotalPrecent(-selectedVestStep.percent);
		setStepsList((prevState) => prevState.filter((step) => step.id !== vestStepId));
	};

	const onEditStep = (vestStep: IVestStep) => {
		if (isNullOrUndefined(vestStep.id)) return;

		const selectedVestStep = stepsList.find((step) => step.id === vestStep.id);
		if (isNullOrUndefined(selectedVestStep)) return;

		setIsStepOnEdit(true);
		onChangeTotalPrecent(-selectedVestStep.percent);
	};

	const onSaveStep = (vestStep: IVestStep) => {
		if (isNullOrUndefined(vestStep.id)) return;

		const selectedVestStep = stepsList.find((step) => step.id === vestStep.id);
		if (isNullOrUndefined(selectedVestStep)) return;

		setIsStepOnEdit(false);
		onChangeTotalPrecent(vestStep.percent);
		setStepsList((prevState) => prevState.map((step) => (step.id === vestStep.id ? vestStep : step)));
	};

	const addVestSchedHandler = async () => {
		if (!validateForm()) return;
		setIsLoading(true);


		vest.vestingSteps = stepsList.map(({ id, ...rst }, idx) => ({ ...rst, stepIndex: idx + 1 })) as IVestStep[];
		const res = await equityPlansStore.AddVestingSchedule();
		console.log("​addVestSchedHandler -> res", res)

		if (!isNullOrUndefined(res.data?.vestingId)) {
			setIsLoading(false);
			equityPlansStore.getEquitySettings();
			props.onSave && props.onSave()
			props.goTo?.(CompanyRegistrationEnum.vestingScheduleTemplates);
		}
		setIsLoading(false);
	};

	return (
		<div className={NewVestStyles}>
			<span className={StepTitle}>Vesting Schedule Template</span>
			<span className={`${StepTitle}__subTitle`}>Vesting schedule settings</span>
			<hr className={StepDivider} />
			<Flex direction="column" align="start" margin="0 0 5px 0" width={"fit-content"}>
				<Flex justify="start" gap={30}>
					<TextInput
						containerClassName="mb-0 input"
						label="Vesting schedule name"
						qaid="NewVestingSchedule.Input.Name"
						name="vestingName"
						value={vest.vestingName}
						onChange={onInputHandler}
						error={formValidationState?.vestingName?.message}
						ref={(el: InputValidationRef) => (inputRefs.current.vestingName = el)}
					/>
					<Select
						className="mb-0 input"
						label="Round Method"
						qaid="NewVestingSchedule.Select.RoundMethod"
						options={roundTypes}
						value={vest?.roundingMethod}
						name="roundingMethod"
						onChange={(value, name) => isNumber(value) && onInputHandler(value, name!)}
						error={formValidationState?.roundingMethod?.message}
						ref={(el: InputValidationRef) => (inputRefs.current.roundingMethod = el)}
					/>
					<Select
						className="mb-0 wide-input"
						label="Vesting Format"
						qaid="NewVestingSchedule.Select.VestingFormat"
						options={stepFormatTypes}
						value={vest?.vestingFormat}
						name="vestingFormat"
						onChange={(value, name) => isNumber(value) && onInputHandler(value, name!)}
						error={formValidationState?.vestingFormat?.message}
						ref={(el: InputValidationRef) => (inputRefs.current.vestingFormat = el)}
					/>
				</Flex>
			</Flex>
			<hr className={StepDivider} />

			<Text className="subTitle">Enter the vesting tranches</Text>
			<div className="stepsContainer">
				<div className="innerStepsContainer">
					{[...stepsList].reverse().map((step, idx) => (
						<VestingStep
							key={idx}
							step={step}
							totalPercent={totalPercent}
							onAddStep={onAddStep}
							onRemoveStep={onRemoveStep}
							onEditStep={onEditStep}
							onSaveStep={onSaveStep}
							onChangeTotalPrecent={onChangeTotalPrecent}
							onStepHasError={onStepHasError}
						/>
					))}
					{totalPercent !== 100 && stepsList.length >= 0 && !isStepOnEdit && !isStepHasError && (
						<VestingStep
							step={{ id: undefined } as IVestStep}
							totalPercent={totalPercent}
							onAddStep={onAddStep}
							onRemoveStep={onRemoveStep}
							onEditStep={onEditStep}
							onSaveStep={onSaveStep}
							onChangeTotalPrecent={onChangeTotalPrecent}
							onStepHasError={onStepHasError}
						/>
					)}
				</div>
			</div>

			<hr className={StepDivider} />

			<Flex justify="end" gap="1rem" margin="1rem 0 0 0">
				<Button qaid="Registration.Button.Back" className={"buttonsContainer__button"} onClick={props.onPrevStep} cancel>
					Back
				</Button>
				<Button
					label="Add Vesting Schedule"
					qaid="NewVestingSchedule.Button.AddVestingSchedule"
					onClick={addVestSchedHandler}
					isLoading={isLoading}
					disabled={totalPercent !== 100}
				/>
			</Flex>
		</div>
	);
};
export default observer(VestingSchedule);
